<template>
  <div>
    <div class="tab-container">
      <header class="d-flex justify-content-center py-3">
        <ul class="nav nav-pills nav-fill">
          <li class="nav-item">
            <router-link :to="`${parentRoute}`" class="nav-link" :class="endsWith(parentRoute)">
              Information
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`${parentRoute}/linked-businesses`"
              class="nav-link"
              :class="includes('linked-businesses')"
            >
              Linked Businesses
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="`${parentRoute}/trades`" class="nav-link" :class="includes('trades')">
              Trades
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`${parentRoute}/compliance`"
              class="nav-link"
              :class="includes('compliance')"
            >
              Compliance
            </router-link>
          </li>
          <li v-if="isBackOfficeAccountManager" class="nav-item">
            <router-link
              :to="`${parentRoute}/retrofit-account`"
              class="nav-link"
              :class="includes('retrofit-account')"
            >
              Retrofit Account
            </router-link>
          </li>
        </ul>
      </header>
    </div>
    <div class="p-3"></div>
  </div>
</template>

<script>
export default {
  name: 'LicenceNumberViewTabs',
  computed: {
    parentRoute() {
      return `/tmlns/${this.$route.params.tmBusinessId}`;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isBackOfficeAccountManager() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('BackOfficeAccountManager')
        : false;
    },
  },
  methods: {
    includes(str) {
      return this.$route.path.includes(str) ? 'active' : '';
    },
    endsWith(str) {
      return this.$route.path.endsWith(str) ? 'active' : '';
    },
  },
};
</script>
