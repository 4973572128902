import { HTTP, authHeader } from './http-service';

class TradeService {
  list() {
    return HTTP.get(`/trade/`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  get(tradeCode) {
    return HTTP.get(`/trade/${tradeCode}`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  delete(tradeId) {
    return HTTP.delete(`/trade/${tradeId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/trade`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  create(payload) {
    return HTTP.post(`/trade`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }
}

export default new TradeService();
