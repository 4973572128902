import { createApp } from 'vue';
import mitt from 'mitt';
import axios from 'axios';
import App from './App.vue';
import { FontAwesomeIcon } from './plugins/font-awesome';
import router from './router';
import store from './store';
import helpers from './plugins/helpers';

const emitter = mitt();

const app = createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .use(helpers)
  .use(store);

app.config.globalProperties.emitter = emitter;

app.mount('#app');

// Version Handling
if (import.meta.env.PROD) {
  const versionDatetime = '__BUILDDATETIME__'; // String replaced by Vite
  const setupVersionChecker = () => {
    let reloading = false;
    const check = async () => {
      if (reloading) return;
      try {
        const { data } = await axios.get('version.json', {
          baseURL: '/',
          params: { t: new Date().getTime() },
        });
        const currentVersion = Date.parse(versionDatetime);
        const nextVersion = Date.parse(data.datetime);
        if (nextVersion > currentVersion) {
          reloading = true;
          window.location.reload(true);
        }
      } catch {
        // Nothing
      }
    };
    // Check on visibility change
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        check();
      }
    });
    // Check every five minutes
    setInterval(check, 300000);
  };

  setupVersionChecker();
}
