<template>
  <div class="mb-4">
    <div v-if="message" class="mt-2 alert alert-info">
      {{ message }}
    </div>
    <div class="btn-toolbar mb-4 mb-md-4">
      <div class="btn-group me-2">
        <a
          v-if="isApplicationEditor"
          href="https://www.trustmark.org.uk/business/information-and-guidance/join-trustmark"
          class="btn btn-primary"
          target="_blank"
          rel="noopener noreferrer"
        >
          Register
        </a>
        <button type="button" class="btn" :class="getCssClass" @click="$emit('advanced-search')">
          Advanced Search
        </button>
        <button type="button" class="btn btn-danger" @click="archive">
          Archive Old Applications
        </button>
      </div>
    </div>
    <div v-if="funnelData" class="text-center mb-5">
      <vue3-chart-js
        :id="chart.id"
        ref="chartRef"
        :type="chart.type"
        :data="chart.data"
        :options="chart.options"
        class="w-100"
        style="height: 200px"
      ></vue3-chart-js>
      <small>Click a section to filter by Status, re-click to clear the filter.</small>
    </div>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core';
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs';
import { helpers, required, email } from '@vuelidate/validators';

export default {
  name: 'ApplicationsToolbar',
  components: {
    Vue3ChartJs,
  },
  emits: ['advanced-search', 'filter-status'],
  data() {
    return {
      v$: useValidate(),
      email: '',
      password: '',
      loading: false,
      message: '',
      advancedSearchIsOpen: false,
      chartFilter: null,
      colours: [
        '#198754',
        '#20c997',
        '#ffc107',
        '#fd7e14',
        '#dc3545',
        '#d63384',
        '#6f42c1',
        '#6610f2',
        '#0d6efd',
      ],
    };
  },
  computed: {
    getCssClass() {
      return this.advancedSearchIsOpen ? 'btn-primary' : 'btn-outline-secondary';
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isApplicationEditor() {
      if (this.currentAccount && this.currentAccount.roles) {
        return this.currentAccount.roles.includes('ApplicationEditor');
      }
      return false;
    },
    funnelData() {
      return this.$store.state.application.funnelData;
    },
    chart() {
      return {
        id: 'statusCountChart',
        type: 'bar',
        data: {
          labels: ['Count'],
          datasets: [],
        },
        options: {
          animation: false,
          onClick: (e) => {
            const area = e.chart.getElementsAtEventForMode(
              e,
              'nearest',
              {
                intersect: true,
              },
              false
            );
            const newFilter = e.chart.data.datasets[area[0].datasetIndex].label[area[0].index];
            if (newFilter === this.chartFilter) {
              this.chartFilter = null;
            } else {
              this.chartFilter = newFilter;
            }
            e.chart.data.datasets = this.setChartData();
            this.advancedSearch();
          },
          plugins: {
            title: {
              display: true,
              text: 'Applications by Status',
            },
          },
          responsive: true,
          indexAxis: 'y',
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
      };
    },
  },
  mounted() {
    this.emitter.on('toggle-advanced-search-applications', (isOpen) => {
      this.advancedSearchIsOpen = isOpen;
    });
    if (!this.funnelData || this.funnelData.length === 0) {
      this.$store.dispatch('application/funnel').then(() => {
        this.setChartData();
      });
    } else {
      this.setChartData();
    }
  },
  methods: {
    advancedSearch() {
      this.loading = true;
      const payload = {
        email: null,
        lastName: null,
        firstName: null,
        registeredCompanyNumber: null,
        registeredCompanyName: null,
        registeredAddressLine1: null,
        responsiblePersonAddressPostcode: null,
        applicationReference: null,
        tradingStandardsRagRating: null,
        tradingStandardsWorkflowStatus: null,
        applicationStatus: this.$store.state.auth.appData.applicationStatuses?.find(
          (x) => x.description === this.chartFilter
        )?.status,
        schemeId: null,
        pageParameters: {
          size: 10,
          number: 0,
        },
        sort: {
          item: 'registeredCompanyName',
          direction: 'ascending',
        },
        filterType: 0,
      };

      this.$store
        .dispatch('application/searchAdvanced', payload)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    archive() {
      this.$store.dispatch('application/archive').then((resp) => {
        this.$store.dispatch('application/executeLastSearch');
        this.message = resp.message;
      });
    },
    setChartData() {
      this.$refs.chartRef.data.datasets = this.funnelData.map((x, i) => {
        return {
          label: [x.item1],
          backgroundColor:
            !this.chartFilter || this.chartFilter === x.item1 ? this.colours[i] : '#eeeeee',
          data: [x.item2],
        };
      });
      this.$refs.chartRef.update(0);
    },
  },
  validations: {
    email: {
      isRequired: helpers.withMessage('Email address is required', required),
      isEmail: helpers.withMessage('Must be a valid email address', email),
    },
    password: {
      isRequired: helpers.withMessage('Password is required', required),
    },
  },
};
</script>
