import { HTTP, authHeader } from './http-service';

class TradingStandardsAdminService {
  listYear(yearNumber) {
    return HTTP.get(`/TradingStandardsYear/renewalYear/${yearNumber}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  listCurrent() {
    return HTTP.get(`/TradingStandardsYear/listCurrent`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getTradingStandardsYear(tmln, renewalYear) {
    return HTTP.get(`/TradingStandardsYear/${tmln}/${renewalYear}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getFunnelData() {
    return HTTP.get(`/TradingStandardsYear/funnel`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getFunnelDataForYear(yearNumber) {
    return HTTP.get(`/TradingStandardsYear/funnel/${yearNumber}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  createTradingStandardsYear(payload) {
    return HTTP.post(`/TradingStandardsYear`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  updateTradingStandardsYear(payload) {
    return HTTP.put(`/TradingStandardsYear`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new TradingStandardsAdminService();
