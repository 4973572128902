import FunderUserService from '../services/funder-user-service';

export const funderUser = {
  namespaced: true,
  state: {
    users: null,
    user: null,
  },
  actions: {
    get({ commit }, funderId) {
      commit('setIsBusy', true, { root: true });
      return FunderUserService.get(funderId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUsers', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    newUser({ commit }) {
      const userTemplate = JSON.parse(JSON.stringify({}));
      commit('setUser', userTemplate);
    },
    byEmail({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return FunderUserService.byEmail(payload.funderId, payload.accountId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUser', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          commit('setUser', null);
          return Promise.reject(error);
        });
    },
    invite({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return FunderUserService.invite(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUser', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    resendInvite({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return FunderUserService.resendInvite(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUser', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    enable({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return FunderUserService.enable(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUser', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    disable({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return FunderUserService.disable(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUser', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setUsers(state, newUsers) {
      state.users = newUsers;
    },
    setUser(state, newUser) {
      state.user = newUser;
    },
  },
};
