<template>
  <div v-if="model">
    <div class="col-sm-2 float-right">
      <router-link :to="`/scheme/${schemeBusiness.schemeId}`">
        <scheme-logo
          :imagekey="schemeImageKeyFromId(schemeBusiness.schemeId)"
          style="height: 75px !important; border: 0; background: transparent"
        />
      </router-link>
    </div>
    <div class="d-flex align-items-center">
      <h1>
        View <span v-if="model">{{ schemeBusiness.registeredCompanyName }}</span>
      </h1>
      <div class="ms-3">
        <h5 v-if="isActive">
          <span class="badge bg-success">Active</span>
        </h5>
        <h5 v-if="!isActive && !isSubmitted">
          <span class="badge bg-danger">Inactive</span>
          <span v-if="schemeBusiness.deregisteredReason" class="ms-1 badge bg-danger">{{
            schemeBusiness.deregisteredReason
          }}</span>
        </h5>
        <h5 v-if="isSubmitted">
          <span class="badge bg-warning">Submitted</span>
        </h5>
      </div>
      <h5 v-if="isPendingLicence" class="ms-1">
        <span class="badge bg-warning">Pending Licence</span>
      </h5>
      <h5 v-if="isAdminPendingLicence" class="ms-1">
        <span class="badge bg-warning">Admin Pending Licence</span>
      </h5>
    </div>
    <h4 v-if="schemeBusiness.tmln">
      <router-link :to="`/tmlns/${schemeBusiness.trustmarkBusinessId}`"
        >Licence Number {{ schemeBusiness.tmln }}</router-link
      >
    </h4>
  </div>
  <tabs class="mt-3" />
  <router-view :v="v$" />
</template>

<script>
import { SchemeLogo } from '@trustmark-2005-ltd/trustmark-shared-components';
import Tabs from './SchemeBusinessTabs.vue';

export default {
  name: 'SchemeBusinessRouter',
  components: {
    Tabs,
    SchemeLogo,
  },
  computed: {
    model() {
      return this.$store.state.schemeBusiness.business;
    },
    schemeBusiness() {
      return this.model.schemeBusiness;
    },
    isActive() {
      return this.schemeBusiness.status === 4;
    },
    isSubmitted() {
      return this.schemeBusiness.status === 3;
    },
    isPendingLicence() {
      return this.schemeBusiness.status === 8;
    },
    isAdminPendingLicence() {
      return (
        this.schemeBusiness.linkPending !== null &&
        this.schemeBusiness.linkPending.isAdminRequest === true
      );
    },
  },
  mounted() {
    this.$store.dispatch('schemeBusiness/get', {
      schemeId: this.$route.params.schemeId,
      schemeBusinessId: this.$route.params.schemeBusinessId,
    });
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  },
  methods: {
    schemeImageKeyFromId(schemeId) {
      const scheme = this.$store.state.auth.appData.schemes.find((x) => x.schemeId === schemeId);
      return scheme.schemeLogoImageKey || scheme.logoImage;
    },
  },
};
</script>
