import ApplicationService from '../services/application-service';

export const application = {
  namespaced: true,
  state: {
    applications: null,
    application: null,
    searchResult: null,
    pageNumber: 0,
    lastSearchConfig: null,
    funnelData: null,
  },
  actions: {
    list({ commit }, applicationRef) {
      commit('setIsBusy', true, { root: true });
      return ApplicationService.list(applicationRef)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setApplications', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    get({ commit }, applicationRef) {
      commit('setIsBusy', true, { root: true });
      commit('setApplication', null);
      return ApplicationService.get(applicationRef).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setApplication', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    funnel({ commit }) {
      commit('setIsBusy', true, { root: true });
      commit('setFunnel', null);
      return ApplicationService.funnel().then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setFunnel', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    executeLastSearch({ dispatch, state, commit }) {
      commit('setIsBusy', true, { root: true });
      if (!state.lastSearchConfig) {
        commit('setIsBusy', false, { root: true });
        throw new Error('No application search has been run yet - cannot rerun');
      }
      dispatch('search', state.lastSearchConfig).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    search({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      data.type = 'standard';
      commit('setLastSearchConfig', data);
      return ApplicationService.search(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setApplicationSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    archive({ commit }) {
      commit('setIsBusy', true, { root: true });
      return ApplicationService.archive().then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    searchAdvanced({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      data.type = 'advanced';
      commit('setLastSearchConfig', data);
      return ApplicationService.advancedSearch(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setApplicationSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    export({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return ApplicationService.export(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateTradingStandardsResponse({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      commit('setLastSearchConfig', data);
      return ApplicationService.updateTradingStandardsResponse(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setApplicationSearchResult', null);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateApplication({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return ApplicationService.updateApplication(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          commit('setApplication', result.model);
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    useExistingTMLN({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return ApplicationService.useExistingTMLN(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setApplication', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setApplications(state, newApplications) {
      state.applications = newApplications;
    },
    setApplication(state, newApplication) {
      state.application = newApplication;
    },
    setFunnel(state, newFunnel) {
      state.funnelData = newFunnel;
    },
    setApplicationSearchResult(state, searchResult) {
      state.searchResult = searchResult;
    },
    setLastSearchConfig(state, searchconfig) {
      state.lastSearchConfig = searchconfig;
    },
    setSearchPageNumber(state, newPageNumber) {
      state.pageNumber = newPageNumber;
    },
  },
};
