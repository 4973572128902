import TradeService from '../services/trade-service';

export const trade = {
  namespaced: true,
  state: {
    trades: null,
    trade: null,
  },
  actions: {
    initialise({ commit, rootState }, suggestedTradeCode) {
      const template = rootState.auth.appData.models.trade;
      const empty = JSON.parse(JSON.stringify(template));
      empty.tradeCode = suggestedTradeCode;
      commit('setTrade', empty);
    },
    list({ commit }, tradeId) {
      commit('setIsBusy', true, { root: true });
      return TradeService.list(tradeId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setTrades', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    create({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return TradeService.create(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    get({ commit }, tradeId) {
      commit('setIsBusy', true, { root: true });
      commit('setTrade', null);
      return TradeService.get(tradeId).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setTrade', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    update({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return TradeService.update(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    delete({ commit }, tradeId) {
      commit('setIsBusy', true, { root: true });
      return TradeService.delete(tradeId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setTrades(state, newTrades) {
      state.trades = newTrades;
    },
    setTrade(state, newTrade) {
      if (newTrade && newTrade.inactiveDateFrom) {
        newTrade.inactiveDateFrom = new Date(newTrade.inactiveDateFrom);
      }
      state.trade = newTrade;
    },
  },
};
