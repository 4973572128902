<template>
  <subtitle>Trades</subtitle>
  <div class="col-sm-12 col-md-10">
    <p>The trades displayed here are the same that are available to lodge work with.</p>

    <div v-if="!schemeTrades && model.schemes">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="schemeTrades">
      <label class="p-1" style="font-weight: 700"
        >{{ filteredSchemeTradesCount }}

        <span v-if="filteredSchemeTradesCount === 1">Trade</span>
        <span v-else>Trades</span>

        <span v-if="filteredSchemeTradesCount != schemeTradesCount"> (filtered)</span>
      </label>

      <div class="btn-toolbar mb-5" role="toolbar">
        <div class="row w-100">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="input-group mb-3">
              <div class="input-group-text bg-transparent border-end-0">
                <font-awesome-icon icon="magnifying-glass" />
              </div>
              <input
                v-model="filterValue"
                type="text"
                class="form-control border-start-0"
                placeholder="Search trades"
              />
              <button class="btn btn-outline-secondary" type="button" @click="clearFilter">
                Clear
              </button>
            </div>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="input-group">
              <div class="input-group-text bg-transparent border-end-0">
                <font-awesome-icon icon="magnifying-glass" />
              </div>
              <datepicker
                id="filterDate"
                v-model="filterDate"
                class="form-control border-start-0"
                input-format="dd/MM/yyyy"
                placeholder="Filter on date"
              />
              <button class="btn btn-outline-secondary" type="button" @click="clearDate">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!filteredSchemeTrades" class="alert alert-info" role="alert">
        Your search results will be listed here.
      </div>

      <div v-if="filteredSchemeTrades.length === 0" class="alert alert-info" role="alert">
        No matching results.
      </div>

      <div class="row">
        <div class="col-sm-12 mb-2">
          <span class="float-right">Last updated: {{ tradesLastUpdated }}</span>
        </div>
      </div>

      <div v-for="st in filteredSchemeTrades" :key="st" class="card">
        <div class="card-header bg-white">
          <div class="d-flex align-items-center bg-white">
            <div class="col-sm-10 card-title">
              <h5>
                Held with {{ schemeNameFromId(st.scheme) }}
                <span class="badge rounded-pill bg-primary text-light">{{ st.trades.length }}</span>
              </h5>
            </div>
            <div class="col-sm-2 float-right">
              <router-link :to="`/scheme/${st.scheme}`">
                <scheme-logo
                  :imagekey="schemeImageKeyFromId(st.scheme)"
                  style="height: 75px !important; border: 0; background: transparent"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Trade Code</th>
                <th scope="col">Description</th>
                <th scope="col">Registrations</th>
              </tr>
            </thead>
            <tbody v-if="schemeTrades">
              <tr v-for="trade in st.trades" :key="trade.tradeCode">
                <td>
                  {{ trade.tradeCode }}
                </td>
                <td>
                  {{ trade.tradeDescription }}
                </td>
                <td>
                  <span v-for="reg in trade.registered" :key="reg">
                    <p v-if="reg.toDate">
                      Valid {{ formatDateNoTime(reg.fromDate) }} to
                      {{ formatDateNoTime(reg.toDate) }}
                    </p>
                    <p v-else>Started {{ formatDateNoTime(reg.fromDate) }} and is ongoing</p>
                    <p v-if="reg.certificateId">Certificate Id: {{ reg.certificateId }}</p>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <card v-if="!publishRequestSent" class="mt-5">
      <p>
        If you think something doesn't look right you can publish the trades for this TMLN to ensure
        they are the latest calculated set.
      </p>
      <button type="button" class="btn btn-lg btn-primary me-3" @click="publishTrades">
        Publish {{ model.tmln }} Trades
      </button>
    </card>

    <div v-if="publishRequestSent" class="alert alert-info mt-5">
      The request has been sent and will take a few moments.
      <a href="#" @click="setSchemeTrades">Refresh now</a>
    </div>
  </div>
</template>

<script>
import { Subtitle, SchemeLogo, Card } from '@trustmark-2005-ltd/trustmark-shared-components';
import Datepicker from 'vue3-datepicker';
import moment from 'moment';
import { formatDateNoTime } from '../../../services/scheme-common';

export default {
  name: 'LicenceNumberViewTrades',
  components: {
    Subtitle,
    Datepicker,
    SchemeLogo,
    Card,
  },
  data() {
    return {
      filterValue: null,
      filterDate: null,
      schemeTrades: null,
      tradesLastUpdated: null,
      publishRequestSent: false,
    };
  },
  computed: {
    schemeTradesCount() {
      return this.schemeTrades
        ? this.schemeTrades.map((x) => x.trades.length).reduce(this.add, 0)
        : 0;
    },
    filteredSchemeTradesCount() {
      return this.filteredSchemeTrades
        ? this.filteredSchemeTrades.map((x) => x.trades.length).reduce(this.add, 0)
        : 0;
    },
    model() {
      return this.$store.state.licenceNumber.trustmarkBusiness;
    },
    filteredSchemeTrades() {
      const clone = JSON.parse(JSON.stringify(this.schemeTrades));

      const fv = this.filterValue;

      if (fv && fv !== '') {
        let code = -1;
        if (Number.isInteger(Number(fv)) && parseInt(fv, 10) > -1) {
          code = parseInt(fv, 10);
        }

        if (fv !== null || code > 0) {
          clone.forEach((st) => {
            st.trades = st.trades.filter((x) => {
              return (
                (code > -1 && x.tradeCode === code) ||
                (code === -1 &&
                  x.tradeDescription &&
                  x.tradeDescription.toUpperCase().indexOf(fv.toUpperCase()) > -1)
              );
            });
          });
        }
      }

      if (this.filterDate) {
        clone.forEach((sc) => {
          sc.trades = this.tradesWithinRange(sc.trades);
          return sc;
        });
      }

      return clone.filter((x) => x.trades && x.trades.length > 0);
    },
  },
  mounted() {
    this.setSchemeTrades();
  },
  methods: {
    formatDateNoTime,
    tradesWithinRange(trades) {
      const y = trades.filter((t) =>
        t.registered.some((r) => this.withinRegistrationDate(r, this.filterDate))
      );
      return y;
    },
    withinRegistrationDate(registration, date) {
      if (!date) {
        return false;
      }
      const d = moment(date);

      // eslint-disable-next-line no-underscore-dangle
      if (!d._isValid) {
        return false;
      }

      let from = null;
      if (registration.fromDate) {
        from = moment(registration.fromDate);
      }
      let to = null;
      if (registration.toDate) {
        to = moment(registration.toDate);
      }

      let g = null;

      if (from && to) {
        g = d.isBetween(from, to, 'hours', '[]');
      } else if (from) {
        g = d.isAfter(from);
      }

      return g;
    },
    add(accumulator, a) {
      return accumulator + a;
    },
    clearFilter() {
      this.filterValue = null;
    },
    clearDate() {
      this.filterDate = null;
    },
    setSchemeTrades() {
      if (!this.model.schemes) return;
      const clone = JSON.parse(JSON.stringify(this.model));
      clone.schemeTrades = [];
      clone.schemes.forEach((schemeId) => {
        this.$store
          .dispatch('tradeCheck/getTrade', {
            tmln: this.model.tmln,
            schemeId,
          })
          .then((result) => {
            const trades = result?.trades;
            this.tradesLastUpdated = result?.lastUpdated;
            clone.schemeTrades.push({
              scheme: schemeId,
              trades,
            });
            this.schemeTrades = clone.schemeTrades;
          });
      });
    },
    schemeImageKeyFromId(schemeId) {
      const scheme = this.$store.state.auth.appData.schemes.find((x) => x.schemeId === schemeId);
      return scheme.schemeLogoImageKey || scheme.logoImage;
    },
    schemeNameFromId(schemeId) {
      const scheme = this.$store.state.auth.appData.schemes.find((x) => x.schemeId === schemeId);
      return scheme.schemeName;
    },
    publishTrades() {
      this.publishRequestSent = true;
    },
  },
};
</script>
