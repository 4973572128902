import { createStore } from 'vuex';
import { auth } from './auth.module';
import { scheme } from './scheme.module';
import { schemeContact } from './scheme-contact.module';
import { schemeBusinessEmail } from './scheme-business-emails.module';
import { schemeNote } from './scheme-note.module';
import { schemeBusinessNote } from './scheme-business-note.module';
import { schemeTrade } from './scheme-trade.module';
import { trade } from './trade.module';
import { application } from './application.module';
import { registrationBilling } from './registration-billing.module';
import { licenceNumber } from './licence-number.module';
import { schemeBusiness } from './scheme-business.module';
import { businessSearch } from './business-search.module';
import { schemeUser } from './scheme-user.module';
import { pendingLicenceAction } from './pending-licence-action.module';
import { tradeCheck } from './scheme-trade-check.module';
import { siteSettings } from './site-settings.module';
import { backofficeUser } from './backoffice-user.module';
import { passwordReset } from './password-reset.module';
import { tradingStandardsAdmin } from './trading-standards-admin.module';
import { softwareProvider } from './software-provider.module';
import { auditQuestions } from './audit-questions.module';
import { schemeUsage } from './scheme-usage.module';
import { tradingStandardsFeedback } from './trading-standards-feedback.module';
import { schemePlatformStatistics } from './scheme-platform-statistics.module';
import { matrixTypes } from './matrix-types.module';
import { blockedEmails } from './blocked-emails.module';
import { funder } from './funder.module';
import { funderUser } from './funder-user.module';
import { propertyCheckerUser } from './property-checker-user.module';
import { businessReports } from './business-reports.module';
import { energySuppliers } from './energy-suppliers.module';
import { disputes } from './disputes.module';
import { disputeSearch } from './dispute-search.module';
import { disputeNote } from './dispute-note.module';
import { disputeEvidence } from './dispute-evidence.module';
import { disputeAssociation } from './dispute-association.module';
const store = createStore({
  modules: {
    application,
    auth,
    scheme,
    schemeContact,
    schemeNote,
    disputes,
    schemeTrade,
    trade,
    registrationBilling,
    licenceNumber,
    schemeBusiness,
    schemeBusinessNote,
    schemeUser,
    pendingLicenceAction,
    tradeCheck,
    schemeBusinessEmail,
    siteSettings,
    backofficeUser,
    passwordReset,
    businessSearch,
    tradingStandardsAdmin,
    softwareProvider,
    auditQuestions,
    schemeUsage,
    tradingStandardsFeedback,
    schemePlatformStatistics,
    matrixTypes,
    blockedEmails,
    funder,
    funderUser,
    propertyCheckerUser,
    businessReports,
    energySuppliers,
    disputeSearch,
    disputeNote,
    disputeEvidence,
    disputeAssociation,
  },
  state: {
    sidebarIsCollapsed: true,
    isBusy: false,
  },
  mutations: {
    setIsBusy(state, newState) {
      state.isBusy = newState;
    },
    setSidebarCollapsed(state, collapsed) {
      state.sidebarIsCollapsed = collapsed;
    },
  },
});

export default store;
