import { HTTP_RETROFIT, authHeader } from './http-service';

class MatrixTypesService {
  appData() {
    return HTTP_RETROFIT.get(`/AppData`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new MatrixTypesService();
