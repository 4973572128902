import { HTTP, authHeader } from './http-service';

class SchemeBusinessNoteService {
  list(schemeBusinessId) {
    return HTTP.get(`/schemeBusinessNote/schemeBusiness/${schemeBusinessId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  get(schemeBusinessId, noteId) {
    return HTTP.get(`/schemeBusinessNote/${noteId}/schemeBusiness/${schemeBusinessId}/`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  create(payload) {
    return HTTP.post(`/schemeBusinessNote`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/schemeBusinessNote`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  delete(payload) {
    return HTTP.delete(
      `/schemeBusinessNote/${payload.noteId}/schemeBusiness/${payload.schemeBusinessId}`,
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }
}

export default new SchemeBusinessNoteService();
