import BusinessSearchService from '../services/business-search-service';

export const businessSearch = {
  namespaced: true,
  state: {
    searchResult: null,
    pageNumber: 0,
    lastSearchConfig: null,
  },
  actions: {
    executeLastSearch({ dispatch, state }) {
      if (!state.lastSearchConfig) {
        throw new Error('No business search has been run yet - cannot rerun');
      }
      dispatch('searchBusiness', state.lastSearchConfig).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    searchBusiness({ commit }, data) {
      data.type = 'standard';
      commit('setLastSearchConfig', data);
      return BusinessSearchService.search(data).then(
        (result) => {
          commit('setBusinessSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    searchBusinessAdvanced({ commit }, data) {
      data.type = 'advanced';
      commit('setLastSearchConfig', data);
      return BusinessSearchService.advancedSearch(data).then(
        (result) => {
          commit('setBusinessSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    export({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return BusinessSearchService.export(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    removeBusinessFromSearchResult(state, businessId) {
      if (state.searchResult) {
        const removed = state.searchResult.results.find((x) => x.businessId === businessId);
        if (removed) {
          const newSearchResult = state.searchResult.results.filter(
            (x) => x.schemeBusinessId !== removed.schemeBusinessId
          );
          state.searchResult = newSearchResult;
        }
      }
    },
    setBusinessSearchResult(state, searchResult) {
      state.searchResult = searchResult;
    },
    setLastSearchConfig(state, searchconfig) {
      state.lastSearchConfig = searchconfig;
    },
    setSearchPageNumber(state, newPageNumber) {
      state.pageNumber = newPageNumber;
    },
  },
};
