<template>
  <div v-if="application">
    <div class="col-sm-12 col-md-10 col-lg-10">
      <h1>View Application: {{ application.applicationReference }}</h1>
      <div class="mb-4">
        <div class="btn-toolbar mb-2 mb-md-0">
          <div class="btn-group me-2">
            <router-link
              v-if="isApplicationEditor"
              :to="`${parentRoute}/edit`"
              class="btn btn-outline-secondary"
            >
              Internal Edit
            </router-link>
          </div>
        </div>
      </div>

      <card label="Current Status">
        <div class="row">
          <div class="col">
            <div class="form-label">
              <key-value-presenter label="Status" :value="currentApplicationStatus" />
            </div>
            <div class="form-label">
              <key-value-presenter
                label="Application Date"
                :value="$helpers.dateString(application.applicationDate)"
              />
            </div>
            <div class="form-label">
              <copy-key-value-presenter
                label="Application Reference"
                :value="application.applicationReference"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-label">
              <key-value-presenter-check-or-cross
                label="Become Trading Standards Approved"
                :state="application.becomeTradingStandardsApproved"
              />
            </div>
            <div v-if="application.becomeTradingStandardsApproved" class="form-label">
              <key-value-presenter
                label="Trading Standards Status"
                :value="currentTradingStandardsStatus"
              />
            </div>
          </div>
        </div>
      </card>

      <card label="Company Information">
        <div class="row">
          <div class="col">
            <div class="form-label">
              <key-value-presenter
                label="Company Name"
                :value="application.registeredCompanyName"
              />
            </div>
            <div class="form-label">
              <key-value-presenter
                label="Company Number"
                :value="application.registeredCompanyNumber"
              />
            </div>

            <div class="form-label">
              <b>Address:</b>
            </div>
            <div class="form-label">
              <conditional-br>{{ application.registeredAddressLine1 }}</conditional-br>
              <conditional-br>{{ application.registeredAddressLine2 }}</conditional-br>
              <conditional-br>{{ application.registeredAddressLine3 }}</conditional-br>
              <conditional-br>{{ application.registeredAddressCity }}</conditional-br>
              <conditional-br>{{ application.registeredAddressCounty }}</conditional-br>
              <conditional-br>{{ application.registeredAddressPostcode }}</conditional-br>
              <conditional-br>{{ application.registeredAddressCountry }}</conditional-br>
              <conditional-br>{{ application.registeredAddressUprn }}</conditional-br>
            </div>
          </div>
          <div class="col">
            <h5 class="card-title mb-3 mt-5">Provided Schemes</h5>

            <div v-for="(scheme, i) in application.schemes" :key="scheme.schemeId">
              <div class="form-label">
                <key-value-presenter label="Scheme ID" :value="scheme.schemeId" />
              </div>
              <div class="form-label">
                <key-value-presenter label="Scheme Name" :value="scheme.schemeName" />
              </div>
              <div class="form-label">
                <key-value-presenter
                  label="Provided TMLN"
                  :value="notOnApplicationString(application.tmln)"
                />
              </div>
              <div class="form-label">
                <key-value-presenter
                  label="Provided Scheme Membership Reference"
                  :value="notOnApplicationString(scheme.membershipNumber)"
                />
              </div>
              <hr v-if="i < application.schemes.length - 1" />
            </div>

            <h5 class="card-title mb-3">Provided Trades</h5>

            <div v-if="!application.trades">
              <p>No trades provided</p>
            </div>
            <div v-if="application.trades">
              <div v-for="trade in application.trades" :key="trade.tradeCode">
                <div class="form-label">
                  <key-value-presenter label="Trade Code" :value="trade.tradeCode" />
                </div>
                <div class="form-label">
                  <key-value-presenter label="Trade Description" :value="trade.tradeDescription" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>

      <card label="Applicant Contact Details">
        <div class="row">
          <div class="col">
            <div class="form-label">
              <key-value-presenter label="First Name" :value="application.firstName" />
            </div>
            <div class="form-label">
              <key-value-presenter label="Last Name" :value="application.lastName" />
            </div>
          </div>
          <div class="col">
            <div class="form-label">
              <key-value-presenter-hyperlinked
                label="Website"
                :value="application.companyWebsite"
                :href="application.companyWebsite"
              />
            </div>
            <div class="form-label">
              <key-value-presenter-hyperlinked
                label="Phone"
                :value="application.primaryContactNumber"
                :href="`tel:${application.primaryContactNumber}`"
              />
            </div>
            <div class="form-label">
              <key-value-presenter-hyperlinked
                label="Email"
                :value="application.email"
                :href="`mailto:${application.email}`"
              />
            </div>
          </div>
        </div>
      </card>

      <card label="Responsible Person">
        <div class="row">
          <div class="col">
            <div class="form-label">
              <key-value-presenter
                label="Same As Application: "
                :value="application.applicationIsResponsiblePerson ? 'Yes' : 'No'"
              />
            </div>
            <div class="form-label">
              <key-value-presenter
                label="First Name"
                :value="application.responsiblePersonFirstName"
              />
            </div>
            <div class="form-label">
              <key-value-presenter
                label="Last Name"
                :value="application.responsiblePersonLastName"
              />
            </div>
            <div class="form-label">
              <key-value-presenter
                label="DOB"
                :value="`${$helpers.dateString(application.responsiblePersonDOB)}`"
              />
            </div>
            <div class="form-label">
              <key-value-presenter-hyperlinked
                label="Phone"
                :value="application.responsiblePersonTelephone"
                :href="`tel:${application.responsiblePersonTelephone}`"
              />
            </div>
            <div class="form-label">
              <key-value-presenter-hyperlinked
                label="Email"
                :value="application.responsiblePersonEmail"
                :href="`mailto:${application.responsiblePersonEmail}`"
              />
            </div>
          </div>
          <div class="col">
            <b>Address:</b>
            <div class="form-label">
              <conditional-br>{{ application.responsiblePersonAddressLine1 }}</conditional-br>
              <conditional-br>{{ application.responsiblePersonAddressLine2 }}</conditional-br>
              <conditional-br>{{ application.responsiblePersonAddressLine3 }}</conditional-br>
              <conditional-br>{{ application.responsiblePersonAddressCity }}</conditional-br>
              <conditional-br>{{ application.responsiblePersonAddressCounty }}</conditional-br>
              <conditional-br>{{ application.responsiblePersonAddressPostcode }}</conditional-br>
              <conditional-br>{{ application.responsiblePersonAddressCountry }}</conditional-br>
              <conditional-br>{{ application.responsiblePersonAddressUprn }}</conditional-br>
            </div>
          </div>
        </div>
      </card>

      <application-view-linked-businesses v-if="isSchemeBusinessViewer" />

      <card v-if="application.becomeTradingStandardsApproved" label="Trading Standards Intel">
        <key-value-presenter
          label="Trading Standards Status"
          :value="currentTradingStandardsStatus"
        />
        <div v-if="application.tradingStandardsWorkflowStatus">
          <rag-rating :rag-rating="application.tradingStandardsRagRating"></rag-rating>
          <br /><br />
          <b>Notes:</b><br />
          {{ application.tradingStandardsNotes }}
        </div>
      </card>

      <card label="Activity">
        <table
          v-if="application.activity && application.activity.length > 0"
          class="table table-hover"
        >
          <thead>
            <tr>
              <th scope="col">Date Time</th>
              <th scope="col">User</th>
              <th scope="col">Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in application.activity" :key="i">
              <td>
                {{ $helpers.dateTimeString(item.dateTimeStamp) }}
              </td>
              <td>
                {{ item.username }}
              </td>
              <td>
                {{ item.description }}
              </td>
            </tr>
          </tbody>
        </table>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Card,
  KeyValuePresenter,
  CopyKeyValuePresenter,
  KeyValuePresenterHyperlinked,
  ConditionalBr,
  KeyValuePresenterCheckOrCross,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import RagRating from './RagRating.vue';
import ApplicationViewLinkedBusinesses from './ApplicationViewLinkedBusinesses.vue';

export default {
  name: 'ApplicationsView',
  components: {
    ConditionalBr,
    KeyValuePresenterHyperlinked,
    CopyKeyValuePresenter,
    KeyValuePresenter,
    Card,
    KeyValuePresenterCheckOrCross,
    RagRating,
    ApplicationViewLinkedBusinesses,
  },
  data() {
    return {};
  },
  computed: {
    application() {
      return this.$store.state.application.application;
    },
    parentRoute() {
      return `/applications/${this.$route.params.applicationRef}`;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isTradingStandards() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('TradingStandards')
        : false;
    },
    isApplicationEditor() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('ApplicationEditor')
        : false;
    },
    isSchemeBusinessViewer() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('SchemeBusinessViewer')
        : false;
    },
    applicationStatuses() {
      return this.$store.state.auth.appData.applicationStatuses;
    },
    tradingStandardsWorkflowStatuses() {
      return this.$store.state.auth.appData.tradingStandardsWorkflowStatuses;
    },
    currentApplicationStatus() {
      const status = this.$store.state.auth.appData.applicationStatuses.find(
        (x) => x.status === this.application.status
      );
      return status.description;
    },
    currentTradingStandardsStatus() {
      if (!this.application.tradingStandardsWorkflowStatus) {
        return 'Trading Standards Application will begin after the scheme has confirmed the business and a TMLN is assigned.';
      }
      return this.application.tradingStandardsWorkflowStatus;
    },
  },
  mounted() {
    this.$store.dispatch('application/get', this.$route.params.applicationRef);
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  },
  methods: {
    notOnApplicationString(input) {
      if (!input || input === '') {
        return 'Not provided with application';
      }
      return input;
    },
  },
};
</script>
