import SchemeUserService from '../services/scheme-user-service';

export const schemeUser = {
  namespaced: true,
  state: {
    users: null,
    user: null,
  },
  actions: {
    get({ commit }, schemeId) {
      commit('setIsBusy', true, { root: true });
      return SchemeUserService.get(schemeId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUsers', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    byEmail({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeUserService.byEmail(payload.schemeId, payload.accountId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUser', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          commit('setUser', null);
          return Promise.reject(error);
        });
    },
    invite({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeUserService.invite(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUser', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    resendInvite({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeUserService.resendInvite(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUser', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    reset({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeUserService.reset(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUser', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    enable({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeUserService.enable(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUser', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    disable({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeUserService.disable(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUser', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setUsers(state, newUsers) {
      state.users = newUsers;
    },
    setUser(state, newUser) {
      state.user = newUser;
    },
  },
};
