import { HTTP, authHeader } from './http-service';

class SiteSettingsService {
  get() {
    return HTTP.get('/SiteSettings', {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  put(payload) {
    return HTTP.put('/SiteSettings', payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new SiteSettingsService();
