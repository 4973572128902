<template>
  <subtitle>Compliance</subtitle>

  <div v-if="!model.isSuspended" class="col-sm-12 col-md-10">
    <card v-if="model.unsuspendedDateTime">
      <div class="form-label">
        <div class="form-label">
          <key-value-presenter
            label="Unsuspended Description"
            :value="model.unsuspendedDescription"
          />
        </div>
        <key-value-presenter
          label="Unsuspended At"
          :value="formatDate(model.unsuspendedDateTime)"
        />
        <div class="form-label">
          <key-value-presenter label="Unsuspended By" :value="model.unsuspendedBy" />
        </div>
      </div>
    </card>
    <card>
      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
      </div>

      <div class="form-group">
        <label for="description" class="form-label">Description *</label>
        <input
          id="description"
          v-model="description"
          class="form-control"
          type="text"
          :class="v$.description.$error ? 'is-invalid' : ''"
          required
        />
        <div v-if="v$.description.$error" class="invalid-feedback">
          {{ v$.description.$errors[0].$message }}
        </div>
      </div>
      <button type="button" class="btn btn-primary mt-3 mb-3" @click="suspend">
        Suspend Account
      </button>
    </card>
  </div>

  <div v-if="model.isSuspended" class="col-sm-12 col-md-10">
    <card>
      <div class="form-label">
        <div class="form-label">
          <key-value-presenter label="Suspended Description" :value="model.suspendedDescription" />
        </div>
        <key-value-presenter label="Suspended At" :value="formatDate(model.suspendedDateTime)" />
        <div class="form-label">
          <key-value-presenter label="Suspended By" :value="model.suspendedBy" />
        </div>
      </div>
    </card>
    <card>
      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
      </div>

      <div class="form-group">
        <label for="description" class="form-label">Description *</label>
        <input
          id="description"
          v-model="description"
          class="form-control"
          type="text"
          :class="v$.description.$error ? 'is-invalid' : ''"
          required
        />
        <div v-if="v$.description.$error" class="invalid-feedback">
          {{ v$.description.$errors[0].$message }}
        </div>
      </div>
      <button type="button" class="btn btn-primary mt-3 mb-3" @click="unsuspend">
        Unsuspend Account
      </button>
    </card>
  </div>
</template>

<script>
import { Subtitle, Card, KeyValuePresenter } from '@trustmark-2005-ltd/trustmark-shared-components';
import useValidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { formatDate } from '../../../services/scheme-common';

export default {
  name: 'LicenceNumberViewCompliance',
  components: {
    Subtitle,
    Card,
    KeyValuePresenter,
  },
  data() {
    return {
      v$: useValidate(),
      successMessage: null,
      errorMessage: null,
      message: null,
      loading: false,
      description: '',
    };
  },
  computed: {
    model() {
      return this.$store.state.licenceNumber.trustmarkBusiness;
    },
  },
  validations: {
    description: {
      isRequired: helpers.withMessage('Description is required', required),
    },
  },
  methods: {
    formatDate,
    suspend() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.message = 'Please enter a description.';
        window.scrollTo(0, 0);
        return;
      }

      this.$store
        .dispatch('licenceNumber/suspend', {
          tmln: this.$store.state.licenceNumber.trustmarkBusiness.tmln,
          description: this.description,
        })
        .then(() => {
          this.$store.dispatch('licenceNumber/getById', this.$route.params.tmBusinessId);
        })
        .catch((error) => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        });
    },
    unsuspend() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.message = 'Please enter a description.';
        window.scrollTo(0, 0);
        return;
      }

      this.$store
        .dispatch('licenceNumber/unsuspend', {
          tmln: this.$store.state.licenceNumber.trustmarkBusiness.tmln,
          description: this.description,
        })
        .then(() => {
          this.$store.dispatch('licenceNumber/getById', this.$route.params.tmBusinessId);
        })
        .catch((error) => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        });
    },
  },
};
</script>

<style scoped lang="scss">
.ml-2 {
  margin-left: 10px;
}

ul {
  padding-left: 1rem;
}

.light {
  color: $gray-600;
}
</style>
