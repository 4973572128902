<template>
  <subtitle>Trading Standards Intel</subtitle>

  <div v-if="data && resultsModel" class="mb-4">
    <div class="btn-toolbar mb-3" role="toolbar">
      <div class="row w-100">
        <div class="col-sm-12 col-md-9 col-lg-6">
          <div class="input-group" :class="{ disabled: disabled || loading }">
            <div id="btnGroupAddon" class="input-group-text bg-transparent border-end-0">
              <font-awesome-icon icon="magnifying-glass" />
            </div>
            <input
              v-model="searchModel.searchTerm"
              type="text"
              class="form-control border-start-0"
              placeholder="Find by TMLN, Company"
            />

            <smart-button :disabled="disabled" :loading="loading" :click="search">
              Search
            </smart-button>
            <smart-button :loading="false" :disabled="!resultsModel || disabled" :click="clear"
              >Clear
            </smart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="data && resultsModel && resultsModel.results.length === 0 && !loading"
    class="alert alert-warning"
    role="alert"
  >
    There are no matching results for this search.
  </div>

  <div class="w-100 overflow-scroll">
    <table v-if="data && resultsModel.results.length > 0" class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Actions</th>
          <th scope="col">Renewal Year</th>
          <th scope="col">TMLN</th>
          <th scope="col">Company</th>
          <th scope="col">Applicant Email</th>
          <th scope="col">Business Email</th>
          <th scope="col">Renewal Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="tsy in resultsModel.results" :key="tsy.tmln">
          <td>
            <div class="btn-group me-2">
              <button class="btn btn-secondary" @click="edit(tsy)">Provide Intel</button>
            </div>
          </td>
          <td>
            {{ tsy.renewalYear }}
          </td>
          <td>
            {{ tsy.tmln }}
          </td>
          <td>
            {{ tsy.registeredCompanyName }}
          </td>
          <td>
            {{ tsy.applicantEmail }}
          </td>
          <td>
            {{ tsy.registeredEmail }}
          </td>
          <td>
            {{ $helpers.dateString(tsy.renewalDate) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { SmartButton, Subtitle } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'TradingStandardsFeedbackView',
  components: {
    SmartButton,
    Subtitle,
  },
  data() {
    return {
      searchModel: {
        searchTerm: null,
      },
      loading: false,
    };
  },
  computed: {
    data() {
      return this.$store.state.tradingStandardsFeedback.data;
    },
    resultsModel() {
      if (!this.searchModel.searchTerm) {
        return {
          results: this.data,
        };
      }
      return {
        results: this.data.filter(
          (x) =>
            x.tmln?.includes(this.searchModel.searchTerm) ||
            x.registeredCompanyName
              ?.toUpperCase()
              .includes(this.searchModel.searchTerm.toUpperCase()) ||
            x.registeredEmail?.toUpperCase().includes(this.searchModel.searchTerm.toUpperCase())
        ),
      };
    },
  },
  mounted() {
    if (!this.data) {
      this.loading = true;
      this.$store.dispatch('tradingStandardsFeedback/actionable').then(() => {
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },
  methods: {
    formatFee(val) {
      if (!val) return '';
      return `£${val}`;
    },
    formatYesNo(val) {
      return val ? 'Yes' : 'No';
    },
    clear() {
      this.searchModel.searchTerm = null;
    },
    edit(tsy) {
      this.$router.push(`/trading-standards-feedback/${tsy.tmln}/${tsy.renewalYear}`);
    },
  },
};
</script>

<style>
.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.dotGreen {
  height: 25px;
  width: 25px;
  background-color: rgb(36, 211, 59);
  border-radius: 50%;
  display: inline-block;
}

.dotYellow {
  height: 25px;
  width: 25px;
  background-color: rgb(228, 222, 38);
  border-radius: 50%;
  display: inline-block;
}

.dotRed {
  height: 25px;
  width: 25px;
  background-color: rgb(211, 62, 36);
  border-radius: 50%;
  display: inline-block;
}
</style>
