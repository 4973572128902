<template>
  <div>
    <div class="btn-toolbar mb-3" role="toolbar">
      <div class="row w-100">
        <div class="col-sm-12 col-md-12 col-lg-8">
          <div class="input-group">
            <div id="btnGroupAddon" class="input-group-text bg-transparent border-end-0">
              <font-awesome-icon icon="magnifying-glass" />
            </div>
            <input
              v-model="searchModel.searchTerm"
              type="text"
              class="form-control border-start-0"
              placeholder="Find Application"
            />
            <smart-button :loading="loading" :click="newSearch"> Search </smart-button>
            <smart-button
              class-override="btn-outline-secondary"
              :loading="false"
              :disabled="!resultsModel"
              :click="reset"
            >
              Reset
            </smart-button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="searchError" class="alert alert-danger" role="alert">
      There was a problem with the search {{ searchError }}
    </div>

    <div
      v-if="resultsModel && resultsModel.results.length === 0 && !loading"
      class="alert alert-warning"
      role="alert"
    >
      There are no matching results for this search.
    </div>

    <table v-if="resultsModel && resultsModel.results.length > 0" class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Reference</th>
          <th scope="col">Status</th>
          <th scope="col">Company</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="application in resultsModel.results" :key="application.applicationReference">
          <td>
            <copy-icon :text="application.applicationReference" />
            <span class="fs-5">{{ application.applicationReference }}</span>
            <br />
            <small>{{ application.applicationDateTimeAgo }}<br /> </small>
            <br />
          </td>
          <td>
            <div><b>Status:</b><br />{{ getApplicationStatus(application.status) }}</div>
            <br />
            <div v-if="getApplicationStatus(application.status) !== 'Rejected'">
              <div v-if="application.becomeTradingStandardsApproved" class="mb-3">
                <b>Trading Standards Status:</b> <br />{{
                  application.tradingStandardsWorkflowStatus
                }}
              </div>
              <rag-rating
                v-if="application.becomeTradingStandardsApproved"
                :rag-rating="application.tradingStandardsRagRating"
              ></rag-rating>
              <br />
            </div>
          </td>
          <td>
            Company Name: {{ application.registeredCompanyName }} <br />
            Company Number: {{ application.registeredCompanyNumber }}<br />
            Company Address:<br />
            <conditional-br>{{ application.registeredAddressLine1 }}</conditional-br>
            <conditional-br>{{ application.registeredAddressLine2 }}</conditional-br>
            <conditional-br>{{ application.registeredAddressLine3 }}</conditional-br>
            <conditional-br>{{ application.registeredAddressCity }}</conditional-br>
            <conditional-br>{{ application.registeredAddressCounty }}</conditional-br>
            <conditional-br>{{ application.registeredAddressPostcode }}</conditional-br>
            <conditional-br>{{ application.registeredAddressCountry }}</conditional-br>
            <conditional-br>{{ application.registeredAddressUprn }}</conditional-br>
            Scheme(s) Provided: {{ application.schemes.map((x) => x.schemeId).join(', ') }}
            <br />
            Applicant Contact: {{ application.lastName }}, {{ application.firstName }} <br />
          </td>

          <td>
            <div class="btn-group btn-group">
              <router-link
                :to="`/applications/${application.applicationReference}`"
                class="btn btn-outline-primary"
                >View</router-link
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="resultsModel">
      <nav>
        <ul class="pagination">
          <li class="pointer page-item" :class="{ disabled: !enablePrevious }">
            <span
              class="page-link"
              tabindex="-1"
              :aria-disabled="!enablePrevious"
              @click="
                () => {
                  selectPage(0);
                }
              "
              >First</span
            >
          </li>
          <li class="pointer page-item" :class="{ disabled: !enablePrevious }">
            <span
              class="page-link"
              tabindex="-1"
              :aria-disabled="!enablePrevious"
              @click="
                () => {
                  selectPage(pageNumber - 1);
                }
              "
              >Previous</span
            >
          </li>
          <li
            v-for="i in pagesToShow"
            :key="i"
            :class="{ active: i - 1 === pageNumber }"
            class="pointer page-item"
            @click="
              () => {
                selectPage(i - 1);
              }
            "
          >
            <span class="page-link">{{ i }}</span>
          </li>
          <li class="pointer page-item" :class="{ disabled: !enableNext }">
            <span
              class="page-link"
              :aria-disabled="!enableNext"
              @click="
                () => {
                  selectPage(pageNumber + 1);
                }
              "
              >Next</span
            >
          </li>
          <li class="pointer page-item" :class="{ disabled: !enableNext }">
            <span
              class="page-link"
              :aria-disabled="!enableNext"
              @click="
                () => {
                  selectPage(resultsModel.totalPages - 1);
                }
              "
              >Last page {{ resultsModel.totalPages }}</span
            >
          </li>
          <!-- <span class="p-2">{{ resultsModel.totalItems }} Applications. </span> -->
        </ul>
      </nav>
    </div>

    <div v-if="!resultsModel && !loading" class="alert alert-info" role="alert">
      Your search results will be listed here.
    </div>
  </div>
</template>
<script>
import {
  SmartButton,
  CopyIcon,
  ConditionalBr,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import RagRating from './RagRating.vue';

export default {
  name: 'ApplicationsSearchTable',
  components: {
    ConditionalBr,
    SmartButton,
    CopyIcon,
    RagRating,
  },
  data() {
    return {
      loading: false,
      searchModel: {
        searchTerm: null,
        previousSearchTerm: null,
      },
      searchError: null,
    };
  },
  computed: {
    applicationStatuses() {
      return this.$store.state.auth.appData.applicationStatuses;
    },
    pagesToShow() {
      const maxDisplay = 10;
      let minPage = this.pageNumber - maxDisplay / 2 + 1;
      if (minPage < 1) {
        minPage = 1;
      }
      let maxPage = minPage + maxDisplay;
      if (maxPage > this.resultsModel.totalPages) {
        maxPage = this.resultsModel.totalPages;
      }
      const pages = [];
      for (let i = minPage; i <= maxPage; i += 1) {
        pages.push(i);
      }
      return pages;
    },
    pageNumber() {
      return this.$store.state.application.pageNumber;
    },
    resultsModel() {
      return this.$store.state.application.searchResult;
    },
    enablePrevious() {
      if (!this.resultsModel) return false;
      return !(this.pageNumber - 1 < 0);
    },
    enableNext() {
      if (!this.resultsModel) return false;
      return this.pageNumber < this.resultsModel.totalPages - 1;
    },
    disabled() {
      return !this.searchModel.searchTerm || this.searchModel.searchTerm.length === 0;
    },
    lastSearchConfig() {
      return this.$store.state.application.lastSearchConfig;
    },
    isStandardSearch() {
      return this.lastSearchConfig.type === 'standard';
    },
  },
  watch: {
    pageNumber() {
      if (this.isStandardSearch) {
        // console.log(`standard pageNumber ${newPageNumber}`);
        this.search();
      }
    },
  },
  mounted() {
    if (
      !this.resultsModel ||
      this.searchModel.previousSearchTerm !== this.$route.query.schemeId ||
      this.searchModel.searchTerm
    ) {
      // Ensure will have the first page on load
      this.search();
    }
  },
  methods: {
    getApplicationStatus(value) {
      const status = this.applicationStatuses.find((x) => x.status === value);
      if (status) return status.description;
      return 'Not set';
    },
    changeErrorMessage(msg) {
      this.searchError = msg;
    },
    selectPage(index) {
      this.$store.commit('application/setSearchPageNumber', index, { root: true });
    },
    newSearch() {
      this.$store.commit('application/setSearchPageNumber', 0, { root: true });
      this.search();
    },
    search() {
      if (this.loading) return;
      this.searchError = null;
      this.loading = true;

      if (
        this.$route.query.schemeId ||
        this.searchModel.searchTerm !== this.searchModel.previousSearchTerm
      ) {
        this.$store.commit('application/setSearchPageNumber', 0, { root: true });
      }

      this.searchModel.previousSearchTerm =
        this.$route.query.schemeId || this.searchModel.searchTerm;

      const payload = {
        pageParameters: {
          size: 10,
          number: this.pageNumber,
        },
        sort: {
          item: 'applicationDate',
          direction: 'descending',
        },
        fieldNames: [
          'email',
          'lastName',
          'firstName',
          'registeredCompanyNumber',
          'registeredCompanyName',
          'registeredAddressLine1',
          'responsiblePersonAddressPostcode',
          'applicationReference',
          'applicationDate',
        ],
        searchTerm: this.$route.query.schemeId || this.searchModel.searchTerm,
        operator: 1,
      };

      this.$store
        .dispatch('application/search', payload)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.searchError = err.message ?? err;
        });
    },
    reset() {
      this.searchModel.searchTerm = '';
      this.search();
    },
  },
};
</script>
