import DisputeEvidenceService from '../services/dispute-evidence-service';
  
export const disputeEvidence = {
  namespaced: true,
  state: {
    evidences: null,
    evidence: null,
  },
  actions: {
    newEvidence({ commit }, disputeId) {
      const template = {
        description: '',
        evidenceType: '',
        filename: '',
        link: '',
        dateOfEvidence: new Date(),
      };
      const empty = JSON.parse(JSON.stringify(template));
      empty.disputeId = disputeId;
      commit('setEvidence', empty);
    },
    listEvidence({ commit }, disputeId) {
      commit('setIsBusy', true, { root: true });
      return DisputeEvidenceService.list(disputeId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setEvidences', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    createEvidence({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return DisputeEvidenceService.create(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    getEvidence({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      commit('setEvidence', null);
      return DisputeEvidenceService.get(payload.disputeId, payload.disputeEvidenceId).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setEvidence', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateEvidence({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return DisputeEvidenceService.update(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    deleteEvidence({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return DisputeEvidenceService.delete(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setEvidences(state, newEvidences) {
      state.evidences = newEvidences;
    },
    setEvidence(state, newEvidence) {
      state.evidence = newEvidence;
      if (state.evidence) {
        state.evidence.dateOfEvidence = new Date(state.evidence.dateOfEvidence);
      }
    },
  },
};
