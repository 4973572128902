import { HTTP, authHeader } from './http-service';

class SchemeService {
  search(payload) {
    return HTTP.post(`/schemeSearch`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  advancedSearch(payload) {
    return HTTP.post(`/schemeSearch/advance`, payload, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  get(payload) {
    return HTTP.get(`/scheme/${payload.schemeId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getUsage() {
    return HTTP.get(`/scheme/usage`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  register(payload) {
    return HTTP.post(`/scheme`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/scheme`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  delete(payload) {
    return HTTP.delete(`/scheme/${payload.schemeId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getDashboard(payload) {
    return HTTP.get(`/schemedashboard/${payload.schemeId}`, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }
}

export default new SchemeService();
