<template>
  <div>
    <div class="btn-toolbar mb-3" role="toolbar">
      <div class="row w-100">
        <div class="col-sm-12 col-md-9 col-lg-6">
          <div class="input-group">
            <div id="btnGroupAddon" class="input-group-text bg-transparent border-end-0">
              <font-awesome-icon icon="magnifying-glass" />
            </div>
            <input
              v-model="searchModel.searchTerm"
              type="text"
              class="form-control border-start-0"
              placeholder="Find software provider"
            />
            <smart-button :loading="loading" :click="newSearch"> Search </smart-button>
            <smart-button
              class-override="btn-outline-secondary"
              :loading="false"
              :disabled="!resultsModel"
              :click="reset"
            >
              Reset
            </smart-button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="searchError" class="alert alert-danger" role="alert">
      There was a problem with the search {{ searchError }}
    </div>

    <div
      v-if="resultsModel && resultsModel.length === 0 && !loading"
      class="alert alert-warning"
      role="alert"
    >
      There are no matching results for this search.
    </div>

    <table v-if="resultsModel && resultsModel.length > 0" class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Account Holder Business Name</th>
          <th scope="col">Status</th>
          <th scope="col"></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="ra in resultsModel" :key="ra.retrofitAccountId">
          <td>{{ ra.accountHolderName }}</td>
          <td>{{ getStatus(ra.isEnabled) }}</td>
          <td>
            <div class="btn-group me-2">
              <router-link
                :to="`/software-provider/${ra.retrofitAccountId}`"
                class="btn btn-outline-primary"
              >
                View
              </router-link>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="!resultsModel && !loading" class="alert alert-info" role="alert">
      Your search results will be listed here.
    </div>
  </div>
</template>
<script>
import { SmartButton } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'SoftwareProviderSearchTable',
  components: {
    SmartButton,
  },
  data() {
    return {
      loading: false,
      searchModel: {
        searchTerm: null,
        previousSearchTerm: null,
      },
      searchError: null,
    };
  },
  computed: {
    disabled() {
      return !this.searchModel.searchTerm || this.searchModel.searchTerm.length === 0;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    resultsModel() {
      return this.$store.state.softwareProvider.softwareProviders;
    },
  },
  mounted() {
    if (!this.resultsModel) {
      // Ensure will have the first page on load
      this.search();
    }
  },
  methods: {
    getStatus(isEnabled) {
      return isEnabled ? 'Active' : 'Disabled';
    },
    changeErrorMessage(msg) {
      this.searchError = msg;
    },
    newSearch() {
      this.search();
    },
    search() {
      if (this.loading) return;
      this.searchError = null;
      this.loading = true;

      this.searchModel.previousSearchTerm = this.searchModel.searchTerm;

      this.$store
        .dispatch('softwareProvider/listSoftwareProviders')
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.searchError = err.message ?? err;
        });
    },
    reset() {
      this.searchModel.searchTerm = '';
      this.search();
    },
  },
};
</script>
