import MatrixTypesService from '../services/matrix-types-service';

export const matrixTypes = {
  namespaced: true,
  state: {
    appData: null,
  },
  actions: {
    getAppData({ commit }) {
      return MatrixTypesService.appData()
        .then((result) => {
          commit('setAppData', result);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setAppData(state, appData) {
      state.appData = appData;
    },
  },
};
