<template>
  <div class="row">
    <div class="col-sm-12" :class="{ 'col-md-6 col-xl-9': showAdvanced }">
      <div class="w-100">
        <h1>Pending Licences</h1>

        <pending-licence-action-list-toolbar @advanced-search="toggleAdvancedSearch" />

        <pending-licence-action-search-table ref="pendingLicenceActionTable" />
      </div>
    </div>
    <div v-if="showAdvanced" class="col-sm-12 col-md-6 col-xl-3">
      <pending-licence-action-search-advanced
        @error-message-changed="showErrorMessage"
        @reset-requested="reset"
        @close="toggleAdvancedSearch"
      />
    </div>
  </div>
</template>

<script>
import PendingLicenceActionSearchAdvanced from './PendingLicenceActionSearchAdvanced.vue';
import PendingLicenceActionSearchTable from './PendingLicenceActionSearchTable.vue';
import PendingLicenceActionListToolbar from './PendingLicenceActionListToolbar.vue';

export default {
  name: 'PendingLicenceActionList',
  components: {
    PendingLicenceActionSearchAdvanced,
    PendingLicenceActionSearchTable,
    PendingLicenceActionListToolbar,
  },
  data() {
    return {
      showAdvanced: false,
    };
  },
  methods: {
    toggleAdvancedSearch() {
      this.showAdvanced = !this.showAdvanced;
      this.emitter.emit('toggle-advanced-search-pending-licenses', this.showAdvanced);
    },
    showErrorMessage(msg) {
      this.$refs.pendingLicenceActionTable.changeErrorMessage(msg);
    },
    reset() {
      this.$refs.pendingLicenceActionTable.reset();
    },
  },
};
</script>
