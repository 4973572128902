<template>
  <b>RAG Rating: </b> <br />
  <span v-if="ragRating">
    <font-awesome-icon icon="traffic-light" class="icon" :class="ragRating" /> {{ ragRating }}
  </span>
  <span v-else class="ghost"> <font-awesome-icon icon="clock" class="icon" /> Pending </span>
</template>

<script>
export default {
  name: 'RagRating',
  props: {
    ragRating: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style scoped>
.Red {
  color: #bb2d3b;
}
.Amber {
  color: orange;
}
.Green {
  color: #26b4b4;
}
.ghost {
  opacity: 0.4;
}
</style>
