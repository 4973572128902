<template>
  <confirm-dialog ref="confirmDialog"></confirm-dialog>
  <card label="Trading Standards">
    <div class="row">
      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <div class="col-sm-12 col-md-6">
        <div class="form-label">
          <key-value-presenter
            label="Registered"
            :value="model.isTradingStandardsCurrent ? 'Yes' : 'No'"
          />
        </div>
      </div>
      <div v-if="model.isTradingStandardsCurrent" class="col-sm-12 col-md-6">
        <div class="form-label">
          <key-value-presenter
            label="Monitor"
            :value="model.tradingStandards.isMonitor ? 'Yes' : 'No'"
          />
        </div>
      </div>
      <div
        v-if="!tradingStandardsApplicationCreate && !model.hasTradingStandards"
        class="col-sm-12 col-md-6"
      >
        <button
          v-if="!tradingStandardsAppExpand"
          type="button"
          class="btn btn-secondary"
          @click="expandCreateTradingStandardsApplication"
        >
          Create Trading Standards Application
        </button>

        <div v-if="tradingStandardsAppExpand">
          <smart-select-key-value
            id="modelSchemeId"
            v-model="requestSchemeBusinessId"
            label="Requesting Business"
            :options="requestingBusinesses"
          />
          <button
            type="button"
            class="btn btn-outline-secondary float-right ms-2"
            @click="cancelCreateTradingStandardsApplication"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary float-right"
            @click="confirmCreateTradingStandardsApplication"
          >
            Create Now
          </button>
        </div>
      </div>
    </div>
    <div v-if="model.hasTradingStandards">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-label">
            <key-value-presenter
              label="Application Date"
              :value="$helpers.dateString(model.tradingStandards.applicationDate)"
            />
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-label">
            <key-value-presenter
              label="Start Date"
              :value="$helpers.dateString(model.tradingStandards.startedDate)"
            />
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-label">
            <key-value-presenter label="Year Number" :value="model.tradingStandards.currentYear" />
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-label">
            <key-value-presenter
              label="Renewal Date"
              :value="$helpers.dateString(model.tradingStandards.renewalDate)"
            />
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-label">
            <key-value-presenter
              label="Current Status"
              :value="model.tradingStandards.currentStatus"
            />
          </div>
        </div>
        <div v-if="isTradingStandards" class="row">
          <div class="text-right">
            <router-link
              :to="`/tradingstandards/${model.tmln}/${model.tradingStandards.currentYear}`"
              >View Trading Standards Process</router-link
            >
          </div>
        </div>
      </div>
      <div v-if="model.tradingStandards.removalDate" class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-label">
            <key-value-presenter
              label="Removal Date"
              :value="getRemovalDescription(model.tradingStandards)"
            />
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import {
  Card,
  KeyValuePresenter,
  confirmType,
  ConfirmDialog,
  SmartSelectKeyValue,
} from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'LicenceNumberViewTradingStandards',
  components: {
    Card,
    KeyValuePresenter,
    ConfirmDialog,
    SmartSelectKeyValue,
  },
  data() {
    return {
      errorMessage: null,
      tradingStandardsApplicationCreate: false,
      requestSchemeBusinessId: null,
      tradingStandardsAppExpand: false,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isTradingStandards() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('TradingStandards')
        : false;
    },
    model() {
      return this.$store.state.licenceNumber.trustmarkBusiness;
    },
    requestingBusinesses() {
      const opts = this.model.linkedSchemeBusinesses.map((s) => {
        return {
          key: s.schemeBusinessId,
          value: `${s.registeredCompanyName} - ${s.postCode}`.trim(),
        };
      });
      opts.unshift({ key: null, value: '' });
      return opts;
    },
  },
  methods: {
    getRemovalDescription(tradingStandards) {
      const removalDate = this.$helpers.dateString(tradingStandards.removalDate);
      const cancelled = tradingStandards.cancelledApplication ? ' - cancelled' : '';
      const notRenewing = tradingStandards.notRenewing ? ' - not renewing' : '';
      return `${removalDate}${cancelled}${notRenewing}`;
    },
    expandCreateTradingStandardsApplication() {
      this.tradingStandardsAppExpand = true;
    },
    cancelCreateTradingStandardsApplication() {
      this.tradingStandardsAppExpand = false;
    },
    confirmCreateTradingStandardsApplication() {
      this.$refs.confirmDialog
        .show({
          title: 'Create Trading Standards Application',
          message:
            'Are you sure you want to create an application to progress Trading Standards for this business?',
          okButtonText: 'Yes',
          confirmType: confirmType.standard,
        })
        .then((result) => {
          if (result) {
            const business = this.model.linkedSchemeBusinesses.filter(
              (x) => x.schemeBusinessId === this.requestSchemeBusinessId
            );
            this.tradingStandardsApplicationCreate = true;
            this.$store
              .dispatch('tradingStandardsAdmin/createTradingStandardsYear', {
                schemeId: business[0].schemeId,
                schemeBusinessId: this.requestSchemeBusinessId,
                schemeBusinessContactId: null,
                trustmarkBusinessId: this.model.trustmarkBusinessId,
                tmln: this.model.tmln,
              })
              .then((resp) => {
                this.$router.push(`/tradingstandards/${resp.tmln}/${resp.renewalYear}`);
              })
              .catch((err) => {
                window.scrollTo(0, 0);
                this.loading = false;
                this.errorMessage =
                  (err.response && err.response.data && err.response.data.message) ||
                  err.message ||
                  err.toString();
              });
          }
        });
    },
  },
};
</script>
