import { HTTP, authHeader } from './http-service';

class DisputeAssociationService {
  list(disputeId) {
    return HTTP.get(`/disputeAssociation/dispute/${disputeId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  get(disputeId, associationId) {
    return HTTP.get(`/disputeAssociation/${associationId}/dispute/${disputeId}/`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  create(payload) {
    return HTTP.post(`/disputeAssociation`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/disputeAssociation`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  delete(payload) {
    return HTTP.delete(
      `/disputeAssociation/${payload.disputeAssociationId}/dispute/${payload.disputeId}`,
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }
}

export default new DisputeAssociationService();
