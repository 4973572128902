<template>
  <div v-if="model">
    <div>
      <card v-if="isSplitRequest" label="New TMLN Request">
        A new TMLN request has been made to split this business and will be be processed shortly.
      </card>

      <card v-if="!isSplitRequest" id="pending_licence" label="Pending Licence">
        <div v-if="errorMessage" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>

        <div class="row mb-4">
          <div class="col-md-5">
            <div class="mb-6">
              <key-value-presenter
                label="Registered Company Name"
                :value="model.registeredCompanyName"
              />
            </div>
            <div class="mb-6">
              <router-link :to="`/tmlns/${model.trustmarkBusinessId}`">
                <font-awesome-icon icon="circle-right" class="icon" />
              </router-link>
              <key-value-presenter label="TMLN" :value="model.tmln" />
            </div>
          </div>
        </div>

        <div v-if="!actionSent && model.action === 0">
          <p v-if="model.isAdminRequest">
            An admin request has been made to join {{ model.tmln }}, you can accept or cancel this
            below.
          </p>
          <p v-if="!model.isAdminRequest">
            This business has a Pending Licence to action and requires a user decision.
          </p>

          <pending-licence-comparison />

          <div class="row">
            <div class="col d-flex">
              <button
                type="button"
                class="btn btn-lg btn-primary me-3"
                @click="acceptPendingLicence"
              >
                Accept and Join {{ model.tmln }}
              </button>
              <button
                v-if="!model.isAdminRequest"
                type="button"
                class="btn btn-lg btn-danger"
                @click="denyPendingLicence"
              >
                Deny and Create a New TMLN
              </button>
              <button
                v-if="model.isAdminRequest"
                type="button"
                class="btn btn-lg btn-danger"
                @click="cancelAdminPendingLicence"
              >
                Cancel Request
              </button>
            </div>
          </div>
        </div>

        <div v-if="actionSent | (model.action !== 0)" class="alert alert-info" role="alert">
          The pending licence has been actioned and will be be processed shortly.
          <button type="button" class="btn btn-info ms-2" @click="refreshBusiness">Refresh</button>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { Card, KeyValuePresenter } from '@trustmark-2005-ltd/trustmark-shared-components';
import PendingLicenceComparison from '../pendingLicenceAction/PendingLicenceComparison.vue';

export default {
  name: 'SchemeBusinessLinkPending',
  components: {
    Card,
    KeyValuePresenter,
    PendingLicenceComparison,
  },
  data() {
    return {
      actionSent: false,
      errorMessage: null,
    };
  },
  computed: {
    model() {
      return this.$store.state.schemeBusiness.business.schemeBusiness.linkPending;
    },
    schemeBusiness() {
      return this.$store.state.schemeBusiness.business.schemeBusiness;
    },
    isSplitRequest() {
      return this.schemeBusiness && this.model && this.model.action === 3;
    },
  },
  methods: {
    acceptPendingLicence() {
      const payload = this.model;
      payload.schemeId = this.schemeBusiness.schemeId;
      payload.schemeBusinessId = this.schemeBusiness.schemeBusinessId;
      this.$store
        .dispatch('pendingLicenceAction/accept', payload)
        .then(() => {
          this.actionSent = true;
        })
        .catch((err) => {
          this.errorMessage = err;
        });
    },
    denyPendingLicence() {
      const payload = this.model;
      payload.schemeId = this.schemeBusiness.schemeId;
      payload.schemeBusinessId = this.schemeBusiness.schemeBusinessId;
      payload.tmln = this.model.tmln;
      this.$store
        .dispatch('pendingLicenceAction/deny', payload)
        .then(() => {
          this.actionSent = true;
        })
        .catch((err) => {
          this.errorMessage = err;
        });
    },
    cancelAdminPendingLicence() {
      const payload = this.model;
      payload.schemeId = this.schemeBusiness.schemeId;
      payload.schemeBusinessId = this.schemeBusiness.schemeBusinessId;
      payload.tmln = this.model.tmln;
      this.$store
        .dispatch('pendingLicenceAction/canceladmin', payload)
        .then(() => {
          this.actionSent = false;
        })
        .catch((err) => {
          this.errorMessage = err;
        });
    },
    refreshBusiness() {
      this.$store.dispatch('schemeBusiness/get', {
        schemeId: this.$route.params.schemeId,
        schemeBusinessId: this.$route.params.schemeBusinessId,
      });
    },
  },
};
</script>
