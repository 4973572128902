import LicenceNumberService from '../services/licence-number-service';
import RetrofitAccountService from '../services/retrofit-account-service';
import ComplianceService from '../services/compliance-service';

export const licenceNumber = {
  namespaced: true,
  state: {
    licenceNumbers: null,
    trustmarkBusiness: null,
    searchResult: null,
    pageNumber: 0,
    lastSearchConfig: null,
    retrofitAccount: null,
    feeCategories: null,
    appData: {},
    listDDTopups: null,
  },
  actions: {
    getRetrofitAccountByTMLN({ commit }, tmln) {
      commit('setIsBusy', true, {
        root: true,
      });
      commit('setRetrofitAccount', null);
      return RetrofitAccountService.get(tmln).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setRetrofitAccount', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    createRetrofitAccount({ commit }, payload) {
      commit('setIsBusy', true, {
        root: true,
      });
      commit('setRetrofitAccount', null);
      return RetrofitAccountService.post(payload).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setRetrofitAccount', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    ddTopup({ commit }, { tmln, amount, reference }) {
      commit('setIsBusy', true, {
        root: true,
      });
      return RetrofitAccountService.ddTopup(tmln, {
        amount,
        reference,
      }).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setRetrofitAccount', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    getDDTopups({ commit, state }) {
      commit('setIsBusy', true, {
        root: true,
      });
      const { retrofitAccountId } = state.retrofitAccount;
      return RetrofitAccountService.getDDTopups(retrofitAccountId).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setListDDTops', result.results);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    refund({ commit }, { tmln, amount, description }) {
      commit('setIsBusy', true, {
        root: true,
      });
      return RetrofitAccountService.refund(tmln, {
        amount,
        description,
      }).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setRetrofitAccount', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    suspend({ commit }, { tmln, description }) {
      commit('setIsBusy', true, {
        root: true,
      });
      return ComplianceService.suspend(tmln, description).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    unsuspend({ commit }, { tmln, description }) {
      commit('setIsBusy', true, {
        root: true,
      });
      return ComplianceService.unsuspend(tmln, description).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    addUser({ commit }, { retrofitAccountId, tmln, accountId, firstName, lastName, roles }) {
      commit('setIsBusy', true, {
        root: true,
      });
      return RetrofitAccountService.user(retrofitAccountId, {
        accountId,
        tmln,
        firstName,
        lastName,
        roles,
      }).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setRetrofitAccount', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    removeUser({ commit }, { retrofitAccountId, accountId }) {
      commit('setIsBusy', true, {
        root: true,
      });
      return RetrofitAccountService.removeUser(retrofitAccountId, accountId).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setRetrofitAccount', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    getAppData({ commit }) {
      commit('setIsBusy', true, {
        root: true,
      });
      return RetrofitAccountService.appData().then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setRetrofitAccountAppData', result);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    getById({ commit }, trustmarkBusinessId) {
      commit('setIsBusy', true, {
        root: true,
      });
      commit('setTrustmarkBusiness', null);
      return LicenceNumberService.getById(trustmarkBusinessId).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setTrustmarkBusiness', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    getByTMLN({ commit }, tmln) {
      commit('setIsBusy', true, {
        root: true,
      });
      commit('setTrustmarkBusiness', null);
      return LicenceNumberService.getByTMLN(tmln).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setTrustmarkBusiness', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    search({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      data.type = 'standard';
      commit('setLastSearchConfig', data);
      return LicenceNumberService.search(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setLicenceNumberSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    searchAdvanced({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      data.type = 'advanced';
      commit('setLastSearchConfig', data);
      return LicenceNumberService.advancedSearch(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setLicenceNumberSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    makePrimary({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      commit('setTrustmarkBusiness', null);
      return LicenceNumberService.makePrimary(payload).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setTrustmarkBusiness', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    makeInactive({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      commit('setTrustmarkBusiness', null);
      return LicenceNumberService.makeInactive(payload).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setTrustmarkBusiness', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    putFeeCategories({ commit }, { retrofitAccountId, feeCategories }) {
      commit('setIsBusy', true, {
        root: true,
      });
      return RetrofitAccountService.putFeeCategories(retrofitAccountId, {
        feeCategories,
      }).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setRetrofitAccount', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    putNotificationEmails({ commit }, { retrofitAccountId, emails }) {
      commit('setIsBusy', true, {
        root: true,
      });
      return RetrofitAccountService.putNotificationEmails(retrofitAccountId, emails).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setRetrofitAccount', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    enableLoginLock({ commit }, { retrofitAccountId }) {
      commit('setIsBusy', true, {
        root: true,
      });
      return RetrofitAccountService.enableLoginLock(retrofitAccountId).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setRetrofitAccount', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    disableLoginLock({ commit }, { retrofitAccountId }) {
      commit('setIsBusy', true, {
        root: true,
      });
      return RetrofitAccountService.disableLoginLock(retrofitAccountId).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setRetrofitAccount', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setRetrofitAccount(state, newRetrofitAccount) {
      state.retrofitAccount = newRetrofitAccount;
    },
    setRetrofitAccountAppData(state, newAppData) {
      state.appData = newAppData;
    },
    setTrustmarkBusiness(state, newTrustmarkBusiness) {
      state.trustmarkBusiness = newTrustmarkBusiness;
    },
    setLicenceNumbers(state, newLicenceNumbers) {
      state.licenceNumbers = newLicenceNumbers;
    },
    setLicenceNumberSearchResult(state, searchResult) {
      state.searchResult = searchResult;
    },
    setLastSearchConfig(state, searchconfig) {
      state.lastSearchConfig = searchconfig;
    },
    setSearchPageNumber(state, newPageNumber) {
      state.pageNumber = newPageNumber;
    },
    setListDDTops(state, newList) {
      state.listDDTopups = newList;
    },
  },
};
