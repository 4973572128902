<template>
  <subtitle>Edit Note</subtitle>

  <div v-if="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <div v-if="successMessage" class="alert alert-success" role="alert">
    {{ successMessage }}
  </div>

  <edit-fields :v="v$" />

  <div class="form-group d-grid gap-2 d-md-flex justify-content-md-end">
    <router-link class="btn btn-secondary float-right btn-lg" type="button" :to="parentRoute">
      <span>Cancel</span>
    </router-link>

    <smart-submit-large label="Save" :loading="loading" @click="save" />
  </div>
</template>

<script>
import { SmartSubmitLarge, Subtitle } from '@trustmark-2005-ltd/trustmark-shared-components';
import useValidate from '@vuelidate/core';
import EditFields from './SchemesEditNotesFields.vue';
import { validationModel } from './validation-model';

export default {
  name: 'SchemesEditNotesEdit',
  components: {
    SmartSubmitLarge,
    EditFields,
    Subtitle,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      successMessage: null,
      noteId: this.$route.params.noteId,
      v$: useValidate(),
    };
  },
  computed: {
    parentRoute() {
      return `/scheme/${this.$route.params.schemeId}/edit/notes`;
    },
    model() {
      return this.$store.state.schemeNote.note;
    },
  },
  mounted() {
    if (!this.model) {
      const payload = {
        noteId: this.noteId,
        schemeId: this.$route.params.schemeId,
      };
      this.$store.dispatch('schemeNote/getNote', payload);
    }
  },
  methods: {
    save() {
      this.v$.$validate();
      if (this.v$.$error) {
        window.scrollTo(0, 0);
        this.errorMessage =
          'Unable to save the note. Please resolve any errors on this form and try again.';
        return;
      }

      const payload = this.model;
      this.$store
        .dispatch('schemeNote/updateNote', payload)
        .then((resp) => {
          this.$store.dispatch('schemeNote/listNotes', this.$route.params.schemeId).then(() => {
            this.successMessage = resp;
            this.$router.push(this.parentRoute);
          });
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        });
    },
  },
  validations: {
    model: validationModel,
  },
};
</script>
