import SchemeContactService from '../services/scheme-contact-service';

export const schemeContact = {
  namespaced: true,
  state: {
    contacts: null,
    contact: null,
  },
  actions: {
    newContact({ commit, rootState }, schemeId) {
      const template = rootState.auth.appData.models.schemeContact;
      const empty = JSON.parse(JSON.stringify(template));
      empty.schemeId = schemeId;
      commit('setContact', empty);
    },
    listContacts({ commit }, schemeId) {
      commit('setIsBusy', true, { root: true });
      return SchemeContactService.list(schemeId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setContacts', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    createContact({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return SchemeContactService.create(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    getContact({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      commit('setContact', null);
      return SchemeContactService.get(payload.schemeId, payload.contactId).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setContact', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateContact({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return SchemeContactService.update(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    deleteContact({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeContactService.delete(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setContacts(state, newContacts) {
      state.contacts = newContacts;
    },
    setContact(state, newContact) {
      state.contact = newContact;
    },
  },
};
