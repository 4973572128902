<template>
  <form>
      <p class="mt-4">
          Please scan the QR code below with your authenticator app and enter the two consecutive codes below (the second code will appear once the first code has timed out).
      </p>
      <div class="d-flex justify-content-center mt-4">
          <qrcode-vue :value="qrCode" :size="200" level="H" />
      </div>
      <div v-if="errorMessage" class="alert alert-danger mt-4" role="alert">
          {{ errorMessage }}
      </div>
      <smart-input
          id="confirmationCode"
          v-model="model.confirmationCode"
          label="Received Verification Code"
          :is-required="!!v$.model.confirmationCode.isRequired"
          :validator="v$.model.confirmationCode"
      />
      <smart-input
          id="totpCode1"
          v-model="model.totpCode1"
          class="mt-0"
          label="First Code"
          :is-required="!!v$.model.totpCode1.isRequired"
          :validator="v$.model.totpCode1"
      />
      <smart-input
          id="totpCode2"
          v-model="model.totpCode2"
          class="mt-4"
          label="Second Code"
          :is-required="!!v$.model.totpCode2.isRequired"
          :validator="v$.model.totpCode2"
      />
      <button
          class="btn btn-primary mt-4"
          :disabled="busy"
          @click="onSubmitTotp"
      >
          Submit
      </button>
  </form>
</template>

<script>
import {
  Card,
  SmartInput,
  SmartButton
} from '@trustmark-2005-ltd/trustmark-shared-components';
import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'Login2FAReset',
  components: {
      Card,
      SmartInput,
      SmartButton,
      QrcodeVue,
  },
  props: {
      totpKey: {
          type: String,
          required: true
      },
      email: {
          type: String,
          required: true
      },
      accessToken: {
          type: String,
          required: true
      },
      password: {
          type: String,
          required: true
      },
  },
  data() {
      return {
          model: {
              confirmationCode: '',
              totpCode1: '',
              totpCode2: '',
          },
          errorMessage: '',
          busy: false,
          v$: useValidate()
      }
  },
  computed: {
      qrCode() {
          return `otpauth://totp/TrustMark%20Backoffice%20Portal:${encodeURIComponent(this.email)}?secret=${this.totpKey}&issuer=TrustMark&algorithm=SHA1`;
      }
  },
  methods: {
      async onSubmitTotp() {
          this.busy = true;
          this.errorMessage = '';
          this.v$.$validate();
          if (this.v$.$invalid) {
              this.busy = false;
              return;
          }

          this.model.accessToken = this.accessToken;

          try {
              await this.$store.dispatch('auth/completeReset', this.model)
              this.busy = false;
              const resp = await this.$store.dispatch('auth/login', {
                  username: this.email,
                  password: this.password,
              })
              if (resp.model.deviceValid) {
                  this.success = true;
                  window.scrollTo(0, 0);
                  this.$store.dispatch('dashboard/getDashboard').then((url) => {
                      this.$router.push(url);
                  });
              }
          } catch (error) {
              this.errorMessage = error?.response?.data?.message || 'An error occurred';
              this.busy = false;
          }            
      }
  },
  validations: {
      model: {
          totpCode1: {
              isRequired: helpers.withMessage('First Code is required', required)
          },
          totpCode2: {
              isRequired: helpers.withMessage('Second Code is required', required)
          },
          confirmationCode: {
              isRequired: helpers.withMessage('Email Code is required', required)
          },
      }
  },        
}
</script>