import SchemeTradeService from '../services/scheme-trade-service';

export const schemeTrade = {
  namespaced: true,
  state: {
    trades: null,
    trade: null,
  },
  actions: {
    initialise({ commit, rootState }, schemeId) {
      const template = rootState.auth.appData.models.schemeTrade;
      const empty = JSON.parse(JSON.stringify(template));
      empty.schemeId = schemeId;
      empty.tradeCode = null;
      commit('setSchemeTrade', empty);
    },
    list({ commit }, schemeId) {
      commit('setIsBusy', true, { root: true });
      return SchemeTradeService.list(schemeId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setSchemeTrades', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    create({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return SchemeTradeService.create(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    get({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      commit('setSchemeTrade', null);
      return SchemeTradeService.get(payload.schemeId, payload.tradeCode).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setSchemeTrade', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    update({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return SchemeTradeService.update(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    delete({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeTradeService.delete(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setSchemeTrades(state, newTrades) {
      state.trades = newTrades;
    },
    setSchemeTrade(state, newTrade) {
      if (newTrade && newTrade.obsoleteDateFrom) {
        newTrade.obsoleteDateFrom = new Date(newTrade.obsoleteDateFrom);
      }
      state.trade = newTrade;
    },
  },
};
