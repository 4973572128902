import RegistrationBillingService from '../services/registration-billing-service';

export const registrationBilling = {
  namespaced: true,
  state: {
    registrations: null,
    registrationBilling: null,
    schemeIds: [],
  },
  actions: {
    newDeclaration({ rootState, commit }) {
      const declarationTemplate = JSON.parse(
        JSON.stringify(rootState.auth.appData.models.schemeRegistrationBillingDto)
      );
      declarationTemplate.isDeclaration = true;
      declarationTemplate.schemeId = rootState.auth.account.scheme.schemeId;
      declarationTemplate.month = new Date().getMonth() + 1;
      declarationTemplate.year = new Date().getFullYear();
      commit('setDeclaration', declarationTemplate);
    },
    listBySchemeId({ commit }, schemeId) {
      return RegistrationBillingService.listBySchemeId(schemeId)
        .then((result) => {
          commit('setRegistrations', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    listByPeriod({ commit }, period) {
      return RegistrationBillingService.listByPeriod(period)
        .then((result) => {
          commit('setRegistrations', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    get({ commit }, payload) {
      commit('setIsBusy', true, {
        root: true,
      });
      commit('setRegistrationBilling', null);
      return RegistrationBillingService.get(payload.schemeId, payload.period).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setRegistrationBilling', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    updateDeclaration({ commit }, data) {
      commit('setIsBusy', true, {
        root: true,
      });
      return RegistrationBillingService.update(data)
        .then((result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setRegistrations(state, newRegistrations) {
      state.registrations = newRegistrations;
      if (state.schemeIds === null || state.schemeIds.length === 0) {
        state.schemeIds = newRegistrations.map((x) => x.schemeId);
      }
    },
    setRegistrationBilling(state, newRegistrationBilling) {
      state.registrationBilling = newRegistrationBilling;
    },
  },
};
