<template>
  <subtitle>Edit Scheme Trade</subtitle>

  <div v-if="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <div v-if="successMessage" class="alert alert-success" role="alert">
    {{ successMessage }}
  </div>

  <edit-fields :v="v$" />

  <div class="form-group d-grid gap-2 d-md-flex justify-content-md-end">
    <router-link class="btn btn-secondary float-right btn-lg" type="button" :to="parentRoute">
      <span>Cancel</span>
    </router-link>

    <smart-submit-large label="Save" :loading="loading" @click="save" />
  </div>
</template>

<script>
import { SmartSubmitLarge, Subtitle } from '@trustmark-2005-ltd/trustmark-shared-components';
import useValidate from '@vuelidate/core';
import { helpers, numeric, required } from '@vuelidate/validators';
import EditFields from './SchemesEditTradesEditFields.vue';

export default {
  name: 'SchemesEditTradesEdit',
  components: {
    SmartSubmitLarge,
    EditFields,
    Subtitle,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      successMessage: null,
      tradeCode: this.$route.params.tradeCode,
      v$: useValidate(),
    };
  },
  computed: {
    parentRoute() {
      return `/scheme/${this.$route.params.schemeId}/edit/scheme-trades`;
    },
    model() {
      return this.$store.state.schemeTrade.trade;
    },
  },
  mounted() {
    if (!this.model) {
      const payload = {
        tradeCode: this.tradeCode,
        schemeId: this.$route.params.schemeId,
      };
      this.$store.dispatch('schemeTrade/get', payload);
    }
  },
  methods: {
    save() {
      this.v$.$validate();
      if (this.v$.$error) {
        window.scrollTo(0, 0);
        this.errorMessage =
          'Unable to save the trade. Please resolve any errors on this form and try again.';
        return;
      }

      const payload = {
        notes: this.model.notes,
        tradeDescription: this.model.tradeDescription,
        tradeCode: parseInt(this.model.tradeCode, 10),
        schemeId: this.$route.params.schemeId,
        status: this.model.status,
        obsoleteDateFrom: this.model.obsoleteDateFrom,
      };

      this.$store
        .dispatch('schemeTrade/update', payload)
        .then((resp) => {
          this.$store.dispatch('schemeTrade/list', this.$route.params.schemeId).then(() => {
            this.successMessage = resp;
            this.$router.push(this.parentRoute);
          });
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        });
    },
  },
  validations() {
    const v = {
      model: {
        tradeDescription: {},
        tradeCode: {
          isRequired: helpers.withMessage('Trade code is required', required),
          numeric: helpers.withMessage('Trade code must be numeric', numeric),
        },
        status: {
          isRequired: helpers.withMessage('Status is required', required),
        },
        notes: {},
      },
    };

    if (this.model?.status === 'Obsolete') {
      v.model.obsoleteDateFrom = {
        isRequired: helpers.withMessage('Obsolete date from is required', required),
      };
    }
    return v;
  },
};
</script>
