<template>
  <div v-if="application" class="row">
    <div class="col-sm-12 col-md-10 col-lg-10">
      <h1>Edit Application: {{ application.applicationReference }}</h1>

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <div v-if="successMessage" class="alert alert-success" role="alert">
        {{ successMessage }}
      </div>

      <card label="Responsible Person">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <smart-input
              id="ResponsiblePersonFirstName"
              v-model="model.responsiblePersonFirstName"
              label="First Name"
              :is-required="!!v$.model.responsiblePersonFirstName.isRequired"
              :validator="v$.model.responsiblePersonFirstName"
            />
            <smart-input
              id="ResponsiblePersonLastName"
              v-model="model.responsiblePersonLastName"
              label="Last Name"
              :is-required="!!v$.model.responsiblePersonLastName.isRequired"
              :validator="v$.model.responsiblePersonLastName"
            />
            <smart-input
              id="ResponsiblePersonEmail"
              v-model="model.responsiblePersonEmail"
              label="Email"
              :is-required="!!v$.model.responsiblePersonEmail.isRequired"
              :validator="v$.model.responsiblePersonEmail"
            />
            <smart-input
              id="ResponsiblePersonTelephone"
              v-model="model.responsiblePersonTelephone"
              label="Telephone"
              :is-required="!!v$.model.responsiblePersonTelephone.isRequired"
              :validator="v$.model.responsiblePersonTelephone"
            />
          </div>
          <div class="col-sm-12 col-md-6">
            <smart-input
              id="ResponsiblePersonAddressLine1"
              v-model="model.responsiblePersonAddressLine1"
              label="Address Line 1"
              :is-required="!!v$.model.responsiblePersonAddressLine1.isRequired"
              :validator="v$.model.responsiblePersonAddressLine1"
            />
            <smart-input
              id="ResponsiblePersonAddressLine2"
              v-model="model.responsiblePersonAddressLine2"
              label="Address Line 2"
              :is-required="!!v$.model.responsiblePersonAddressLine2.isRequired"
              :validator="v$.model.responsiblePersonAddressLine2"
            />
            <smart-input
              id="ResponsiblePersonAddressLine3"
              v-model="model.responsiblePersonAddressLine3"
              label="Address Line 3"
              :is-required="v$.model.responsiblePersonAddressLine3.isRequired"
              :validator="v$.model.responsiblePersonAddressLine3"
            />
            <smart-input
              id="ResponsiblePersonAddressCity"
              v-model="model.responsiblePersonAddressCity"
              label="City"
              :is-required="!!v$.model.responsiblePersonAddressCity.isRequired"
              :validator="v$.model.responsiblePersonAddressCity"
            />
            <smart-input
              id="ResponsiblePersonAddressCounty"
              v-model="model.responsiblePersonAddressCounty"
              label="County"
              :is-required="!!v$.model.responsiblePersonAddressCounty.isRequired"
              :validator="v$.model.responsiblePersonAddressCounty"
            />
            <smart-input
              id="ResponsiblePersonAddressPostcode"
              v-model="model.responsiblePersonAddressPostcode"
              label="Postcode"
              :is-required="!!v$.model.responsiblePersonAddressPostcode.isRequired"
              :validator="v$.model.responsiblePersonAddressPostcode"
            />
            <smart-input
              id="ResponsiblePersonAddressCountry"
              v-model="model.responsiblePersonAddressCountry"
              label="Country"
              :is-required="!!v$.model.responsiblePersonAddressCountry.isRequired"
              :validator="v$.model.responsiblePersonAddressCountry"
            />
          </div>
        </div>
      </card>

      <card label="Status">
        <smart-select-text-value-numeric
          id="modelSchemeId"
          v-model="model.status"
          label="Status"
          :is-required="!!v$.model.status"
          :validator="v$.model.status"
          :options="applicationStatuses"
        />
      </card>

      <card label="Internal Comments">
        <div class="row">
          <div class="col-sm-12">
            <smart-textarea
              id="internalComments"
              v-model="model.internalComments"
              :validator="v$.model.internalComments"
              style="min-height: 200px"
            />
            <small>These can only be seen internally by TrustMark</small>
          </div>
        </div>
      </card>

      <card label="Activity">
        <table v-if="model.activity && model.activity.length > 0" class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Date Time</th>
              <th scope="col">User</th>
              <th scope="col">Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in model.activity" :key="i">
              <td>
                {{ $helpers.dateTimeString(item.dateTimeStamp) }}
              </td>
              <td>
                {{ item.username }}
              </td>
              <td>
                {{ item.description }}
              </td>
            </tr>
          </tbody>
        </table>
      </card>

      <div class="form-group d-grid gap-2 d-md-flex justify-content-md-end">
        <router-link class="btn btn-secondary float-right btn-lg" type="button" :to="parentRoute">
          <span>Cancel</span>
        </router-link>

        <smart-submit-large label="Save" :loading="loading" @click="save" />
      </div>
    </div>
  </div>
  <div style="height: 250px"></div>
</template>

<script>
import {
  SmartSubmitLarge,
  Card,
  SmartSelectTextValueNumeric,
  SmartInput,
  SmartTextarea,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import useValidate from '@vuelidate/core';
import { between, helpers, required, maxLength } from '@vuelidate/validators';
import { formatDate, age } from '../../services/scheme-common';

export default {
  name: 'ApplicationsEdit',
  components: {
    SmartSubmitLarge,
    Card,
    SmartInput,
    SmartSelectTextValueNumeric,
    SmartTextarea,
  },
  data() {
    return {
      v$: useValidate(),
      successMessage: null,
      errorMessage: null,
      model: {
        status: null,
        applicationReference: null,
      },
    };
  },
  computed: {
    newUpdateRequest() {
      return this.$store.state.auth.appData.models.applicationInternalUpdate;
    },
    application() {
      return this.$store.state.application.application;
    },
    parentRoute() {
      return `/applications/${this.$route.params.applicationRef}`;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isTradingStandards() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('TradingStandards')
        : false;
    },
    applicationStatuses() {
      return this.$store.state.auth.appData.applicationStatuses.map((x) => {
        return { text: x.description, value: x.status };
      });
    },
    tradingStandardsWorkflowStatuses() {
      return this.$store.state.auth.appData.tradingStandardsWorkflowStatuses;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    if (
      !this.application ||
      this.application.applicationRef !== this.$route.params.applicationRef
    ) {
      this.$store.dispatch('application/get', this.$route.params.applicationRef).then(() => {
        this.model = JSON.parse(JSON.stringify(this.application));
      });
    } else {
      this.model = JSON.parse(JSON.stringify(this.application));
    }
  },
  methods: {
    formatDate,
    age,
    save() {
      this.errorMessage = null;
      this.successMessage = null;

      const statusBypass = ['Archived', 'Rejected', 'Not Eligible', 'Application Withdrawn'];

      if (
        statusBypass.indexOf(
          this.applicationStatuses.find((x) => x.value === this.model.status)?.text
        ) === -1
      ) {
        this.v$.$validate();

        if (this.v$.$error) {
          window.scrollTo(0, 0);
          this.errorMessage =
            'Unable to save application. Please resolve any errors on this form and try again.';
          return;
        }
      }

      const payload = JSON.parse(JSON.stringify(this.model));
      this.$store
        .dispatch('application/updateApplication', payload)
        .then(() => {
          this.successMessage = 'Changes saved';
          this.$router.push(this.parentRoute);
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        });
    },
  },
  validations() {
    return {
      model: {
        responsiblePersonFirstName: {
          isRequired: helpers.withMessage('First Name is required', required),
          maxLength: maxLength(100),
        },
        responsiblePersonLastName: {
          isRequired: helpers.withMessage('Last Name is required', required),
          maxLength: maxLength(100),
        },
        responsiblePersonEmail: {
          isRequired: helpers.withMessage('Email is required', required),
          maxLength: maxLength(200),
        },
        responsiblePersonTelephone: {
          isRequired: helpers.withMessage('Telephone is required', required),
          maxLength: maxLength(100),
        },
        responsiblePersonAddressLine1: {
          maxLength: maxLength(100),
        },
        responsiblePersonAddressLine2: {
          maxLength: maxLength(100),
        },
        responsiblePersonAddressLine3: {
          maxLength: maxLength(100),
        },
        responsiblePersonAddressCity: {
          maxLength: maxLength(100),
        },
        responsiblePersonAddressCounty: {
          maxLength: maxLength(100),
        },
        responsiblePersonAddressPostcode: {
          maxLength: maxLength(100),
        },
        responsiblePersonAddressCountry: {
          maxLength: maxLength(100),
        },
        status: {
          isRequired: helpers.withMessage('Status is required', required),
          between: helpers.withMessage('Status is required', between(1, 11)),
        },
        internalComments: {
          maxLength: maxLength(2000),
        },
      },
    };
  },
};
</script>
