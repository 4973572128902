<template>
  <div>
    <div class="btn-toolbar mb-3" role="toolbar">
      <div class="row w-100">
        <div class="col-sm-12 col-md-9 col-lg-6">
          <div class="input-group">
            <div id="btnGroupAddon" class="input-group-text bg-transparent border-end-0">
              <font-awesome-icon icon="magnifying-glass" />
            </div>
            <input
              v-model="searchModel.searchTerm"
              type="text"
              class="form-control border-start-0"
              placeholder="Find licence number"
            />
            <smart-button :loading="loading" :click="newSearch"> Search </smart-button>
            <smart-button
              class-override="btn-outline-secondary"
              :loading="false"
              :disabled="!resultsModel"
              :click="reset"
            >
              Reset
            </smart-button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="searchError" class="alert alert-danger" role="alert">
      There was a problem with the search {{ searchError }}
    </div>

    <div
      v-if="resultsModel && resultsModel.results.length === 0 && !loading"
      class="alert alert-warning"
      role="alert"
    >
      There are no matching results for this search.
    </div>

    <table v-if="resultsModel && resultsModel.results.length > 0" class="table table-hover">
      <thead>
        <tr>
          <th scope="col">TMLN</th>
          <th scope="col">Status</th>
          <th scope="col">Registered Company Name</th>
          <th scope="col">Schemes</th>
          <th scope="col"></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="tmb in resultsModel.results" :key="tmb.trustmarkBusinessId">
          <td>{{ tmb.tmln }}</td>
          <td>{{ tmb.statusString }}</td>
          <td>{{ tmb.registeredCompanyName }}</td>
          <td>{{ !tmb.schemes ? 'None' : tmb.schemes.join(' ') }}</td>
          <td>
            <div class="btn-group me-2">
              <router-link
                :to="`/tmlns/${tmb.trustmarkBusinessId}`"
                class="btn btn-outline-primary"
              >
                View
              </router-link>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="resultsModel">
      <nav>
        <ul class="pagination">
          <li class="pointer page-item" :class="{ disabled: !enablePrevious }">
            <span
              class="page-link"
              tabindex="-1"
              :aria-disabled="!enablePrevious"
              @click="
                () => {
                  selectPage(0);
                }
              "
              >First</span
            >
          </li>
          <li class="pointer page-item" :class="{ disabled: !enablePrevious }">
            <span
              class="page-link"
              tabindex="-1"
              :aria-disabled="!enablePrevious"
              @click="
                () => {
                  selectPage(pageNumber - 1);
                }
              "
              >Previous</span
            >
          </li>
          <li
            v-for="i in pagesToShow"
            :key="i"
            :class="{ active: i - 1 === pageNumber }"
            class="pointer page-item"
            @click="
              () => {
                selectPage(i - 1);
              }
            "
          >
            <span class="page-link">{{ i }}</span>
          </li>
          <li class="pointer page-item" :class="{ disabled: !enableNext }">
            <span
              class="page-link"
              :aria-disabled="!enableNext"
              @click="
                () => {
                  selectPage(pageNumber + 1);
                }
              "
              >Next</span
            >
          </li>
          <li class="pointer page-item" :class="{ disabled: !enableNext }">
            <span
              class="page-link"
              :aria-disabled="!enableNext"
              @click="
                () => {
                  selectPage(resultsModel.totalPages - 1);
                }
              "
              >Last page {{ resultsModel.totalPages }}</span
            >
          </li>
          <!-- <span class="p-2">{{ resultsModel.totalItems }} Licences. </span> -->
        </ul>
      </nav>
    </div>

    <div v-if="!resultsModel && !loading" class="alert alert-info" role="alert">
      Your search results will be listed here.
    </div>
  </div>
</template>
<script>
import { SmartButton } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'LicenceNumberSearchTable',
  components: {
    SmartButton,
  },
  data() {
    return {
      loading: false,
      searchModel: {
        searchTerm: null,
        previousSearchTerm: null,
      },
      searchError: null,
    };
  },
  computed: {
    pagesToShow() {
      const maxDisplay = 10;
      let minPage = this.pageNumber - maxDisplay / 2 + 1;
      if (minPage < 1) {
        minPage = 1;
      }
      let maxPage = minPage + maxDisplay;
      if (maxPage > this.resultsModel.totalPages) {
        maxPage = this.resultsModel.totalPages;
      }
      const pages = [];
      for (let i = minPage; i <= maxPage; i += 1) {
        pages.push(i);
      }
      return pages;
    },
    pageNumber() {
      return this.$store.state.licenceNumber.pageNumber;
    },
    resultsModel() {
      return this.$store.state.licenceNumber.searchResult;
    },
    enablePrevious() {
      if (!this.resultsModel) return false;
      return !(this.pageNumber - 1 < 0);
    },
    enableNext() {
      if (!this.resultsModel) return false;
      return this.pageNumber < this.resultsModel.totalPages - 1;
    },
    disabled() {
      return !this.searchModel.searchTerm || this.searchModel.searchTerm.length === 0;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    lastSearchConfig() {
      return this.$store.state.licenceNumber.lastSearchConfig;
    },
    isStandardSearch() {
      return this.lastSearchConfig.type === 'standard';
    },
  },
  watch: {
    pageNumber(newPageNumber) {
      if (this.isStandardSearch) {
        console.log(`standard pageNumber ${newPageNumber}`);
        this.search();
      }
    },
  },
  mounted() {
    if (!this.resultsModel) {
      // Ensure will have the first page on load
      this.search();
    }
  },
  methods: {
    changeErrorMessage(msg) {
      this.searchError = msg;
    },
    selectPage(index) {
      this.$store.commit('licenceNumber/setSearchPageNumber', index, { root: true });
    },
    newSearch() {
      this.$store.commit('licenceNumber/setSearchPageNumber', 0, { root: true });
      this.search();
    },
    search() {
      if (this.loading) return;
      this.searchError = null;
      this.loading = true;

      if (this.searchModel.searchTerm !== this.searchModel.previousSearchTerm) {
        this.$store.commit('licenceNumber/setSearchPageNumber', 0, { root: true });
      }

      this.searchModel.previousSearchTerm = this.searchModel.searchTerm;

      const payload = {
        pageParameters: {
          size: 10,
          number: this.pageNumber,
        },
        sort: {
          item: 'registeredCompanyName',
          direction: 'ascending',
        },
        fieldNames: ['trustmarkBusinessId', 'tMLN', 'registeredCompanyName', 'schemes'],
        searchTerm: this.searchModel.searchTerm,
        operator: 1,
      };

      this.$store
        .dispatch('licenceNumber/search', payload)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.searchError = err.message ?? err;
        });
    },
    reset() {
      this.searchModel.searchTerm = '';
      this.search();
    },
  },
};
</script>
