import { HTTP_RETROFIT, authHeader } from './http-service';

class EnergySuppliersService {

  getAll() {
    return HTTP_RETROFIT.get(`/AppData`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data.energySuppliers;
    });
  }

  getNotificationLogs(energySupplierId) {
    return HTTP_RETROFIT.get(`/BackOffice/energysupplier/${energySupplierId}/notificationlogs`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new EnergySuppliersService();
