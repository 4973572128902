<template>
  <div v-if="model" class="h-100">
    <form ref="formSchemeTradeEdit" class="has-validation">
      <div class="pt-3">
        <div class="row">
          <div class="col-sm-12 col-md-10 col-lg-10">
            <card label="Trade Details">
              <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                  <label for="schemeTradeCode" class="form-label">Trade Code</label>
                  <input
                    id="schemeTradeCode"
                    :value="model.tradeCode"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>

                <div class="col-sm-12 col-md-8 col-lg-8">
                  <label for="schemeTradeCode" class="form-label">Trade Description</label>
                  <input
                    id="schemeTradeDescription"
                    :value="getTradeName(model.tradeCode)"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <smart-select
                id="tradeStatus"
                v-model="model.status"
                label="Status"
                :is-required="!!v$.model.status.isRequired"
                :validator="v$.model.status"
                :options="statusList"
              />

              <div v-if="model.status === 'Obsolete'">
                <label class="mb-2">Obsolete Date From *</label>
                <datepicker
                  id="obsoleteDateFrom"
                  v-model="model.obsoleteDateFrom"
                  :validator="v$.model.obsoleteDateFrom"
                  class="form-control mb-2 bg-transparent"
                  :class="{ 'is-invalid': v$.model.obsoleteDateFrom.$errors?.[0]?.$message }"
                  input-format="dd/MM/yyyy"
                />
                <div
                  v-if="v$.model.obsoleteDateFrom.$errors?.[0]?.$message?.length > 0"
                  class="text-danger"
                >
                  {{ v$.model.obsoleteDateFrom.$errors?.[0]?.$message }}
                </div>
              </div>

              <smart-textarea
                id="schemeNotes"
                v-model="model.notes"
                style="min-height: 200px"
                label="Scheme notes"
                :is-required="!!v$.model.notes.isRequired"
                :validator="v$.model.notes"
              />
            </card>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { Card, SmartTextarea, SmartSelect } from '@trustmark-2005-ltd/trustmark-shared-components';
import Datepicker from 'vue3-datepicker';
import { getTradeName } from '../../../../services/scheme-common';

export default {
  name: 'SchemesEditTradesEdit',
  components: {
    SmartTextarea,
    Card,
    Datepicker,
    SmartSelect,
  },
  props: {
    v: {
      type: Object,
      required: true,
      default: null,
    },
  },
  computed: {
    v$() {
      return this.v;
    },
    model() {
      return this.$store.state.schemeTrade.trade;
    },
    statusList() {
      return this.$store.state.auth.appData.schemeTradeStatuses;
    },
    trades() {
      if (!this.$store.state.trade.trades) {
        return null;
      }
      return this.$store.state.trade.trades.map((x) => {
        return { text: `${x.tradeCode} - ${x.tradeDescription}`, value: x.tradeCode };
      });
    },
  },
  mounted() {
    this.$store.dispatch('trade/list', this.$route.params.schemeId);
  },
  methods: {
    getTradeName,
  },
};
</script>
