import { HTTP, authHeader } from './http-service';

class ComplianceService {
  suspend(tmln, description) {
    return HTTP.post(
      `/trustmarkbusiness/suspend/${tmln}`,
      { description },
      { headers: authHeader() }
    ).then((response) => {
      return response.data;
    });
  }

  unsuspend(tmln, description) {
    return HTTP.post(
      `/trustmarkbusiness/unsuspend/${tmln}`,
      { description },
      { headers: authHeader() }
    ).then((response) => {
      return response.data;
    });
  }
}

export default new ComplianceService();
