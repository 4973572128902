import axios from 'axios';
import logoutCleanup from './logout-cleanup';
import { HTTP, authHeader, setAuthLocalStorage, API_URL } from './http-service';

class AuthService {
  login(user) {
    return axios
      .post(
        `${API_URL}auth/login`,
        {
          username: user.username,
          password: user.password,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.model.accessToken && response.data.model.deviceValid) {
          setAuthLocalStorage(response);
        }
        return response.data;
      });
  }

  sendResetCode(payload) {
    return axios.post(`${API_URL}resetpassword/sendcode`, payload);
  }

  resetPassword(payload) {
    return axios.post(`${API_URL}resetpassword/reset-with-code`, payload);
  }

  logout() {
    logoutCleanup();
  }

  register(user) {
    return axios.post(`${API_URL}register`, {
      email: user.email,
      password: user.password,
      mobileNumber: user.mobileNumber,
      usesTotp: user.usesTotp,
    });
  }

  verify(user) {
    return axios.post(`${API_URL}register/verify`, user);
  }

  sendSms(payload) {
    const bearer = {
      Authorization: `Bearer ${payload.accessToken}`,
    };
    return axios.post(
      `${API_URL}authdevice/sendsms`,
      {},
      {
        headers: bearer,
      }
    );
  }

  verifySms(payload) {
    const bearer = {
      Authorization: `Bearer ${payload.accessToken}`,
    };
    return axios.post(`${API_URL}authdevice/verifysms`, payload, {
      headers: bearer,
      withCredentials: true,
    });
  }

  completeReset(payload) {
    const bearer = {
      Authorization: `Bearer ${payload.accessToken}`,
    };
    return axios.post(`${API_URL}authdevice/completereset`, payload, {
      headers: bearer,
      withCredentials: true,
    });    
  }

  changeMobile(payload) {
    const bearer = {
      Authorization: `Bearer ${payload.accessToken}`,
    };
    return axios.post(`${API_URL}authdevice/changemobile`, payload, {
      headers: bearer,
    });
  }

  sendEmailVerifyCode(payload) {
    const bearer = {
      Authorization: `Bearer ${payload.accessToken}`,
    };
    return axios.post(`${API_URL}authdevice/sendmobilechangecode`, payload, {
      headers: bearer,
    });
  }

  getAppData() {
    return HTTP.get('/appData', {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  verifyTotp(payload) {
    const bearer = {
      Authorization: `Bearer ${payload.accessToken}`,
    };
    return axios.post(`${API_URL}authdevice/verifytotp`, payload, {
      headers: bearer,
      withCredentials: true,
    });
  }

  registerWithTotp(user) {
    return axios.post(`${API_URL}register/totp`, user);
  }

  verifyTotpRegistration(payload) {
    return axios.post(`${API_URL}register/verify/totp`, payload);
  }
  
  requestTotp() {
    return axios.post(`${API_URL}Account/requestTotp`, {}, {
      headers: authHeader(),
      withCredentials: true,
    });
  }
  completeTotp(form) {
    return axios.post(`${API_URL}Account/completeTotp`, form, {
      headers: authHeader(),
      withCredentials: true,
    });
  }
  requestSms(form) {
    return axios.post(`${API_URL}Account/requestSms`, form, {
      headers: authHeader(),
      withCredentials: true,
    });
  }
  completeSms(form) {
    return axios.post(`${API_URL}Account/completeSms`, form, {
      headers: authHeader(),
      withCredentials: true,
    });
  }
  refresh(payload) {
    return axios.post(`${API_URL}auth/refresh`, payload, {
      headers: authHeader(),
      withCredentials: true,
    }).then((response) => {    
      if (response.data.model.accessToken) {
        setAuthLocalStorage(response);
      }
      return response.data;
    });
  }
}

export default new AuthService();
