import { HTTP, authHeader } from './http-service';

class LicenceNumberService {
  getById(trustmarkBusinessId) {
    return HTTP.get(`/trustmarkbusiness/${trustmarkBusinessId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getByTMLN(tmln) {
    return HTTP.get(`/trustmarkbusiness/tmln/${tmln}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  search(payload) {
    return HTTP.post(`/trustmarkbusinesssearch`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  advancedSearch(payload) {
    return HTTP.post(`/trustmarkbusinesssearch/advance`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  makePrimary(payload) {
    return HTTP.put(
      `/trustmarkbusiness/makeprimary/${payload.trustmarkBusinessId}/${payload.schemeBusinessId}`,
      {},
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }

  makeInactive(payload) {
    return HTTP.put(
      `/trustmarkbusiness/makeinactive/${payload.trustmarkBusinessId}/${payload.schemeBusinessId}`,
      {},
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }
}

export default new LicenceNumberService();
