import { HTTP, authHeader } from './http-service';

class BlockedEmailsService {
  delete(payload) {
    return HTTP.put(`/blockedEmail`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  check(payload) {
    return HTTP.post(`/blockedEmail/check`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getAll() {
    return HTTP.get(`/blockedEmail`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }
}

export default new BlockedEmailsService();
