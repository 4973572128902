<template>
  <div class="row">
    <div class="col-sm-12">
      <h5 class="form-label card-title">{{ contact.firstName }} {{ contact.lastName }}</h5>
    </div>
    <div class="col">
      <div class="mb-3">
        <div class="form-label">
          <key-value-presenter label="Job Title" :value="contact.jobTitle" />
        </div>
        <div class="form-label">
          <key-value-presenter-hyperlinked
            label="Email"
            :value="contact.email"
            :href="`mailto:${contact.email}`"
            :hide-if-null="true"
          />
        </div>
      </div>
    </div>
    <div class="col">
      <div class="mb-3">
        <copy-key-value-presenter
          label="Contact Number"
          :value="!contact.primaryContactNumber ? 'Not entered' : contact.primaryContactNumber"
        />
      </div>
      <div class="mb-3">
        <copy-key-value-presenter
          v-if="contact.piNumber"
          label="PI Number"
          :value="!contact.piNumber ? 'Not entered' : contact.piNumber"
        />
      </div>
      <div class="mb-3">
        <key-value-presenter label="Modified" :value="$helpers.getLastModifiedString(contact)" />
      </div>
      <div class="mb-3">
        <key-value-presenter-check-or-cross
          v-if="contact.isFinanceContact"
          label="Is Finance Contact"
          :state="contact.isFinanceContact"
        />
        <key-value-presenter-check-or-cross
          v-if="contact.isComplianceContact"
          label="Is Compliance Contact"
          :state="contact.isComplianceContact"
        />
        <key-value-presenter-check-or-cross
          v-if="contact.isOperationContact"
          label="Is Operation Contact"
          :state="contact.isOperationContact"
        />
        <key-value-presenter-check-or-cross
          v-if="contact.isWebsiteContact"
          label="Is Website Contact"
          :state="contact.isWebsiteContact"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  CopyKeyValuePresenter,
  KeyValuePresenter,
  KeyValuePresenterHyperlinked,
  KeyValuePresenterCheckOrCross,
} from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'ContactsView',
  components: {
    KeyValuePresenterCheckOrCross,
    KeyValuePresenterHyperlinked,
    KeyValuePresenter,
    CopyKeyValuePresenter,
  },
  props: {
    contact: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {};
  },
};
</script>
