<template>
  <div v-if="model" class="h-100">
    <form ref="formNote" class="has-validation">
      <div class="pt-3">
        <div class="row">
          <div class="col-sm-12 col-md-10 col-lg-10">
            <card label="Note Details">
              <smart-textarea
                id="noteText"
                v-model="model.noteText"
                style="min-height: 300px"
                label="Note text"
                :is-required="!!v$.model.noteText.isRequired"
                :validator="v$.model.noteText"
              />
            </card>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { Card, SmartTextarea } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'SchemesEditNotesFields',
  components: {
    SmartTextarea,
    Card,
  },
  props: {
    v: {
      type: Object,
      required: true,
      default: null,
    },
  },
  computed: {
    v$() {
      return this.v;
    },
    model() {
      return this.$store.state.schemeNote.note;
    },
  },
};
</script>
