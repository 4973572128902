<template>
  <nav class="navbar sticky-top navbar-expand navbar-dark bg-dark">
    <a class="d-lg-none btn nav-link" @click.prevent="toggleSidebar">
      <font-awesome-icon :icon="sidebarIsCollapsed ? 'bars' : 'xmark'" fixed-width size="lg" />
    </a>

    <div class="col">
      <a href="/" class="active"
        ><img
          src="/trustmark_logo_light_2023.svg"
          class="img img-fluid"
          style="height: 32px; margin: 7px 26px"
      /></a>
    </div>

    <div class="navbar-nav mr-auto">
      <li class="d-none d-md-block nav-item text-nowrap">
        <span to="/profile" class="nav-link">
          <font-awesome-icon icon="user" />
          {{ currentAccount.accountName }}
        </span>
      </li>
      <li class="nav-item text-nowrap">
        <a class="btn nav-link" @click.prevent="logOut">
          <font-awesome-icon icon="right-from-bracket" /> Log out
        </a>
      </li>
    </div>
  </nav>
</template>

<script>
import logoutCleanup from '../services/logout-cleanup';

export default {
  name: 'BackOfficeNavBar',
  computed: {
    currentAccount() {
      return this.$store.state.auth.account;
    },
    sidebarIsCollapsed() {
      return this.$store.state.sidebarIsCollapsed;
    },
  },
  methods: {
    logOut() {
      this.$store.commit('setIsBusy', true, { root: true });
      logoutCleanup();
    },
    toggleSidebar() {
      this.$store.commit('setSidebarCollapsed', !this.sidebarIsCollapsed);
    },
  },
};
</script>

<style scoped>
.bg-dark {
  background-color: #002951 !important;
}

.nav-link {
  color: white !important;
}

.nav-item {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
