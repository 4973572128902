<template>
  <confirm-dialog ref="confirmDeleteScheme"></confirm-dialog>
  <subtitle>Advanced</subtitle>

  <div v-if="scheme" class="h-100 pt-3">
    <div class="row">
      <div class="col-sm-12 col-md-10 col-lg-10">
        <card label="Danger Zone" class="border-danger">
          <p>You can delete this scheme, there is no going back. Please be certain.</p>
          <button type="button" class="btn btn-lg btn-danger" @click="confirmDelete">
            Delete Record
          </button>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Subtitle,
  Card,
  ConfirmDialog,
  confirmType,
} from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'SchemesEditAdvanced',
  components: {
    Subtitle,
    Card,
    ConfirmDialog,
  },
  data() {
    return {
      message: null,
      errorMessage: null,
    };
  },
  computed: {
    parentRoute() {
      return `/scheme/${this.$route.params.schemeId}/edit`;
    },
    scheme() {
      return this.$store.state.scheme.scheme;
    },
  },
  methods: {
    deleteRecord() {
      const payload = {
        schemeId: this.scheme.schemeId,
      };
      this.$store
        .dispatch('scheme/deleteScheme', payload)
        .then(() => {
          this.$router.push('/schemes');
        })
        .catch((err) => {
          this.errorMessage = err;
        });
    },
    confirmDelete() {
      this.$refs.confirmDeleteScheme
        .show({
          title: 'Delete Scheme',
          message: 'Are you sure you want to delete this scheme? This cannot be undone.',
          okButtonText: 'Delete Forever',
          confirmType: confirmType.destructive,
        })
        .then((result) => {
          if (result === true) {
            this.deleteRecord();
          }
        });
    },
  },
};
</script>
