import { email, helpers, required } from '@vuelidate/validators';
import { isPhoneNumber } from '@trustmark-2005-ltd/trustmark-shared-components';

export const validationModel = {
  firstName: {
    isRequired: helpers.withMessage('Last name is required', required),
  },
  lastName: {
    isRequired: helpers.withMessage('First name is required', required),
  },
  jobTitle: {},
  primaryContactNumber: {
    isPhoneNumber: helpers.withMessage(
      'Primary contact number must be a valid phone number',
      isPhoneNumber
    ),
    isRequired: helpers.withMessage('Phone number is required', required),
  },
  email: {
    isEmail: helpers.withMessage('Must be a valid email address', email),
    isRequired: helpers.withMessage('Email is required', required),
  },
  piNumber: {},
  isComplianceContact: {},
  isFinanceContact: {},
  isOperationContact: {},
  isWebsiteContact: {},
};
