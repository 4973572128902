<template>
  <div v-if="model">
    <div class="mb-4">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <h1>Licence Number {{ model.tmln }}</h1>
          <div class="ms-3">
            <h5 v-if="isActiveStatus">
              <span class="badge bg-success">Active</span>
            </h5>
            <h5 v-if="!isActiveStatus">
              <span class="badge bg-danger">Inactive</span>
            </h5>
          </div>
        </div>
        <div>
          <button class="btn btn-primary" @click="onGoToTrustmark()">
            Visit on trustmark.org.uk
          </button>
        </div>
      </div>
      <h3>{{ model.registeredCompanyName }}</h3>
    </div>

    <licence-number-tabs />

    <router-view :v="v$" />
  </div>
</template>

<script>
import LicenceNumberTabs from './LicenceNumberViewTabs.vue';

export default {
  name: 'LicenceNumberViewRouter',
  components: {
    LicenceNumberTabs,
  },
  data() {
    return {
      tmBusinessId: this.$route.params.tmBusinessId,
      loading: false,
    };
  },
  computed: {
    model() {
      return this.$store.state.licenceNumber.trustmarkBusiness;
    },
    isActiveStatus() {
      return this.model.status === 1;
    },
  },
  mounted() {
    this.$store.dispatch('licenceNumber/getById', this.$route.params.tmBusinessId);
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  },
  methods: {
    onGoToTrustmark() {
      if (import.meta.env.NODE_ENV === 'production') {
        window.open(
          `https://www.trustmark.org.uk/homeowner/find-a-business?search=true&tmln=${this.model.tmln}`,
          '_blank',
          'noopener,noreferrer'
        );
      } else {
        window.open(
          `https://www.demo.trustmark-apps.org.uk/homeowner/find-a-business?search=true&tmln=${this.model.tmln}`,
          '_blank',
          'noopener,noreferrer'
        );
      }
    },
  },
};
</script>
