<template>
  <confirm-dialog ref="confirm"></confirm-dialog>
  <subtitle>Activity Logs</subtitle>

  <activity-list :model="model" />
</template>

<script>
import {
  Subtitle,
  ConfirmDialog,
  ActivityList,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import { HTTP, authHeader } from '../../services/http-service';

export default {
  name: 'SchemeBusinessActivityLogs',
  components: {
    ActivityList,
    Subtitle,
    ConfirmDialog,
  },
  data() {
    return {
      model: null,
    };
  },
  computed: {
    authHeader() {
      return authHeader;
    },
  },
  mounted() {
    return HTTP.get(
      `/activitylog/business/${this.$route.params.schemeId}/${this.$route.params.schemeBusinessId}`,
      {
        headers: this.authHeader(),
      }
    ).then((response) => {
      this.model = response.data.model;
    });
  },
  methods: {},
};
</script>
