<template>
  <h1>Create Trade</h1>

  <div v-if="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <div v-if="successMessage" class="alert alert-success" role="alert">
    {{ successMessage }}
  </div>

  <edit-fields :v="v$" />

  <div class="row">
    <div class="col-sm-12 col-md-10 col-lg-10">
      <div class="form-group d-grid gap-2 d-md-flex justify-content-md-end">
        <router-link class="btn btn-secondary float-right btn-lg" type="button" :to="parentRoute">
          <span>Cancel</span>
        </router-link>

        <smart-submit-large label="Save" :loading="loading" @click="save" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  isNullOrWhiteSpace,
  SmartSubmitLarge,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import useValidate from '@vuelidate/core';
import { helpers, numeric, required, requiredIf } from '@vuelidate/validators';
import EditFields from './TradesFields.vue';

export default {
  name: 'TradesCreate',
  components: {
    SmartSubmitLarge,
    EditFields,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      successMessage: null,
      v$: useValidate(),
    };
  },
  computed: {
    parentRoute() {
      return `/trades/`;
    },
    model() {
      return this.$store.state.trade.trade;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    if (!this.$store.state.trade.trade) {
      this.$router.push(this.parentRoute);
    }
  },
  methods: {
    save() {
      this.v$.$validate();
      if (this.v$.$error) {
        window.scrollTo(0, 0);
        this.errorMessage =
          'Unable to save the trade. Please resolve any errors on this form and try again.';
        return;
      }

      const payload = this.model;

      if (!isNullOrWhiteSpace(this.model.tradeSkills)) {
        payload.tradeSkills = `${this.model.tradeSkills}`; // copy by val
        payload.tradeSkills = payload.tradeSkills.split(',').map((s) => s.trim());
      }

      if (!isNullOrWhiteSpace(this.model.searchKeywords)) {
        payload.searchKeywords = `${this.model.searchKeywords}`; // copy by val
        payload.searchKeywords = payload.searchKeywords.split(',').map((s) => s.trim());
      }

      payload.tradeCode = parseInt(payload.tradeCode, 10);
      payload.isActive = this.model.isActive;

      this.$store
        .dispatch('trade/create', payload)
        .then((resp) => {
          this.$store.dispatch('trade/list', this.$route.params.schemeId).then(() => {
            this.successMessage = resp;
            this.$router.push(this.parentRoute);
          });
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        });
    },
  },
  validations() {
    return {
      model: {
        tradeCode: {
          isRequired: helpers.withMessage('Trade code is required', required),
          numeric: helpers.withMessage('Trade code must be numeric', numeric),
        },
        tradeDescription: {
          isRequired: helpers.withMessage('Trade description is required', required),
        },
        inactiveDateFrom: {
          isRequired: helpers.withMessage(
            'Inactive date from is required',
            requiredIf(() => !this.model.isActive)
          ),
        },
        isActive: { isRequired: helpers.withMessage('Active state is required', required) },
        tradeSkills: {},
        searchKeywords: {},

        standard: {},
        ghgvsName: {},
        pasAnnex: {},

        internalNotes: {},
        externalNotes: {},
      },
    };
  },
};
</script>
