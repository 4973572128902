<template>
  <div class="col-sm-12 col-md-10">
    <subtitle> Overview </subtitle>

    <div v-if="model && model.schemeBusiness">
      <scheme-business-link-pending />

      <scheme-business-rematch
        v-if="!model.schemeBusiness.tmln && model.schemeBusiness.linkPending !== null"
      />

      <card>
        <div v-if="isApplicationWithSchemeForSubmit" class="row">
          <div class="col-xs-12 alert-info p-2 mb-3 text-center">
            <span>
              This business is part of online application
              <router-link :to="`/applications/${model.schemeBusiness.applicationReference}`"
                ><b>{{ model.schemeBusiness.applicationReference }}</b></router-link
              >
              and requires the scheme to confirm and submit.
            </span>
            <p v-if="model.schemeBusiness.applicationSchemeProgressStatus" class="mb-0">
              <br />{{ schemeName }}'s last update to Trustmark -
              <b>{{
                applicationSchemeProgressStatusName(
                  model.schemeBusiness.applicationSchemeProgressStatus
                )
              }}</b>
              on
              {{ $helpers.formatDate(model.schemeBusiness.applicationSchemeProgressStatusDate) }}
            </p>
          </div>
        </div>
        <div
          v-if="!isApplicationWithSchemeForSubmit && model.schemeBusiness.applicationReference"
          class="row"
        >
          <div class="col-xs-12 alert-info p-2 mb-3 text-center">
            <span>
              This business was part of online application
              <router-link :to="`/applications/${model.schemeBusiness.applicationReference}`"
                ><b>{{ model.schemeBusiness.applicationReference }}</b></router-link
              >. {{ model.schemeBusiness.schemeBusinessArchiveReason }}
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-6">
            <conditional-new-line
              :label="`${schemeName}'s Reference:`"
              :text="membershipReferenceDesc"
            />

            <conditional-new-line
              label="TrustMark Licence Number (TMLN):"
              :text="model.schemeBusiness.tmln"
            />

            <conditional-new-line
              v-if="model.schemeBusiness.tmlnAllocatedDateTime"
              label="Date the TMLN was allocated:"
              :text="$helpers.dateTimeString(model.schemeBusiness.tmlnAllocatedDateTime)"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-6">
            <conditional-new-line
              label="Status:"
              :text="statusAsString(model.schemeBusiness.status)"
            />

            <conditional-new-line
              label="Change Date:"
              :text="$helpers.dateTimeString(model.schemeBusiness.statusDateChange)"
            />

            <conditional-new-line
              label="Updated Date:"
              :text="$helpers.dateTimeString(model.schemeBusiness.updatedAt)"
            />
          </div>
        </div>
      </card>

      <card label="Business Registration Information">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-label">
              <key-value-presenter
                label="Registered Company Name"
                :value="model.schemeBusiness.registeredCompanyName"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="form-label">
              <key-value-presenter
                label="Registered Company Number"
                :value="model.schemeBusiness.registeredCompanyNumber"
              />
            </div>
          </div>
        </div>
      </card>

      <card label="Website Contact Information">
        <div class="row">
          <div class="col">
            <div class="form-label">
              <key-value-presenter-hyperlinked
                label="Email"
                :value="model.schemeBusiness.email"
                :href="`mailto:${model.schemeBusiness.email}`"
              />
            </div>
            <div class="form-label">
              <key-value-presenter-hyperlinked
                label="Public Phone Number"
                :value="model.schemeBusiness.primaryContactNumber"
                :href="`tel:${model.schemeBusiness.primaryContactNumber}`"
                description="This is the phone number that will appear on the TrustMark website"
              />
            </div>
            <div class="form-label">
              <key-value-presenter-hyperlinked
                label="Website"
                :value="model.schemeBusiness.website"
                :href="model.schemeBusiness.website"
                :hide-if-null="true"
              />
            </div>
            <div class="form-label">
              <key-value-presenter-hyperlinked
                label="Administrative Phone Number"
                :value="model.schemeBusiness.phone"
                :href="`tel:${model.schemeBusiness.phone}`"
                :hide-if-null="true"
              />
            </div>
            <div class="form-label">
              <key-value-presenter-hyperlinked
                label="Fax"
                :value="model.schemeBusiness.fax"
                :href="`tel:${model.schemeBusiness.fax}`"
                :hide-if-null="true"
              />
            </div>
          </div>
          <div class="col">
            <div class="mb-4">
              <b>Address:<br /></b>
              <conditional-new-line :blank-if-null="true" :text="model.schemeBusiness.address1" />
              <conditional-new-line :blank-if-null="true" :text="model.schemeBusiness.address2" />
              <conditional-new-line :blank-if-null="true" :text="model.schemeBusiness.town" />
              <conditional-new-line :blank-if-null="true" :text="model.schemeBusiness.county" />
              <conditional-new-line :blank-if-null="true" :text="model.schemeBusiness.district" />
              <conditional-new-line :blank-if-null="true" :text="model.schemeBusiness.postCode" />
              <conditional-new-line :blank-if-null="true" :text="model.schemeBusiness.country" />
              <br />
              <conditional-new-line
                v-if="model.schemeBusiness.longitude"
                label="Location:"
                :text="`${model.schemeBusiness.latitude}, ${model.schemeBusiness.longitude}`"
              />
              <conditional-new-line
                v-if="!model.schemeBusiness.longitude"
                label="Location:"
                text="Not confirmed"
              />
            </div>
          </div>
        </div>
      </card>

      <card label="Notes">
        <div class="row">
          <div class="col-sm-12">
            <label v-if="model.schemeBusiness.notes">{{ model.schemeBusiness.notes }}</label>
            <label v-if="!model.schemeBusiness.notes"
              >The scheme has not entered any notes for this business.</label
            >
          </div>
        </div>
      </card>

      <card v-if="model.schemeBusiness.legacyOrganisationId" label="Legacy Information">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-label">
              <key-value-presenter
                label="Legacy Organisation Id"
                :value="model.schemeBusiness.legacyOrganisationId"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="form-label">
              <key-value-presenter
                label="Legacy Address Id"
                :value="model.schemeBusiness.legacyOrganisationAddressId"
              />
            </div>
          </div>
        </div>
        <div v-if="canCreateLogin" class="row">
          <confirm-dialog ref="confirmCreateLoginDialog" :actiondisabled="!createLoginCanProceed">
            <slot>
              <div class="row mb-3">
                <div class="form-group">
                  <label for="email" class="form-label">Email Address</label>
                  <input
                    id="createLoginEmail"
                    v-model="createLoginEmail"
                    name="createLoginEmail"
                    type="text"
                    class="form-control"
                    :class="!createLoginIsValid ? 'is-invalid' : ''"
                    autocomplete="off"
                  />
                  <div class="invalid-feedback">You must enter an email and matching confirm</div>
                </div>
              </div>
              <div class="row mb-5">
                <div class="form-group">
                  <label for="email" class="form-label">Confirm Email Address</label>
                  <input
                    id="createLoginEmailConfirm"
                    v-model="createLoginEmailConfirm"
                    name="createLoginEmailConfirm"
                    type="text"
                    :class="!createLoginIsValid ? 'is-invalid' : ''"
                    class="form-control"
                    autocomplete="off"
                  />
                  <div class="invalid-feedback">You must enter an email and matching confirm</div>
                </div>
              </div>
            </slot>
          </confirm-dialog>
          <div>
            <p>
              If you would like to create a direct Lodgement Portal login for this inactive business
              click here
            </p>
            <button type="button" class="btn btn-primary" @click="createLodgementPortalLogin">
              Create Lodgement Portal Login for Branch
              {{ model.schemeBusiness.legacyOrganisationAddressId }}
            </button>
            <div v-if="createLoginMessage" class="alert alert-info mt-3" role="alert">
              {{ createLoginMessage }}
            </div>
          </div>
        </div>
        <div class="row">
          <p class="mt-3 fw-bold">
            {{ listLegacyLodgementBranchesMessage }} {{ legacyTrustmarkBusinessId }}
          </p>
          <card v-for="(item, i) in legacyBranches" :key="i">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-label">
                  <key-value-presenter label="Legacy Address Id" :value="item.trustmarkBranchId" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-label">
                  <key-value-presenter label="Branch Name" :value="item.branchName" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-label">
                  <key-value-presenter
                    label="TrustmarkBusinessId"
                    :value="item.trustmarkBusinessId"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-label">
                  <key-value-presenter label="Scheme Name" :value="item.schemeName" />
                </div>
              </div>
            </div>
          </card>
        </div>
      </card>

      <card
        v-if="
          model.schemeBusiness.businessIdentityChangeHistories &&
          model.schemeBusiness.businessIdentityChangeHistories.length > 0
        "
        label="Business Identity History"
      >
        <div
          v-for="b in model.schemeBusiness.businessIdentityChangeHistories"
          :key="b.createdAt"
          class="mb-4"
        >
          <div v-if="b.previousTMLN !== b.newTMLN" class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-label">
                <key-value-presenter label="Previous TMLN" :value="b.previousTMLN" />
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-label">
                <key-value-presenter label="New TMLN" :value="b.newTMLN" />
              </div>
            </div>
          </div>
          <div v-if="b.previousRegisteredCompanyName !== b.newRegisteredCompanyName" class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-label">
                <key-value-presenter
                  label="Previous Registered Company Name"
                  :value="b.previousRegisteredCompanyName"
                />
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-label">
                <key-value-presenter
                  label="New Registered Company Name"
                  :value="b.newRegisteredCompanyName"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-label">
                <key-value-presenter label="Created By" :value="b.createdBy" />
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-label">
                <key-value-presenter
                  label="Created At"
                  :value="$helpers.dateTimeString(b.createdAt)"
                />
              </div>
            </div>
          </div>
        </div>
      </card>

      <card v-if="isLicenceEditor && model.schemeBusiness.tmln" label="Licence Actions">
        <div v-if="isApplicationWithSchemeForSubmit">
          <p>
            This business is part of online application
            <b>{{ model.schemeBusiness.applicationReference }}</b> and requires the scheme to
            confirm and submit.
          </p>
          <p v-if="model.schemeBusiness.applicationSchemeProgressStatus" class="mb-0">
            <br />{{ schemeName }}'s last update to Trustmark -
            <b>{{
              applicationSchemeProgressStatusName(
                model.schemeBusiness.applicationSchemeProgressStatus
              )
            }}</b>
            on
            {{ $helpers.formatDate(model.schemeBusiness.applicationSchemeProgressStatusDate) }}
          </p>
        </div>

        <div v-if="!isApplicationWithSchemeForSubmit">
          <scheme-business-change-tmln v-if="model.schemeBusiness.tmln" />

          <scheme-business-resubmit v-if="model.schemeBusiness.status !== 3" />

          <div v-if="model.schemeBusiness.status === 3" class="alert alert-info" role="alert">
            The licence is being processed for this business.
            <button type="button" class="btn btn-info ms-2" @click="refreshBusiness">
              Refresh
            </button>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Card,
  KeyValuePresenter,
  Subtitle,
  KeyValuePresenterHyperlinked,
  ConditionalNewLine,
  ConfirmDialog,
  confirmType,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import SchemeBusinessLinkPending from './SchemeBusinessLinkPending.vue';
import SchemeBusinessChangeTmln from './SchemeBusinessChangeTmln.vue';
import SchemeBusinessResubmit from './SchemeBusinessResubmit.vue';
import SchemeBusinessRematch from './SchemeBusinessRematch.vue';

export default {
  name: 'SchemeBusinessOverview',
  components: {
    Card,
    Subtitle,
    KeyValuePresenter,
    KeyValuePresenterHyperlinked,
    SchemeBusinessLinkPending,
    SchemeBusinessChangeTmln,
    SchemeBusinessResubmit,
    SchemeBusinessRematch,
    ConditionalNewLine,
    ConfirmDialog,
  },
  data() {
    return {
      createLoginMessage: null,
      createLoginEmail: null,
      createLoginEmailConfirm: null,
      listLegacyLodgementBranchesMessage: null,
      legacyBranches: [],
      legacyTrustmarkBusinessId: null,
    };
  },
  computed: {
    model() {
      return this.$store.state.schemeBusiness.business;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isLicenceEditor() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('LicenceEditor')
        : false;
    },
    schemeBusiness() {
      return this.model.schemeBusiness;
    },
    scheme() {
      return this.$store.state.auth.appData.schemes.find(
        (x) => x.schemeId === this.schemeBusiness.schemeId
      );
    },
    schemeName() {
      return (
        this.$store.state.auth.appData.schemes.find(
          (x) => x.schemeId === this.schemeBusiness.schemeId
        )?.schemeName ?? this.schemeBusiness.schemeId
      );
    },
    isApplicationWithSchemeForSubmit() {
      return (
        this.schemeBusiness.applicationSchemeSubmittedDate === null &&
        this.schemeBusiness.status === 2 // TMApplication
      );
    },
    membershipReferenceDesc() {
      if (this.isApplicationWithSchemeForSubmit) {
        return `${this.model.schemeBusiness.membershipReference} (unconfirmed)`;
      }
      return this.model.schemeBusiness.membershipReference;
    },
    createLoginIsValid() {
      if (!this.createLoginEmail) return true;
      if (!this.createLoginEmail || this.createLoginEmail === '') return false;
      if (this.createLoginEmail !== this.createLoginEmailConfirm) return false;
      return true;
    },
    createLoginCanProceed() {
      return this.createLoginIsValid && this.createLoginEmail?.length > 0;
    },
    canCreateLogin() {
      return this.schemeBusiness && this.schemeBusiness.status === 5; // Inactive
    },
  },
  mounted() {
    this.listLegacyLodgementBranches();
  },
  methods: {
    applicationSchemeProgressStatusName(status) {
      return this.$store.state.auth.appData.applicationSchemeProgressStatuses?.find(
        (x) => x.status === status
      )?.description;
    },
    statusAsString(statusCode) {
      return this.$store.state.auth.appData.schemeBusinessStatuses.find(
        (x) => x.status === parseInt(statusCode, 10)
      )?.description;
    },
    refreshBusiness() {
      this.$store.dispatch('schemeBusiness/get', {
        schemeId: this.$route.params.schemeId,
        schemeBusinessId: this.$route.params.schemeBusinessId,
      });
    },
    createLodgementPortalLogin() {
      this.$refs.confirmCreateLoginDialog
        .show({
          title: 'Create Lodgement Portal Login',
          message: 'Enter the email address to use for this new login to the Lodgement Portal.',
          okButtonText: 'OK',
          confirmType: confirmType.standard,
        })
        .then((result) => {
          if (result === true) {
            this.createLoginMessage = null;
            this.$store
              .dispatch('schemeBusiness/createLodgementPortalLogin', {
                schemeId: this.$route.params.schemeId,
                schemeBusinessId: this.$route.params.schemeBusinessId,
                email: this.createLoginEmail,
              })
              .then((resp) => {
                this.createLoginMessage = resp.message;
                this.createLoginEmail = null;
                this.createLoginEmailConfirm = null;
              })
              .catch((ex) => {
                this.createLoginMessage = ex.message ?? ex;
              });
          }
        });
    },
    listLegacyLodgementBranches() {
      this.listLegacyLodgementBranchesMessage = `Checking for other legacy lodgement branches used...`;
      this.$store
        .dispatch('schemeBusiness/listLegacyLodgementBranches', {
          schemeId: this.$route.params.schemeId,
          schemeBusinessId: this.$route.params.schemeBusinessId,
        })
        .then((resp) => {
          this.legacyBranches = resp.businesses.filter(
            (x) => x.trustmarkBranchId !== resp.legacyOrganisationAddressId
          );
          if (this.legacyBranches.length > 0) {
            this.listLegacyLodgementBranchesMessage = `Other legacy lodgement branches used for ${resp.tmln} `;
          } else {
            this.listLegacyLodgementBranchesMessage = `No other legacy lodgement branches have been used for ${resp.tmln} `;
          }
          const thisLegacyBusiness = resp.businesses.filter(
            (x) => x.trustmarkBranchId === resp.legacyOrganisationAddressId
          );
          if (thisLegacyBusiness.length === 1) {
            this.legacyTrustmarkBusinessId = thisLegacyBusiness[0].trustmarkBusinessId;
          }
        })
        .catch((ex) => {
          this.listLegacyLodgementBranchesMessage = ex.message ?? ex;
        });
    },
  },
};
</script>
