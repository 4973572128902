import BlockedEmailsService from '../services/blocked-emails-service';

export const blockedEmails = {
  namespaced: true,
  state: {
    blockedEmails: [],
  },
  actions: {
    getAll({ commit }) {
      commit('setIsBusy', true, { root: true });
      commit('setBlockedEmails', []);
      return BlockedEmailsService.getAll()
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setBlockedEmails', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    delete({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      // commit('setBlockedEmails', []);
      return BlockedEmailsService.delete(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setBlockedEmails', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    check({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      // commit('setBlockedEmails', []);
      return BlockedEmailsService.check(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setBlockedEmails(state, newState) {
      state.blockedEmails = newState;
    },
  },
};
