<template>
  <back-office-nav-bar v-if="currentAccount" />

  <div class="w-100 overflow-hidden d-flex flex-column flex-lg-row">
    <back-office-sidebar v-if="currentAccount" />
    <main class="w-100 overflow-hidden min-vh-100">
      <div class="p-4">
        <router-view />
      </div>
    </main>
  </div>
</template>

<script>
import BackOfficeNavBar from './BackOfficeNavBar.vue';
import BackOfficeSidebar from './BackOfficeSidebar.vue';

export default {
  name: 'WorkspaceLayout',
  components: {
    BackOfficeNavBar,
    BackOfficeSidebar,
  },
  computed: {
    currentAccount() {
      return this.$store.state.auth.account;
    },
  },
};
</script>
