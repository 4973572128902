<template>
  <div>
    <confirm-dialog ref="confirmResubmitDialog"></confirm-dialog>
    <card label="Resubmit">
      <div v-if="errorResubmit" class="alert alert-danger" role="alert">
        {{ errorResubmit }}
      </div>
      <p class="fw-bold">
        If you would like to resubmit this business for processing to check if it should be aligned
        with an exising TMLN.
      </p>
      <div class="mt-3 mb-5">
        <button type="button" class="btn btn-lg btn-primary me-3" @click="confirmResubmit">
          Resubmit Business
        </button>
      </div>
    </card>
  </div>
</template>

<script>
import { ConfirmDialog, confirmType, Card } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'SchemeBusinessResubmit',
  components: { Card, ConfirmDialog },
  data() {
    return {
      loading: false,
      errorResubmit: null,
    };
  },
  computed: {
    model() {
      return this.$store.state.schemeBusiness.business;
    },
  },
  methods: {
    confirmResubmit() {
      this.$refs.confirmResubmitDialog
        .show({
          title: 'Confirm Resubmit',
          message: 'Are you sure you want to resubmit this business for processing now?',
          okButtonText: 'Resubmit Business',
          confirmType: confirmType.standard,
        })
        .then((result) => {
          if (result === true) {
            this.errorSplitTMLN = null;
            const payload = {
              schemeId: this.model.schemeId,
              schemeBusinessId: this.model.schemeBusinessId,
            };
            this.$store
              .dispatch('schemeBusiness/resubmit', payload)
              .then(() => {
                this.loading = false;
              })
              .catch((err) => {
                this.loading = false;
                this.errorSplitTMLN = err.message ?? err;
              });
          }
        });
    },
  },
};
</script>
