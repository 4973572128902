import axios from 'axios';
import moment from 'moment';
import { authHeader, API_URL } from './http-service';

class ExportBillingRegistrationService {
  exportSchemeCsv(schemeId) {
    const http = axios.create({
      baseURL: API_URL,
      responseType: 'blob',
    });
    http.defaults.withCredentials = true;

    return http
      .get(`/schemeregistrationbilling/scheme/${schemeId}/csv`, {
        headers: authHeader(),
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${moment().format('DD.MM.YYYY')} Billling Registration (${schemeId}).csv`;
        link.click();
        URL.revokeObjectURL(link.href);
        Promise.resolve(response);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }

  exportPeriodCsv(period) {
    const http = axios.create({
      baseURL: API_URL,
      responseType: 'blob',
    });
    http.defaults.withCredentials = true;

    return http
      .get(`/schemeregistrationbilling/period/${period}/csv`, {
        headers: authHeader(),
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${moment().format('DD.MM.YYYY')} Billling Registration (${period}).csv`;
        link.click();
        URL.revokeObjectURL(link.href);
        return Promise.resolve(response);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }

  exportPeriodAndSchemeIdCsv(period, schemeId) {
    const http = axios.create({
      baseURL: API_URL,
    });
    http.defaults.withCredentials = true;

    return http
      .get(`/schemeregistrationbilling/period/${period}/${schemeId}/csv`, {
        headers: authHeader(),
      })
      .then((response) => {
        const link = document.createElement('a');
        link.href = response.data.model;
        link.setAttribute(
          'download',
          `${moment().format('DD.MM.YYYY')} Billling Registration (${period} - ${schemeId}).csv`
        );
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
        link.setAttribute('type', 'text/csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        return Promise.resolve(response);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }
}

export default new ExportBillingRegistrationService();
