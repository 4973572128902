import axios from 'axios';
import { HTTP_RETROFIT, HTTP, HTTP_FILE, API_URL, authHeader } from './http-service';

class FunderService {
  register(payload) {
    return HTTP.post(`/Funder`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  get(id) {
    return HTTP.get(`/Funder/${id}`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/Funder`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  delete(id) {
    return HTTP.delete(`/Funder/${id}`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  search(payload) {
    return HTTP_RETROFIT.post(`/Funder/search/`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  advancedSearch(payload) {
    return HTTP_RETROFIT.post(`/Funder/searchAdvance`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getSignedUrl(funderId, filename) {
    return HTTP_FILE.get(
      `/BackofficeFile/GetPresignedPost/funder/${funderId}?filename=${encodeURIComponent(filename)}`
    ).then((response) => {
      return response.data;
    });
  }

  getDashboardStats(funderId) {
    return HTTP_RETROFIT.get(`/FunderReference/dashboard/${funderId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  createFunderApiKey(request) {
    const http = axios.create({
      baseURL: API_URL,
      responseType: 'blob',
    });
    http.defaults.withCredentials = true;

    return http
      .post(`/funder/${request.funderId}/apikey/file`, request, {
        headers: authHeader(),
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);

        // Extract the filename from the response headers if available
        const contentDisposition = response.headers['Content-Disposition'];
        let fileName = 'apikey.csv';
        if (contentDisposition) {
          // Try to extract filename* first
          let [, filenameStar] = contentDisposition.match(/filename\*=(.+)$/) || [];
          filenameStar = filenameStar ? decodeURIComponent(filenameStar.split("''")[1]) : null;

          // Fallback to extracting filename
          const [, filename] = contentDisposition.match(/filename="([^"]*)"/) || [];

          // Use filename* if available, otherwise use filename
          fileName = filenameStar || filename || fileName;
        }
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
        return Promise.resolve(response);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }
}

export default new FunderService();
