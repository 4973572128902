import { HTTP, authHeader } from './http-service';

class ApplicationService {
  list(applicationRef) {
    return HTTP.get(`/application/${applicationRef}`, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  get(applicationRef) {
    return HTTP.get(`/application/${applicationRef}/`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  funnel() {
    return HTTP.get(`/applicationSearch/funnel/`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  search(payload) {
    return HTTP.post(`/applicationSearch`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  advancedSearch(payload) {
    return HTTP.post(`/applicationSearch/advance`, payload, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  archive() {
    return HTTP.post(`/application/archive`, {}, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  export(payload) {
    return HTTP.post(`/applicationSearch/export`, payload, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  updateTradingStandardsResponse(payload) {
    return HTTP.put(`/TradingStandards`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  updateApplication(payload) {
    const headers = authHeader();
    headers['Content-Type'] = 'text/json';
    return HTTP.put(`/application`, payload, {
      headers,
    }).then((response) => {
      return response.data;
    });
  }

  useExistingTMLN(payload) {
    const headers = authHeader();
    headers['Content-Type'] = 'text/json';
    return HTTP.put(`/application/useexistingtmln`, payload, {
      headers,
    }).then((response) => {
      return response.data;
    });
  }
}

export default new ApplicationService();
