import { HTTP, authHeader } from './http-service';

class DisputeNoteService {
  list(disputeId) {
    return HTTP.get(`/disputeInternalNote/dispute/${disputeId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  get(disputeId, noteId) {
    return HTTP.get(`/disputeInternalNote/${noteId}/dispute/${disputeId}/`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  create(payload) {
    return HTTP.post(`/disputeInternalNote`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/disputeInternalNote`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  delete(payload) {
    return HTTP.delete(
      `/disputeInternalNote/${payload.disputeInternalNoteId}/dispute/${payload.disputeId}`,
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }
}

export default new DisputeNoteService();
