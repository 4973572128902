<template>
  <div class="card bg-light">
    <div class="card-header">
      Advanced Search
      <span
        class="float-end sticky-top"
        :class="getCssClass"
        style="cursor: pointer"
        @click="close()"
      >
        <font-awesome-icon icon="xmark" />
      </span>
    </div>
    <div class="card-body">
      <smart-input id="modelTMLN" v-model="model.tmln" label="TMLN" :is-required="false" />

      <smart-input
        id="modelRegisteredCompanyName"
        v-model="model.registeredCompanyName"
        label="Registered Company Name"
        :is-required="false"
      />

      <smart-input
        id="modelRegisteredCompanyNumber"
        v-model="model.registeredCompanyNumber"
        label="Registered Company Number"
        :is-required="false"
      />

      <smart-input
        id="modelMembershipReference"
        v-model="model.membershipReference"
        label="Scheme Membership Reference"
        :is-required="false"
      />

      <smart-select-key-value
        id="modelSchemeId"
        v-model="model.schemeId"
        label="Anytime Scheme"
        :is-required="false"
        :options="schemeIds"
      />

      <smart-select-key-value
        id="modelActiveSchemeId"
        v-model="model.activeSchemeId"
        label="Active Scheme"
        :is-required="false"
        :options="schemeIds"
      />

      <smart-select-text-value-numeric
        id="modelStatus"
        v-model="model.status"
        label="Status"
        :options="licenceNumberStatuses"
        :is-required="false"
      />

      <smart-input
        id="modelPreviousTMLN"
        v-model="model.previousTMLN"
        label="Previous TMLN"
        :is-required="false"
      />
      <smart-input
        id="modelPreviousRegisteredCompanyName"
        v-model="model.previousRegisteredCompanyName"
        label="Previous Registered Company Name"
        :is-required="false"
      />

      <smart-select-key-value
        id="isSuspended"
        v-model="model.isSuspended"
        label="Is Suspended"
        :is-required="false"
        :options="binaryOptions"
      />

      <smart-select-key-value
        id="isTradingStandardsCurrent"
        v-model="model.isTradingStandardsCurrent"
        label="Is Trading Standards"
        :is-required="false"
        :options="binaryOptions"
      />

      <smart-input
        id="suspendedDescription"
        v-model="model.suspendedDescription"
        label="Suspended Description"
        :is-required="false"
      />

      <div class="row">
        <div class="col-6">
          <smart-button
            class-override="btn-outline-secondary"
            :disabled="false"
            :loading="false"
            :click="reset"
          >
            Reset
          </smart-button>
        </div>
        <div class="col-6">
          <smart-submit
            :small="true"
            :disabled="false"
            :loading="loading"
            :click="newAdvancedSearch"
            label="Apply"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SmartInput,
  SmartSubmit,
  SmartButton,
  SmartSelectTextValueNumeric,
  SmartSelectKeyValue,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import { constants } from '../../services/scheme-common';

export default {
  name: 'LicenceNumberSearchAdvanced',
  components: {
    SmartButton,
    SmartSubmit,
    SmartInput,
    SmartSelectTextValueNumeric,
    SmartSelectKeyValue,
  },
  emits: ['reset-requested', 'error-message-changed', 'close'],
  data() {
    return {
      loading: false,
      model: {
        tmln: null,
        status: null,
        schemeIds: [],
        registeredCompanyNumber: null,
        registeredCompanyName: null,
        membershipReference: null,
        schemeId: null,
        activeSchemeId: null,
        previousTMLN: null,
        previousRegisteredCompanyName: null,
      },
      matchTypes: [constants.matchTypes.exact, constants.matchTypes.partial],
      binaryOptions: [
        { key: true, value: 'Yes' },
        { key: false, value: 'No' },
      ],
    };
  },
  computed: {
    pageNumber() {
      return this.$store.state.licenceNumber.pageNumber;
    },
    licenceNumberStatuses() {
      const values = this.$store.state.auth.appData.licenceNumberStatuses.map((x) => {
        return { text: x.description, value: x.status };
      });
      values.splice(0, 0, { text: '(All)', value: '' });
      return values;
    },
    lastSearchConfig() {
      return this.$store.state.licenceNumber.lastSearchConfig;
    },
    isAdvancedSearch() {
      return this.lastSearchConfig.type === 'advanced';
    },
    schemeIds() {
      const opts = this.$store.state.auth.appData.schemes.map((s) => {
        return { key: s.schemeId, value: s.schemeName };
      });
      opts.unshift({ key: null, value: '' });
      return opts;
    },
  },
  watch: {
    pageNumber(newPageNumber) {
      if (this.isAdvancedSearch) {
        console.log(`advanced pageNumber ${newPageNumber}`);
        this.advancedSearch();
      }
    },
  },
  methods: {
    newAdvancedSearch() {
      this.$store.commit('licenceNumber/setSearchPageNumber', 0, { root: true });
      this.advancedSearch();
    },
    advancedSearch() {
      this.$emit('error-message-changed', null);
      this.loading = true;

      const payload = JSON.parse(JSON.stringify(this.model));
      if (payload.schemeId) {
        payload.schemeIds.push(payload.schemeId);
      }
      if (payload.isSuspended === 'true') {
        payload.isSuspended = true;
      } else if (payload.isSuspended === 'false') {
        payload.isSuspended = false;
      } else {
        payload.isSuspended = null;
      }
      if (payload.isTradingStandardsCurrent === 'true') {
        payload.isTradingStandardsCurrent = true;
      } else if (payload.isTradingStandardsCurrent === 'false') {
        payload.isTradingStandardsCurrent = false;
      } else {
        payload.isTradingStandardsCurrent = null;
      }

      payload.pageParameters = {
        size: 10,
        number: this.pageNumber,
      };
      payload.sort = {
        item: 'registeredCompanyName',
        direction: 'ascending',
      };
      payload.filterType = 0;

      this.$store
        .dispatch('licenceNumber/searchAdvanced', payload)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$emit('error-message-changed', err.message);
        });
    },
    reset() {
      this.model.status = null;
      this.model.tmln = null;
      this.model.registeredCompanyName = null;
      this.model.registeredCompanyNumber = null;
      this.model.membershipReference = null;
      this.model.status = null;
      this.model.schemeId = null;
      this.model.previousRegisteredCompanyName = null;
      this.model.previousTMLN = null;
      this.model.isSuspended = null;
      this.model.suspendedDescription = null;
      this.model.isTradingStandardsCurrent = null;
      this.$emit('reset-requested', null);
    },
    close() {
      this.$emit('close', null);
    },
  },
};
</script>
