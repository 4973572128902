<template>
  <confirm-dialog ref="confirmDeleteNote"></confirm-dialog>
  <div>
    <subtitle>Notes</subtitle>

    <div class="h-100 pt-3">
      <div class="row">
        <div class="col">
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>

          <div class="mb-4">
            <div class="btn-toolbar mb-2 mb-md-0">
              <div class="btn-group me-2">
                <button class="btn btn-secondary" @click="createNote">Create Note</button>
              </div>
            </div>
          </div>

          <table v-if="notes" class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Note</th>
                <th scope="col" style="min-width: 150px">Modified</th>
                <th scope="col" style="min-width: 150px">Created</th>
                <th scope="col" style="min-width: 150px">Last Modified By</th>
                <th scope="col" style="width: 1px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="note in sortedNotes" :key="note.noteId">
                <td>
                  {{ note.noteText }}
                </td>
                <td>
                  {{ $helpers.getLastModifiedString(note) }}
                </td>
                <td>
                  {{ $helpers.dateTimeString(note.createdAt) }}
                </td>
                <td>
                  {{ note.updatedBy || note.createdBy }}
                </td>
                <td>
                  <div class="btn-group btn-group">
                    <button class="btn btn-outline-secondary" @click="editNote(note.noteId)">
                      Edit
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-danger"
                      @click="confirmDeleteNote(note.noteId)"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div v-if="loading && !notes" class="ghost">Loading notes...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  confirmType,
  Subtitle,
  ConfirmDialog,
} from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'SchemesEditNotesList',
  components: {
    Subtitle,
    ConfirmDialog,
  },
  data() {
    return {
      errorMessage: null,
      loading: true,
    };
  },
  computed: {
    parentRoute() {
      return `/scheme/${this.$route.params.schemeId}/edit/notes`;
    },
    notes() {
      return this.$store.state.schemeNote.notes;
    },
    sortedNotes() {
      const clone = JSON.parse(JSON.stringify(this.notes));
      return clone.sort(
        (a, b) => this.$helpers.getLastModifiedDate(b) - this.$helpers.getLastModifiedDate(a)
      );
    },
  },
  mounted() {
    if (!this.notes || this.notes.length === 0) {
      this.listNotes();
    }
  },
  methods: {
    listNotes() {
      this.$store
        .dispatch('schemeNote/listNotes', this.$route.params.schemeId)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.errorMessage = err;
        });
    },
    createNote() {
      this.$store.dispatch('schemeNote/newNote', this.$route.params.schemeId).then(() => {
        this.$router.push(`${this.parentRoute}/create`);
      });
    },
    editNote(noteId) {
      const payload = {
        noteId,
        schemeId: this.$route.params.schemeId,
      };
      this.$store.dispatch('schemeNote/getNote', payload).then(() => {
        this.$router.push(`${this.parentRoute}/edit/${noteId}`);
      });
    },
    deleteNote(noteId) {
      const payload = {
        noteId,
        schemeId: this.$route.params.schemeId,
      };
      this.$store.dispatch('schemeNote/deleteNote', payload).then(() => {
        this.listNotes();
      });
    },
    confirmDeleteNote(noteId) {
      this.$refs.confirmDeleteNote
        .show({
          title: 'Delete Note',
          message: 'Are you sure you want to delete this note?',
          confirmType: confirmType.destructive,
        })
        .then((result) => {
          if (result === true) {
            this.deleteNote(noteId);
          }
        });
    },
  },
};
</script>
