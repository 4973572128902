import { HTTP, authHeader } from './http-service';

class TradingStandardsFeedbackService {
  actionable() {
    return HTTP.get(`/TradingStandards/actionable`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/TradingStandards`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new TradingStandardsFeedbackService();
