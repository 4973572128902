<template>
  <div class="row">
    <div class="col-sm-12" :class="{ 'col-md-6 col-xl-9': showAdvanced }">
      <div class="w-100">
        <h1>Licence Numbers</h1>

        <licence-number-toolbar @advanced-search="toggleAdvancedSearch" />

        <licence-number-search-table ref="licenceNumberTable" />
      </div>
    </div>
    <div v-if="showAdvanced" class="col-sm-12 col-md-6 col-xl-3">
      <licence-number-search-advanced
        @error-message-changed="showErrorMessage"
        @reset-requested="reset"
        @close="toggleAdvancedSearch"
      />
    </div>
  </div>
</template>

<script>
import LicenceNumberSearchAdvanced from './LicenceNumberSearchAdvanced.vue';
import LicenceNumberSearchTable from './LicenceNumberSearchTable.vue';
import LicenceNumberToolbar from './LicenceNumberToolbar.vue';

export default {
  name: 'LicenceNumbersList',
  components: {
    LicenceNumberSearchAdvanced,
    LicenceNumberSearchTable,
    LicenceNumberToolbar,
  },
  emits: ['toggle-advanced-search-license-numbers'],
  data() {
    return {
      showAdvanced: false,
    };
  },
  methods: {
    toggleAdvancedSearch() {
      this.showAdvanced = !this.showAdvanced;
      this.emitter.emit('toggle-advanced-search-license-numbers', this.showAdvanced);
    },
    showErrorMessage(msg) {
      this.$refs.licenceNumberTable.changeErrorMessage(msg);
    },
    reset() {
      this.$refs.licenceNumberTable.reset();
    },
  },
};
</script>
