import SiteSettingsService from '../services/site-settings-service';

export const siteSettings = {
  namespaced: true,
  state: {
    settings: null,
  },
  actions: {
    get({ commit }) {
      commit('setIsBusy', true, { root: true });
      commit('setSiteSettings', null);
      return SiteSettingsService.get()
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setSiteSettings', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    put({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      commit('setSiteSettings', null);
      return SiteSettingsService.put(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setSiteSettings', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setSiteSettings(state, newSiteSettings) {
      state.settings = newSiteSettings;
    },
  },
};
