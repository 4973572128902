<template>
  <div class="w-100">
    <div class="mb-4">
      <h1>Register Scheme</h1>
    </div>

    <scheme-list-toolbar />

    <form v-if="model" ref="formSchemeRegister" class="has-validation">
      <div :disabled="loading">
        <div class="row">
          <div class="col-sm-12 col-md-10 col-lg-10">
            <div class="alert alert-info">
              The scheme will be created with a Pending status to allow you to complete the setup
              configuration.
            </div>
            <div class="form-group">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>

              <div v-if="successMessage" class="alert alert-success" role="alert">
                {{ successMessage }}
              </div>
            </div>

            <card label="Scheme">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <smart-input
                    id="schemeId"
                    v-model="model.schemeId"
                    label="Scheme Id"
                    description="A short single word key to identity this scheme"
                    :is-required="!!v$.model.schemeId.isRequired"
                    :validator="v$.model.schemeId"
                    custom-class="text-lowercase"
                  />
                </div>

                <div class="col-sm-12 col-md-6">
                  <smart-input
                    id="schemeName"
                    v-model="model.schemeName"
                    label="Scheme Name"
                    :is-required="!!v$.model.schemeName.isRequired"
                    :validator="v$.model.schemeName"
                  />
                </div>
              </div>
            </card>

            <div class="form-group d-grid gap-2 d-md-flex justify-content-md-end">
              <smart-submit-large label="Register" :loading="loading" @click="register" />
              <router-link class="btn btn-secondary float-right btn-lg" type="button" to="/schemes">
                <span>Cancel</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import useValidate from '@vuelidate/core';
import { helpers, required, maxLength } from '@vuelidate/validators';
import {
  SmartSubmitLarge,
  SmartInput,
  Card,
} from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'SchemesRegister',
  components: {
    SmartSubmitLarge,
    SmartInput,
    Card,
  },
  data() {
    return {
      v$: useValidate(),
      loading: false,
      message: '',
      successMessage: '',
      model: null,
    };
  },
  computed: {
    newScheme() {
      return this.$store.state.auth.appData.models.scheme;
    },
  },
  mounted() {
    if (!this.model) {
      this.reset();
    }
  },
  validations: {
    model: {
      schemeName: {
        isRequired: helpers.withMessage('Scheme name is required', required),
      },
      schemeId: {
        isRequired: helpers.withMessage('Scheme Id is required', required),
        maxLength: maxLength(10),
        charset: helpers.withMessage('Scheme Id can only contain a-z or a hypen', (input) =>
          /^[a-z]+(-[a-z]+)*$/.test(input.toLowerCase())
        ),
      },
    },
  },
  methods: {
    reset() {
      this.loading = false;
      this.message = '';
      this.successMessage = '';
      this.model = JSON.parse(JSON.stringify(this.newScheme));
      this.v$.$reset();
    },
    register() {
      this.v$.$validate();
      if (this.v$.$error) {
        window.scrollTo(0, 0);
        this.message =
          'Unable to register scheme. Please resolve any errors on this form and try again.';
        this.$refs.formSchemeRegister.focus();
        return;
      }

      this.loading = true;
      this.message = '';

      const name = this.model.registeredCompanyName;

      const payload = this.model;
      payload.hideFromJoin = true;
      payload.schemeId = payload.schemeId.toLowerCase();
      this.$store.dispatch('scheme/registerScheme', payload).then(
        (resp) => {
          const msg = `${name} was successfully registered`;
          this.reset();
          this.successMessage = msg;
          window.scrollTo(0, 0);
          this.$router.push(`/scheme/${resp.model.schemeId}/edit`);
        },
        (error) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
