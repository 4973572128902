import TradingStandardsFeedbackService from '../services/trading-standards-feedback-service';

export const tradingStandardsFeedback = {
  namespaced: true,
  state: {
    data: null,
  },
  actions: {
    actionable({ commit }) {
      commit('setIsBusy', true, { root: true });
      return TradingStandardsFeedbackService.actionable()
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setData', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    update({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return TradingStandardsFeedbackService.update(payload).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setData(state, newData) {
      state.data = newData;
    },
  },
};
