import { HTTP, authHeader } from './http-service';

class SchemeTradeService {
  list(schemeId) {
    return HTTP.get(`/schemeTrade/scheme/${schemeId}`, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  get(schemeId, tradeCode) {
    return HTTP.get(`/schemeTrade/${tradeCode}/scheme/${schemeId}/`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  delete(payload) {
    return HTTP.delete(`/schemeTrade/${payload.tradeCode}/scheme/${payload.schemeId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/schemeTrade`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  create(payload) {
    return HTTP.post(`/schemeTrade`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }
}

export default new SchemeTradeService();
