<template>
  <div v-if="model" class="h-100">
    <form ref="formSchemeTradeCreate" class="has-validation">
      <div class="pt-3">
        <div class="row">
          <div class="col-sm-12 col-md-10 col-lg-10">
            <card label="Trade Details">
              <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                  <smart-datalist-text-value
                    id="schemeTradeCode"
                    v-model="model.tradeCode"
                    label="Trade Code"
                    :is-required="!!v$.model.tradeCode.isRequired"
                    :validator="v$.model.tradeCode"
                    :options="trades"
                  />
                </div>
                <div class="col-sm-12 col-md-8 col-lg-8">
                  <label for="schemeTradeDescription" class="form-label">Trade Description</label>
                  <input
                    id="schemeTradeDescription"
                    :value="getTradeName(model.tradeCode)"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <smart-select
                id="tradeStatus"
                v-model="model.status"
                label="Status"
                :is-required="!!v$.model.status.isRequired"
                :validator="v$.model.status"
                :options="statusList"
              />

              <smart-textarea
                id="notes"
                v-model="model.notes"
                style="min-height: 200px"
                label="Notes"
                :is-required="!!v$.model.notes.isRequired"
                :validator="v$.model.notes"
              />
            </card>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  Card,
  SmartTextarea,
  SmartSelect,
  SmartDatalistTextValue,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import { getTradeName } from '../../../../services/scheme-common';

export default {
  name: 'SchemesEditTradesFieldsCreate',
  components: {
    SmartDatalistTextValue,
    SmartTextarea,
    Card,
    SmartSelect,
  },
  props: {
    v: {
      type: Object,
      required: true,
      default: null,
    },
  },
  computed: {
    v$() {
      return this.v;
    },
    model() {
      return this.$store.state.schemeTrade.trade;
    },
    statusList() {
      return this.$store.state.auth.appData.schemeTradeStatuses;
    },
    trades() {
      if (!this.$store.state.trade.trades) {
        return null;
      }
      return this.$store.state.trade.trades.map((x) => {
        return { text: x.tradeDescription, value: x.tradeCode };
      });
    },
  },
  mounted() {
    this.$store.dispatch('trade/list', this.$route.params.schemeId);
    if (!this.model) {
      this.$store.dispatch('schemeTrade/initialise', this.$route.params.schemeId);
    }
  },
  methods: {
    getTradeName,
  },
};
</script>
