<template>
  <div v-if="model && schemeBusiness" class="col-sm-12 col-md-12">
    <subtitle>Trades</subtitle>
    <div v-if="trades">
      <div class="col-sm-12 mb-2">
        <span v-if="model && model.trades" class="float-right"
          >Last updated: {{ $helpers.dateTimeString(model.trades.lastUpdated) }}</span
        >
      </div>
      <div v-if="trades.length === 0" class="mb-3">
        <p>{{ schemeName }} has not submitted any trades for this business.</p>
      </div>
      <div v-if="trades.length > 0">
        <p>{{ schemeName }} has submitted the following trades for this business.</p>
        <div id="tradeAccordion" class="accordion">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#activeTrades"
                aria-expanded="false"
                aria-controls="activeTrades"
              >
                Active Trades
              </button>
            </h2>
            <div
              id="activeTrades"
              class="accordion-collapse collapse show"
              data-bs-parent="#tradeAccordion"
            >
              <div class="accordion-body">
                <ul v-if="schemeBusiness.tradeDescriptions">
                  <li v-for="trade in schemeBusiness.tradeDescriptions" :key="trade">
                    {{ trade }} - {{ getTradeName(trade) }}
                  </li>
                </ul>
                <div v-else><span class="text-danger">No active trades</span></div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#inactiveTrades"
                aria-expanded="false"
                aria-controls="inactiveTrades"
              >
                Inactive Trades
              </button>
            </h2>
            <div
              id="inactiveTrades"
              class="accordion-collapse collapse"
              data-bs-parent="#tradeAccordion"
            >
              <div class="accordion-body">
                <ul v-if="schemeBusiness.tradeDescriptionsInactive">
                  <li v-for="trade in schemeBusiness.tradeDescriptionsInactive" :key="trade">
                    {{ trade }} - {{ getTradeName(trade) }}
                  </li>
                </ul>
                <div v-else>No inactive trades</div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#allTrades"
                aria-expanded="true"
                aria-controls="allTrades"
              >
                Trade Data Entries
              </button>
            </h2>
            <div
              id="allTrades"
              class="accordion-collapse collapse"
              data-bs-parent="#tradeAccordion"
            >
              <div class="row accordion-body">
                <div v-for="trade in trades" :key="trade" class="col-sm-6">
                  <card :label="`Trade Code: ${trade.tradeCode}`">
                    <p>
                      {{ getTradeName(trade.tradeCode) }}
                    </p>
                    <copy-key-value-presenter
                      label="Certificate ID"
                      :value="trade.certificateId"
                      :hide-if-null="true"
                    />

                    <key-value-presenter
                      label="Period"
                      :value="`${$helpers.dateString(trade.fromDate)} - ${timeOrPresent(
                        trade.toDate
                      )}`"
                    />
                  </card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <subtitle class="mt-3">Registrations</subtitle>

    <key-value-presenter-check-or-cross
      label="Has Active Registration"
      :state="!model.schemeBusiness.isDeregistered"
    />

    <key-value-presenter
      v-if="model.schemeBusiness.currentRegistrationFromDate"
      label="Current Registration Period"
      :value="`${$helpers.dateString(
        model.schemeBusiness.currentRegistrationFromDate
      )} - ${timeOrPresent(model.schemeBusiness.currentRegistrationToDate)}`"
    />

    <key-value-presenter
      v-if="model.schemeBusiness.isDeregistered"
      label="Deregistered Reason"
      :value="model.schemeBusiness.deregisteredReason"
    />
    <div class="row">
      <div
        v-for="rp in model.schemeBusiness.registrationPeriods"
        :key="rp"
        class="col-md-12 col-md-6 col-lg-4"
      >
        <card
          :label="`Period: ${$helpers.dateString(rp.fromDate)} - ${rp.toDateString}`"
          class="mt-4"
        >
          <key-value-presenter-check-or-cross label="Registered" :state="!rp.isDeregistered" />

          <key-value-presenter
            v-if="rp.isDeregistered"
            label="Deregistraion Reason"
            :value="rp.reason"
          />
          <key-value-presenter label="Is Voided" :value="rp.isVoided ? 'Yes' : 'No'" />

          <key-value-presenter label="Is API Update" :value="rp.isApiUpdate ? 'Yes' : 'No'" />

          <span v-if="!model.schemeBusiness.registrationPeriods.length" class="ghost">(None)</span>
        </card>
      </div>
    </div>

    <div class="pt-3">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <card v-if="hasSuspensions" label="Suspension History">
            <div v-if="!hasSuspensions"></div>

            <div v-if="hasSuspensions">
              <div class="row">
                <div-column-header :colclass="'col-sm-2'">Suspended By</div-column-header>
                <div-column-header :colclass="'col-sm-2'">Suspended Date</div-column-header>
                <div-column-header :colclass="'col-sm-2'">Unsuspended By</div-column-header>
                <div-column-header :colclass="'col-sm-2'">Unsuspended Date</div-column-header>
                <div-column-header :colclass="'col-sm-2'"
                  >Unsuspended Information
                </div-column-header>
                <div-column-header :colclass="'col-sm-1'">Billing From</div-column-header>
                <div-column-header :colclass="'col-sm-1'">Billing End</div-column-header>
              </div>
              <div v-for="sh in suspendHistory" :key="sh.createdAt" class="pt-1 pb-1">
                <div class="row" :class="sh.isBillable ? 'billable-suspension' : ''">
                  <div class="col-sm-2 d-flex align-items-center">
                    {{ sh.suspendedByType }}
                    <br />
                    {{ sh.createdBy }}
                  </div>
                  <div class="col-sm-2 d-flex align-items-center">
                    {{ $helpers.dateString(sh.createdAt) }}
                  </div>
                  <div class="col-sm-2 d-flex align-items-center">
                    {{ sh.unsuspendedBy }}
                  </div>
                  <div class="col-sm-2 d-flex align-items-center">
                    {{ $helpers.dateString(sh.unsuspendedDateTime) }}
                  </div>
                  <div class="col-sm-2 d-flex align-items-center">
                    <span>{{ sh.unsuspendedStatus }}</span
                    >&nbsp;
                    <span>{{
                      sh.unsuspendedDescription ? ` - ${sh.unsuspendedDescription}` : null
                    }}</span>
                  </div>
                  <div class="col-sm-1 d-flex align-items-center">
                    {{ sh.billingStartPeriod }}
                  </div>
                  <div class="col-sm-1 d-flex align-items-center">
                    {{
                      sh.isBillable && sh.billingLastPeriod === null
                        ? 'Ongoing until unsuspended'
                        : sh.billingLastPeriod
                    }}
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>

    <card v-if="!publishRequestSent" class="mt-5">
      <div v-if="errorPublishTrades" class="alert alert-danger" role="alert">
        {{ errorPublishTrades }}
      </div>
      <p>
        If you think something doesn't look right you can publish the trades for this TMLN to ensure
        they are the latest calculated set.
      </p>
      <button type="button" class="btn btn-lg btn-primary me-3" @click="publishTrades">
        Publish {{ model.tmln }} Trades
      </button>
    </card>

    <div v-if="publishRequestSent && !publishRequestUpdated" class="alert alert-info mt-5">
      The request has been sent and will take a few moments.
      <a href="#" @click="refreshSchemeBusiness">Refresh now</a>
    </div>

    <div v-if="publishRequestUpdated" class="alert alert-info mt-5">
      These are the latest trades for this business, updated
      {{ $helpers.dateTimeString(model.trades.lastUpdated) }}
    </div>
  </div>
</template>

<script>
import {
  Card,
  KeyValuePresenterCheckOrCross,
  CopyKeyValuePresenter,
  KeyValuePresenter,
  Subtitle,
  DivColumnHeader,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import { getTradeName } from '../../services/scheme-common';

export default {
  name: 'SchemeBusinessTradesAndRegistrations',
  components: {
    Card,
    Subtitle,
    CopyKeyValuePresenter,
    KeyValuePresenterCheckOrCross,
    KeyValuePresenter,
    DivColumnHeader,
  },
  data() {
    return {
      publishRequestSent: false,
      errorPublishTrades: null,
      publishRequestUpdated: false,
    };
  },
  computed: {
    model() {
      return this.$store.state.schemeBusiness.business;
    },
    schemeBusiness() {
      return this.model.schemeBusiness;
    },
    trades() {
      if (!this.model || !this.model.trades) return [];
      return this.model.trades.trades;
    },
    scheme() {
      return this.$store.state.auth.appData.schemes.find(
        (x) => x.schemeId === this.schemeBusiness.schemeId
      );
    },
    schemeName() {
      return (
        this.$store.state.auth.appData.schemes.find(
          (x) => x.schemeId === this.schemeBusiness.schemeId
        )?.schemeName ?? this.schemeBusiness.schemeId
      );
    },
    allTrades() {
      return this.$store.state.trade.trades;
    },
    hasSuspensions() {
      return this.model && this.suspendHistory && this.suspendHistory.length > 0;
    },
    suspendHistory() {
      const r = this.model?.suspendHistory;
      if (!r) return null;
      return r.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    isSchemeSuspended() {
      const schemeSuspended =
        this.schemeBusiness &&
        this.schemeBusiness.deregisteredReason === 'Suspension' &&
        (this.schemeBusiness.status === 5 || // Inactive
          this.schemeBusinesss.status === 3); // Submitted
      return schemeSuspended;
    },
  },
  mounted() {
    if (!this.$store.state.trade.trades) {
      this.$store.dispatch('trade/list', this.$route.params.schemeId);
    }
  },
  methods: {
    timeOrPresent(toDate) {
      return toDate ? this.$helpers.dateString(toDate) : 'present';
    },
    publishTrades() {
      const payload = {
        schemeId: this.model.schemeId,
        schemeBusinessId: this.model.schemeBusinessId,
      };
      this.$store
        .dispatch('schemeBusiness/publishtrades', payload)
        .then(() => {
          this.publishRequestSent = true;
        })
        .catch((err) => {
          this.errorPublishTrades = err.message ?? err;
        });
    },
    refreshSchemeBusiness() {
      const ld = this.model.trades.lastUpdated;
      const payload = {
        schemeId: this.model.schemeId,
        schemeBusinessId: this.model.schemeBusinessId,
      };
      this.$store
        .dispatch('schemeBusiness/get', payload)
        .then(() => {
          if (ld !== this.model.trades.lastUpdated) {
            this.publishRequestUpdated = true;
          }
        })
        .catch((err) => {
          this.errorPublishTrades = err.message ?? err;
        });
    },
    getTradeName,
  },
};
</script>

<style scoped>
.billable-suspension {
  background-color: #afdcbf;
}
</style>
