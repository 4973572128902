<template>
  <subtitle>Details</subtitle>

  <div v-if="model" class="h-100">
    <div :disabled="loading" class="pt-3">
      <div class="row">
        <div class="col-sm-12 col-md-10 col-lg-10">
          <form ref="formScheme" class="has-validation">
            <card label="Identity and Status">
              <div class="col-sm-12">
                <div class="form-group mb-3">
                  <label for="schemeId" class="form-label mb-0"> Scheme Id </label>
                  <label class="form-control" disabled="disabled">{{ model.schemeId }}</label>
                </div>
              </div>

              <smart-input
                id="schemeName"
                v-model="model.schemeName"
                label="Scheme Name"
                :is-required="!!v$.model.schemeName.isRequired"
                :validator="v$.model.schemeName"
              />

              <div v-if="!isActiveStatus" class="alert alert-danger mt-4">
                If the status of this scheme is not active none of their businesses will be able to
                lodge work and they won't appear in any searches on the TrustMark website.
              </div>

              <smart-select
                id="status"
                v-model="model.status"
                label="Status"
                :is-required="!!v$.model.status.isRequired"
                :validator="v$.model.status"
                :options="statusList"
              />
            </card>

            <card label="Options">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <smart-select-binary
                    id="hideFromJoin"
                    v-model="model.hideFromJoin"
                    label="Hide on Application"
                    :is-required="v$.model.hideFromJoin.isRequired"
                    :validator="v$.model.hideFromJoin"
                  />
                </div>
                <div class="col-sm-12 col-md-6">
                  <smart-select-binary
                    id="useBillingDeclaration"
                    v-model="model.useBillingDeclaration"
                    label="Use Billing Declaration?"
                    :is-required="v$.model.useBillingDeclaration.isRequired"
                    :validator="v$.model.useBillingDeclaration"
                  />
                </div>
              </div>
            </card>

            <card label="Scheme Information">
              <smart-input
                id="webAddress"
                v-model="model.webAddress"
                label="Web Address"
                :is-required="!model.hideFromJoin"
                :validator="v$.model.webAddress"
              />

              <smart-input
                id="membershipWebAddress"
                v-model="model.membershipWebAddress"
                label="Membership Web Address"
                :is-required="!model.hideFromJoin"
                :validator="v$.model.membershipWebAddress"
              />

              <smart-textarea
                id="membershipInformation"
                v-model="model.membershipInformation"
                label="Membership Information"
                :is-required="!model.hideFromJoin"
                :validator="v$.model.membershipInformation"
              />

              <smart-textarea
                id="consumerDescription"
                v-model="model.consumerDescription"
                label="Consumer Description"
                :is-required="!model.hideFromJoin"
                :validator="v$.model.consumerDescription"
              />

              <card label="Benefits" class="mt-5">
                <div v-for="(benefit, i) in model.benefits" :key="i" class="form-group mb-3">
                  <label class="form-label">Benefit {{ i + 1 }}</label>

                  <div class="input-group">
                    <input v-model="benefit.description" type="text" class="form-control" />
                    <button
                      class="btn btn-outline-danger btn-lg"
                      type="button"
                      @click="removeBenefit(i)"
                    >
                      <font-awesome-icon :icon="['fas', 'trash']" class="icon" />
                    </button>
                    <button
                      v-if="i > 0"
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="moveBenefit(i, i - 1)"
                    >
                      <font-awesome-icon icon="chevron-up" class="icon" />
                    </button>
                    <button
                      v-if="i < model.benefits.length - 1"
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="moveBenefit(i, i + 1)"
                    >
                      <font-awesome-icon icon="chevron-down" class="icon" />
                    </button>
                  </div>
                </div>
                <button
                  type="button"
                  class="btn btn-secondary float-right mt-3"
                  @click="addBenefit"
                >
                  Add Benefit
                </button>
              </card>

              <smart-input
                id="logoImage"
                v-model="model.logoImage"
                label="Sitefinity Image Key"
                :is-required="!!v$.model.logoImage.isRequired"
                :validator="v$.model.logoImage"
              />

              <file-upload
                id="schemeLogoImageKey"
                v-model="model.schemeLogoImageKey"
                label="Scheme Image Logo"
                :validator="v$.model.schemeLogoImageKey"
              />
            </card>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SmartSelectBinary,
  SmartSelect,
  SmartInput,
  Subtitle,
  SmartTextarea,
  Card,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import FileUpload from '../../FileUpload.vue';

export default {
  name: 'SchemesEditDetails',
  components: {
    SmartSelectBinary,
    SmartInput,
    Subtitle,
    Card,
    SmartSelect,
    SmartTextarea,
    FileUpload,
  },
  props: {
    v: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      copied: false,
      loading: false,
      chosenAddress: null,
    };
  },
  computed: {
    v$() {
      return this.v;
    },
    model() {
      return this.$store.state.scheme.scheme;
    },
    isActiveStatus() {
      return this.model?.status === 'Active';
    },
    statusList() {
      const values = this.$store.state.auth.appData.schemeStatuses;
      return values;
    },
  },
  methods: {
    updateOrder() {
      this.model.benefits.forEach((x, i) => {
        x.orderNumber = i;
      });
    },
    addBenefit() {
      if (!this.model.benefits) {
        this.model.benefits = [];
      }
      this.model.benefits.push({ description: null, orderNumber: this.model.benefits.length });
      this.updateOrder();
    },
    removeBenefit(index) {
      this.model.benefits.splice(index, 1);
      this.updateOrder();
    },
    moveBenefit(sourceIndex, targetIndex) {
      this.model.benefits.splice(targetIndex, 0, this.model.benefits.splice(sourceIndex, 1)[0]);
      this.updateOrder();
    },
    changeAddress(udprn) {
      const selected = this.addresses.find((x) => {
        return x.udprn === parseInt(udprn, 10);
      });

      if (!selected) {
        return;
      }

      let { town } = selected;

      if (selected.town === null || selected.town.length === 0) {
        town = selected.ward;
        if (selected.ward === null || selected.ward.length === 0) {
          town = selected.district;
        }
      }

      this.model.address1 = selected.line1;
      this.model.address2 = selected.line2;
      this.model.town = town;
      this.model.county = selected.postalCounty;
      this.model.country = selected.country;
      this.model.uprn = selected.uprn;
      this.model.udprn = selected.udprn;
      this.model.district = selected.district;
    },
    copy() {
      const that = this;
      navigator.clipboard.writeText(this.text).then(
        () => {
          that.copied = true;
          window.setTimeout(() => {
            that.copied = false;
          }, 1000);
        },
        () => {
          this.copied = false;
        }
      );
    },
  },
};
</script>
