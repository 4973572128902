import { HTTP, authHeader } from './http-service';

class BusinessSearchService {
  search(payload) {
    return HTTP.post(`/SchemeBusinessSearch`, payload, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  advancedSearch(payload) {
    return HTTP.post(`/SchemeBusinessSearch/advance`, payload, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  export(payload) {
    return HTTP.post(`/SchemeBusinessSearch/export`, payload, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }
}

export default new BusinessSearchService();
