<template>
  <div>
    <subtitle>Scheme Users</subtitle>
    <user-table />
  </div>
</template>

<script>
import { Subtitle } from '@trustmark-2005-ltd/trustmark-shared-components';
import UserTable from './UserTable.vue';

export default {
  name: 'UserList',
  components: {
    Subtitle,
    UserTable,
  },
};
</script>
