import SchemeNoteService from '../services/scheme-note-service';

export const schemeNote = {
  namespaced: true,
  state: {
    notes: null,
    note: null,
  },
  actions: {
    newNote({ commit, rootState }, schemeId) {
      const template = rootState.auth.appData.models.schemeNote;
      const empty = JSON.parse(JSON.stringify(template));
      empty.schemeId = schemeId;
      commit('setNote', empty);
    },
    listNotes({ commit }, schemeId) {
      commit('setIsBusy', true, { root: true });
      return SchemeNoteService.list(schemeId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setNotes', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    createNote({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return SchemeNoteService.create(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    getNote({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      commit('setNote', null);
      return SchemeNoteService.get(payload.schemeId, payload.noteId).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setNote', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateNote({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return SchemeNoteService.update(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    deleteNote({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeNoteService.delete(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setNotes(state, newNotes) {
      state.notes = newNotes;
    },
    setNote(state, newNote) {
      state.note = newNote;
    },
  },
};
