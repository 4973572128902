import FunderService from '../services/funder-service';

export const funder = {
  namespaced: true,
  state: {
    searchResult: null,
    funder: null,
    pageNumber: 0,
    lastSearchConfig: null,
  },
  actions: {
    registerFunder({ commit, dispatch }, data) {
      commit('setIsBusy', true, { root: true });
      return FunderService.register(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          setTimeout(() => {
            dispatch('executeLastSearch');
          }, 6000);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    getFunder({ commit }, id) {
      commit('setIsBusy', true, { root: true });
      commit('setFunder', null);
      return FunderService.get(id).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setFunder', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateFunder({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return FunderService.update(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    deleteFunder({ commit, dispatch }, payload) {
      commit('setIsBusy', true, { root: true });

      return FunderService.delete(payload.funderId).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setFunder', null);
          setTimeout(() => {
            dispatch('executeLastSearch');
          }, 6000);
          commit('removeFromSearchResult', payload.funderId);
          const { message } = result;
          return Promise.resolve(message);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    executeLastSearch({ dispatch, state }) {
      if (!state.lastSearchConfig) {
        throw new Error('No funder search has been run yet - cannot rerun');
      }
      dispatch('searchFunder', state.lastSearchConfig).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    searchFunder({ commit }, data) {
      data.type = 'standard';
      commit('setLastSearchConfig', data);
      return FunderService.search(data).then(
        (result) => {
          commit('setFunderSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    searchFunderAdvanced({ commit }, data) {
      data.type = 'advanced';
      commit('setLastSearchConfig', data);
      return FunderService.advancedSearch(data).then(
        (result) => {
          commit('setFunderSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getDashboardStats(_, funderId) {
      return FunderService.getDashboardStats(funderId).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createFunderApiKey(_, request) {
      return FunderService.createFunderApiKey(request).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setFunderSearchResult(state, searchResult) {
      state.searchResult = searchResult;
    },
    setLastSearchConfig(state, searchconfig) {
      state.lastSearchConfig = searchconfig;
    },
    setSearchPageNumber(state, newPageNumber) {
      state.pageNumber = newPageNumber;
    },
    removeFromSearchResult(state, funderId) {
      if (state.searchResult && state.searchResult.results) {
        state.searchResult.results = state.searchResult.results.filter(
          (x) => x.funderId !== funderId
        );
      }
    },
    setFunder(state, newFunder) {
      state.funder = newFunder;
    },
  },
};
