import { HTTP_RETROFIT, authHeader } from './http-service';

class RetrofitAccountService {
  get(tmln) {
    return HTTP_RETROFIT.get(`/BackOffice/retrofitaccount/${tmln}`, {
      headers: { 
        ...authHeader(),
        withCredentials: true,
      },
    }).then((response) => {
      return response.data;
    });
  }

  post(payload) {
    return HTTP_RETROFIT.post(`/BackOffice/retrofitaccount`, payload, {
      headers: { 
        ...authHeader(),
        withCredentials: true,
      },
    }).then((response) => {
      return response.data;
    });
  }

  ddTopup(tmln, payload) {
    return HTTP_RETROFIT.post(`/BackOffice/ddtopup/${tmln}`, payload, {
      headers: { 
        ...authHeader(),
        withCredentials: true,
      },
    }).then((response) => {
      return response.data;
    });
  }

  getDDTopups(retrofitAccountId) {
    return HTTP_RETROFIT.get(`/BackOffice/ddtopups?retrofitAccountId=${retrofitAccountId}`, {
      headers: { 
        ...authHeader(),
        withCredentials: true,
      },
    }).then((response) => {
      return response.data;
    });
  }

  refund(tmln, payload) {
    return HTTP_RETROFIT.post(`/BackOffice/refund/${tmln}`, payload, {
      headers: { 
        ...authHeader(),
        withCredentials: true,
      },
    }).then((response) => {
      return response.data;
    });
  }

  user(retrofitAccountId, payload) {
    return HTTP_RETROFIT.put(`/BackOffice/user/${retrofitAccountId}`, payload, {
      headers: { 
        ...authHeader(),
        withCredentials: true,
      },
    }).then((response) => {
      return response.data;
    });
  }

  removeUser(retrofitAccountId, accountId) {
    return HTTP_RETROFIT.put(
      `/BackOffice/removeuser/${retrofitAccountId}`,
      { accountId },
      {
        headers: { 
          ...authHeader(),
          withCredentials: true,
        },
      }
    ).then((response) => {
      return response.data;
    });
  }

  appData() {
    return HTTP_RETROFIT.get(`/AppData`, {
      headers: { 
        ...authHeader(),
        withCredentials: true,
      },
    }).then((response) => {
      return response.data;
    });
  }

  getFeeCategories(retrofitAccountId) {
    return HTTP_RETROFIT.get(`/BackOffice/feecategories/${retrofitAccountId}`, {
      headers: { 
        ...authHeader(),
        withCredentials: true,
      },
    }).then((response) => {
      return response.data;
    });
  }

  putFeeCategories(retrofitAccountId, payload) {
    return HTTP_RETROFIT.put(`/BackOffice/feecategories/${retrofitAccountId}`, payload, {
      headers: { 
        ...authHeader(),
        withCredentials: true,
      },
    }).then((response) => {
      return response.data;
    });
  }

  putNotificationEmails(retrofitAccountId, payload) {
    return HTTP_RETROFIT.put(`/BackOffice/notificationemails/${retrofitAccountId}`, payload, {
      headers: { 
        ...authHeader(),
        withCredentials: true,
      },
    }).then((response) => {
      return response.data;
    });
  }

  enableLoginLock(retrofitAccountId) {
    return HTTP_RETROFIT.put(
      `/BackOffice/loginlocked/${retrofitAccountId}/enable`,
      { },
      {
        headers: { 
          ...authHeader(),
          withCredentials: true,
        },
      }
    ).then((response) => {
      return response.data;
    });
  }

  disableLoginLock(retrofitAccountId) {
    return HTTP_RETROFIT.put(
      `/BackOffice/loginlocked/${retrofitAccountId}/disable`,
      { },
      {
        headers: { 
          ...authHeader(),
          withCredentials: true,
        },
      }
    ).then((response) => {
      return response.data;
    });
  }
}

export default new RetrofitAccountService();
