<template>
  <div class="row">
    <div class="col-sm-12" :class="{ 'col-md-6 col-xl-9': showAdvanced }">
      <div class="w-100">
        <h1>Software Providers</h1>

        <software-provider-toolbar />

        <software-provider-search-table ref="softwareProvidersTable" />
      </div>
    </div>
  </div>
</template>

<script>
import SoftwareProviderSearchTable from './SoftwareProviderSearchTable.vue';
import SoftwareProviderToolbar from './SoftwareProviderToolbar.vue';

export default {
  name: 'SoftwareProvidersList',
  components: {
    SoftwareProviderSearchTable,
    SoftwareProviderToolbar,
  },
  data() {
    return {
      showAdvanced: false,
    };
  },
  methods: {
    showErrorMessage(msg) {
      this.$refs.softwareProvidersTable.changeErrorMessage(msg);
    },
    reset() {
      this.$refs.softwareProvidersTable.reset();
    },
  },
};
</script>
