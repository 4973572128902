import { HTTP, authHeader } from './http-service';

class DisputeService {
  get(payload) {
    return HTTP.get(`/dispute/${payload.disputeId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  create(payload) {
    return HTTP.post(`/dispute`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/dispute`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  delete(payload) {
    return HTTP.delete(`/dispute/${payload.disputeId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new DisputeService();
