import { HTTP, authHeader } from './http-service';

class PendingLicenceActionService {
  search(payload) {
    return HTTP.post(`/pendinglicenceaction/search`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  searchAdvance(payload) {
    return HTTP.post(`/pendinglicenceaction/searchadvance`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  selectOption(payload) {
    return HTTP.put(`/pendinglicenceaction/selectOption`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  accept(payload) {
    return HTTP.put(`/pendinglicenceaction/accept`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  deny(payload) {
    return HTTP.put(`/pendinglicenceaction/deny`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  moveTMLN(data) {
    return HTTP.post(`/PendingLicenceAction`, data, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  splitTMLN(schemeId, schemeBusinessId) {
    return HTTP.delete(
      `/PendingLicenceAction/splittmln/scheme/${schemeId}/business/${schemeBusinessId}`,
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }

  canceladmin(payload) {
    return HTTP.put(`/pendinglicenceaction/canceladmin`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getBadge() {
    return HTTP.get(`/pendinglicenceaction/badge`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new PendingLicenceActionService();
