<template>
  <div v-if="!hasPendingAction">
    <confirm-dialog ref="confirmChangeTmlnDialog"></confirm-dialog>
    <card label="Change TMLN">
      <p class="fw-bold">
        If you would like to move {{ model.registeredCompanyName }} to a new TMLN
      </p>
      <div class="mt-3 mb-5">
        <div class="ps-3 pe-3">
          <div class="form-group">
            <div v-if="errorSplitTMLN" class="alert alert-danger" role="alert">
              {{ errorSplitTMLN }}
            </div>
          </div>
          <p>
            This action will create a TMLN which this business will be the only linked business, it
            will be removed from {{ model.tmln }}.
          </p>
          <button type="button" class="btn btn-lg btn-primary me-3" @click="confirmSplitTMLN">
            Create New TMLN
          </button>
        </div>
      </div>
      <div v-if="canMove" class="mt-3 mb-5">
        <hr />
        <p class="fw-bold">
          If you would like to move {{ model.registeredCompanyName }} to an existing TMLN
        </p>
        <div class="ps-3 pe-3">
          <div class="form-group">
            <div v-if="errorMoveTMLN" class="alert alert-danger" role="alert">
              {{ errorMoveTMLN }}
            </div>
          </div>
          <div class="mb-3">
            <label for="destinationTMLN" class="form-label">Move this to an existing TMLN</label>
            <input
              id="destinationTMLN"
              v-model="destinationTMLN"
              class="form-control"
              style="max-width: 250px"
              placeholder="TMLN"
            />
          </div>
          <button
            type="button"
            class="btn btn-lg btn-primary me-3"
            :disabled="destinationTMLN ? false : true"
            @click="moveTMLN"
          >
            Move to Existing TMLN
          </button>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { ConfirmDialog, confirmType, Card } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'SchemeBusinessRequestChangeTmln',
  components: { Card, ConfirmDialog },
  data() {
    return {
      canMove: true,
      loading: false,
      destinationTMLN: null,
      errorSplitTMLN: null,
      errorMoveTMLN: null,
    };
  },
  computed: {
    model() {
      return this.$store.state.schemeBusiness.business.schemeBusiness;
    },
    hasPendingAction() {
      return this.model && this.model.linkPending !== null;
    },
  },
  methods: {
    confirmSplitTMLN() {
      this.$refs.confirmChangeTmlnDialog
        .show({
          title: 'Confirm Split TMLN',
          message:
            "Are you sure you want to split this licence so that this business will have it's own unique TMLN?",
          okButtonText: 'Split TMLN',
          confirmType: confirmType.standard,
        })
        .then((result) => {
          if (result === true) {
            this.errorSplitTMLN = null;
            const payload = {
              schemeId: this.model.schemeId,
              schemeBusinessId: this.model.schemeBusinessId,
            };
            this.$store
              .dispatch('pendingLicenceAction/splitTMLN', payload)
              .then(() => {
                this.loading = false;
              })
              .catch((err) => {
                this.loading = false;
                this.errorSplitTMLN = err.message ?? err;
              });
          }
        });
    },
    moveTMLN() {
      this.$refs.confirmChangeTmlnDialog
        .show({
          title: 'Move TMLN',
          message: `Are you sure you want to move this business over to TMLN ${this.destinationTMLN}?`,
          okButtonText: 'Confirm Move',
          confirmType: confirmType.standard,
        })
        .then((result) => {
          if (result === true) {
            this.errorMoveTMLN = null;
            const payload = {
              schemeId: this.model.schemeId,
              schemeBusinessId: this.model.schemeBusinessId,
              tmln: this.destinationTMLN,
            };
            this.$store
              .dispatch('pendingLicenceAction/moveTMLN', payload)
              .then(() => {
                this.loading = false;
                window.scrollTo(0, 0);
              })
              .catch((err) => {
                this.loading = false;
                this.errorMoveTMLN = err.message ?? err;
              });
          }
        });
    },
  },
};
</script>
