<template>
  <div>
    <subtitle>Notes</subtitle>

    <div class="h-100 pt-3">
      <div class="row">
        <div class="col">
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>

          <table v-if="notes" class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Note</th>
                <th scope="col" style="min-width: 150px">Modified</th>
                <th scope="col" style="min-width: 150px">Created</th>
                <th scope="col" style="min-width: 150px">Last Modified By</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="note in sortedNotes" :key="note.noteId">
                <td>
                  {{ note.noteText }}
                </td>
                <td>
                  {{ $helpers.getLastModifiedString(note) }}
                </td>
                <td>
                  {{ $helpers.dateTimeString(note.createdAt) }}
                </td>
                <td>
                  {{ note.updatedBy || note.createdBy }}
                </td>
              </tr>
            </tbody>
          </table>

          <div v-if="loading && !notes" class="ghost">Loading notes...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Subtitle } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'SchemesViewNotesList',
  components: {
    Subtitle,
  },
  data() {
    return {
      errorMessage: null,
      loading: true,
    };
  },
  computed: {
    parentRoute() {
      return `/scheme/${this.$route.params.schemeId}/notes`;
    },
    notes() {
      return this.$store.state.schemeNote.notes;
    },
    sortedNotes() {
      const clone = JSON.parse(JSON.stringify(this.notes));
      return clone.sort(
        (a, b) => this.$helpers.getLastModifiedDate(b) - this.$helpers.getLastModifiedDate(a)
      );
    },
  },
  mounted() {
    if (!this.notes || this.notes.length === 0) {
      this.listNotes();
    }
  },
  methods: {
    listNotes() {
      this.$store
        .dispatch('schemeNote/listNotes', this.$route.params.schemeId)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.errorMessage = err;
        });
    },
  },
};
</script>
