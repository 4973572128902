<template>
  <confirm-dialog ref="confirmDeleteTrade"></confirm-dialog>
  <h1>Trades</h1>

  <div class="btn-toolbar mb-2 mb-md-0">
    <div class="btn-group me-2">
      <button v-if="isTradeEditor" class="btn btn-primary" @click="create">Create Trade</button>
    </div>
  </div>

  <label class="p-1" style="font-weight: 700"
    >{{ filteredList.length }} Trades
    <span v-if="filterValue"> (filtered)</span>
  </label>

  <div class="btn-toolbar mb-3" role="toolbar">
    <div class="row w-100">
      <div class="col-sm-12 col-md-9 col-lg-6">
        <div class="input-group">
          <div id="btnGroupAddon" class="input-group-text bg-transparent border-end-0">
            <font-awesome-icon icon="magnifying-glass" />
          </div>
          <input
            v-model="filterValue"
            type="text"
            class="form-control border-start-0"
            placeholder="Find trade"
          />
          <button class="btn btn-outline-secondary" type="button" @click="clear">Clear</button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!trades" class="ghost">Loading trades...</div>

  <div v-if="trades && trades.length === 0" class="alert alert-warning">No trades to show.</div>

  <div v-if="trades && trades.length > 0" class="h-100 pt-3">
    <div class="row">
      <div
        class="col-sm-12 col-md-12 col-lg-12"
        style="max-height: calc(100vh - 230px); overflow-x: scroll"
      >
        <div v-if="errorMessage" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>

        <table v-if="trades" class="table table-hover">
          <thead>
            <tr>
              <th scope="col" style="min-width: 120px">Trade Code</th>
              <th scope="col">Trade Description</th>
              <th scope="col">Technical Standards</th>
              <th scope="col" style="min-width: 200px">Internal Notes</th>
              <th scope="col" style="width: 140px">Last Modified</th>
              <th scope="col" style="width: 1px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="trade in filteredList" :key="trade.tradeId">
              <td>
                <p>
                  <b>{{ trade.tradeCode }}</b>
                </p>
                <p>
                  {{
                    trade.isActive
                      ? 'Active'
                      : `Inactive from ${$helpers.dateString(trade.inactiveDateFrom)}`
                  }}
                </p>
              </td>
              <td>
                <conditional-new-line
                  v-if="trade.tradeDescription"
                  :text="trade.tradeDescription"
                  label="Description"
                  :blank-if-null="true"
                />
                <conditional-new-line
                  v-if="trade.ghgvsName"
                  :text="trade.ghgvsName"
                  label="Consumer Description"
                  :blank-if-null="true"
                />
              </td>
              <td>
                <conditional-new-line
                  v-if="trade.standard"
                  :text="trade.standard"
                  label="Standard"
                  :blank-if-null="true"
                />
                <conditional-new-line
                  v-if="trade.pasAnnex"
                  :text="trade.pasAnnex"
                  label="PAS Annex"
                  :blank-if-null="true"
                />
              </td>
              <td>
                <conditional-new-line
                  v-if="trade.internalNotes"
                  :text="trade.internalNotes"
                  label="Internal Notes:"
                  blank-if-null="true"
                />
              </td>
              <td>
                {{ $helpers.getLastModifiedString(trade) }}
              </td>
              <td>
                <div v-if="isTradeEditor" class="btn-group btn-group">
                  <button class="btn btn-outline-primary" @click="edit(trade.tradeId)">Edit</button>
                  <button
                    v-if="isTradeEditor"
                    type="button"
                    class="btn btn-outline-danger"
                    @click="confirmErase(trade.tradeId)"
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ConditionalNewLine,
  confirmType,
  ConfirmDialog,
} from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'TradesList',
  components: {
    ConditionalNewLine,
    ConfirmDialog,
  },
  data() {
    return {
      filterValue: null,
      errorMessage: null,
      loading: true,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isTradeEditor() {
      if (this.currentAccount && this.currentAccount.roles) {
        return this.currentAccount.roles.includes('TradeEditor');
      }
      return false;
    },
    suggestedTradeCode() {
      if (!this.filteredList || this.filteredList.length === 0) {
        return 0;
      }

      return this.filteredList[this.filteredList.length - 1].tradeCode + 1;
    },
    parentRoute() {
      return `/trades`;
    },
    trades() {
      return this.$store.state.trade.trades;
    },
    filteredList() {
      if (!this.trades || this.trades.length === 0) {
        return [];
      }

      let t = JSON.parse(JSON.stringify(this.trades));
      t.sort((a, b) => a.tradeCode - b.tradeCode);

      const { filterValue } = this;

      let code = -1;
      if (Number.isInteger(Number(filterValue)) && parseInt(filterValue, 10) > -1) {
        code = parseInt(filterValue, 10);
      }

      if (filterValue !== null || code > 0) {
        t = t.filter(
          (x) =>
            (code > -1 && x.tradeCode === code) ||
            (x.tradeDescription !== null &&
              x.tradeDescription.toUpperCase().indexOf(filterValue.toUpperCase()) > -1) ||
            (filterValue.indexOf('inactive') === 0 && !x.isActive) ||
            (filterValue.indexOf('active') === 0 && x.isActive)
        );
      }

      return t;
    },
  },
  mounted() {
    if (!this.trades || this.trades.length === 0) {
      this.list();
    }
  },
  methods: {
    list() {
      this.$store
        .dispatch('trade/list', this.$route.params.tradeId)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.errorMessage = err;
        });
    },
    create() {
      this.$store.dispatch('trade/initialise', this.suggestedTradeCode).then(() => {
        this.$router.push(`${this.parentRoute}/create`);
      });
    },
    edit(tradeId) {
      this.$store.dispatch('trade/get', tradeId).then(() => {
        this.$router.push(`${this.parentRoute}/edit/${tradeId}`);
      });
    },
    erase(tradeId) {
      this.$store.dispatch('trade/delete', tradeId).then(() => {
        this.list();
      });
    },
    confirmErase(tradeId) {
      this.$refs.confirmDeleteTrade
        .show({
          title: 'Delete Trade',
          message: 'Are you sure you want to delete this trade?',
          confirmType: confirmType.destructive,
        })
        .then((result) => {
          if (result === true) {
            this.erase(tradeId);
          }
        });
    },
    clear() {
      this.filterValue = null;
    },
  },
};
</script>
<style scoped>
.chip {
  padding: 0 10px 3px 10px;
}

.mr-2 {
  margin-right: 10px;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 0 auto;
}

th {
  /* header cell */
  position: sticky;
  top: 0;
  background-color: white;
  color: black;
  z-index: 10;
}
</style>
