import { HTTP_RETROFIT, authHeader } from './http-service';

class SoftwareProviderService {
  list() {
    return HTTP_RETROFIT.get(`/BackOffice/softwareproviders`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  get(retrofitAccountId) {
    return HTTP_RETROFIT.get(`/BackOffice/softwareprovider/${retrofitAccountId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  post(payload) {
    return HTTP_RETROFIT.post(`/BackOffice/retrofitaccount`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  user(retrofitAccountId, payload) {
    return HTTP_RETROFIT.put(`/BackOffice/user/${retrofitAccountId}`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  appData() {
    return HTTP_RETROFIT.get(`/AppData`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  disableAccount(retrofitAccountId) {
    return HTTP_RETROFIT.put(
      `/BackOffice/softwareprovider/${retrofitAccountId}/disable`,
      {},
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }

  enableAccount(retrofitAccountId) {
    return HTTP_RETROFIT.put(
      `/BackOffice/softwareprovider/${retrofitAccountId}/enable`,
      {},
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }

  deleteSoftwareProvider(retrofitAccountId) {
    return HTTP_RETROFIT.delete(
      `/BackOffice/softwareprovider/${retrofitAccountId}`,
      {},
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }
}

export default new SoftwareProviderService();
