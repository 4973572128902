import TradingStandardsAdminService from '../services/trading-standards-admin-service';

export const tradingStandardsAdmin = {
  namespaced: true,
  state: {
    yearData: [],
    yearFilterOption: 'Current Workflow',
    tradingStandardsYear: null,
    tradingStandardsYearFunnelData: null,
    badges: {
      pending: 0,
      applied: 0,
      invoicePaid: 0,
      tradingStandardsResponseReceived: null,
    },
  },
  actions: {
    listYear({ commit }, yearNumber) {
      commit('setIsBusy', true, { root: true });
      return TradingStandardsAdminService.listYear(yearNumber)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const yearIndex = {
            year: yearNumber,
            data: result.model,
          };
          commit('setYearData', yearIndex);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    listYearNow({ commit }) {
      commit('setIsBusy', true, { root: true });
      const yearNumber = new Date().getUTCFullYear();
      return TradingStandardsAdminService.listYear(yearNumber)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const yearIndex = {
            year: yearNumber,
            data: result.model,
          };
          commit('setYearData', yearIndex);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    listCurrent({ commit }) {
      commit('setIsBusy', true, { root: true });
      return TradingStandardsAdminService.listCurrent()
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const yearIndex = {
            year: 'all',
            data: result.model,
          };
          commit('setYearData', yearIndex);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    getFunnelData({ commit }) {
      commit('setIsBusy', true, { root: true });
      commit('setTradingStandardsYear', null);
      return TradingStandardsAdminService.getFunnelData().then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setTradingStandardsYearFunnelData', result.model);
          commit('setBadges', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    getFunnelDataForYear({ commit }, yearNumber) {
      commit('setIsBusy', true, { root: true });
      commit('setTradingStandardsYear', null);
      return TradingStandardsAdminService.getFunnelDataForYear(yearNumber).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setTradingStandardsYearFunnelData', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    getTradingStandardsYear({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      commit('setTradingStandardsYear', null);
      return TradingStandardsAdminService.getTradingStandardsYear(
        payload.tmln,
        payload.renewalYear
      ).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setTradingStandardsYear', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    createTradingStandardsYear({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return TradingStandardsAdminService.createTradingStandardsYear(payload).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setTradingStandardsYear', result.model);
          return Promise.resolve(result.model);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateTradingStandardsYear({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return TradingStandardsAdminService.updateTradingStandardsYear(payload).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setTradingStandardsYear', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setYearData(state, newYearData) {
      state.yearData = [];
      state.yearData.push(newYearData);
    },
    setTradingStandardsYear(state, newTradingStandardsYear) {
      state.tradingStandardsYear = newTradingStandardsYear;
    },
    setTradingStandardsYearFunnelData(state, newData) {
      state.tradingStandardsYearFunnelData = newData;
    },
    setBadges(state, newData) {
      const pending = newData.filter((x) => x.item1 === 'Pending');
      state.badges.pending = pending.length > 0 ? pending[0].item2 : 0;
      const applied = newData.filter((x) => x.item1 === 'Applied');
      state.badges.applied = applied.length > 0 ? applied[0].item2 : 0;
      const invoicePaid = newData.filter((x) => x.item1 === 'Invoice Paid');
      state.badges.invoicePaid = invoicePaid.length > 0 ? invoicePaid[0].item2 : 0;
      const tradingStandardsResponseReceived = newData.filter(
        (x) => x.item1 === 'Trading Standards Response Received'
      );
      state.badges.tradingStandardsResponseReceived =
        tradingStandardsResponseReceived.length > 0 ? tradingStandardsResponseReceived[0].item2 : 0;
    },
    setYearFilterOption(state, newData) {
      state.yearFilterOption = newData;
    },
  },
};
