<template>
  <subtitle>Create Note</subtitle>

  <div v-if="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <div v-if="successMessage" class="alert alert-success" role="alert">
    {{ successMessage }}
  </div>

  <edit-fields :v="v$" />

  <div class="row">
    <div class="col-sm-12 col-md-10 col-lg-10">
      <div class="form-group d-grid gap-2 d-md-flex justify-content-md-end">
        <router-link class="btn btn-secondary float-right btn-lg" type="button" :to="parentRoute">
          <span>Cancel</span>
        </router-link>

        <smart-submit-large
          v-if="isSchemeBusinessInternalNoteEditor"
          label="Save"
          :loading="loading"
          @click="save"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Subtitle, SmartSubmitLarge } from '@trustmark-2005-ltd/trustmark-shared-components';
import useValidate from '@vuelidate/core';
import EditFields from './SchemeBusinessNotesFields.vue';
import { validationModel } from './validation-model';

export default {
  name: 'SchemeBusinessNotesCreate',
  components: {
    SmartSubmitLarge,
    EditFields,
    Subtitle,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      successMessage: null,
      v$: useValidate(),
    };
  },
  computed: {
    parentRoute() {
      return `/scheme-business/${this.$route.params.schemeId}/${this.$route.params.schemeBusinessId}/notes`;
    },
    model() {
      return this.$store.state.schemeBusinessNote.note;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isSchemeBusinessInternalNoteEditor() {
      if (this.currentAccount && this.currentAccount.roles) {
        return this.currentAccount.roles.includes('SchemeBusinessInternalNoteEditor');
      }
      return false;
    },
  },
  methods: {
    save() {
      this.v$.$validate();
      if (this.v$.$error) {
        window.scrollTo(0, 0);
        this.errorMessage =
          'Unable to save the note. Please resolve any errors on this form and try again.';
        return;
      }

      const payload = this.model;
      payload.schemeId = this.$route.params.schemeId;
      payload.schemeBusinessId = this.$route.params.schemeBusinessId;
      this.$store
        .dispatch('schemeBusinessNote/createNote', payload)
        .then((resp) => {
          this.$store
            .dispatch('schemeBusinessNote/listNotes', this.$route.params.schemeBusinessId)
            .then(() => {
              this.successMessage = resp;
              this.$router.push(this.parentRoute);
            });
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        });
    },
  },
  validations: {
    model: validationModel,
  },
};
</script>
