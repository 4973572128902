<template>
  <subtitle>Add Trade To Scheme</subtitle>

  <div v-if="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <div v-if="successMessage" class="alert alert-success" role="alert">
    {{ successMessage }}
  </div>

  <edit-fields :v="v$" />

  <div class="row">
    <div class="col-sm-12 col-md-10 col-lg-10">
      <div class="form-group d-grid gap-2 d-md-flex justify-content-md-end">
        <router-link class="btn btn-secondary float-right btn-lg" type="button" :to="parentRoute">
          <span>Cancel</span>
        </router-link>

        <smart-submit-large label="Save" :loading="loading" @click="save" />
      </div>
    </div>
  </div>
</template>

<script>
import { Subtitle, SmartSubmitLarge } from '@trustmark-2005-ltd/trustmark-shared-components';
import useValidate from '@vuelidate/core';
import EditFields from './SchemesEditTradesFieldsCreate.vue'  ;
import { validationModel } from './validation-model';
import { getTradeName } from '../../../../services/scheme-common';

export default {
  name: 'SchemesEditTradesCreate',
  components: {
    SmartSubmitLarge,
    EditFields,
    Subtitle,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      successMessage: null,
      v$: useValidate(),
    };
  },
  computed: {
    parentRoute() {
      return `/scheme/${this.$route.params.schemeId}/edit/scheme-trades`;
    },
    model() {
      return this.$store.state.schemeTrade.trade;
    },
  },
  methods: {
    getTradeName,
    save() {
      this.v$.$validate();
      if (this.v$.$error) {
        window.scrollTo(0, 0);
        this.errorMessage =
          'Unable to save the trade. Please resolve any errors on this form and try again.';
        return;
      }

      const payload = {
        notes: this.model.notes,
        tradeDescription: this.getTradeName(this.model.tradeCode),
        tradeCode: parseInt(this.model.tradeCode, 10),
        schemeId: this.$route.params.schemeId,
        status: this.model.status,
      };

      this.$store
        .dispatch('schemeTrade/create', payload)
        .then((resp) => {
          this.$store.dispatch('schemeTrade/list', this.$route.params.schemeId).then(() => {
            this.successMessage = resp;
            this.$router.push(this.parentRoute);
          });
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        });
    },
  },
  validations: {
    model: validationModel,
  },
};
</script>
