<template>
  <subtitle>Topup Account</subtitle>

  <div class="col-sm-12 col-md-10">
    <card>
      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
      </div>

      <div class="form-group">
        <label for="tmln" class="form-label">TMLN</label>
        <input id="tmln" v-model="tmln" class="form-control" type="text" disabled />
      </div>
      <div class="form-group">
        <label for="amount" class="form-label">Amount in GBP *</label>
        <input
          id="amount"
          v-model="amount"
          class="form-control"
          type="text"
          :class="v$.amount.$error ? 'is-invalid' : ''"
          required
        />
        <div v-if="v$.amount.$error" class="invalid-feedback">
          {{ v$.amount.$errors[0].$message }}
        </div>
      </div>
      <div class="form-group">
        <label for="reference" class="form-label">Reference *</label>
        <input
          id="reference"
          v-model="reference"
          class="form-control"
          type="text"
          :class="v$.reference.$error ? 'is-invalid' : ''"
          required
        />
        <div v-if="v$.reference.$error" class="invalid-feedback">
          {{ v$.reference.$errors[0].$message }}
        </div>
      </div>
      <button type="button" class="btn btn-primary mt-3" @click="topup">Topup</button>
    </card>
  </div>

  <div class="col-sm-12 col-md-10">
    <card>
      <div class="card-body">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Transaction Id</th>
              <th scope="col">Description</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody v-if="listDDTopups">
            <tr v-for="ddTopup in listDDTopups" :key="ddTopup">
              <td>
                {{ $helpers.formatDate(ddTopup.transactionDt) }}
              </td>
              <td>
                {{ ddTopup.transactionId }}
              </td>
              <td>
                {{ ddTopup.description }}
              </td>
              <td style="text-align: right; padding-right: 10px">
                {{ $helpers.currencyFormat(ddTopup.amount) }}
              </td>
            </tr>
          </tbody>
        </table>
        <small class="mt-3">New transactions may take a few moments to appear</small>
      </div>
    </card>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core';
import { Subtitle, Card } from '@trustmark-2005-ltd/trustmark-shared-components';
import { helpers, required } from '@vuelidate/validators';
import { formatDate } from '../../../services/scheme-common';

export default {
  name: 'LicenceNumberViewRetrofitTopup',
  components: {
    Subtitle,
    Card,
  },
  data() {
    return {
      v$: useValidate(),
      amount: '0.00',
      reference: '',
      message: '',
    };
  },
  computed: {
    model() {
      return this.$store.state.licenceNumber.retrofitAccount;
    },
    tmln() {
      return this.$store.state.licenceNumber.trustmarkBusiness.tmln;
    },
    trustmarkBusinessId() {
      return this.$store.state.licenceNumber.trustmarkBusiness.trustmarkBusinessId;
    },
    listDDTopups() {
      return this.$store.state.licenceNumber.listDDTopups;
    },
  },
  validations: {
    amount: {
      isRequired: helpers.withMessage('Amount is required', required),
    },
    reference: {
      isRequired: helpers.withMessage('Reference is required', required),
    },
  },
  mounted() {
    this.$store
      .dispatch(
        'licenceNumber/getRetrofitAccountByTMLN',
        this.$store.state.licenceNumber.trustmarkBusiness.tmln
      )
      .then(() => {
        this.$store.dispatch('licenceNumber/getDDTopups', {});
      });
  },
  methods: {
    formatDate,
    asString(statusCode) {
      return this.$store.state.auth.appData.licenceNumberStatuses.find(
        (x) => x.status === parseInt(statusCode, 10)
      ).description;
    },
    topup() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.message = 'Please enter a payment reference and amount.';
        window.scrollTo(0, 0);
        return;
      }

      this.$store
        .dispatch('licenceNumber/ddTopup', {
          amount: this.amount,
          reference: this.reference,
          tmln: this.$store.state.licenceNumber.trustmarkBusiness.tmln,
        })
        .then(() => {
          this.$router.push({
            name: 'retrofit-account',
            params: { tmBusinessId: this.trustmarkBusinessId },
          });
        })
        .catch((error) => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        });

      this.message = '';
    },
  },
};
</script>
