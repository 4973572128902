import { helpers, numeric, required } from '@vuelidate/validators';

export const validationModel = {
  tradeDescription: {},
  tradeCode: {
    isRequired: helpers.withMessage('Trade code is required', required),
    numeric: helpers.withMessage('Trade code must be numeric', numeric),
  },
  status: {
    isRequired: helpers.withMessage('Status is required', required),
  },
  notes: {},
};
