<template>
  <div v-if="licenceBusiness && linkPending" class="mt-5">
    <card label="Comparison">
      <div class="col-sm-12 col-md-9">
        <p>You can review the key fields for this Pending Licence to make a decision.</p>
        <p>
          Accept and the business will join the existing licence
          <router-link :to="`/tmlns/${linkPending.trustmarkBusinessId}`">{{
            linkPending.tmln
          }}</router-link
          >, Deny will create a new TMLN to uniquely reference
          {{ schemeBusiness.registeredCompanyName }}.
        </p>
        <p v-if="linkPendingOptions.length > 1">
          Choose which match using the horizonal scroll display below, they are ranked
          <b>1 through to {{ linkPendingOptions.length }}</b>
        </p>
      </div>

      <div class="table-responsive">
        <table class="p-3">
          <tr class="pt-3 pb-3 mb-2">
            <td class="sticky white"></td>
            <td v-for="l in linkPendingOptions" :key="l.trustmarkBusinessId" class="text-center">
              <b>Rank #{{ l.rank }}</b>
            </td>
          </tr>

          <tr class="pt-3 pb-2 fw-bold">
            <td class="sticky white">
              <div class="row">
                <div class="col">Field</div>
                <div class="col">New Business Value</div>
              </div>
            </td>
            <td :colspan="linkPendingOptions.length">Existing Licence Values</td>
          </tr>

          <tr
            class="pt-3 pb-3 mb-2"
            :class="rowClassMismatch(schemeBusiness.tmln, linkPending.tmln)"
          >
            <td :class="rowClassMismatch(schemeBusiness.tmln, linkPending.tmln)" class="sticky">
              <div class="row">
                <div class="col">TMLN</div>
                <div class="col">{{ schemeBusiness.tmln ?? 'Not yet assigned' }}</div>
              </div>
            </td>
            <td
              v-for="l in linkPendingOptions"
              :key="l.trustmarkBusinessId"
              :class="rowClassMismatch(schemeBusiness.tmln, l.tmln)"
            >
              {{ l.tmln ?? 'Not yet assigned' }}
            </td>
          </tr>
          <tr
            class="pt-3 pb-3 mb-2"
            :class="
              rowClassForMatch(
                schemeBusiness.registeredCompanyName,
                licenceBusiness.registeredCompanyName
              )
            "
          >
            <td
              :class="
                rowClassForMatch(
                  schemeBusiness.registeredCompanyName,
                  licenceBusiness.registeredCompanyName
                )
              "
              class="sticky"
            >
              <div class="row">
                <div class="col">Company Name</div>
                <div class="col">{{ schemeBusiness.registeredCompanyName }}</div>
              </div>
            </td>
            <td
              v-for="l in linkPendingOptions"
              :key="l.trustmarkBusinessId"
              :class="
                rowClassForMatch(
                  schemeBusiness.registeredCompanyName,
                  getLicenceBusinessByTMBusinessId(l.trustmarkBusinessId).registeredCompanyName
                )
              "
            >
              {{ getLicenceBusinessByTMBusinessId(l.trustmarkBusinessId).registeredCompanyName }}
            </td>
          </tr>
          <tr
            class="pt-3 pb-3 mb-2"
            :class="
              rowClassForMatch(
                schemeBusiness.registeredCompanyNumber,
                licenceBusiness.registeredCompanyNumber
              )
            "
          >
            <td
              :class="
                rowClassForMatch(
                  schemeBusiness.registeredCompanyNumber,
                  licenceBusiness.registeredCompanyNumber
                )
              "
              class="sticky"
            >
              <div class="row">
                <div class="col">Company Number</div>
                <div class="col">{{ schemeBusiness.registeredCompanyNumber }}</div>
              </div>
            </td>
            <td
              v-for="l in linkPendingOptions"
              :key="l.trustmarkBusinessId"
              :class="
                rowClassForMatch(
                  schemeBusiness.registeredCompanyNumber,
                  getLicenceBusinessByTMBusinessId(l.trustmarkBusinessId).registeredCompanyNumber
                )
              "
            >
              {{ getLicenceBusinessByTMBusinessId(l.trustmarkBusinessId).registeredCompanyNumber }}
            </td>
          </tr>
          <tr
            class="pt-3 pb-3 mb-2"
            :class="
              rowClassForMatch(
                schemeBusiness.email,
                getLicenceBusinessByTMBusinessId(linkPending.trustmarkBusinessId)?.registeredEmail
              )
            "
          >
            <td
              :class="
                rowClassForMatch(
                  schemeBusiness.email,
                  getLicenceBusinessByTMBusinessId(linkPending.trustmarkBusinessId)?.registeredEmail
                )
              "
              class="sticky"
            >
              <div class="row">
                <div class="col">Email</div>
                <div class="col">{{ schemeBusiness.email }}</div>
              </div>
            </td>
            <td
              v-for="l in linkPendingOptions"
              :key="l.trustmarkBusinessId"
              :class="
                rowClassForMatch(
                  schemeBusiness.email,
                  getLicenceBusinessByTMBusinessId(l.trustmarkBusinessId).registeredEmail
                )
              "
            >
              {{ getLicenceBusinessByTMBusinessId(l.trustmarkBusinessId).registeredEmail }}
            </td>
          </tr>
          <tr
            class="pt-3 pb-3 mb-2"
            :class="
              rowClassMismatch(
                schemeBusiness.membershipReference,
                licenceBusiness.linkedSchemeBusinesses.find(
                  (x) => x.membershipReference === schemeBusiness.membershipReference
                )?.membershipReference
              )
            "
          >
            <td
              :class="
                rowClassMismatch(
                  schemeBusiness.membershipReference,
                  licenceBusiness.linkedSchemeBusinesses.find(
                    (x) => x.membershipReference === schemeBusiness.membershipReference
                  )?.membershipReference
                )
              "
              class="sticky"
            >
              <div class="row">
                <div class="col">Membership Reference</div>
                <div class="col">{{ schemeBusiness.membershipReference }}</div>
              </div>
            </td>
            <td
              v-for="l in linkPendingOptions"
              :key="l.trustmarkBusinessId"
              :class="
                rowClassMismatch(
                  schemeBusiness.membershipReference,
                  getLicenceBusinessByTMBusinessId(
                    l.trustmarkBusinessId
                  )?.linkedSchemeBusinesses?.find(
                    (x) => x.membershipReference === schemeBusiness.membershipReference
                  )?.membershipReference
                )
              "
            >
              {{
                getLicenceBusinessByTMBusinessId(l.trustmarkBusinessId)
                  ?.linkedSchemeBusinesses?.map((x) => x.membershipReference)
                  .join(', ')
              }}
            </td>
          </tr>
          <tr class="pt-3 pb-3 mb-2">
            <td class="sticky white">
              <div class="row">
                <div class="col">Address</div>
                <div class="col">
                  {{ schemeBusiness.address1 }}<br />
                  {{ schemeBusiness.address2 }}<br />
                  {{ schemeBusiness.town }}<br />
                  {{ schemeBusiness.county }}<br />
                  {{ schemeBusiness.district }}<br />
                </div>
              </div>
            </td>
            <td v-for="l in linkPendingOptions" :key="l.trustmarkBusinessId">
              {{ getLicenceBusinessByTMBusinessId(l.trustmarkBusinessId).primaryAddress1 }}<br />
              {{ getLicenceBusinessByTMBusinessId(l.trustmarkBusinessId).primaryAddress2 }}<br />
              {{ getLicenceBusinessByTMBusinessId(l.trustmarkBusinessId).primaryTown }}<br />
              {{ getLicenceBusinessByTMBusinessId(l.trustmarkBusinessId).primaryCounty }}<br />
              {{ getLicenceBusinessByTMBusinessId(l.trustmarkBusinessId).primaryDistrict }}<br />
            </td>
          </tr>
          <tr
            class="pt-3 pb-3 mb-2"
            :class="
              rowClassMismatch(
                schemeBusiness.postCode,
                licenceBusiness.linkedSchemeBusinesses.find(
                  (x) => x.postCode === schemeBusiness.postCode
                )?.postCode
              )
            "
          >
            <td
              :class="
                rowClassMismatch(
                  schemeBusiness.postCode,
                  licenceBusiness.linkedSchemeBusinesses.find(
                    (x) => x.postCode === schemeBusiness.postCode
                  )?.postCode
                )
              "
              class="sticky"
            >
              <div class="row">
                <div class="col">Postcode</div>
                <div class="col">{{ schemeBusiness.postCode }}</div>
              </div>
            </td>
            <td
              v-for="l in linkPendingOptions"
              :key="l.trustmarkBusinessId"
              :class="
                rowClassMismatch(
                  schemeBusiness.postCode,
                  getLicenceBusinessByTMBusinessId(
                    l.trustmarkBusinessId
                  )?.linkedSchemeBusinesses?.find((x) => x.postCode === schemeBusiness.postCode)
                    ?.postCode
                )
              "
            >
              {{
                getLicenceBusinessByTMBusinessId(l.trustmarkBusinessId)
                  ?.linkedSchemeBusinesses?.map((x) => x.postCode)
                  .join(', ')
              }}
            </td>
          </tr>
          <tr class="pt-3 pb-3 mb-2">
            <td class="sticky white">
              <div class="row">
                <div class="col">Scheme</div>
                <div class="col">{{ schemeBusiness.schemeId }}</div>
              </div>
            </td>
            <td v-for="l in linkPendingOptions" :key="l.trustmarkBusinessId">
              {{
                getLicenceBusinessByTMBusinessId(l.trustmarkBusinessId)
                  ?.linkedSchemeBusinesses?.map((x) => x.schemeId)
                  .join(', ')
              }}
            </td>
          </tr>
          <tr class="pt-3 pb-3 mb-2">
            <td class="sticky white"></td>
            <td v-for="l in linkPendingOptions" :key="l.trustmarkBusinessId">
              <div v-if="isPendingOptionSelected(l.trustmarkBusinessId)" class="text-center">
                <button type="button" class="btn btn-outline-dark" disabled="disabled">
                  Selected
                </button>
              </div>
              <div v-else class="text-center">
                <button
                  v-if="l.tmln"
                  type="button"
                  class="btn btn-secondary"
                  :disabled="loading"
                  @click.prevent="selectPendingOption(l.trustmarkBusinessId)"
                >
                  Select Option
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </card>
  </div>
</template>

<script>
import { Card } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'PendingLicenceComparison',
  components: {
    Card,
  },
  data() {
    return {
      licenceBusinesses: [],
      loading: false,
    };
  },
  computed: {
    schemeBusiness() {
      return this.$store.state.schemeBusiness.business.schemeBusiness;
    },
    linkPending() {
      return this.$store.state.schemeBusiness.business.schemeBusiness.linkPending;
    },
    linkPendingOptions() {
      const options = this.$store.state.schemeBusiness.business.schemeBusiness.linkPendingOptions;
      options.sort((a, b) => (a.rank > b.rank ? 1 : -1));
      return options;
    },
    licenceBusiness() {
      return this.$store.state.pendingLicenceAction.licenceBusiness;
    },
  },
  async mounted() {
    await this.loadLicenceBusinesses();

    if (this.licenceBusiness === null || this.licenceBusiness.tmln !== this.linkPending.tmln) {
      this.loadLicenceBusiness();
    }
  },
  methods: {
    loadLicenceBusiness() {
      const { tmln } = this.linkPending;
      this.$store.dispatch('pendingLicenceAction/getLicenceByTMLN', tmln);
    },
    async loadLicenceBusinesses() {
      this.licenceBusinesses = [];
      if (this.linkPendingOptions) {
        // eslint-disable-next-line
        for (const l of this.linkPendingOptions) {
          if (l.tmln) {
            // eslint-disable-next-line
            const lb = await this.$store.dispatch('pendingLicenceAction/getLicenceByTMLN', l.tmln);
            this.licenceBusinesses.push(lb);
          } else {
            const lb = {
              registeredCompanyName: l.registeredCompanyName,
              registeredCompanyNumber: l.registeredCompanyNumber,
            };
            this.licenceBusinesses.push(lb);
          }
        }
      }
    },
    rowClassForMatch(newData, existingData) {
      if (newData !== existingData) {
        return 'bg-mismatch';
      }
      return 'bg-match';
    },
    rowClassMismatch(newData, existingData) {
      if (newData !== existingData) {
        return 'bg-mismatch';
      }
      return 'bg-match';
    },
    getLicenceBusinessByTMBusinessId(trustmarkBusinessId) {
      return (
        this.licenceBusinesses.find((x) => x.trustmarkBusinessId === trustmarkBusinessId) || {}
      );
    },
    isPendingOptionSelected(trustmarkBusinessId) {
      return this.linkPending.trustmarkBusinessId === trustmarkBusinessId;
    },
    async selectPendingOption(trustmarkBusinessId) {
      this.loading = true;
      try {
        await this.$store.dispatch('pendingLicenceAction/selectOption', {
          schemeId: this.schemeBusiness.schemeId,
          schemeBusinessId: this.schemeBusiness.schemeBusinessId,
          trustmarkBusinessId,
        });

        await this.$store.dispatch('schemeBusiness/get', {
          schemeId: this.$route.params.schemeId,
          schemeBusinessId: this.$route.params.schemeBusinessId,
        });
        await this.loadLicenceBusinesses();
        await this.loadLicenceBusiness();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.bg-match {
  background: #b9e8aa;
}
.bg-mismatch {
  background: #f7bbbb;
}

.sticky {
  min-width: 500px;
  position: sticky;
  background-clip: padding-box;
  z-index: 2;
  left: 0;
}

table {
  td {
    padding: 8px;
  }
}

tr {
  line-height: 36px;
}

.white {
  background-color: white;
}
</style>
