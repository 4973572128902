<template>
  <div v-if="!currentAccount" class="row justify-content-center p-3 m-5">
    <div class="col-8 col-md-3">
      <img src="../assets/logo.png" class="img img-fluid" />
    </div>
  </div>

  <div v-if="!success">
    <div v-if="!currentAccount" class="container-login">
      <h2 class="pb-5">BackOffice</h2>

      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
        <div v-if="successMessage" class="alert alert-success" role="alert">
          {{ successMessage }}
        </div>
      </div>

      <form v-if="!isNewDevice" ref="formLogin" class="has-validation">
        <fieldset :disabled="loading">
          <div class="form-group">
            <label for="email" class="form-label">Your Email Address</label>
            <input
              id="email"
              v-model="email"
              name="email"
              type="text"
              class="form-control form-control-lg"
              :class="v$.email.$error ? 'is-invalid' : ''"
              autocomplete="email"
            />
            <div v-if="v$.email.$error" class="invalid-feedback">
              {{ v$.email.$errors[0].$message }}
            </div>
          </div>
          <div class="form-group has-validation">
            <label for="password" class="form-label">Password</label>
            <input
              id="password"
              v-model="password"
              name="password"
              type="password"
              class="form-control form-control-lg"
              :class="v$.password.$error ? 'is-invalid' : ''"
              autocomplete="current-password"
            />
            <div v-if="v$.password.$error" class="invalid-feedback">
              {{ v$.password.$errors[0].$message }}
            </div>
          </div>

          <div class="row pt-5">
            <div class="col">
              <div><router-link to="/register">Register from an invite</router-link><br /></div>
              <div class="mt-3">
                <router-link to="/forgot">Forgot password</router-link>
              </div>
            </div>
            <div class="col justify-content-md-end">
              <smart-submit-large label="Log in" :loading="loading" @click="handleLogin" />
            </div>
          </div>

          <div class="form-group d-grid gap-2 d-md-flex"></div>
        </fieldset>
      </form>

      <form v-if="isNewDevice && !loading" ref="formSms">
        <h3 class="mb-3">Two-Factor Authentication</h3>
        <div v-if="smsCode === 'required'">
          <div v-if="requiresReauth">
            <Login2FAReset :email="email" :totpKey="totpKey" :access-token="smsToken" :password="password" />
          </div>

          <div v-else-if="!maskedMobile && !totpEnabled">
            <p>Protecting your account is our highest priority.</p>
            <p>
              Request a verification code using the button below, then provide the code sent to your
              inbox along with your new mobile number. You will then receive an SMS to confirm the
              new number is correct.
            </p>

            <div class="row">
              <div class="col">
                <button
                  type="button"
                  class="btn-primary btn float-left mb-2 mt-4"
                  @click="sendEmailVerifyCode"
                >
                  Email Verification Code
                </button>
              </div>
            </div>

            <smart-input
              id="emailCode"
              v-model="emailCode"
              label="Received Verification Code"
              :disabled="verificationCode !== 'requested'"
              :is-required="!!v$.emailCode.isRequired"
              :validator="v$.emailCode"
            />

            <smart-input
              id="mobileNumber"
              v-model="mobileNumber"
              label="Mobile Number for SMS verification"
              :disabled="verificationCode !== 'requested'"
              :is-required="!!v$.mobileNumber.isRequired"
              :validator="v$.mobileNumber"
            />
            <div class="row pt-5">
              <div class="col">
                <div class="mt-3">
                  <router-link to="/login" @click="resetLogin">Back to Login</router-link>
                </div>
              </div>
              <div class="col">
                <button
                  type="button"
                  class="btn-primary btn btn-lg float-right"
                  :disabled="verificationCode !== 'requested'"
                  @click="saveMobileNumber"
                >
                  Send SMS for Verification
                </button>
              </div>
            </div>
          </div>
          <div v-else-if="maskedMobile || totpEnabled">
            <div v-if="!totpEnabled">
              <p>We would like to verify you.</p>
              <button type="button" class="btn-primary btn btn-lg" @click="sendSmsCode">
                Send a Verify Code to {{ maskedMobile }}
              </button>
            </div>
            <div v-if="totpEnabled">
              <p>We would like to verify you.</p>
              <smart-input
                id="totpCode"
                v-model="totpCode"
                label="Please enter the code displayed on your authenticator app"
                :is-required="!!v$.totpCode.isRequired"
                :validator="v$.totpCode"
              />
              <button type="button" class="btn-primary btn btn-lg" @click="verifyTotpCode">
                Authenticate
              </button>
            </div>
            <div class="row pt-5">
              <div class="col">
                <div v-if="!totpEnabled" class="mt-3">
                  <router-link to="/login" @click="changeMobile">Change Mobile Number</router-link>
                </div>
                <div class="mt-3">
                  <router-link to="/login" @click="resetLogin">Back to Login</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="smsCode === 'requested'">
          <p>
            Your account is protected by two-factor authentication. We've just sent you a text
            message to {{ maskedMobile }}.
          </p>
          <div class="form-group">
            <label for="receivedSmsCode" class="form-label">Received SMS code</label>
            <input
              id="receivedSmsCode"
              v-model="receivedSmsCode"
              name="receivedSmsCode"
              type="text"
              class="form-control form-control-lg"
              @keydown.enter.prevent="verifySmsCode"
            />
          </div>
          <div class="row pt-5">
            <div class="col">
              <div>
                <router-link to="/login" @click="viewSendSms">I didn't receive a code</router-link
                ><br />
              </div>
              <div class="mt-3">
                <router-link to="/login" @click="resetLogin">Back to Login</router-link>
              </div>
            </div>
            <div class="col justify-content-md-end">
              <smart-submit-large label="Verify Code" :loading="loading" @click="verifySmsCode" />
            </div>
          </div>
        </div>
      </form>
    </div>
    <small class="me-3 pt-5 float-right">Build: {{ buildDate }}</small>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core';
import { helpers, required, email, maxLength, requiredIf } from '@vuelidate/validators';
import { SmartSubmitLarge, SmartInput } from '@trustmark-2005-ltd/trustmark-shared-components';
import Login2FAReset from './Login2FAReset.vue';

export default {
  name: 'Login',
  components: {
    SmartSubmitLarge,
    SmartInput,
    Login2FAReset,
  },
  data() {
    return {
      v$: useValidate(),
      email: '',
      password: '',
      loading: false,
      message: '',
      successMessage: '',
      isNewDevice: false,
      receivedSmsCode: '',
      maskedMobile: null,
      totpEnabled: false,
      totpCode: '',
      success: false,
      buildDate: import.meta.env.VITE_BUILD_VERSION,
      requiresReauth: false,
      totpKey: null,
    };
  },
  validations: {
    email: {
      isRequired: helpers.withMessage('Email address is required', required),
      isEmail: helpers.withMessage('Must be a valid email address', email),
    },
    password: {
      isRequired: helpers.withMessage('Password is required', required),
    },
    mobileNumber: {
      maxLength: maxLength(12),
      // isRequired: helpers.withMessage('Mobile number is required', (value, vm) => {
      //   if (!vm.isNewDevice) {
      //     return true;
      //   }
      //   return vm.maskedMobile !== null;
      // }),
      // isPhoneNumber: helpers.withMessage('Phone number must be in a valid format', isPhoneNumber),
    },
    totpCode: {},
    isNewDevice: {},
    emailCode: {},
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    smsCode() {
      return this.$store.state.auth.smsCode;
    },
    verificationCode() {
      return this.$store.state.auth.verificationCode;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    changeMobile() {
      this.maskedMobile = null;
    },
    viewSendSms() {
      this.$store.commit('auth/setSmsCode', 'required', { root: true });
    },
    saveMobileNumber() {
      this.message = null;
      this.successMessage = null;
      const payload = {
        mobileNumber: this.mobileNumber,
        accessToken: this.smsToken,
        mobileChangeCode: this.emailCode,
      };
      this.$store
        .dispatch('auth/changeMobile', payload)
        .then(() => {
          this.maskedMobile = this.mobileNumber;
        })
        .catch((error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        });
    },
    sendEmailVerifyCode() {
      this.message = null;
      this.successMessage = null;
      const payload = {
        accessToken: this.smsToken,
      };
      this.$store
        .dispatch('auth/sendEmailVerifyCode', payload)
        .then(() => {
          this.successMessage = 'Check your inbox for the verification code we sent you.';
        })
        .catch((error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        });
    },
    resetLogin() {
      this.message = null;
      this.successMessage = null;
      this.isNewDevice = false;
      this.receivedSmsCode = '';
      this.totpCode = '';
      this.loading = false;
      this.smsToken = null;
      this.maskedMobile = null;
      this.totpEnabled = false;
      this.requiresReauth = false;
      this.totpKey = null;
    },
    handleLogin() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.message = 'Please enter your email address and password to log in.';
        window.scrollTo(0, 0);
        return;
      }

      this.loading = true;
      this.message = '';
      this.successMessage = '';

      const user = {
        username: this.email,
        password: this.password,
      };

      this.$store.dispatch('auth/login', user).then(
        (resp) => {
          if (resp.model.deviceValid) {
            this.success = true;
            window.scrollTo(0, 0);
            if (resp.model.roles.length === 3 && resp.model.roles.includes('TradingStandards')) {
              this.$router.push('/applications');
              return;
            }
            if (
              resp.model.roles.length === 3 &&
              resp.model.roles.includes('TradingStandardsFeedback')
            ) {
              this.$router.push('/trading-standards-feedback');
              return;
            }
            this.$router.push('/');
          } else {
            this.requiresReauth = resp.model.requiresReauth;
            this.loading = false;
            this.smsToken = resp.model.accessToken;
            this.isNewDevice = true;
            this.maskedMobile = resp.model.maskedMobile;
            this.totpEnabled = resp.model.usesTotp;
            this.totpKey = resp.model.totpKey;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    sendSmsCode() {
      this.loading = false;
      const payload = {
        accessToken: this.smsToken,
      };
      this.$store.dispatch('auth/smsRequestCode', payload).catch((error) => {
        this.loading = false;
        this.message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
      });
    },
    verifyTotpCode() {
      this.loading = false;
      const payload = {
        accessToken: this.smsToken,
        totpCode1: this.totpCode,
      };
      this.$store.dispatch('auth/verifyTotp', payload).then(() => {
        this.handleLogin();
      })
      .catch((error) => {
        this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
      });
    },
    verifySmsCode() {
      this.loading = false;
      const payload = {
        accessToken: this.smsToken,
        smsCode: this.receivedSmsCode,
      };
      this.$store
        .dispatch('auth/smsVerifyCode', payload)
        .then(() => {
          this.handleLogin();
        })
        .catch((error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        });
    },
  },
};
</script>

<style>
.container-login {
  max-width: 740px !important;
  padding: 40px 40px;
  margin: auto;
}

label {
  margin-top: 10px;
}
</style>
