import TradeCheckService from '../services/trade-check-service';

export const tradeCheck = {
  namespaced: true,
  state: {
    tradesForSchemes: {},
  },
  actions: {
    getTrade({ commit }, payload) {
      const key = `${payload.tmln}|${payload.schemeId}`;
      if (this.state.tradeCheck.tradesForSchemes && this.state.tradeCheck.tradesForSchemes[key]) {
        commit('setIsBusy', false, { root: true });
        return Promise.resolve(this.state.tradeCheck.tradesForSchemes[key]);
      }

      commit('setIsBusy', true, { root: true });
      return TradeCheckService.get(payload.tmln, payload.schemeId).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setTradeForScheme', { key, result });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setTradeForScheme(state, payload) {
      state.tradesForSchemes[payload.key] = payload.result;
    },
  },
};
