import { HTTP, authHeader } from './http-service';

class SchemeBusinessService {
  get(schemeId, schemeBusinessId) {
    return HTTP.get(`/SchemeBusiness/${schemeId}/${schemeBusinessId}/`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  resubmit(schemeId, schemeBusinessId) {
    return HTTP.put(
      `/SchemeBusiness/${schemeId}/${schemeBusinessId}/resubmit`,
      {},
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }

  rematch(schemeId, schemeBusinessId, tmln) {
    return HTTP.put(
      `/PendingLicenceAction/rematch`,
      {
        schemeId,
        schemeBusinessId,
        tmln,
      },
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }

  publishtrades(schemeId, schemeBusinessId) {
    return HTTP.post(
      `/SchemeBusiness/${schemeId}/${schemeBusinessId}/publishtrades`,
      {},
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }

  createLodgementPortalLogin(schemeId, schemeBusinessId, email) {
    return HTTP.post(
      `/SchemeBusiness/${schemeId}/${schemeBusinessId}/lodgementPortalLogin`,
      { email },
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }

  listLegacyLodgementBranches(schemeId, schemeBusinessId) {
    return HTTP.get(`/SchemeBusiness/${schemeId}/${schemeBusinessId}/legacylodgement/branches`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new SchemeBusinessService();
