<template>
  <div>
    <confirm-dialog ref="confirmChangeTmlnDialog"></confirm-dialog>
    <h3 class="position-relative">
      Businesses
      <span v-if="model.schemeBusinesses" class="badge rounded-pill bg-primary text-light">{{
        model.schemeBusinesses.length
      }}</span>
    </h3>
    <div v-if="!model.schemeBusinesses || model.schemeBusinesses.length === 0">
      <p>This application doesn't have any businesses created.</p>
    </div>
    <div v-if="model.schemeBusinesses && model.schemeBusinesses.length > 0">
      <div v-if="model" class="col-sm-12">
        <div v-if="infoMessage" class="alert alert-info" role="alert">
          {{ infoMessage }}
        </div>

        <card v-for="lsb in model.schemeBusinesses" :key="lsb.schemeBusinessId">
          <div class="d-flex align-items-center">
            <h5>{{ lsb.registeredCompanyName }}</h5>
            <div class="ms-3">
              <h5 v-if="lsb.isActive">
                <span class="badge bg-success">Active</span>
              </h5>
              <h5 v-if="!lsb.isActive">
                <span class="badge bg-danger">Inactive</span>
              </h5>
            </div>
            <div class="ms-1">
              <h5 v-if="lsb.isPrimary">
                <span class="badge bg-success">Primary</span>
              </h5>
              <h5 v-if="!lsb.isPrimary">
                <span class="badge bg-dark">Secondary</span>
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-8">
              <div class="form-input">
                <key-value-presenter
                  label="Membership Reference"
                  :value="lsb.membershipReference"
                />
              </div>
              <div class="form-label">
                <key-value-presenter
                  label="Scheme"
                  :value="
                    model.schemes.filter((x) => x.schemeId === lsb.schemeId).length > 0 &&
                    model.schemes.filter((x) => x.schemeId === lsb.schemeId)[0].schemeName
                  "
                />
              </div>
              <div class="form-label">
                <key-value-presenter
                  label="Registered Company Number"
                  :value="lsb.registeredCompanyNumber"
                />
              </div>
              <div class="form-label">
                <key-value-presenter label="Post Code" :value="lsb.postCode" />
              </div>
              <div class="form-label">
                <copy-key-value-presenter
                  label="Scheme Business ID"
                  :value="lsb.schemeBusinessId"
                />
              </div>
            </div>

            <div class="col-sm-12 col-md-4">
              <router-link :to="`/scheme/${lsb.schemeId}`">
                <scheme-logo :imagekey="schemeImageKey(lsb)" />
              </router-link>
            </div>
          </div>
          <button
            v-if="isApplicationEditor"
            class="btn btn-secondary mt-3 mb-3"
            type="button"
            @click="toggleArchiveUseExistingTMLN"
          >
            Use Existing TMLN
          </button>

          <card v-if="showArchiveUseExistingTMLN" label="Use Existing TMLN">
            <p>
              This will link this application to an existing TMLN, if the business record created by
              the application is currently on the status Application it will be archived; any other
              status on the existing business will be retained.
            </p>
            <p>
              This function is useful when the business has already been created by the scheme and
              you want to link this application to that business and licence.
            </p>
            <div v-if="errorMoveTMLN" class="alert alert-danger" role="alert">
              {{ errorMoveTMLN }}
            </div>
            <div class="mb-3">
              <label for="destinationTMLN" class="form-label">Use an existing TMLN</label>
              <input
                id="destinationTMLN"
                v-model="destinationTMLN"
                class="form-control"
                style="max-width: 250px"
                placeholder="TMLN"
                @change="tmlnChanged"
              />
            </div>
            <p>{{ destinationRegisteredBusinessName }}</p>
            <button
              v-if="isApplicationEditor"
              type="button"
              class="btn btn-lg btn-primary me-3"
              :disabled="destinationTMLN ? false : true"
              @click="useExistingTMLN(lsb)"
            >
              Use Existing TMLN
            </button>
          </card>

          <div v-if="lsb.applicationTracking">
            <h3 class="mb-2 mt-4">Application Tracking</h3>
            <div class="row">
              <div class="col-sm-12 col-md-8">
                <div class="form-label">
                  <key-value-presenter
                    label="Application Date"
                    :value="$helpers.dateString(lsb.applicationTracking.applicationDate)"
                  />
                </div>
                <div class="form-label">
                  <key-value-presenter
                    label="First Viewed"
                    :value="
                      $helpers.dateString(lsb.applicationTracking.firstViewedDate) ||
                      'Not viewed yet'
                    "
                  />
                </div>
                <div class="form-label">
                  <key-value-presenter
                    label="Last Viewed"
                    :value="
                      $helpers.dateString(lsb.applicationTracking.lastViewedDate) ||
                      'Not viewed yet'
                    "
                  />
                </div>
                <div class="form-label">
                  <key-value-presenter
                    label="Days to First View"
                    :value="lsb.applicationTracking.daysToFirstView || 'Not viewed yet'"
                  />
                </div>
                <div class="form-label">
                  <key-value-presenter
                    label="Times Viewed"
                    :value="lsb.applicationTracking.timesViewed || 0"
                  />
                </div>
                <div
                  v-if="lsb.applicationTracking.applicationSchemeSubmittedDate"
                  class="form-label"
                >
                  <key-value-presenter
                    label="Application to Scheme Submitted Date"
                    :value="
                      $helpers.dateString(lsb.applicationTracking.applicationSchemeSubmittedDate)
                    "
                  />
                </div>
                <div
                  v-if="lsb.applicationTracking.applicationSchemeSubmittedDate"
                  class="form-label"
                >
                  <key-value-presenter
                    label="Days to Submitted"
                    :value="lsb.applicationTracking.daysToSubmitted"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="lsb.applicationSchemeProgressStatus === 0">
            <key-value-presenter
              label="In-progress Status"
              value="The scheme has not updated an in-progress status for this application yet."
            />
          </div>
          <div v-if="lsb.applicationSchemeProgressStatus !== 0">
            <p>The scheme has provided the following in-progress update for you.</p>
            <div class="form-label">
              <key-value-presenter
                label="In-progress Status"
                :value="lsb.applicationSchemeProgressStatusName"
              />
            </div>
            <div class="form-label">
              <key-value-presenter
                label="Updated On"
                :value="$helpers.formatDate(lsb.applicationSchemeProgressStatusDate)"
              />
            </div>
          </div>

          <div v-if="lsb.schemeBusinessArchiveReason" class="mt-1">
            <key-value-presenter
              label="Scheme Archive Reason"
              :value="lsb.schemeBusinessArchiveReason"
              :class="text-danger"
            />
          </div>

          <router-link
            :to="`/scheme-business/${lsb.schemeId}/${lsb.schemeBusinessId}`"
            class="btn btn-primary float-right"
          >
            View
          </router-link>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Card,
  CopyKeyValuePresenter,
  KeyValuePresenter,
  SchemeLogo,
  ConfirmDialog,
  confirmType,
} from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'ApplicationViewLinkedBusinesses',
  components: {
    Card,
    CopyKeyValuePresenter,
    KeyValuePresenter,
    SchemeLogo,
    ConfirmDialog,
  },
  data() {
    return {
      showArchiveUseExistingTMLN: false,
      destinationTMLN: null,
      destinationRegisteredBusinessName: 'Enter a valid TMLN',
      errorMoveTMLN: null,
      infoMessage: null,
    };
  },
  computed: {
    model() {
      return this.$store.state.application.application;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isApplicationEditor() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('ApplicationEditor')
        : false;
    },
  },
  methods: {
    schemeImageKey(item) {
      const { schemeId } = item;
      const scheme = this.$store.state.auth.appData.schemes.find((x) => x.schemeId === schemeId);
      if (!scheme) return null;
      return scheme.schemeLogoImageKey || scheme.logoImage;
    },
    toggleArchiveUseExistingTMLN() {
      this.showArchiveUseExistingTMLN = !this.showArchiveUseExistingTMLN;
    },
    tmlnChanged(e) {
      const { value } = e.target;
      this.$store
        .dispatch('licenceNumber/getByTMLN', value)
        .then((resp) => {
          this.destinationRegisteredBusinessName = resp.model.registeredCompanyName;
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            this.errorMoveTMLN = `Cannot find TMLN ${value}`;
            return;
          }
          this.errorMoveTMLN = err;
        });
    },
    useExistingTMLN(lsb) {
      this.$refs.confirmChangeTmlnDialog
        .show({
          title: 'Use Existing TMLN',
          message: `Are you sure you want to attempt to link this application to the existing ${this.destinationTMLN} primary business?`,
          okButtonText: 'Confirm Use Existing TMLN',
          confirmType: confirmType.standard,
        })
        .then((result) => {
          if (result === true) {
            this.errorMoveTMLN = null;
            const payload = {
              applicationReference: this.model.applicationReference,
              tmln: this.destinationTMLN,
              schemeId: lsb.schemeId,
              schemeBusinessId: lsb.schemeBusinessId,
            };
            this.$store
              .dispatch('application/useExistingTMLN', payload)
              .then((resp) => {
                this.infoMessage = resp.message;
                this.showArchiveUseExistingTMLN = false;
              })
              .catch((err) => {
                this.errorMoveTMLN = err.message ?? err;
              });
          }
        });
    },
  },
};
</script>
