import moment from 'moment';

export const constants = {
  matchTypes: {
    exact: 'Match exactly',
    partial: 'Match partially',
  },
  dateFormat: 'DD/MM/YYYY HH:mm',
};

export const getCountries = function getCountries() {
  return ['England', 'Northern Ireland', 'Scotland', 'Wales'];
};

export const getLastModified = function getLastModified(obj) {
  if (!obj) {
    return null;
  }
  if (obj.updatedAt) {
    return obj.updatedAt;
  }
  if (obj.createdAt) {
    return obj.createdAt;
  }
  return null;
};

export const getLastModifiedDate = function getLastModifiedDate(obj) {
  const lm = getLastModified(obj);
  if (!lm) {
    return null;
  }
  return new Date(lm);
};

export const getLastModifiedString = function getLastModifiedString(obj) {
  const modified = getLastModifiedDate(obj);
  if (!modified) {
    return null;
  }
  return moment(modified).format(constants.dateFormat).toString();
};

export const formatDate = function formatDate(d) {
  const dd = new Date(d);
  return moment(dd).format(constants.dateFormat);
};

export const formatDateCustom = function formatDateCustom(d, format) {
  const dd = new Date(d);
  return moment(dd).format(format);
};

export const formatUTC = function formatUTC(d) {
  const dd = new Date(d);
  return moment(dd).utc().format(constants.dateFormat);
};

export const formatDateNoTime = function formatDateNoTime(d) {
  const dd = new Date(d);
  return moment(dd).format('DD/MM/YYYY');
};

export const age = function age(d) {
  return moment(new Date()).diff(moment(d), 'years');
};

export const getTradeName = function getTradeName(tradeCode) {
  if (this.$store.state.trade.trades) {
    const match = this.$store.state.trade.trades.filter(
      (x) => x.tradeCode === parseInt(tradeCode, 10)
    );

    if (match && match.length > 0) {
      return match[0].tradeDescription;
    }
    return null;
  }
  return null;
};

export const normaliseStatistics = function normaliseStatistics(
  statistics,
  collection,
  startDate,
  endDate,
  sort,
  filter = () => true
) {
  const stats = [];
  if (statistics) {
    const unsortedStats = [];
    const datesInRange = statistics.filter(
      (x) => new Date(x.statsDate) >= startDate && new Date(x.statsDate) <= endDate
    );
    for (const d of datesInRange) { // eslint-disable-line
      for (const code of d[collection].filter(filter)) { // eslint-disable-line
        let obj = unsortedStats.find((x) => x.key === code.key);
        if (!obj) {
          obj = { key: code.key, description: code.description };
          unsortedStats.push(obj);
        }
        obj[d.statsDate] = code.count;
      }
    }

    // fill in empty dates for statistic
    for (const d of datesInRange) { // eslint-disable-line
      for (const obj of unsortedStats) { // eslint-disable-line
        if (!(d.statsDate in obj)) {
          obj[d.statsDate] = 0;
        }
      }
    }

    for (const s of unsortedStats) { // eslint-disable-line
      const sortedKeys = Object.keys(s).sort((a, b) => {
        if (a === 'key' || b === 'key') {
          return -1;
        }
        return new Date(a) < new Date(b) ? 1 : -1;
      });
      const sorted = sortedKeys.reduce(
        (obj, key) => {
          if (key !== 'key') {
            obj[key] = s[key];
          }
          return obj;
        },
        { key: s.key }
      );
      stats.push(sorted);
    }
  }
  stats.sort((a, b) => {
    if (sort.key === 'total') {
      let atotal = 0;
      let btotal = 0;
      for (const ak of Object.keys(a)) { // eslint-disable-line
        if (typeof a[ak] === 'number') {
          atotal += a[ak];
        }
      }
      for (const bk of Object.keys(b)) { // eslint-disable-line
        if (typeof b[bk] === 'number') {
          btotal += b[bk];
        }
      }
      if (sort.direction === 'asc') {
        return atotal > btotal;
      }
      return atotal < btotal;
    }
    if (sort.direction === 'asc') {
      return a[sort.key] > b[sort.key] ? 1 : -1;
    }
    return a[sort.key] < b[sort.key] ? 1 : -1;
  });
  return stats;
};

export const normaliseActionMetricStatistics = function normaliseActionMetricStatistics(
  statistics,
  collection,
  startDate,
  endDate,
  sort,
  filter = () => true
) {
  const stats = [];
  if (statistics) {
    const unsortedStats = [];
    const datesInRange = statistics.filter(
      (x) => new Date(x.statsDate) >= startDate && new Date(x.statsDate) <= endDate
    );
    for (const d of datesInRange) { // eslint-disable-line
      for (const code of d[collection].filter(filter)) { // eslint-disable-line
        let obj = unsortedStats.find((x) => x.key === code.retrofitActionMetric);
        if (!obj) {
          obj = { key: code.retrofitActionMetric };
          unsortedStats.push(obj);
        }
        if (!(d.statsDate in obj)) {
          obj[d.statsDate] = 0;
        }
        obj[d.statsDate] += code.count;
      }
    }

    // fill in empty dates for statistic
    for (const d of datesInRange) { // eslint-disable-line
      for (const obj of unsortedStats) { // eslint-disable-line
        if (!(d.statsDate in obj)) {
          obj[d.statsDate] = 0;
        }
      }
    }

    for (const s of unsortedStats) { // eslint-disable-line
      const sortedKeys = Object.keys(s).sort((a, b) => {
        if (a === 'key' || b === 'key') {
          return -1;
        }
        return new Date(a) < new Date(b) ? 1 : -1;
      });
      const sorted = sortedKeys.reduce(
        (obj, key) => {
          if (key !== 'key') {
            obj[key] = s[key];
          }
          return obj;
        },
        { key: s.key }
      );
      stats.push(sorted);
    }
  }
  stats.sort((a, b) => {
    if (sort.key === 'total') {
      let atotal = 0;
      let btotal = 0;
      for (const ak of Object.keys(a)) { // eslint-disable-line
        if (typeof a[ak] === 'number') {
          atotal += a[ak];
        }
      }
      for (const bk of Object.keys(b)) { // eslint-disable-line
        if (typeof b[bk] === 'number') {
          btotal += b[bk];
        }
      }
      if (sort.direction === 'asc') {
        return atotal > btotal;
      }
      return atotal < btotal;
    }
    if (sort.direction === 'asc') {
      return a[sort.key] > b[sort.key] ? 1 : -1;
    }
    return a[sort.key] < b[sort.key] ? 1 : -1;
  });
  return stats;
};
