<template>
  <div v-if="model" class="col-sm-12 col-md-10">
    <subtitle>Contacts</subtitle>

    <card v-for="(contact, i) in model.contacts" :key="contact.contactId">
      <contact-view :contact="contact" />
      <hr v-if="i < model.contacts.length - 1" />
    </card>

    <div v-if="model.contacts.length === 0">
      The scheme has not created any contacts for this business.
    </div>
  </div>
</template>

<script>
import { Card, Subtitle } from '@trustmark-2005-ltd/trustmark-shared-components';
import ContactView from '../contacts/ContactsView.vue';

export default {
  name: 'SchemeBusinessContacts',
  components: {
    ContactView,
    Card,
    Subtitle,
  },
  data() {
    return {};
  },
  computed: {
    model() {
      return this.$store.state.schemeBusiness.business;
    },
  },
};
</script>
