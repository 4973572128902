<template>
  <div class="mb-4">
    <div class="btn-toolbar mb-2 mb-md-0">
      <div class="btn-group me-2">
        <router-link to="/register-software-provider" class="btn btn-primary">
          Register Software Provider
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SoftwareProviderToolbar',
};
</script>
