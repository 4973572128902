<template>
  <div class="mb-4">
    <div class="btn-toolbar mb-2 mb-md-0">
      <div class="btn-group me-2">
        <button type="button" class="btn" :class="getCssClass" @click="$emit('advanced-search')">
          Advanced Search
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toolbar',
  emits: ['advanced-search'],
  data() {
    return {
      advancedSearchIsOpen: false,
    };
  },
  computed: {
    getCssClass() {
      return this.advancedSearchIsOpen ? 'btn-primary' : 'btn-outline-secondary';
    },
  },
  mounted() {
    this.emitter.on('toggle-advanced-search-license-numbers', (isOpen) => {
      this.advancedSearchIsOpen = isOpen;
    });
  },
};
</script>
