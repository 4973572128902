import PendingLicenceActionService from '../services/pending-licence-action-service';
import LicenceNumberService from '../services/licence-number-service';

export const pendingLicenceAction = {
  namespaced: true,
  state: {
    pendingLicenceActions: null,
    searchResult: null,
    pageNumber: 0,
    lastSearchConfig: null,
    licenceBusiness: null,
    badge: null,
  },
  actions: {
    selectOption({ commit }, data) {
      commit('setIsBusy', true, {
        root: true,
      });
      return PendingLicenceActionService.selectOption(data).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    accept({ commit }, data) {
      commit('setIsBusy', true, {
        root: true,
      });
      return PendingLicenceActionService.accept(data).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    deny({ commit }, data) {
      commit('setIsBusy', true, {
        root: true,
      });
      return PendingLicenceActionService.deny(data).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    search({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      data.type = 'standard';
      commit('setLastSearchConfig', data);
      return PendingLicenceActionService.search(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setPendingLicenceActionSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    advancedSearch({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      data.type = 'advanced';
      commit('setLastSearchConfig', data);
      return PendingLicenceActionService.searchAdvance(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setPendingLicenceActionSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    getBadge({ commit }) {
      return PendingLicenceActionService.getBadge().then(
        (result) => {
          commit('setBadge', result.badge);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getLicenceByTMLN({ commit }, tmln) {
      commit('setIsBusy', true, { root: true });
      commit('setLicenceBusiness', null);
      return LicenceNumberService.getByTMLN(tmln).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setLicenceBusiness', result.model);
          return Promise.resolve(result.model);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    moveTMLN({ commit }, payload) {
      commit('setIsBusy', true, {
        root: true,
      });
      commit(
        'schemeBusiness/setBusinessLinkPending',
        {
          linkPending: null,
          tmln: null,
          trustmarkBusinessId: null,
          status: 3,
        },
        { root: true }
      );
      return PendingLicenceActionService.moveTMLN(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('schemeBusiness/setBusinessLinkPending', result.model, { root: true });
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        });
    },
    splitTMLN({ commit }, payload) {
      commit('setIsBusy', true, {
        root: true,
      });
      return PendingLicenceActionService.splitTMLN(payload.schemeId, payload.schemeBusinessId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('schemeBusiness/setBusinessLinkPending', result.model, { root: true });
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        });
    },
    canceladmin({ commit }, data) {
      commit('setIsBusy', true, {
        root: true,
      });
      return PendingLicenceActionService.canceladmin(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('schemeBusiness/setBusinessLinkPending', result.model, { root: true });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setPendingLicenceActions(state, newPendingLicenceActions) {
      state.pendingLicenceActions = newPendingLicenceActions;
    },
    setPendingLicenceActionSearchResult(state, searchResult) {
      state.searchResult = searchResult;
    },
    setLastSearchConfig(state, searchconfig) {
      state.lastSearchConfig = searchconfig;
    },
    setSearchPageNumber(state, newPageNumber) {
      state.pageNumber = newPageNumber;
    },
    setLicenceBusiness(state, newLicenceBusiness) {
      state.licenceBusiness = newLicenceBusiness;
    },
    setBadge(state, newBadge) {
      state.badge = newBadge;
    },
  },
};
