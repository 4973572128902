<template>
  <div class="row">
    <div class="col-sm-12" :class="{ 'col-md-6 col-xl-9': showAdvanced }">
      <div class="w-100">
        <div class="row">
          <div class="col-sm-6">
            <div class="mb-4">
              <h1>BackOffice</h1>
              <p>Welcome back to TrustMark BackOffice.</p>
            </div>
          </div>
        </div>
        </div>
    </div>
  </div>
</template>
