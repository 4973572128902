import { HTTP, authHeader } from './http-service';

class FunderUserService {
  get(funderId) {
    return HTTP.get(`/funderUser/${funderId}/`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  byEmail(funderId, accountId) {
    const headers = authHeader();
    headers['Content-Type'] = 'text/json';

    return HTTP.post(`/funderUser/${funderId}/byemail?accountId=${accountId}`, `"${accountId}"`, {
      headers,
    }).then((response) => {
      return response.data;
    });
  }

  invite(payload) {
    return HTTP.post(`/funderUser/invite/`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  resendInvite(payload) {
    const headers = authHeader();
    headers['Content-Type'] = 'text/json';
    return HTTP.post(
      `/funderUser/sendInvite?email=${payload.accountId}`,
      `"${payload.accountId}"`,
      {
        headers,
      }
    ).then((response) => {
      return response.data;
    });
  }

  disable(payload) {
    const headers = authHeader();
    headers['Content-Type'] = 'text/json';
    return HTTP.put(`/funderUser/disable?email=${payload.accountId}`, `"${payload.accountId}"`, {
      headers,
    }).then((response) => {
      return response.data;
    });
  }

  enable(payload) {
    const headers = authHeader();
    headers['Content-Type'] = 'text/json';
    return HTTP.put(`/funderUser/enable?email=${payload.accountId}`, `"${payload.accountId}"`, {
      headers,
    }).then((response) => {
      return response.data;
    });
  }
}

export default new FunderUserService();
