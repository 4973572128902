<template>
  <div v-if="application">
    <h1>View Application: {{ application.applicationReference }}</h1>
    <div class="mb-4"></div>
    <div v-if="application">
      <div class="row">
        <div class="col-sm-12 col-md-10 col-lg-10">
          <div v-if="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>

          <div v-if="successMessage" class="alert alert-success">
            {{ successMessage }}
          </div>

          <card label="Trading Standards Intel">
            <div class="col-sm-12">
              <div class="form-group mb-3">
                <label for="schemeId" class="form-label mb-0"> Registered Company Name </label>
                <label class="form-control bg-dark" disabled="disabled">{{
                  application.registeredCompanyName
                }}</label>
              </div>
            </div>

            <smart-textarea
              id="notes"
              v-model="model.notes"
              label="Provide Notes"
              style="min-height: 200px"
              :is-required="!!v$.model.notes.isRequired"
              :validator="v$.model.notes"
            />

            <smart-select
              id="modelRagRating"
              v-model="model.ragRating"
              label="RAG Rating"
              :options="ragRatings"
              :is-required="!!v$.model.ragRating.isRequired"
              :validator="v$.model.ragRating"
            />

            <smart-select-binary
              id="intelApproved"
              v-model="model.intelApproved"
              label="Intel Approved"
              :is-required="!!v$.model.intelApproved"
              :validator="v$.model.intelApproved"
            />
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-10 col-lg-10">
          <div class="form-group d-grid gap-2 d-md-flex justify-content-md-end">
            <router-link
              class="btn btn-secondary float-right btn-lg"
              type="button"
              :to="parentRoute"
            >
              <span>Cancel</span>
            </router-link>

            <smart-submit-large label="Save" :loading="loading" @click="save" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Card,
  SmartTextarea,
  SmartSelect,
  SmartSubmitLarge,
  SmartSelectBinary,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import { helpers, required } from '@vuelidate/validators';
import useValidate from '@vuelidate/core';
import { formatDate, age } from '../../services/scheme-common';

export default {
  name: 'ApplicationsView',
  components: {
    SmartSelect,
    SmartTextarea,
    Card,
    SmartSubmitLarge,
    SmartSelectBinary,
  },
  data() {
    return {
      model: {
        notes: null,
        ragRating: null,
      },
      ragRatings: ['Red', 'Amber', 'Green'],
      v$: useValidate(),
    };
  },
  computed: {
    application() {
      return this.$store.state.application.application;
    },
    parentRoute() {
      return `/applications/${this.$route.params.applicationRef}`;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isTradingStandards() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('TradingStandards')
        : false;
    },
  },
  mounted() {
    if (
      !this.application ||
      this.application.applicationRef !== this.$route.params.applicationRef
    ) {
      this.$store.dispatch('application/get', this.$route.params.applicationRef).then(() => {
        this.model.notes = this.application.tradingStandardsNotes;
        this.model.ragRating = this.application.tradingStandardsRagRating;
      });
    } else {
      this.model.notes = this.application.tradingStandardsNotes;
      this.model.ragRating = this.application.tradingStandardsRagRating;
    }
  },
  methods: {
    formatDate,
    age,
    endsWith(str) {
      return this.$route.path.endsWith(str) ? 'btn-primary' : 'btn-secondary';
    },
    save() {
      this.v$.$validate();
      if (this.v$.$error) {
        window.scrollTo(0, 0);
        this.errorMessage =
          'Unable to save the note. Please resolve any errors on this form and try again.';
        return;
      }

      const payload = {
        ApplicationReference: this.$route.params.applicationRef,
        TradingStandardsRagRating: this.model.ragRating,
        TradingStandardsNotes: this.model.notes,
      };

      this.$store
        .dispatch('application/updateTradingStandardsResponse', payload)
        .then(() => {
          this.$router.push(this.parentRoute);
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        });
    },
  },
  validations: {
    model: {
      notes: {
        isRequired: helpers.withMessage('Notes are required', required),
      },
      ragRating: {
        isRequired: helpers.withMessage('Red / amber / green rating is required', required),
      },
    },
  },
};
</script>
