<template>
  <div v-if="!currentAccount" class="row justify-content-center p-3 m-5">
    <div class="col-8 col-md-3">
      <img src="../assets/logo.png" class="img img-fluid" />
    </div>
  </div>

  <div v-if="!currentAccount" class="container-login">
    <h2 class="pb-5">Reset Password</h2>

    <form ref="formLogin" class="has-validation">
      <fieldset :disabled="loading">
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>

        <div v-if="!passwordChanged">
          <div class="alert alert-info">
            <b>Please check your email.</b> If you are registered with us, we will have emailed you
            a confirmation code which is required in order to reset your password.
          </div>

          <smart-input
            id="email"
            v-model="email"
            :is-required="!!v$.email.isRequired"
            :validator="v$.email"
            label="Your Email Address"
          />

          <smart-input
            id="newPassword"
            v-model="newPassword"
            type="password"
            :is-required="!!v$.newPassword.isRequired"
            :validator="v$.newPassword"
            label="New Password"
          />

          <smart-input
            id="confirmPassword"
            v-model="confirmPassword"
            type="password"
            :is-required="!!v$.confirmPassword.isRequired"
            :validator="v$.confirmPassword"
            label="Confirm Password"
          />
          <smart-input
            id="confirmationCode"
            v-model="confirmationCode"
            :is-required="!!v$.confirmationCode.isRequired"
            :validator="v$.confirmationCode"
            label="Confirmation Code (from email)"
          />
        </div>
        <div v-else class="alert alert-success">
          Your password has been changed. Please log in using your new password.
        </div>

        <div class="row pt-5">
          <div class="col">
            <div v-if="!passwordChanged">
              <router-link to="/register">Register from an invite</router-link>
              <br />
            </div>
            <div class="mt-3">
              <router-link to="/login">Login</router-link>
            </div>
          </div>
          <div class="col justify-content-md-end">
            <smart-submit-large
              v-if="!passwordChanged"
              label="Reset Password"
              :loading="loading"
              @click="resetPassword"
            />

            <router-link v-else class="btn btn-lg btn-primary float-right" to="/login"
              >Done</router-link
            >
          </div>
        </div>

        <div class="form-group d-grid gap-2 d-md-flex"></div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core';
import { helpers, required, email } from '@vuelidate/validators';
import { SmartSubmitLarge, SmartInput } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'ResetPassword',
  components: {
    SmartInput,
    SmartSubmitLarge,
  },
  data() {
    return {
      v$: useValidate(),
      email: null,
      confirmationCode: null,
      newPassword: null,
      confirmPassword: null,
      loading: false,
      passwordChanged: false,
      message: '',
    };
  },
  validations: {
    email: {
      isRequired: helpers.withMessage('Email address is required', required),
      isEmail: helpers.withMessage('Must be a valid email address', email),
    },
    confirmationCode: {
      isRequired: helpers.withMessage(
        'Please provide the confirmation code from the email you received',
        required
      ),
    },
    newPassword: {
      isRequired: helpers.withMessage('Your new password is required', required),
      isGoodPassword: helpers.withMessage(
        'Password strength is not good enough',
        (password) =>
          password.length >= 8 &&
          /[a-z]/.test(password) &&
          /[A-Z]/.test(password) &&
          /[0-9]/.test(password)
      ),
    },
    confirmPassword: {
      isRequired: helpers.withMessage('Please confirm your new password', required),
      isSame: helpers.withMessage('Passwords must match', (value, vm) => {
        return value === vm.newPassword;
      }),
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    resetPassword() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.message = 'Please fill out all fields. Emails must be in a valid format';
        this.$refs.formLogin.focus();
        window.scrollTo(0, 0);
        return;
      }

      this.loading = true;
      this.message = '';

      this.$store
        .dispatch('passwordReset/resetWithCode', {
          username: this.email,
          confirmationCode: this.confirmationCode,
          newPassword: this.newPassword,
        })
        .then(
          () => {
            window.scrollTo(0, 0);
            this.passwordChanged = true;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
  },
};
</script>

<style>
.container-login {
  max-width: 740px !important;
  padding: 40px 40px;
  margin: auto;
}

label {
  margin-top: 10px;
}
</style>
