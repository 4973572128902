<template>
  <subtitle>Information</subtitle>

  <div v-if="model" class="col-sm-12 col-md-10">
    <card>
      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div class="form-label">
            <key-value-presenter label="Company Name" :value="model.registeredCompanyName" />
          </div>
        </div>

        <div class="col-sm-12 col-md-4">
          <div class="form-label">
            <copy-key-value-presenter label="TMLN" :value="model.tmln" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div class="form-label">
            <copy-key-value-presenter
              label="Company Number"
              :value="model.registeredCompanyNumber"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-4">
          <div class="form-label">
            <key-value-presenter
              label="Created"
              :value="$helpers.dateTimeString(model.createdAt)"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div class="form-label">
            <b>Status: </b> {{ asString(model.status) }}
            <check-or-cross :state="model.status === 1" />
          </div>
        </div>

        <div class="col-sm-12 col-md-4">
          <div class="form-label">
            <key-value-presenter label="Created By" :value="model.createdBy" :hide-if-null="true" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div class="form-label">
            <key-value-presenter-hyperlinked
              label="Registered Email"
              :value="model.registeredEmail"
              :href="`mailto:${model.registeredEmail}`"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-4">
          <div class="form-label">
            <key-value-presenter
              label="Updated"
              :value="$helpers.dateTimeString(model.updatedAt)"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div class="form-label">
            <copy-key-value-presenter
              label="Trustmark Business Id"
              :value="model.trustmarkBusinessId"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-4">
          <div class="form-label">
            <key-value-presenter label="Updated By" :value="model.updatedBy" :hide-if-null="true" />
          </div>
        </div>
      </div>
    </card>

    <licence-number-view-trading-standards />

    <card v-if="model.schemes">
      <h5 class="card-title mb-3">
        Schemes
        <span class="badge rounded-pill bg-primary text-light">{{ model.schemes.length }}</span>
      </h5>
      <div class="row">
        <div v-for="schemeId in model.schemes" :key="schemeId" class="col-3">
          <router-link :to="`/scheme/${schemeId}`">
            <scheme-logo :imagekey="schemeImageKeyFromId(schemeId)" />
          </router-link>
        </div>
      </div>
    </card>

    <card
      v-if="
        model.businessIdentityChangeHistories && model.businessIdentityChangeHistories.length > 0
      "
      label="Business Identity History"
    >
      <div v-for="b in model.businessIdentityChangeHistories" :key="b.createdAt" class="mb-4">
        <div v-if="b.previousTMLN !== b.newTMLN" class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-label">
              <key-value-presenter label="Previous TMLN" :value="b.previousTMLN" />
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="form-label">
              <key-value-presenter label="New TMLN" :value="b.newTMLN" />
            </div>
          </div>
        </div>
        <div v-if="b.previousRegisteredCompanyName !== b.newRegisteredCompanyName" class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-label">
              <key-value-presenter
                label="Previous Registered Company Name"
                :value="b.previousRegisteredCompanyName"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="form-label">
              <key-value-presenter
                label="New Registered Company Name"
                :value="b.newRegisteredCompanyName"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-label">
              <key-value-presenter label="Created By" :value="b.createdBy" />
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="form-label">
              <key-value-presenter
                label="Created At"
                :value="$helpers.dateTimeString(b.createdAt)"
              />
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import {
  Subtitle,
  Card,
  CopyKeyValuePresenter,
  KeyValuePresenter,
  KeyValuePresenterHyperlinked,
  CheckOrCross,
  SchemeLogo,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import LicenceNumberViewTradingStandards from './LicenceNumberViewTradingStandards.vue';

export default {
  name: 'LicenceNumberViewInformation',
  components: {
    CheckOrCross,
    Subtitle,
    Card,
    KeyValuePresenter,
    CopyKeyValuePresenter,
    KeyValuePresenterHyperlinked,
    SchemeLogo,
    LicenceNumberViewTradingStandards,
  },
  data() {
    return {};
  },
  computed: {
    model() {
      return this.$store.state.licenceNumber.trustmarkBusiness;
    },
  },
  methods: {
    asString(statusCode) {
      return this.$store.state.auth.appData.licenceNumberStatuses?.find(
        (x) => x.status === parseInt(statusCode, 10)
      )?.description;
    },
    schemeImageKeyFromId(schemeId) {
      const scheme = this.$store.state.auth.appData.schemes.find((x) => x.schemeId === schemeId);
      return scheme.schemeLogoImageKey || scheme.logoImage;
    },
  },
};
</script>
