<template>
  <div v-if="model" class="h-100">
    <form ref="formTrade" class="has-validation">
      <div class="pt-3">
        <div class="row">
          <div class="col-sm-12 col-md-10 col-lg-10">
            <card label="Trade Details">
              <div class="row">
                <div class="col">
                  <smart-input
                    id="tradeCode"
                    v-model="model.tradeCode"
                    label="Trade Code"
                    :is-required="!!v$.model.tradeCode.isRequired"
                    :validator="v$.model.tradeCode"
                  />
                </div>
                <div class="col">
                  <smart-select-binary
                    id="isActive "
                    v-model="model.isActive"
                    label="Active"
                    :is-required="v$.model.isActive.isRequired"
                    :validator="v$.model.isActive"
                    @Change="onIsActiveChange"
                  />
                </div>
              </div>

              <div
                v-if="!model.isActive && model.statusHistories?.length > 0"
                class="alert alert-info"
                role="alert"
              >
                This trade was made inactive on
                {{ formatDateCustom(latestStatusHistory.createdAt, 'DD/MM/yyyy') }} by
                {{ latestStatusHistory.createdBy }}.
                <span v-if="latestStatusHistory.affectedSchemeIds"
                  ><br class="mb-3" />This affected the following scheme trades:
                  {{ latestStatusHistory.affectedSchemeIds.join(', ') }}.</span
                >
              </div>

              <div v-if="!model.isActive">
                <div class="col-sm-3">
                  <label for="inactiveDateFrom" class="form-label">Inactive Date From</label>
                </div>
                <datepicker
                  id="inactiveDateFrom"
                  v-model="model.inactiveDateFrom"
                  :validator="v$.model.inactiveDateFrom"
                  class="form-control bg-transparent mb-3"
                  :class="{ 'is-invalid': v$.model.inactiveDateFrom.$errors?.[0]?.$message }"
                  input-format="dd/MM/yyyy"
                  placeholder="dd/mm/yyyy"
                />
                <div
                  v-if="v$.model.inactiveDateFrom.$errors?.[0]?.$message?.length > 0"
                  class="text-danger"
                >
                  {{ v$.model.inactiveDateFrom.$errors?.[0]?.$message }}
                </div>
              </div>

              <smart-input
                id="tradeDescription"
                v-model="model.tradeDescription"
                label="Trade Description"
                :is-required="!!v$.model.tradeDescription.isRequired"
                :validator="v$.model.tradeDescription"
              />

              <smart-datalist
                id="ghgvsName"
                v-model="model.ghgvsName"
                label="Consumer Description"
                :is-required="!!v$.model.ghgvsName.isRequired"
                :validator="v$.model.ghgvsName"
                :options="ghgvsNameList"
              />

              <smart-textarea
                id="tradeSkills"
                v-model="model.tradeSkills"
                label="Trade Skills (separate with commas)"
                :is-required="!!v$.model.tradeSkills.isRequired"
                :validator="v$.model.tradeSkills"
              />

              <smart-textarea
                id="searchKeywords"
                v-model="model.searchKeywords"
                label="Search Keywords (separate with commas)"
                :is-required="!!v$.model.searchKeywords.isRequired"
                :validator="v$.model.searchKeywords"
              />
            </card>

            <card label="Technical Standards (If applicable)">
              <smart-datalist
                id="standard"
                v-model="model.standard"
                label="Standard"
                :is-required="!!v$.model.standard.isRequired"
                :validator="v$.model.standard"
                :options="standardList"
              />

              <smart-datalist
                id="pasAnnex"
                v-model="model.pasAnnex"
                label="PAS Annex"
                :is-required="!!v$.model.pasAnnex.isRequired"
                :validator="v$.model.pasAnnex"
                :options="pasAnnexList"
              />
            </card>

            <card label="Notes">
              <smart-textarea
                id="internalNotes"
                v-model="model.internalNotes"
                style="min-height: 200px"
                label="Internal"
                :is-required="!!v$.model.internalNotes.isRequired"
                :validator="v$.model.internalNotes"
              />

              <smart-textarea
                id="externalNotes"
                v-model="model.externalNotes"
                style="min-height: 200px"
                label="External"
                :is-required="!!v$.model.externalNotes.isRequired"
                :validator="v$.model.externalNotes"
              />
            </card>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  SmartInput,
  Card,
  SmartSelectBinary,
  SmartTextarea,
  SmartDatalist,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import Datepicker from 'vue3-datepicker';
import { formatDateCustom } from '../../services/scheme-common';

export default {
  name: 'TradesFields',
  components: {
    SmartDatalist,
    SmartInput,
    SmartTextarea,
    SmartSelectBinary,
    Card,
    Datepicker,
  },
  props: {
    v: {
      type: Object,
      required: true,
      default: null,
    },
  },
  computed: {
    v$() {
      return this.v;
    },
    model() {
      return this.$store.state.trade.trade;
    },
    ghgvsNameList() {
      if (!this.$store.state.trade || !this.$store.state.trade.trades) {
        return [];
      }
      const list = this.$store.state.trade.trades.map((x) => x.ghgvsName).filter((x) => x !== null);
      return [...new Set(list)];
    },
    pasAnnexList() {
      if (!this.$store.state.trade || !this.$store.state.trade.trades) {
        return [];
      }
      const list = this.$store.state.trade.trades.map((x) => x.pasAnnex).filter((x) => x !== null);
      return [...new Set(list)];
    },
    standardList() {
      if (!this.$store.state.trade || !this.$store.state.trade.trades) {
        return [];
      }
      const list = this.$store.state.trade.trades.map((x) => x.standard).filter((x) => x !== null);
      const distinctList = [...new Set(list)];
      return distinctList.sort();
    },
    latestStatusHistory() {
      return this.model?.statusHistories?.[this.model?.statusHistories?.length - 1];
    },
  },
  methods: {
    formatDateCustom,
    onIsActiveChange() {
      if (this.model.isActive) {
        this.model.inactiveDateFrom = null;
      } else {
        this.model.inactiveDateFrom = new Date();
      }
    },
  },
};
</script>
