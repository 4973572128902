<template>
  <div class="form-group mb-4">
    <label :for="id" class="form-label">{{ label }}</label>
    <input
      :id="id"
      class="form-control"
      type="file"
      :disabled="disabled"
      @change="onFileSelected"
    />
    <div v-if="error" class="invalid-feedback d-block">{{ error }}</div>
    <div v-if="modelValue" class="mt-4">
      <div class="mb-2"><small>Current Scheme Image Logo</small></div>
      <img :src="modelValue" style="max-width: 100%" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SchemeService from '../services/scheme-service';

export default {
  name: 'FileUpload',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'File Upload',
    },
    acceptTypes: {
      type: Array,
      default: () => ['image/png', 'image/jpeg', 'image/gif'],
    },
    description: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      customError: '',
      busy: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    error() {
      return this.validator?.$errors?.[0]?.$message || this.customError;
    },
    disabled() {
      return this.busy || this.readonly;
    },
    scheme() {
      return this.$store.state.scheme.scheme;
    },
  },
  methods: {
    onFileSelected(e) {
      this.customError = '';
      const file = e.target?.files?.[0];

      if (this.acceptTypes.indexOf(file.type) === -1) {
        this.customError = `File must be one of the following types: ${this.acceptTypes.join(
          ', '
        )}.`;
        return;
      }

      const config = {
        headers: {
          'x-amz-acl': 'public-read',
        },
      };

      this.busy = true;
      SchemeService.getSignedUrl(this.scheme.schemeId, file.name)
        .then((result) => {
          axios.put(result, file, config).then((fileResult) => {
            // eslint-disable-next-line
            this.value = fileResult.config.url.split('?')[0]; 
            this.busy = false;
          });
        })
        .catch(() => {
          this.customError = 'Could not upload file, please try again.';
          this.busy = false;
        });
    },
  },
};
</script>
