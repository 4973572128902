<template>
  <div>
    <div class="col-sm-12 col-md-10 col-lg-10">
      <subtitle>Register Software Provider</subtitle>

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <div v-if="successMessage" class="alert alert-success" role="alert">
        {{ successMessage }}
      </div>

      <card label="Details">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <smart-input
              id="firstName"
              v-model="model.accountHolderName"
              label="Account Holder Business Name"
              :is-required="!!v$.model.accountHolderName.isRequired"
              :validator="v$.model.accountHolderName"
            />
          </div>
        </div>
      </card>

      <card label="Add User">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <smart-input
              id="firstName"
              v-model="model.firstName"
              label="First Name"
              :is-required="!!v$.model.firstName.isRequired"
              :validator="v$.model.firstName"
            />
            <smart-input
              id="lastName"
              v-model="model.lastName"
              label="Last Name"
              :is-required="!!v$.model.lastName.isRequired"
              :validator="v$.model.lastName"
            />
            <smart-input
              id="email"
              v-model="model.email"
              label="Email"
              :is-required="!!v$.model.email.isRequired"
              :validator="v$.model.email"
            />
          </div>
        </div>
      </card>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group d-grid gap-2 d-md-flex justify-content-md-end">
            <router-link
              class="btn btn-secondary float-right btn-lg"
              type="button"
              to="software-providers"
            >
              <span>Cancel</span>
            </router-link>
            <smart-submit-large label="Save" :loading="loading" @click="save" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Card,
  Subtitle,
  SmartInput,
  SmartSubmitLarge,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import useValidate from '@vuelidate/core';
import { helpers, required, maxLength, email } from '@vuelidate/validators';

export default {
  name: 'SoftwareProviderCreate',
  components: {
    Card,
    Subtitle,
    SmartInput,
    SmartSubmitLarge,
  },
  data() {
    return {
      v$: useValidate(),
      errorMessage: '',
      successMessage: '',
      model: {
        accountHolderName: '',
        firstName: '',
        lastName: '',
        accountId: '',
        roles: [],
      },
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isApplicationEditor() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('ApplicationEditor')
        : false;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  },
  methods: {
    save() {
      this.v$.$validate();
      if (this.v$.$error) {
        window.scrollTo(0, 0);
        this.errorMessage = 'Please resolve any errors on this form and try again.';
        return;
      }

      this.$store
        .dispatch('softwareProvider/createRetrofitAccount', {
          accountHolderName: this.model.accountHolderName,
          addThirdPartySubmissions: true,
          firstName: this.model.firstName,
          lastName: this.model.lastName,
          email: this.model.email,
        })
        .then(
          (resp) => {
            window.scrollTo(0, 0);
            this.$router.push(`/software-provider/${resp.model.retrofitAccountId}`);
          },
          (error) => {
            this.errorMessage =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
  },
  validations() {
    return {
      model: {
        accountHolderName: {
          isRequired: helpers.withMessage('Account Holder Business Name is required', required),
          maxLength: maxLength(100),
        },
        firstName: {
          isRequired: helpers.withMessage('First Name is required', required),
          maxLength: maxLength(100),
        },
        lastName: {
          isRequired: helpers.withMessage('Last Name is required', required),
          maxLength: maxLength(100),
        },
        email: {
          isRequired: helpers.withMessage('Email is required', required),
          maxLength: maxLength(200),
          isEmail: helpers.withMessage('Must be a valid email address', email),
        },
      },
    };
  },
};
</script>
