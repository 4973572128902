<template>
  <confirm-dialog ref="confirmDialog" />
  <div v-if="softwareProvider">
    <div class="col-sm-12 col-md-10 col-lg-10">
      <h1>View Software Provider: {{ softwareProvider.accountHolderName }}</h1>
      <div class="mb-4">
        <div class="btn-toolbar mb-2 mb-md-0">
          <div class="btn-group me-2">
            <router-link
              v-if="isApplicationEditor"
              to="/software-providers"
              class="btn btn-outline-secondary"
            >
              Back
            </router-link>
          </div>
        </div>
      </div>

      <card label="Details">
        <div class="row">
          <div class="form-label">
            <key-value-presenter
              label="Account Holder Business Name"
              :value="softwareProvider.accountHolderName"
            />
          </div>
          <div class="form-label">
            <key-value-presenter label="Products" :value="softwareProvider.products.join(', ')" />
          </div>
          <div class="form-label">
            <key-value-presenter
              label="Wallet Balance"
              :value="`£ ${softwareProvider.walletBalance}`"
            />
          </div>

          <div v-if="!existingUsers" class="col-sm-12 col-md-6">
            <p>No current users</p>
          </div>
          <div v-if="existingUsers" class="col-sm-12 col-md-6 mt-3">
            <h6>Existing Users</h6>
            <ul>
              <li v-for="user in existingUsers" :key="user.accountId" class="mt-3">
                {{ user.displayName }}
                <br /><span class="small light"><b>Email:</b> {{ user.accountId }}</span>
                <br /><span class="small light"><b>Roles:</b> {{ user.roles.join(', ') }}</span>
              </li>
            </ul>
          </div>

          <div class="col-sm-12 col-md-6">
            <button
              v-if="softwareProvider.isEnabled"
              type="button"
              class="btn btn-danger"
              @click="confirmDisableAccount(softwareProvider)"
            >
              Disable
            </button>
            <button
              v-if="!softwareProvider.isEnabled"
              type="button"
              class="btn btn-secondary"
              @click="confirmEnableAccount(softwareProvider)"
            >
              Enable
            </button>
          </div>
        </div>
      </card>

      <card label="Danger Zone" class="border-danger">
        <p>You can delete this software provider, there is no going back. Please be certain.</p>
        <button type="button" class="btn btn-lg btn-danger" @click="confirmDelete">
          Delete Record
        </button>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Card,
  KeyValuePresenter,
  confirmType,
  ConfirmDialog,
} from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'SoftwareProviderView',
  components: {
    Card,
    KeyValuePresenter,
    ConfirmDialog,
  },
  data() {
    return {};
  },
  computed: {
    softwareProvider() {
      return this.$store.state.softwareProvider.softwareProvider;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isApplicationEditor() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('ApplicationEditor')
        : false;
    },
    existingUsers() {
      return this.softwareProvider.usersWithRoles;
    },
  },
  mounted() {
    this.$store.dispatch(
      'softwareProvider/getSoftwareProviderById',
      this.$route.params.softwareProviderId
    );
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  },
  methods: {
    deleteRecord() {
      this.$store
        .dispatch('softwareProvider/deleteSoftwareProvider', this.$route.params.softwareProviderId)
        .then(() => {
          this.$store.dispatch('softwareProvider/listSoftwareProviders').then(() => {
            this.$router.push('/software-providers');
          });
        })
        .catch((err) => {
          this.errorMessage = err;
        });
    },
    confirmDelete() {
      this.$refs.confirmDialog
        .show({
          title: 'Delete Software Provider',
          message: 'Are you sure you want to delete this software provider? This cannot be undone.',
          okButtonText: 'Delete Forever',
          confirmType: confirmType.destructive,
        })
        .then((result) => {
          if (result === true) {
            this.deleteRecord();
          }
        });
    },
    confirmDisableAccount(softwareProvider) {
      this.$refs.confirmDialog
        .show({
          title: 'Disable Account',
          message: 'Are you sure you want to disable this account?',
          okButtonText: 'Disable',
          confirmType: confirmType.destructive,
        })
        .then((result) => {
          if (result) {
            this.disableAccount(softwareProvider);
          }
        });
    },
    disableAccount(softwareProvider) {
      this.$store
        .dispatch('softwareProvider/disableAccount', softwareProvider.retrofitAccountId)
        .then(() => {
          this.$store.dispatch('softwareProvider/listSoftwareProviders').then(() => {
            this.loading = false;
          });
        })
        .catch((err) => {
          this.loading = false;
          this.searchError = err.message;
        });
    },
    confirmEnableAccount(softwareProvider) {
      this.$refs.confirmDialog
        .show({
          title: 'Enable Account',
          message: 'Are you sure you want to enable this account?',
          okButtonText: 'Enable',
        })
        .then((result) => {
          if (result) {
            this.enableAccount(softwareProvider);
          }
        });
    },
    enableAccount(softwareProvider) {
      this.$store
        .dispatch('softwareProvider/enableAccount', softwareProvider.retrofitAccountId)
        .then(() => {
          this.$store.dispatch('softwareProvider/listSoftwareProviders').then(() => {
            this.loading = false;
          });
        })
        .catch((err) => {
          this.loading = false;
          this.searchError = err.message;
        });
    },
  },
};
</script>
