<template>
  <subtitle>Refund Account Wallet</subtitle>

  <div class="col-sm-12 col-md-10">
    <card>
      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
      </div>

      <div class="form-group">
        <label for="tmln" class="form-label">TMLN</label>
        <input id="tmln" v-model="tmln" class="form-control" type="text" disabled />
      </div>
      <div class="form-group">
        <label for="amount" class="form-label">Amount in GBP *</label>
        <input
          id="amount"
          v-model="amount"
          class="form-control"
          type="text"
          :class="v$.amount.$error ? 'is-invalid' : ''"
          required
        />
        <div v-if="v$.amount.$error" class="invalid-feedback">
          {{ v$.amount.$errors[0].$message }}
        </div>
      </div>
      <div class="form-group">
        <label for="description" class="form-label">Description *</label>
        <input
          id="description"
          v-model="description"
          class="form-control"
          type="text"
          :class="v$.description.$error ? 'is-invalid' : ''"
          required
        />
        <div v-if="v$.description.$error" class="invalid-feedback">
          {{ v$.description.$errors[0].$message }}
        </div>
      </div>
      <button type="button" class="btn btn-primary mt-3" @click="refund">Refund</button>
    </card>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core';
import { Subtitle, Card } from '@trustmark-2005-ltd/trustmark-shared-components';
import { helpers, required } from '@vuelidate/validators';
import { formatDate } from '../../../services/scheme-common';

export default {
  name: 'LicenceNumberViewRetrofitRefund',
  components: {
    Subtitle,
    Card,
  },
  data() {
    return {
      v$: useValidate(),
      amount: 0,
      description: '',
      message: '',
    };
  },
  computed: {
    model() {
      return this.$store.state.licenceNumber.retrofitAccount;
    },
    tmln() {
      return this.$store.state.licenceNumber.trustmarkBusiness.tmln;
    },
    trustmarkBusinessId() {
      return this.$store.state.licenceNumber.trustmarkBusiness.trustmarkBusinessId;
    },
  },
  validations: {
    amount: {
      isRequired: helpers.withMessage('Amount is required', required),
    },
    description: {
      isRequired: helpers.withMessage('Reference is required', required),
    },
  },
  methods: {
    formatDate,
    asString(statusCode) {
      return this.$store.state.auth.appData.licenceNumberStatuses.find(
        (x) => x.status === parseInt(statusCode, 10)
      ).description;
    },
    refund() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.message = 'Please enter a payment description and amount.';
        window.scrollTo(0, 0);
        return;
      }

      this.$store
        .dispatch('licenceNumber/refund', {
          amount: this.amount,
          description: this.description,
          tmln: this.$store.state.licenceNumber.trustmarkBusiness.tmln,
        })
        .then(() => {
          this.$router.push({
            name: 'retrofit-account',
            params: { tmBusinessId: this.trustmarkBusinessId },
          });
        })
        .catch((error) => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        });

      this.message = '';
    },
  },
};
</script>
