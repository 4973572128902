import { HTTP, authHeader } from './http-service';

class DisputeSearchService {
  search(payload) {
    return HTTP.post(`/DisputeSearch`, payload, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  advancedSearch(payload) {
    return HTTP.post(`/DisputeSearch/advance`, payload, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }
}

export default new DisputeSearchService();
