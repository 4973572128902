import { HTTP, authHeader } from './http-service';

class BusinessReportService {
  getAll() {
    return HTTP.get(`/export/getall`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  get(id) {
    return HTTP.get(`/export/${id}`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  delete(id) {
    return HTTP.delete(`/export/${id}`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  deletePending(id) {
    return HTTP.delete(`/export/pending/${id}`, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  download(id) {
    return HTTP.get(`/export/download/csv/${id}`, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  post(payload) {
    return HTTP.post(`/export`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new BusinessReportService();
