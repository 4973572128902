<template>
  <h1>Trading Standards</h1>

  <div v-if="yearData && resultsModel" class="mb-4">
    <div class="btn-toolbar mb-3" role="toolbar">
      <div class="row w-100">
        <div class="col-sm-12 col-md-9 col-lg-6">
          <div class="input-group" :class="{ disabled: loading }">
            <div id="btnGroupAddon" class="input-group-text bg-transparent border-end-0">
              <font-awesome-icon icon="magnifying-glass" />
            </div>
            <input
              v-model="searchModel.searchTerm"
              type="text"
              class="form-control border-start-0"
              placeholder="Find by TMLN, Company"
            />

            <smart-button
              class-override="btn-outline-secondary"
              :loading="false"
              :disabled="!resultsModel || loading"
              :click="clear"
              >Clear
            </smart-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <span
      v-for="option in yearsFilterOptions"
      :key="option"
      class="badge rounded-pill me-1"
      :class="yearOptionClass(option)"
      style="cursor: pointer"
      @click="filterBaseData(option)"
    >
      {{ option }}
    </span>
  </div>

  <div v-if="funnelData" class="text-center mb-5">
    <vue3-chart-js
      :id="chart.id"
      ref="chartRef"
      :type="chart.type"
      :data="chart.data"
      :options="chart.options"
      class="w-100"
      style="height: 200px"
    ></vue3-chart-js>
    <small>Click a section to filter by Status, re-click to clear the filter.</small>
  </div>

  <div
    v-if="yearData && resultsModel && resultsModel.results.length === 0 && !loading"
    class="alert alert-warning"
    role="alert"
  >
    There are no matching results for this search.
  </div>

  <div class="w-100 overflow-scroll">
    <table v-if="yearData && resultsModel.results.length > 0" class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Row</th>
          <th scope="col">Actions</th>
          <th scope="col">Year (Number)</th>
          <th scope="col">TMLN</th>
          <th scope="col">Company</th>
          <th scope="col">Status</th>
          <th scope="col">Trading Standards Invoice Issued</th>
          <th scope="col">Trading Standards Invoice Paid Date</th>
          <th scope="col">Trading Standards Notified Date</th>
          <th scope="col">RAG Rating</th>
          <th scope="col">Renewal</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(tsy, index) in resultsModel.results" :key="tsy.tmln">
          <td>
            {{ index + 1 }}
          </td>
          <td>
            <div class="btn-group me-2">
              <button class="btn btn-outline-primary" @click="edit(tsy)">Edit</button>
            </div>
          </td>
          <td>{{ tsy.renewalYear }} ({{ tsy.yearNumber }})</td>
          <td>
            <router-link v-if="tsy.trustmarkBusinessId" :to="`/tmlns/${tsy.trustmarkBusinessId}`">{{
              tsy.tmln
            }}</router-link>
            <span v-if="!tsy.trustmarkBusinessId">{{ tsy.tmln }}</span>
          </td>
          <td>
            {{ tsy.registeredCompanyName }}
          </td>
          <td>
            {{ tsy.tradingStandardsWorkflowStatus }}
          </td>
          <td>
            {{ formatYesNo(tsy.tradingStandardsInvoiceIssued) }}
          </td>
          <td>
            {{ $helpers.dateString(tsy.tradingStandardsInvoicePaidDate) }}
          </td>
          <td>
            {{ $helpers.dateString(tsy.tradingStandardsNotifiedDate) }}
          </td>
          <td><span :class="`dot${tsy.tradingStandardsRagRating}`"></span></td>
          <td>
            {{ $helpers.dateString(tsy.nextRenewalDate) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { SmartButton } from '@trustmark-2005-ltd/trustmark-shared-components';
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs';

export default {
  name: 'TradingStandardsAdminView',
  components: {
    SmartButton,
    Vue3ChartJs,
  },
  data() {
    return {
      baseFilterOption: null,
      searchModel: {
        searchTerm: null,
      },
      chartFilter: null,
      loading: false,
      colours: [
        '#198754',
        '#20c997',
        '#ffc107',
        '#fd7e14',
        '#dc3545',
        '#d63384',
        '#6f42c1',
        '#6610f2',
        '#0d6efd',
      ],
    };
  },
  computed: {
    yearData() {
      return this.$store.state.tradingStandardsAdmin.yearData[0];
    },
    funnelData() {
      return this.$store.state.tradingStandardsAdmin.tradingStandardsYearFunnelData;
    },
    resultsModel() {
      if (!this.searchModel.searchTerm) {
        return {
          results: this.yearData.data.filter(
            (x) => !this.chartFilter || this.chartFilter === x.tradingStandardsWorkflowStatus
          ),
        };
      }
      return {
        results: this.yearData.data.filter(
          (x) =>
            (x.tmln?.includes(this.searchModel.searchTerm) ||
              x.registeredCompanyName
                ?.toUpperCase()
                .includes(this.searchModel.searchTerm.toUpperCase()) ||
              x.registeredEmail
                ?.toUpperCase()
                .includes(this.searchModel.searchTerm.toUpperCase())) &&
            (!this.chartFilter || this.chartFilter === x.tradingStandardsWorkflowStatus)
        ),
      };
    },
    chart() {
      return {
        id: 'statusCountChart',
        type: 'bar',
        data: {
          labels: ['Count'],
          datasets: [],
        },
        options: {
          animation: false,
          onClick: (e) => {
            const area = e.chart.getElementsAtEventForMode(
              e,
              'nearest',
              {
                intersect: true,
              },
              false
            );
            const newFilter = e.chart.data.datasets[area[0].datasetIndex].label[area[0].index];
            if (newFilter === this.chartFilter) {
              this.chartFilter = null;
            } else {
              this.chartFilter = newFilter;
            }
            e.chart.data.datasets = this.setChartData();
          },
          plugins: {
            title: {
              display: true,
              text: 'Applications by Workflow Status',
            },
          },
          responsive: true,
          indexAxis: 'y',
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
      };
    },
    yearsFilterOptions() {
      const yearOptions = [];
      const yearNumber = new Date().getUTCFullYear();
      for (let index = 2022; index <= yearNumber; index += 1) {
        yearOptions.push(index);
      }
      yearOptions.push('Current Workflow');
      return yearOptions;
    },
  },
  mounted() {
    if (!this.baseFilterOption) {
      this.baseFilterOption = this.$store.state.tradingStandardsAdmin.yearFilterOption;
    }
    if (!this.yearData || !this.funnelData) {
      this.clear();
    } else {
      this.loading = false;
      this.setChartData();
    }
  },
  methods: {
    filterBaseData(option) {
      this.baseFilterOption = option;
      this.clear();
    },
    yearOptionClass(option) {
      return this.baseFilterOption === option ? 'bg-secondary' : 'bg-primary';
    },
    formatFee(val) {
      if (!val) return '';
      return `£${val}`;
    },
    formatYesNo(val) {
      return val ? 'Yes' : 'No';
    },
    clear() {
      this.searchModel.searchTerm = null;
      this.chartFilter = null;
      this.loading = true;
      if (this.baseFilterOption === 'Current Workflow') {
        Promise.all([
          this.$store.dispatch('tradingStandardsAdmin/listCurrent'),
          this.$store.dispatch('tradingStandardsAdmin/getFunnelData'),
        ]).then(() => {
          this.loading = false;
          this.setChartData();
        });
      } else {
        Promise.all([
          this.$store.dispatch('tradingStandardsAdmin/listYear', this.baseFilterOption),
          this.$store.dispatch('tradingStandardsAdmin/getFunnelDataForYear', this.baseFilterOption),
        ]).then(() => {
          this.loading = false;
          this.setChartData();
        });
      }
      this.$store.commit('tradingStandardsAdmin/setYearFilterOption', this.baseFilterOption);
    },
    setChartData() {
      this.$refs.chartRef.data.datasets = this.funnelData.map((x, i) => {
        return {
          label: [x.item1],
          backgroundColor:
            !this.chartFilter || this.chartFilter === x.item1 ? this.colours[i] : '#eeeeee',
          data: [x.item2],
        };
      });
      this.$refs.chartRef.update(0);
    },
    edit(tsy) {
      this.$router.push(`/tradingstandards/${tsy.tmln}/${tsy.renewalYear}`);
    },
  },
};
</script>

<style>
.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.dotGreen {
  height: 25px;
  width: 25px;
  background-color: rgb(36, 211, 59);
  border-radius: 50%;
  display: inline-block;
}

.dotYellow {
  height: 25px;
  width: 25px;
  background-color: rgb(228, 222, 38);
  border-radius: 50%;
  display: inline-block;
}

.dotRed {
  height: 25px;
  width: 25px;
  background-color: rgb(211, 62, 36);
  border-radius: 50%;
  display: inline-block;
}
</style>
