<template>
  <div v-if="model" class="row">
    <div class="col-sm-12 col-md-10 col-lg-10">
      <h1>
        Edit Trading Standards
        <span :class="`dot${model.tradingStandardsRagRating}`"></span>
      </h1>

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <div v-if="successMessage" class="alert alert-success" role="alert">
        {{ successMessage }}
      </div>

      <confirm-dialog ref="usePrimaryBusinessAddress"></confirm-dialog>

      <div class="col-sm-12 mb-3">
        <div v-if="!model.renewalCreated" class="alert alert-success mb-1">
          <b
            >This is the most recent application for {{ model.registeredCompanyName }}
            {{ model.tmln }}</b
          >
        </div>
        <div class="alert alert-info mb-1">
          <b
            >Current status of this {{ model.renewalYear }} application is
            {{ model.tradingStandardsWorkflowStatus }}</b
          >
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center mb-4">
        <router-link
          v-if="model.yearNumber !== 0 && model.createdBy !== 'Migration'"
          class="btn btn-secondary"
          type="button"
          :to="previousYearRoute"
        >
          <span>Previous Year</span>
        </router-link>
        <router-link
          v-if="model.renewalCreated"
          class="btn btn-secondary"
          type="button"
          :to="nextYearRoute"
        >
          <span>Next Year</span>
        </router-link>
      </div>

      <card label="TrustMark Licenced Business">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <smart-input
              id="RegisteredCompanyName"
              v-model="model.registeredCompanyName"
              label="Registered Company Name"
              :is-required="!!v$.model.registeredCompanyName.isRequired"
              :validator="v$.model.registeredCompanyName"
              disabled="readonly"
            />
          </div>
          <div class="col-sm-12 col-md-6">
            <smart-input id="tmln" v-model="model.tmln" label="TMLN" disabled="readonly" />
            <div v-if="isLicenceViewer" class="text-right">
              <router-link :to="`/tmlns/${model.trustmarkBusinessId}`"
                >View Licence {{ model.tmln }}</router-link
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <smart-input
              id="RegisteredEmail"
              v-model="model.registeredEmail"
              label="Registered Email"
              :is-required="!!v$.model.registeredEmail.isRequired"
              :validator="v$.model.registeredEmail"
              :disabled="lockedYear"
            />
          </div>
          <div class="col-sm-12 col-md-6">
            <smart-input
              id="ApplicantEmail"
              v-model="model.applicantEmail"
              label="Applicant Email"
              :is-required="!!v$.model.applicantEmail.isRequired"
              :validator="v$.model.applicantEmail"
              :disabled="lockedYear"
            />
          </div>
        </div>
      </card>

      <card label="Responsible Person">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <smart-input
              id="ResponsiblePersonFirstName"
              v-model="model.responsiblePersonFirstName"
              label="First Name"
              :is-required="!!v$.model.responsiblePersonFirstName.isRequired"
              :validator="v$.model.responsiblePersonFirstName"
              :disabled="lockedYear"
            />
            <smart-input
              id="ResponsiblePersonLastName"
              v-model="model.responsiblePersonLastName"
              label="Last Name"
              :is-required="!!v$.model.responsiblePersonLastName.isRequired"
              :validator="v$.model.responsiblePersonLastName"
              :disabled="lockedYear"
            />
            <smart-input
              id="ResponsiblePersonEmail"
              v-model="model.responsiblePersonEmail"
              label="Email"
              :is-required="!!v$.model.responsiblePersonEmail.isRequired"
              :validator="v$.model.responsiblePersonEmail"
              :disabled="lockedYear"
            />
            <smart-input
              id="ResponsiblePersonTelephone"
              v-model="model.responsiblePersonTelephone"
              label="Telephone"
              :is-required="!!v$.model.responsiblePersonTelephone.isRequired"
              :validator="v$.model.responsiblePersonTelephone"
              :disabled="lockedYear"
            />
            <label for="responsiblePersonDOB" class="form-label">Date of Birth</label>
            <datepicker
              id="responsiblePersonDOB"
              v-model="model.responsiblePersonDOB"
              class="form-control"
              :style="lockedYearStyle"
              input-format="dd/MM/yyyy"
              :disabled="lockedYear"
            />
          </div>
          <div class="col-sm-12 col-md-6">
            <smart-input
              id="ResponsiblePersonAddressLine1"
              v-model="model.responsiblePersonAddressLine1"
              label="Address Line 1"
              :is-required="!!v$.model.responsiblePersonAddressLine1.isRequired"
              :validator="v$.model.responsiblePersonAddressLine1"
              :disabled="lockedYear"
            />
            <smart-input
              id="ResponsiblePersonAddressLine2"
              v-model="model.responsiblePersonAddressLine2"
              label="Address Line 2"
              :is-required="!!v$.model.responsiblePersonAddressLine2.isRequired"
              :validator="v$.model.responsiblePersonAddressLine2"
              :disabled="lockedYear"
            />
            <smart-input
              id="ResponsiblePersonAddressLine3"
              v-model="model.responsiblePersonAddressLine3"
              label="Address Line 3"
              :is-required="v$.model.responsiblePersonAddressLine3.isRequired"
              :validator="v$.model.responsiblePersonAddressLine3"
              :disabled="lockedYear"
            />
            <smart-input
              id="ResponsiblePersonAddressCity"
              v-model="model.responsiblePersonAddressCity"
              label="City"
              :is-required="!!v$.model.responsiblePersonAddressCity.isRequired"
              :validator="v$.model.responsiblePersonAddressCity"
              :disabled="lockedYear"
            />
            <smart-input
              id="ResponsiblePersonAddressCounty"
              v-model="model.responsiblePersonAddressCounty"
              label="County"
              :is-required="!!v$.model.responsiblePersonAddressCounty.isRequired"
              :validator="v$.model.responsiblePersonAddressCounty"
              :disabled="lockedYear"
            />
            <smart-input
              id="ResponsiblePersonAddressPostcode"
              v-model="model.responsiblePersonAddressPostcode"
              label="Postcode"
              :is-required="!!v$.model.responsiblePersonAddressPostcode.isRequired"
              :validator="v$.model.responsiblePersonAddressPostcode"
              :disabled="lockedYear"
            />
            <smart-input
              id="ResponsiblePersonAddressCountry"
              v-model="model.responsiblePersonAddressCountry"
              label="Country"
              :is-required="!!v$.model.responsiblePersonAddressCountry.isRequired"
              :validator="v$.model.responsiblePersonAddressCountry"
              :disabled="lockedYear"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6"></div>
          <div class="col-sm-12 col-md-6">
            <button
              type="button"
              class="btn btn-outline-secondary"
              @click="onUsePrimaryBusinessAddress"
            >
              Use Primary Business Address
            </button>
          </div>
        </div>
      </card>

      <card label="Application">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-label">
              <key-value-presenter
                label="Orignal Application Date"
                :value="$helpers.dateTimeString(model.applicationDate)"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <smart-input
              id="renewalYear"
              v-model="model.renewalYear"
              label="Year"
              disabled="readonly"
            />
          </div>
          <div class="col-sm-12 col-md-4">
            <smart-input
              id="yearNumber"
              v-model="model.yearNumber"
              label="Year Number"
              :disabled="
                model.createdBy === 'Migration' && !model.renewalCreated ? null : 'readonly'
              "
            />
          </div>
          <div class="col-sm-12 col-md-4">
            <smart-select-text-value-numeric
              id="fee"
              v-model="model.fee"
              label="Fee (£)"
              :options="feeOptions"
              :is-required="!!v$.model.fee.isRequired"
              :validator="v$.model.fee"
              :disabled="lockedYear"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <div class="form-group">
              <label for="renewalEmailSentDate" class="form-label">Proceed Email Sent Date</label>
              <div class="input-group">
                <datepicker
                  id="renewalEmailSentDate"
                  v-model="model.renewalEmailSentDate"
                  class="form-control"
                  :style="lockedYearStyle"
                  input-format="dd/MM/yyyy"
                  :disabled="lockedYear"
                />
                <button
                  v-if="!lockedYear"
                  class="btn btn-outline-secondary"
                  type="button"
                  @click="model.renewalEmailSentDate = null"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <label for="renewalChaserEmailSentDate" class="form-label"
              >Chaser Email Sent Date</label
            >
            <div class="input-group">
              <datepicker
                id="renewalChaserEmailSentDate"
                v-model="model.renewalChaserEmailSentDate"
                class="form-control"
                :style="lockedYearStyle"
                input-format="dd/MM/yyyy"
                :disabled="lockedYear"
              />
              <button
                v-if="!lockedYear"
                class="btn btn-outline-secondary"
                type="button"
                @click="model.renewalChaserEmailSentDate = null"
              >
                Clear
              </button>
            </div>
            <small
              >Automatcailly sent 14 days after the Proceed Email Sent Date and will close if no
              confirmation after a further 7 days.</small
            >
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="form-group">
              <label for="confirmationProceedDate" class="form-label"
                >Confirmation Proceed Date</label
              >
              <div class="input-group">
                <datepicker
                  id="confirmationProceedDate"
                  v-model="model.confirmationProceedDate"
                  class="form-control"
                  :style="lockedYearStyle"
                  input-format="dd/MM/yyyy"
                  :disabled="lockedYear"
                />
                <button
                  v-if="!lockedYear"
                  class="btn btn-outline-secondary"
                  type="button"
                  @click="model.confirmationProceedDate = null"
                >
                  Clear
                </button>
              </div>
            </div>
            <small
              >Once confirmed the application is status is Applied. Reinstate a Closed application
              using this field.</small
            >
          </div>
        </div>
      </card>

      <card label="Issue Invoice Stage">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <smart-select-binary
              id="tradingStandardsInvoiceIssued"
              v-model="model.tradingStandardsInvoiceIssued"
              label="Trading Standards Invoice Issued"
              :is-required="!!v$.model.tradingStandardsInvoiceIssued"
              :validator="v$.model.tradingStandardsInvoiceIssued"
              :disabled="lockedYear"
            />
          </div>
          <div v-if="model.tradingStandardsInvoiceIssued" class="col-sm-12 col-md-4">
            <smart-input
              id="TradingStandardsInvoiceReference"
              v-model="model.tradingStandardsInvoiceReference"
              label="Trading Standards Invoice Reference"
              :is-required="!!v$.model.tradingStandardsInvoiceReference"
              :validator="v$.model.tradingStandardsInvoiceReference"
              :disabled="lockedYear"
            />
          </div>
          <div v-if="model.tradingStandardsInvoiceIssued" class="col-sm-12 col-md-4">
            <label for="tradingStandardsInvoiceDate" class="form-label"
              >Trading Standards Invoice Date *</label
            >
            <div class="input-group">
              <datepicker
                id="tradingStandardsInvoiceDate"
                v-model="model.tradingStandardsInvoiceDate"
                class="form-control"
                :class="
                  v$.model.tradingStandardsInvoiceDate &&
                  v$.model.tradingStandardsInvoiceDate.$error
                    ? 'is-invalid'
                    : ''
                "
                :style="lockedYearStyle"
                input-format="dd/MM/yyyy"
                :disabled="lockedYear"
              />
              <button
                v-if="!lockedYear"
                class="btn btn-outline-secondary"
                type="button"
                @click="model.tradingStandardsInvoiceDate = null"
              >
                Clear
              </button>
            </div>
            <div
              v-if="v$.model.tradingStandardsInvoiceDate.$error"
              class="invalid-feedback mb-3"
              style="display: block"
            >
              {{ v$.model.tradingStandardsInvoiceDate.$errors[0].$message }}
            </div>
          </div>
        </div>
      </card>

      <card label="Invoice Paid Stage">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <smart-select-binary
              id="tradingStandardsInvoicePaid"
              v-model="model.tradingStandardsInvoicePaid"
              label="Trading Standards Invoice Paid"
              :is-required="!!v$.model.tradingStandardsInvoicePaid"
              :validator="v$.model.tradingStandardsInvoicePaid"
              :disabled="lockedYear"
            />
          </div>
          <div v-if="model.tradingStandardsInvoicePaid" class="col-sm-12 col-md-4">
            <label for="tradingStandardsInvoicePaidDate" class="form-label"
              >Trading Standards Invoice Paid Date *</label
            >
            <div class="input-group">
              <datepicker
                id="TradingStandardsInvoicePaidDate"
                v-model="model.tradingStandardsInvoicePaidDate"
                class="form-control"
                :class="
                  v$.model.tradingStandardsInvoicePaidDate &&
                  v$.model.tradingStandardsInvoicePaidDate.$error
                    ? 'is-invalid'
                    : ''
                "
                :style="lockedYearStyle"
                input-format="dd/MM/yyyy"
                :disabled="lockedYear"
              />
              <button
                v-if="!lockedYear"
                class="btn btn-outline-secondary"
                type="button"
                @click="model.tradingStandardsInvoicePaidDate = null"
              >
                Clear
              </button>
            </div>
            <div
              v-if="v$.model.tradingStandardsInvoicePaidDate.$error"
              class="invalid-feedback mb-3"
              style="display: block"
            >
              {{ v$.model.tradingStandardsInvoicePaidDate.$errors[0].$message }}
            </div>
          </div>
        </div>
      </card>

      <card v-if="model.tradingStandardsInvoicePaid" label="Trading Standards Stage">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <label for="tradingStandardsNotifiedDate" class="form-label"
              >Trading Standards Notified Date</label
            >
            <div class="input-group">
              <datepicker
                id="tradingStandardsNotifiedDate"
                v-model="model.tradingStandardsNotifiedDate"
                class="form-control"
                :style="lockedYearStyle"
                input-format="dd/MM/yyyy"
                :disabled="lockedYear"
              />
              <button
                v-if="!lockedYear"
                class="btn btn-outline-secondary"
                type="button"
                @click="model.tradingStandardsNotifiedDate = null"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <card label="Trading Standards Intel" class="mt-4">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <label for="tradingStandardsRagRating" class="form-label">RAG Rating</label>
              <div class="form-input">
                <p
                  v-if="model.tradingStandardsRagRating"
                  :class="`dot${model.tradingStandardsRagRating}`"
                ></p>
                <p v-if="!model.tradingStandardsRagRating">
                  Waiting for Trading Standards to submit.
                </p>
              </div>
            </div>
            <div v-if="model.tradingStandardsRagRating" class="col-sm-12 col-md-6">
              <label class="form-label">Intel Approved</label>
              <p>{{ model.intelApproved ? 'Yes' : 'No' }}</p>
            </div>
          </div>
          <div v-if="model.tradingStandardsRagRating" class="row">
            <div class="col-sm-12">
              <label class="form-label">Comments Provided</label>
              <p>{{ model.tradingStandardsNotes }}</p>
              <p v-if="!model.tradingStandardsNotes">
                <i>No comments provided by Trading Standards</i>
              </p>
            </div>
          </div>
          <div v-if="model.tradingStandardsRagRating" class="row">
            <div class="col-sm-6">
              <label class="form-label">Dated</label>
              <p>{{ $helpers.dateTimeString(model.intelDate) }}</p>
            </div>
            <div class="col-sm-6">
              <label class="form-label">Provided By</label>
              <p>{{ model.intelUsername }}</p>
            </div>
          </div>
        </card>
        <div class="row">
          <div v-if="canIssueCertificate" class="col-sm-12 col-md-6">
            <smart-select-binary
              id="certificateIssued"
              v-model="model.certificateIssued"
              label="Certificate Issued"
              :is-required="!!v$.model.certificateIssued"
              :validator="v$.model.certificateIssued"
              :disabled="lockedYear"
            />
          </div>
        </div>
      </card>

      <card label="Next Renewal">
        <div class="row">
          <div class="col-sm-12 col-md-3">
            <smart-select-binary
              id="notRenewing"
              v-model="model.notRenewing"
              label="Not Renewing"
              :is-required="!!v$.model.notRenewing"
              :validator="v$.model.notRenewing"
              :disabled="lockedYear"
            />
          </div>
          <div v-if="!model.notRenewing" class="col-sm-12 col-md-6">
            <label for="nextRenewalDate" class="form-label">Renewal Date</label>
            <div class="input-group">
              <datepicker
                id="renewalDate"
                v-model="model.nextRenewalDate"
                class="form-control"
                :class="
                  v$.model.nextRenewalDate && v$.model.nextRenewalDate.$error ? 'is-invalid' : ''
                "
                :style="lockedYearStyle"
                input-format="dd/MM/yyyy"
                :disabled="lockedYear"
              />
              <button
                v-if="!lockedYear"
                class="btn btn-outline-secondary"
                type="button"
                @click="model.nextRenewalDate = null"
              >
                Clear
              </button>
            </div>
            <div
              v-if="v$.model.renewalDate.$error"
              class="invalid-feedback mb-3"
              style="display: block"
            >
              {{ v$.model.nextRenewalDate.$errors[0].$message }}
            </div>
          </div>
        </div>
      </card>

      <card label="Status">
        <p>
          Suggested Status is <b>{{ suggestedStatus }}</b>
        </p>
        <smart-select
          id="modelTradingStandardsWorkflowStatus"
          v-model="model.tradingStandardsWorkflowStatus"
          label="Trading Standards Workflow Status"
          :is-required="!!v$.model.tradingStandardsWorkflowStatus"
          :validator="v$.model.tradingStandardsWorkflowStatus"
          :options="tradingStandardsWorkflowStatuses"
          :disabled="lockedYear"
        />

        <small v-if="!lockedYear">Triggers an email</small>

        <div class="row">
          <div class="col-sm-12">
            <div
              v-if="
                model.tradingStandardsWorkflowStatus === 'Approved' &&
                model.originalStatus !== 'Approved'
              "
              class="alert alert-info mb-5"
            >
              Warning: This will set the business as Trading Standards Approved
            </div>
          </div>
          <div class="col-sm-12">
            <div
              v-if="
                model.originalStatus === 'Approved' &&
                model.tradingStandardsWorkflowStatus !== 'Approved'
              "
              class="alert alert-danger mb-5"
            >
              Warning: This will remove the business as Trading Standards Approved
            </div>
          </div>
        </div>
      </card>

      <card label="Internal Comments">
        <div class="row">
          <div class="col-sm-12">
            <smart-textarea
              id="internalComments"
              v-model="model.internalComments"
              style="min-height: 200px"
              :validator="v$.model.internalComments"
              :disabled="lockedYear"
            />
            <small>These can only be seen internally by TrustMark</small>
          </div>
        </div>
      </card>

      <card v-if="!model.activity || model.activity.length == 0" label="Activity">
        <p>No activity</p>
      </card>
      <card v-if="model.activity && model.activity.length > 0" label="Activity">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Date Time</th>
              <th scope="col">User</th>
              <th scope="col">Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in activityPage" :key="i">
              <td>
                {{ $helpers.dateTimeString(item.dateTimeStamp) }}
              </td>
              <td>
                {{ item.username }}
              </td>
              <td>
                {{ item.description }}
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="...">
          <ul class="pagination pagination-sm">
            <li
              v-for="i in totalPages"
              :key="i"
              class="page-item"
              :class="i === pageNumber ? 'active' : ''"
              aria-current="page"
            >
              <span class="page-link pointer" @click="pageNumber = i">{{ i }}</span>
            </li>
          </ul>
        </nav>
      </card>

      <div class="form-group d-grid gap-2 d-md-flex justify-content-md-end">
        <router-link class="btn btn-secondary float-right btn-lg" type="button" :to="parentRoute">
          <span>Cancel</span>
        </router-link>

        <smart-submit-large v-if="!lockedYear" label="Save" :loading="loading" @click="save" />
      </div>
    </div>
  </div>
  <div style="height: 250px"></div>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import {
  SmartSubmitLarge,
  Card,
  SmartSelectBinary,
  SmartSelect,
  SmartInput,
  SmartTextarea,
  KeyValuePresenter,
  SmartSelectTextValueNumeric,
  isPhoneNumber,
  ConfirmDialog,
  confirmType,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import useValidate from '@vuelidate/core';
import { helpers, required, requiredIf, maxLength, numeric } from '@vuelidate/validators';
import { formatDate, age } from '../../services/scheme-common';

export default {
  name: 'TradingStandardsYearView',
  components: {
    SmartSubmitLarge,
    SmartSelectBinary,
    Card,
    SmartSelect,
    SmartInput,
    Datepicker,
    SmartTextarea,
    KeyValuePresenter,
    SmartSelectTextValueNumeric,
    ConfirmDialog,
  },
  data() {
    return {
      v$: useValidate(),
      successMessage: null,
      errorMessage: null,
      model: {
        tradingStandardsWorkflowStatus: null,
        originalStatus: null,
        intelApproved: null,
        tradingStandardsInvoiceIssued: null,
        tradingStandardsInvoiceDate: null,
        tradingStandardsInvoiceReference: null,
        tradingStandardsInvoicePaid: null,
        tradingStandardsInvoicePaidDate: null,
        applicationReference: null,
        tradingStandardsNotifiedDate: null,
        confirmationProceedDate: null,
      },
      feeOptions: [
        { text: '0.00', value: 0 },
        { text: '65.00', value: 65 },
        { text: '90.00', value: 90 },
      ],
      pageSize: 5,
      pageNumber: 1,
    };
  },
  computed: {
    parentRoute() {
      return `/tradingstandards`;
    },
    previousYearRoute() {
      return `/tradingstandards/${this.model.tmln}/${
        parseInt(this.$route.params.renewalYear, 10) - 1
      }`;
    },
    nextYearRoute() {
      return `/tradingstandards/${this.model.tmln}/${
        parseInt(this.$route.params.renewalYear, 10) + 1
      }`;
    },
    lockedYear() {
      return this.model.renewalCreated ? 'disabled' : null;
    },
    lockedYearStyle() {
      return !this.lockedYear ? 'background: white' : null;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isTradingStandards() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('TradingStandards')
        : false;
    },
    isLicenceViewer() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('LicenceViewer')
        : false;
    },
    requiresTradingStandardsInvoicePaidDate() {
      return (
        this.model &&
        this.model.tradingStandardsInvoicePaid &&
        !this.model.tradingStandardsInvoicePaidDate
      );
    },
    requiresTradingStandardsInvoiceDate() {
      return (
        this.model &&
        this.model.tradingStandardsInvoiceIssued &&
        !this.model.tradingStandardsInvoiceDate
      );
    },
    requiresTradingStandardsInvoiceReference() {
      return (
        this.model &&
        this.model.tradingStandardsInvoiceIssued &&
        (!this.model.tradingStandardsInvoiceReference ||
          !this.model.tradingStandardsInvoiceReference.length)
      );
    },
    tradingStandardsWorkflowStatuses() {
      return this.$store.state.auth.appData.tradingStandardsWorkflowStatuses;
    },
    canIssueCertificate() {
      return (
        this.model.tradingStandardsRagRating === 'Green' ||
        this.model.tradingStandardsRagRating === 'Amber'
      );
    },
    suggestedStatus() {
      if (this.model.tradingStandardsRagRating === 'Red') {
        return 'Declined';
      }
      if (
        (this.model.tradingStandardsRagRating === 'Green' ||
          this.model.tradingStandardsRagRating === 'Amber') &&
        this.model.certificateIssued
      ) {
        return 'Approved';
      }
      if (this.model.tradingStandardsRagRating) {
        return 'Trading Standards Response Received';
      }
      if (this.model.tradingStandardsNotifiedDate) {
        return 'With Trading Standards';
      }
      if (this.model.tradingStandardsInvoicePaid) {
        return 'Invoice Paid';
      }
      if (this.model.tradingStandardsInvoiceIssued) {
        return 'Invoice Raised';
      }
      if (this.model.confirmationProceedDate) {
        return 'Applied';
      }
      if (this.model.applicationDate) {
        return 'Pending';
      }
      return null;
    },
    activityPage() {
      return this.model.activity.slice(
        (this.pageNumber - 1) * this.pageSize,
        this.pageNumber * this.pageSize
      );
    },
    totalPages() {
      return Math.ceil(this.model.activity.length / this.pageSize);
    },
  },
  watch: {
    $route() {
      if (this.$route.params.renewalYear) {
        this.loadData();
      }
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatDate,
    age,
    onUsePrimaryBusinessAddress() {
      this.$refs.usePrimaryBusinessAddress
        .show({
          title: 'Use Primary Business Address',
          message: 'Are you sure you want to use the primary business address?',
          confirmType: confirmType.standard,
          okButtonText: 'Yes',
        })
        .then((r) => {
          if (r) {
            this.loading = true;
            this.$store
              .dispatch('licenceNumber/getById', this.model.trustmarkBusinessId)
              .then((resp) => {
                this.loading = false;
                if (resp.model && resp.model.primaryAddress1) {
                  this.model.responsiblePersonAddressLine1 = resp.model.primaryAddress1;
                  this.model.responsiblePersonAddressLine2 = resp.model.primaryAddress2;
                  this.model.responsiblePersonAddressLine3 = '';
                  this.model.responsiblePersonAddressCity = resp.model.primaryTown;
                  this.model.responsiblePersonAddressCounty = resp.model.primaryCounty;
                  this.model.responsiblePersonAddressPostcode = resp.model.primaryPostCode;
                  this.model.responsiblePersonAddressCountry = resp.model.primaryCountry;
                } else {
                  window.scrollTo(0, 0);
                  this.errorMessage = 'No primary address found';
                }
              });
          }
        });
    },
    loadData() {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
      if (
        !this.model ||
        this.model.tmln !== this.$route.params.tmln ||
        this.model.renewalYear !== this.$route.params.renewalYear
      ) {
        const request = {
          tmln: this.$route.params.tmln,
          renewalYear: this.$route.params.renewalYear,
        };
        this.$store
          .dispatch('tradingStandardsAdmin/getTradingStandardsYear', request)
          .then((resp) => {
            this.model = resp.model;
            this.model.originalStatus = this.model.tradingStandardsWorkflowStatus;
            if (this.model.tradingStandardsInvoicePaidDate !== null) {
              this.model.tradingStandardsInvoicePaidDate = new Date(
                this.model.tradingStandardsInvoicePaidDate
              );
            }
            if (this.model.tradingStandardsInvoiceDate !== null) {
              this.model.tradingStandardsInvoiceDate = new Date(
                this.model.tradingStandardsInvoiceDate
              );
            }
            if (this.model.tradingStandardsNotifiedDate !== null) {
              this.model.tradingStandardsNotifiedDate = new Date(
                this.model.tradingStandardsNotifiedDate
              );
            }
            if (this.model.renewalEmailSentDate !== null) {
              this.model.renewalEmailSentDate = new Date(this.model.renewalEmailSentDate);
            }
            if (this.model.confirmationProceedDate !== null) {
              this.model.confirmationProceedDate = new Date(this.model.confirmationProceedDate);
            }
            if (this.model.renewalChaserEmailSentDate !== null) {
              this.model.renewalChaserEmailSentDate = new Date(
                this.model.renewalChaserEmailSentDate
              );
            }
            if (this.model.renewalDate !== null) {
              this.model.renewalDate = new Date(this.model.renewalDate);
            }
            if (this.model.nextRenewalDate !== null) {
              this.model.nextRenewalDate = new Date(this.model.nextRenewalDate);
            }
            if (this.model.responsiblePersonDOB !== null) {
              this.model.responsiblePersonDOB = new Date(this.model.responsiblePersonDOB);
            }
          });
      }
    },
    save() {
      this.errorMessage = null;
      this.successMessage = null;

      this.v$.$validate();
      if (this.v$.$error) {
        window.scrollTo(0, 0);
        this.errorMessage =
          'Unable to save Trading Standards Application. Please resolve any errors on this form and try again.';
        return;
      }

      const payload = JSON.parse(JSON.stringify(this.model));
      payload.tradingStandardsInvoicePaidDate = this.$helpers.dateForApi(
        payload.tradingStandardsInvoicePaidDate
      );
      payload.tradingStandardsInvoiceDate = this.$helpers.dateForApi(
        payload.tradingStandardsInvoiceDate
      );
      payload.tradingStandardsNotifiedDate = this.$helpers.dateForApi(
        payload.tradingStandardsNotifiedDate
      );
      payload.renewalEmailSentDate = this.$helpers.dateForApi(payload.renewalEmailSentDate);
      payload.confirmationProceedDate = this.$helpers.dateForApi(payload.confirmationProceedDate);
      payload.renewalChaserEmailSentDate = this.$helpers.dateForApi(
        payload.renewalChaserEmailSentDate
      );
      payload.renewalDate = this.$helpers.dateForApi(payload.renewalDate);
      payload.nextRenewalDate = this.$helpers.dateForApi(payload.nextRenewalDate);
      payload.responsiblePersonDOB = this.$helpers.dateForApi(payload.responsiblePersonDOB);
      this.$store
        .dispatch('tradingStandardsAdmin/updateTradingStandardsYear', payload)
        .then(() => {
          this.successMessage = 'Changes saved';
          this.model.originalStatus = this.model.tradingStandardsWorkflowStatus;
          this.$store.dispatch('tradingStandardsAdmin/listCurrent').then(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
          });
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        });
    },
  },
  validations() {
    return {
      model: {
        registeredCompanyName: {
          isRequired: helpers.withMessage('Registered Company Name is required', required),
          maxLength: maxLength(200),
        },
        registeredEmail: {
          isRequired: helpers.withMessage('Registered Email is required', required),
          maxLength: maxLength(200),
        },
        applicantEmail: {
          maxLength: maxLength(100),
        },
        responsiblePersonFirstName: {
          isRequired: helpers.withMessage('First Name is required', required),
          maxLength: maxLength(100),
        },
        responsiblePersonLastName: {
          isRequired: helpers.withMessage('Last Name is required', required),
          maxLength: maxLength(100),
        },
        responsiblePersonEmail: {
          isRequired: helpers.withMessage('Email is required', required),
          maxLength: maxLength(200),
        },
        responsiblePersonTelephone: {
          isRequired: helpers.withMessage('Telephone is required', required),
          maxLength: maxLength(100),
          isPhoneNumber: helpers.withMessage(
            'Phone number must be in a valid format',
            isPhoneNumber
          ),
        },
        responsiblePersonAddressLine1: {
          maxLength: maxLength(100),
        },
        responsiblePersonAddressLine2: {
          maxLength: maxLength(100),
        },
        responsiblePersonAddressLine3: {
          maxLength: maxLength(100),
        },
        responsiblePersonAddressCity: {
          maxLength: maxLength(100),
        },
        responsiblePersonAddressCounty: {
          maxLength: maxLength(100),
        },
        responsiblePersonAddressPostcode: {
          maxLength: maxLength(100),
        },
        responsiblePersonAddressCountry: {
          maxLength: maxLength(100),
        },
        tradingStandardsWorkflowStatus: {
          isRequired: helpers.withMessage('Wokflow Status is required', required),
        },
        tradingStandardsInvoiceDate: {
          required: helpers.withMessage(
            'Invoice Date is requried',
            requiredIf(() => this.model.tradingStandardsInvoiceIssued)
          ),
        },
        tradingStandardsInvoiceReference: {
          required: helpers.withMessage(
            'Invoice Reference is requried',
            requiredIf(() => this.model.tradingStandardsInvoiceIssued)
          ),
        },
        tradingStandardsInvoicePaidDate: {
          required: helpers.withMessage(
            'Invoice Paid Date is requried',
            requiredIf(() => this.model.tradingStandardsInvoicePaid)
          ),
        },
        tradingStandardsInvoiceIssued: {},
        tradingStandardsInvoicePaid: {},
        intelApproved: {},
        internalComments: {
          maxLength: maxLength(2000),
        },
        renewalDate: {
          required: requiredIf(() => this.yearNumber > 0),
        },
        certificateIssued: {},
        fee: {
          isRequired: helpers.withMessage('Fee is required', required),
          numeric: helpers.withMessage('Fee must be numeric', numeric),
        },
        notRenewing: {},
        renewalEmailSentDate: {},
        nextRenewalDate: {
          required: requiredIf(() => this.yearNumber > 0),
        },
      },
    };
  },
};
</script>
<style>
.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.dotGreen {
  height: 25px;
  width: 25px;
  background-color: rgb(36, 211, 59);
  border-radius: 50%;
  display: inline-block;
}

.dotAmber {
  height: 25px;
  width: 25px;
  background-color: rgb(228, 222, 38);
  border-radius: 50%;
  display: inline-block;
}

.dotRed {
  height: 25px;
  width: 25px;
  background-color: rgb(211, 62, 36);
  border-radius: 50%;
  display: inline-block;
}
</style>
