import { HTTP, authHeader } from './http-service';

class RegistrationBillingService {
  listBySchemeId(schemeId) {
    return HTTP.get(`/schemeregistrationbilling/scheme/${schemeId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  listByPeriod(period) {
    return HTTP.get(`/schemeregistrationbilling/period/${period}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  get(schemeId, period) {
    return HTTP.get(`/schemeregistrationbilling/scheme/${schemeId}/period/${period}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/schemeregistrationbilling`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new RegistrationBillingService();
