import { HTTP_RETROFIT, HTTP, authHeader } from './http-service';

class DisputesService {
  getProject(id) {
    return HTTP_RETROFIT.get(`/disputes/project/redirect/${id}`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  authorise(payload) {
    return HTTP.post(`/auth/retrofit?k=${payload.token}`).then((response) => {
      return response.data;
    });
  }
}

export default new DisputesService();
