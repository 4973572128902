import DisputeNoteService from '../services/dispute-note-service';

export const disputeNote = {
  namespaced: true,
  state: {
    notes: null,
    note: null,
  },
  actions: {
    newNote({ commit }, disputeId) {
      const template = {
        note: '',
      };
      const empty = JSON.parse(JSON.stringify(template));
      empty.disputeId = disputeId;
      commit('setNote', empty);
    },
    listNotes({ commit }, disputeId) {
      commit('setIsBusy', true, { root: true });
      return DisputeNoteService.list(disputeId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setNotes', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    createNote({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return DisputeNoteService.create(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    getNote({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      commit('setNote', null);
      return DisputeNoteService.get(payload.disputeId, payload.disputeInternalNoteId).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setNote', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateNote({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return DisputeNoteService.update(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    deleteNote({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return DisputeNoteService.delete(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setNotes(state, newNotes) {
      state.notes = newNotes;
    },
    setNote(state, newNote) {
      state.note = newNote;
    },
  },
};
