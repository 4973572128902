<template>
  <div>
    <div class="tab-container">
      <header class="d-flex justify-content-center py-3">
        <ul class="nav nav-pills nav-fill">
          <li class="nav-item">
            <router-link :to="`${parentRoute}`" class="nav-link" :class="endsWith('edit')"
              >Details
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`${parentRoute}/contacts`"
              class="nav-link"
              :class="includes('edit/contacts')"
              >Contacts
            </router-link>
          </li>
          <li v-if="isSchemeUserViewer" class="nav-item">
            <router-link
              :to="`${parentRoute}/users`"
              class="nav-link"
              :class="includes('edit/users')"
              >User Logins
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`${parentRoute}/notes`"
              class="nav-link"
              :class="includes('edit/notes')"
              >Notes
            </router-link>
          </li>
          <li v-if="isSchemeTradesEditor" class="nav-item">
            <router-link
              :to="`${parentRoute}/scheme-trades`"
              class="nav-link"
              :class="includes('edit/scheme-trades')"
              >Scheme Trades
            </router-link>
          </li>
          <li v-if="isSchemeEditorAdvanced" class="nav-item">
            <router-link
              :to="`${parentRoute}/advanced`"
              class="nav-link"
              :class="includes('advanced')"
              >Advanced
            </router-link>
          </li>
        </ul>
      </header>
    </div>
    <div class="p-3"></div>
  </div>
</template>

<script>
export default {
  name: 'SchemesEditTabs',
  computed: {
    parentRoute() {
      return `/scheme/${this.$route.params.schemeId}/edit`;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isSchemeEditorAdvanced() {
      if (this.currentAccount && this.currentAccount.roles) {
        return this.currentAccount.roles.includes('SchemeEditorAdvanced');
      }
      return false;
    },
    isSchemeUserAdmin() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('SchemeUserAdmin')
        : false;
    },
    isSchemeUserViewer() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('SchemeUserViewer')
        : false;
    },
    isSchemeTradesEditor() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('SchemeTradesEditor')
        : false;
    },
  },
  methods: {
    includes(str) {
      return this.$route.path.includes(str) ? 'active' : '';
    },
    endsWith(str) {
      return this.$route.path.endsWith(str) ? 'active' : '';
    },
  },
};
</script>
