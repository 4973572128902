<template>
  <div v-if="model" class="row">
    <div class="col-sm-12 col-md-10 col-lg-10">
      <subtitle>
        Provide Intel
        <span :class="`dot${model.tradingStandardsRagRating}`"></span>
      </subtitle>

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <div v-if="successMessage" class="alert alert-success" role="alert">
        {{ successMessage }}
      </div>

      <card label="Application">
        <div v-if="model.responsiblePersonChanged" class="col-sm-12">
          <div class="alert alert-info mb-5">
            <b>The responsible person has changed for this year.</b>
          </div>
        </div>

        <card label="TrustMark Licenced Business">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <smart-input
                id="RegisteredCompanyName"
                v-model="model.registeredCompanyName"
                label="Registered Company Name"
                disabled="readonly"
              />
            </div>
            <div class="col-sm-12 col-md-6">
              <smart-input id="tmln" v-model="model.tmln" label="TMLN" disabled="readonly" />
              <div v-if="isLicenceViewer" class="text-right">
                <a :href="model.trustmarkWebsiteUrl" target="_blank" rel="noopener noreferrer"
                  >View on trustmark.org.uk</a
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <smart-input
                id="RegisteredEmail"
                v-model="model.registeredEmail"
                label="Registered Email"
                disabled="readonly"
              />
            </div>
            <div class="col-sm-12 col-md-6">
              <smart-input
                id="ApplicantEmail"
                v-model="model.applicantEmail"
                label="Applicant Email"
                disabled="readonly"
              />
            </div>
          </div>
        </card>

        <card label="Responsible Person">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <smart-input
                id="ResponsiblePersonFirstName"
                v-model="model.responsiblePersonFirstName"
                label="First Name"
                disabled="readonly"
              />
              <smart-input
                id="ResponsiblePersonLastName"
                v-model="model.responsiblePersonLastName"
                label="Last Name"
                disabled="readonly"
              />
              <smart-input
                id="ResponsiblePersonEmail"
                v-model="model.responsiblePersonEmail"
                label="Email"
                disabled="readonly"
              />
              <smart-input
                id="ResponsiblePersonTelephone"
                v-model="model.responsiblePersonTelephone"
                label="Telephone"
                disabled="readonly"
              />
              <smart-input
                id="responsiblePersonDOB"
                v-model="model.responsiblePersonDOBString"
                label="Date of Birth"
                disabled="readonly"
              />
            </div>
            <div class="col-sm-12 col-md-6">
              <smart-input
                id="ResponsiblePersonAddressLine1"
                v-model="model.responsiblePersonAddressLine1"
                label="Address Line 1"
                disabled="readonly"
              />
              <smart-input
                id="ResponsiblePersonAddressLine2"
                v-model="model.responsiblePersonAddressLine2"
                label="Address Line 2"
                disabled="readonly"
              />
              <smart-input
                id="ResponsiblePersonAddressLine3"
                v-model="model.responsiblePersonAddressLine3"
                label="Address Line 3"
                disabled="readonly"
              />
              <smart-input
                id="ResponsiblePersonAddressCity"
                v-model="model.responsiblePersonAddressCity"
                label="City"
                disabled="readonly"
              />
              <smart-input
                id="ResponsiblePersonAddressCounty"
                v-model="model.responsiblePersonAddressCounty"
                label="County"
                disabled="readonly"
              />
              <smart-input
                id="ResponsiblePersonAddressPostcode"
                v-model="model.responsiblePersonAddressPostcode"
                label="Postcode"
                disabled="readonly"
              />
              <smart-input
                id="ResponsiblePersonAddressCountry"
                v-model="model.responsiblePersonAddressCountry"
                label="Country"
                disabled="readonly"
              />
            </div>
          </div>
        </card>

        <card label="Application Dates">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-label">
                <key-value-presenter
                  label="Orignal Application Date"
                  :value="$helpers.dateTimeString(model.applicationDate)"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-4">
              <smart-input
                id="renewalYear"
                v-model="model.renewalYear"
                label="Renewal Year"
                disabled="readonly"
              />
            </div>
            <div class="col-sm-12 col-md-4">
              <smart-input
                id="yearNumber"
                v-model="model.yearNumber"
                label="Year Number"
                disabled="readonly"
              />
            </div>
          </div>
        </card>
      </card>

      <card label="Provide Intel">
        <card label="Trading Standards Result">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <smart-select-key-value
                id="tradingStandardsRagRating"
                v-model="model.tradingStandardsRagRating"
                label="RAG Rating"
                :is-required="false"
                :options="ragRatings"
                :validator="v$.model.tradingStandardsRagRating"
              />
            </div>
          </div>
          <div v-if="model.tradingStandardsRagRating" class="row">
            <div class="col-sm-12 col-md-6">
              <smart-select-binary
                id="intelApproved"
                v-model="model.intelApproved"
                label="Intel Approved"
                :validator="v$.model.intelApproved"
              />
            </div>
          </div>
        </card>

        <card label="Trading Standards Comments">
          <div class="row">
            <div class="col-sm-12">
              <smart-textarea
                id="tradingStandardsNotes"
                v-model="model.tradingStandardsNotes"
                :validator="v$.model.tradingStandardsNotes"
                style="min-height: 200px"
              />
            </div>
          </div>
        </card>
      </card>

      <div class="form-group d-grid gap-2 d-md-flex justify-content-md-end">
        <router-link class="btn btn-secondary float-right btn-lg" type="button" :to="parentRoute">
          <span>Cancel</span>
        </router-link>

        <smart-submit-large label="Save" :loading="loading" @click="save" />
      </div>
    </div>
  </div>
  <div style="height: 250px"></div>
</template>

<script>
import {
  SmartSubmitLarge,
  Card,
  Subtitle,
  SmartSelectBinary,
  SmartInput,
  SmartSelectKeyValue,
  SmartTextarea,
  KeyValuePresenter,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import useValidate from '@vuelidate/core';
import { formatDate, age } from '../../services/scheme-common';

export default {
  name: 'TradingStandardsFeedbackEditView',
  components: {
    SmartSubmitLarge,
    SmartSelectBinary,
    Card,
    Subtitle,
    SmartSelectKeyValue,
    SmartInput,
    SmartTextarea,
    KeyValuePresenter,
  },
  data() {
    return {
      v$: useValidate(),
      successMessage: null,
      errorMessage: null,
      model: {
        tradingStandardsWorkflowStatus: null,
        originalStatus: null,
        intelApproved: null,
        tradingStandardsInvoiceIssued: null,
        tradingStandardsInvoiceDate: null,
        tradingStandardsInvoiceReference: null,
        tradingStandardsInvoicePaid: null,
        tradingStandardsInvoicePaidDate: null,
        applicationReference: null,
        tradingStandardsNotifiedDate: null,
        confirmationProceedDate: null,
      },
      feeOptions: [
        { text: '55.00', value: 55 },
        { text: '90.00', value: 90 },
      ],
      ragRatings: [
        { text: '', value: null },
        { text: 'Red', value: 'Red' },
        { text: 'Amber', value: 'Amber' },
        { text: 'Green', value: 'Green' },
      ],
    };
  },
  computed: {
    parentRoute() {
      return `/trading-standards-feedback`;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isTradingStandards() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('TradingStandards')
        : false;
    },
    isLicenceViewer() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('LicenceViewer')
        : false;
    },
    data() {
      return this.$store.state.tradingStandardsFeedback.data;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    if (!this.data) {
      this.loading = true;
      this.$store.dispatch('tradingStandardsFeedback/actionable').then(() => {
        this.loading = false;
        this.model = this.data.find(
          (x) =>
            x.tmln === this.$route.params.tmln &&
            x.renewalYear === parseInt(this.$route.params.renewalYear, 10)
        );
        this.model.responsiblePersonDOBString = this.$helpers.dateString(
          this.model.responsiblePersonDOB
        );
      });
    } else {
      this.loading = false;
      this.model = this.data.find(
        (x) =>
          x.tmln === this.$route.params.tmln &&
          x.renewalYear === parseInt(this.$route.params.renewalYear, 10)
      );
      this.model.responsiblePersonDOBString = this.$helpers.dateString(
        this.model.responsiblePersonDOB
      );
    }
  },
  methods: {
    formatDate,
    age,
    save() {
      this.errorMessage = null;
      this.successMessage = null;

      this.v$.$validate();
      if (this.v$.$error) {
        window.scrollTo(0, 0);
        this.errorMessage =
          'Unable to save Trading Standards Intel. Please resolve any errors on this form and try again.';
        return;
      }

      const payload = JSON.parse(JSON.stringify(this.model));
      payload.responsiblePersonDOB = this.$helpers.dateForApi(payload.responsiblePersonDOB);
      this.$store
        .dispatch('tradingStandardsFeedback/update', payload)
        .then(() => {
          this.successMessage = 'Changes saved';
          this.$store.dispatch('tradingStandardsFeedback/actionable').then(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
          });
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        });
    },
  },
  validations() {
    return {
      model: {
        intelApproved: {},
        tradingStandardsNotes: {},
        tradingStandardsRagRating: {},
      },
    };
  },
};
</script>
<style>
.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.dotGreen {
  height: 25px;
  width: 25px;
  background-color: rgb(36, 211, 59);
  border-radius: 50%;
  display: inline-block;
}

.dotAmber {
  height: 25px;
  width: 25px;
  background-color: rgb(228, 222, 38);
  border-radius: 50%;
  display: inline-block;
}

.dotRed {
  height: 25px;
  width: 25px;
  background-color: rgb(211, 62, 36);
  border-radius: 50%;
  display: inline-block;
}
</style>
