import { HTTP, authHeader } from './http-service';

class SchemeNoteService {
  list(schemeId) {
    return HTTP.get(`/schemeNote/scheme/${schemeId}`, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  get(schemeId, noteId) {
    return HTTP.get(`/schemeNote/${noteId}/scheme/${schemeId}/`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  create(payload) {
    return HTTP.post(`/schemeNote`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/schemeNote`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  delete(payload) {
    return HTTP.delete(`/schemeNote/${payload.noteId}/scheme/${payload.schemeId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new SchemeNoteService();
