<template>
  <card label="Fee Categories">
    <div class="row">
      <div class="col">
        <div v-if="errorMessage" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>

        <div v-if="successMessage" class="alert alert-success" role="alert">
          {{ successMessage }}
        </div>
      </div>
    </div>

    <div v-for="(f, i) in model.feeCategories" :key="i" class="row mb-4">
      <div class="col">
        <div class="form-group">
          <label for="retrofitMatrixType" class="form-label">Type *</label>
          <select id="retrofitMatrixType" v-model="f.retrofitMatrixType" class="form-select">
            <option v-for="t in retrofitMatrixTypes" :key="t" :value="t">
              {{ t }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="schemeId" class="form-label">Scheme *</label>
          <select id="schemeId" v-model="f.schemeId" class="form-select">
            <option v-for="s in schemes" :key="s.schemeId" :value="s.schemeId">
              {{ s.schemeName }}
            </option>
            <option v-if="f.schemeId && !inSchemes(f.schemeId)" :value="f.schemeId">
              {{ getSchemeName(f.schemeId) }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="feeCategory" class="form-label">Fee Category *</label>
          <select id="feeCategory" v-model="f.feeCategory" class="form-select">
            <option v-for="s in categories" :key="s" :value="s">
              {{ s }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <button type="button" class="btn btn-danger remove" @click="removeFeeCategory(i)">
          Remove
        </button>
      </div>
    </div>

    <smart-submit-large label="Add" :loading="loading" class="ml-2" @click="addFeeCategory" />
    <smart-submit-large label="Save" :loading="loading" @click="save" />
  </card>
</template>

<script>
import { Card, SmartSubmitLarge } from '@trustmark-2005-ltd/trustmark-shared-components';
import { formatDate } from '../../../services/scheme-common';

export default {
  name: 'LicenceNumberViewRetrofitAccountFeeCategories',
  components: {
    Card,
    SmartSubmitLarge,
  },
  data() {
    return {
      successMessage: null,
      errorMessage: null,
      loading: false,
    };
  },
  computed: {
    model() {
      return this.$store.state.licenceNumber.retrofitAccount;
    },
    retrofitMatrixTypes() {
      return this.$store.state.licenceNumber.appData.matrixTypes.map((x) => x.type);
    },
    schemes() {
      return [
        // { schemeId: '', schemeName: '' },
        ...this.$store.state.licenceNumber.appData.schemes.filter(
          (x) => this.$store.state.licenceNumber.trustmarkBusiness.schemes.indexOf(x.schemeId) > -1
        ),
      ];
    },
    categories() {
      return this.$store.state.licenceNumber.appData.feeCategories;
    },
  },
  mounted() {},
  methods: {
    formatDate,
    inSchemes(schemeId) {
      return this.schemes.find((x) => x.schemeId === schemeId) !== undefined;
    },
    getSchemeName(schemeId) {
      const scheme = this.$store.state.licenceNumber.appData.schemes.find(
        (x) => x.schemeId === schemeId
      );
      if (scheme) {
        return scheme.schemeName;
      }
      return '';
    },
    asString(statusCode) {
      return this.$store.state.auth.appData.licenceNumberStatuses.find(
        (x) => x.status === parseInt(statusCode, 10)
      ).description;
    },
    addFeeCategory() {
      this.model.feeCategories = this.model.feeCategories || [];
      this.model.feeCategories.push({
        feeCategory: 'Standard',
      });
    },
    removeFeeCategory(i) {
      this.model.feeCategories.splice(i, 1);
    },
    isValid() {
      let ret = true;

      const l = [];
      let i = 0;
      for (i; i < this.model.feeCategories.length; i += 1) {
        const f = this.model.feeCategories[i];
        if (!f.retrofitMatrixType) {
          ret = false;
        }

        const n = `${f.retrofitMatrixType}${f.schemeId}`;
        if (l.indexOf(n) === -1) {
          l.push(n);
        }
      }

      if (l.length !== this.model.feeCategories.length) {
        ret = false;
      }

      if (!ret) {
        this.errorMessage =
          'Ensure that all items have a Type, and unique Type, Scheme combination';
      }

      return ret;
    },
    save() {
      this.errorMessage = null;
      this.successMessage = null;

      if (!this.isValid()) {
        // window.scrollTo(0, 0);
        // this.errorMessage =
        //   'Unable to save fee categories. Please resolve any errors on this form and try again.';
        return;
      }

      this.$store
        .dispatch('licenceNumber/putFeeCategories', {
          retrofitAccountId: this.model.retrofitAccountId,
          feeCategories: this.model.feeCategories,
        })
        .then(() => {
          this.successMessage = 'Fee categories saved';
          this.loading = false;
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        });
    },
  },
};
</script>

<style scoped lang="scss">
.ml-2 {
  margin-left: 10px;
}

ul {
  padding-left: 1rem;
}

.light {
  color: $gray-600;
}

.remove {
  position: relative;
  bottom: -45px;
}
</style>
