<template>
  <confirm-dialog ref="confirmDialog" />
  <div>
    <h3 class="position-relative">
      Linked Businesses
      <span class="badge rounded-pill bg-primary text-light">{{
        model.linkedSchemeBusinesses.length
      }}</span>
    </h3>
    <div v-if="model.linkedSchemeBusinesses.length === 0">
      <p>This licence doesn't have any links to businesses.</p>
    </div>
    <div v-if="model.linkedSchemeBusinesses.length > 0">
      <p>All of the following businesses are linked to this licence number {{ model.tmln }}.</p>
      <p v-if="primary">
        The <span class="badge bg-success">Primary</span> will lead the display information which is
        currently
        <router-link :to="`/scheme-business/${primary.schemeId}/${primary.schemeBusinessId}`">{{
          primary.registeredCompanyName
        }}</router-link
        >.
      </p>
      <div v-if="model" class="col-sm-12 col-md-10">
        <card v-for="lsb in model.linkedSchemeBusinesses" :key="lsb.schemeBusinessId">
          <div class="d-flex align-items-center">
            <h5>{{ lsb.registeredCompanyName }}</h5>
            <div class="ms-3">
              <h5 v-if="lsb.isActive">
                <span class="badge bg-success">Active</span>
              </h5>
              <h5 v-if="!lsb.isActive">
                <span class="badge bg-danger">Inactive</span>
              </h5>
            </div>
            <div class="ms-1">
              <h5 v-if="lsb.isPrimary">
                <span class="badge bg-success">Primary</span>
              </h5>
              <h5 v-if="!lsb.isPrimary">
                <span class="badge bg-dark">Secondary</span>
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-8">
              <div class="form-input">
                <key-value-presenter
                  label="Membership Reference"
                  :value="lsb.membershipReference"
                />
              </div>
              <div class="form-label">
                <key-value-presenter label="Scheme" :value="lsb.schemeId" />
              </div>
              <div class="form-label">
                <key-value-presenter
                  label="Registered Company Number"
                  :value="lsb.registeredCompanyNumber"
                />
              </div>
              <div class="form-label">
                <key-value-presenter label="Post Code" :value="lsb.postCode" />
              </div>
              <div class="form-label">
                <copy-key-value-presenter
                  label="Scheme Business ID"
                  :value="lsb.schemeBusinessId"
                />
              </div>
            </div>

            <div class="col-sm-12 col-md-4">
              <router-link :to="`/scheme/${lsb.schemeId}`">
                <scheme-logo :imagekey="schemeImageKey(lsb)" />
              </router-link>
            </div>
          </div>
          <button
            v-if="isLicenceEditor && !lsb.isPrimary"
            type="button"
            class="btn btn-secondary me-2"
            @click="makePrimary(lsb.schemeBusinessId)"
          >
            Make Primary Business
          </button>
          <button
            v-if="isLicenceEditor && !lsb.isActive && lsb.isPrimary"
            type="button"
            class="btn btn-primary me-2"
            @click="makePrimary(lsb.schemeBusinessId)"
          >
            Make Active
          </button>
          <button
            v-if="isLicenceEditor && lsb.isActive"
            type="button"
            class="btn btn-danger me-2"
            @click="makeInactive(lsb.schemeBusinessId)"
          >
            Make Inactive
          </button>
          <router-link
            :to="`/scheme-business/${lsb.schemeId}/${lsb.schemeBusinessId}`"
            class="btn btn-primary float-right"
          >
            View
          </router-link>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Card,
  CopyKeyValuePresenter,
  KeyValuePresenter,
  SchemeLogo,
  confirmType,
  ConfirmDialog,
} from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'LicenceNumberViewLinkedBusinesses',
  components: {
    Card,
    CopyKeyValuePresenter,
    KeyValuePresenter,
    SchemeLogo,
    ConfirmDialog,
  },
  data() {
    return {};
  },
  computed: {
    model() {
      return this.$store.state.licenceNumber.trustmarkBusiness;
    },
    primary() {
      return this.model.linkedSchemeBusinesses.find((x) => x.isPrimary);
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isLicenceEditor() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('LicenceEditor')
        : false;
    },
  },
  methods: {
    schemeImageKey(item) {
      const { schemeId } = item;
      const scheme = this.$store.state.auth.appData.schemes.find((x) => x.schemeId === schemeId);
      return scheme.schemeLogoImageKey || scheme.logoImage;
    },
    makePrimary(schemeBusinessId) {
      this.$refs.confirmDialog
        .show({
          title: 'Make Primary Business',
          message: 'Are you sure you want to make this the primary business on the licence?',
          okButtonText: 'Yes, Make Primary',
          confirmType: confirmType.standard,
        })
        .then((result) => {
          if (result) {
            const payload = {
              schemeBusinessId,
              trustmarkBusinessId: this.model.trustmarkBusinessId,
            };
            this.$store.dispatch('licenceNumber/makePrimary', payload);
          }
        });
    },
    makeInactive(schemeBusinessId) {
      this.$refs.confirmDialog
        .show({
          title: 'Make Business Inactive',
          message:
            'The scheme should really be doing this but you can make this business inactive from TrustMark, do you want to do that now?',
          okButtonText: 'Yes, Make Inactive',
          confirmType: confirmType.standard,
        })
        .then((result) => {
          if (result) {
            const payload = {
              schemeBusinessId,
              trustmarkBusinessId: this.model.trustmarkBusinessId,
            };
            this.$store.dispatch('licenceNumber/makeInactive', payload);
          }
        });
    },
  },
};
</script>
