import { HTTP, authHeader } from './http-service';

class SchemeContactService {
  list(schemeId) {
    return HTTP.get(`/schemeContact/scheme/${schemeId}`, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  get(schemeId, contactId) {
    return HTTP.get(`/schemeContact/${contactId}/scheme/${schemeId}/`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  create(payload) {
    return HTTP.post(`/schemeContact`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/schemeContact`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  delete(payload) {
    return HTTP.delete(`/schemeContact/${payload.contactId}/scheme/${payload.schemeId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new SchemeContactService();
