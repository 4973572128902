import SchemeService from '../services/scheme-usage-service';

export const schemeUsage = {
  namespaced: true,
  state: {
    schemeUsage: null,
  },
  actions: {
    getUsage({ commit }) {
      commit('setIsBusy', true, { root: true });
      commit('setSchemeUsage', null);
      return SchemeService.getUsage()
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setSchemeUsage', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setSchemeUsage(state, newState) {
      state.schemeUsage = newState;
    },
  },
};
