import BackOfficeUserService from '../services/backoffice-user-service';

export const backofficeUser = {
  namespaced: true,
  state: {
    searchResult: null,
    user: null,
    userEvents: [],
  },
  actions: {
    listUsers({ commit }) {
      return BackOfficeUserService.list().then(
        (result) => {
          const searchResult = {
            results: result.model,
          };
          commit('setUserSearchResult', searchResult);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getEvents({ commit }, accountId) {
      commit('setUserEvents', []);
      return BackOfficeUserService.getEvents(accountId).then(
        (result) => {
          commit('setUserEvents', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    newUser({ rootState, commit }) {
      const userTemplate = JSON.parse(JSON.stringify(rootState.auth.appData.models.backOfficeUser));
      commit('setUser', userTemplate);
    },
    getUserDetails({ commit }, userId) {
      commit('setIsBusy', true, { root: true });
      commit('setUser', null);
      const payload = {
        userId,
      };
      return BackOfficeUserService.get(payload).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setUser', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateUser({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return BackOfficeUserService.update(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    reset({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return BackOfficeUserService.reset(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    updateUserScheme({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return BackOfficeUserService.updateScheme(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    invite({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return BackOfficeUserService.invite(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    enableUser({ commit }, email) {
      const data = {
        accountId: email,
        isDisabled: false,
      };
      commit('setIsBusy', true, { root: true });
      return BackOfficeUserService.enable(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    disableUser({ commit }, email) {
      const data = {
        accountId: email,
        isDisabled: true,
      };
      commit('setIsBusy', true, { root: true });
      return BackOfficeUserService.disable(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setUserSearchResult(state, searchResult) {
      state.searchResult = searchResult;
    },
    setUser(state, newuser) {
      state.user = newuser;
    },
    setUserEvents(state, events) {
      state.userEvents = events;
    },
  },
};
