import EnergySuppliersService from '../services/energy-suppliers-service';

export const energySuppliers = {
  namespaced: true,
  state: {
    energySuppliers: [],
    energySupplier: {},
    notificationLogs: [],
    pageNumber: 0,
    searchResult: {},
    lastSearchConfig: null,
    versionList: [],
    searchModel: {
      searchTerm: null,
      previousSearchTerm: null,
    },
    advancedSearchIsOpen: false,
    advancedSearchModel: {
      organisationName: null,
      useWebhooks: null,
      useEmailNotifications: null,
      prefixMRN: null,
    },
  },
  actions: {
    getAll({ commit }) {
      commit('setIsBusy', true, { root: true });
      commit('setEnergySuppliers', []);
      return EnergySuppliersService.getAll()
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setEnergySuppliers', result);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    getNotificationLogs({ commit }, energySupplierId) {
      commit('setIsBusy', true, { root: true });
      commit('setNotificationLogs', []);
      return EnergySuppliersService.getNotificationLogs(energySupplierId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setNotificationLogs', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setEnergySuppliers(state, newEnergySuppliers) {
      state.energySuppliers = newEnergySuppliers;
    },
    setEnergySupplier(state, newEnergySupplier) {
      state.energySupplier = newEnergySupplier;
    },
    setNotificationLogs(state, newNotificationLogs) {
      state.notificationLogs = newNotificationLogs;
    },
  },
};
