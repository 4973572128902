import SchemeBusinessService from '../services/scheme-business-service';

export const schemeBusiness = {
  namespaced: true,
  state: {
    business: null,
  },
  actions: {
    get({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      commit('setBusiness', null);
      return SchemeBusinessService.get(payload.schemeId, payload.schemeBusinessId).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setBusiness', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    resubmit({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeBusinessService.resubmit(payload.schemeId, payload.schemeBusinessId).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setBusiness', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    rematch({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeBusinessService.rematch(
        payload.schemeId,
        payload.schemeBusinessId,
        payload.tmln
      ).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setBusinessLinkPending', {
            linkPending: null,
            tmln: null,
            trustmarkBusinessId: null,
            status: 3,
          });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    publishtrades({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeBusinessService.publishtrades(payload.schemeId, payload.schemeBusinessId).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    createLodgementPortalLogin({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeBusinessService.createLodgementPortalLogin(
        payload.schemeId,
        payload.schemeBusinessId,
        payload.email
      ).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    listLegacyLodgementBranches({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeBusinessService.listLegacyLodgementBranches(
        payload.schemeId,
        payload.schemeBusinessId
      ).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setBusiness(state, business) {
      state.business = business;
    },
    setBusinessLinkPending(state, newlinkPendingActioned) {
      state.business.schemeBusiness.linkPending = newlinkPendingActioned.linkPending;
      state.business.schemeBusiness.status = newlinkPendingActioned.status;
      state.business.schemeBusiness.tmln = newlinkPendingActioned.tmln;
      state.business.schemeBusiness.trustmarkBusinessId =
        newlinkPendingActioned.trustmarkBusinessId;
    },
    setBusinessActiveTradingStandardsApplication(state, newModel) {
      state.business.schemeBusiness.activeTradingStandardsApplicationReference =
        newModel.applicationReference;
    },
  },
};
