<template>
  <confirm-dialog ref="confirmDeleteTrade"></confirm-dialog>
  <subtitle>Trades</subtitle>

  <label class="p-1" style="font-weight: 700"
    >{{ filteredList.length }} Trades
    <span v-if="filterValue"> (filtered)</span>
  </label>

  <div class="btn-toolbar mb-3" role="toolbar">
    <div class="row w-100">
      <div class="col-sm-12 col-md-9 col-lg-6">
        <div class="input-group">
          <div id="btnGroupAddon" class="input-group-text bg-transparent border-end-0">
            <font-awesome-icon icon="magnifying-glass" />
          </div>
          <input
            v-model="filterValue"
            type="text"
            class="form-control border-start-0"
            placeholder="Find scheme trade"
          />
          <button class="btn btn-outline-secondary" type="button" @click="clear">Clear</button>
        </div>
      </div>
    </div>
  </div>

  <div class="h-100 pt-3">
    <div class="row">
      <div class="col">
        <div v-if="errorMessage" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>

        <div class="mb-4">
          <div class="btn-toolbar mb-2 mb-md-0">
            <div class="btn-group me-2">
              <button class="btn btn-secondary" @click="create">Add Trade To Scheme</button>
            </div>
          </div>
        </div>

        <table v-if="filteredList" class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Trade Code</th>
              <th scope="col">Trade Description</th>
              <th scope="col">Status</th>
              <th scope="col">Obsolete Date From</th>
              <th scope="col">Notes</th>
              <th scope="col" style="width: 1px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="trade in filteredList" :key="trade.tradeId">
              <td>
                {{ trade.tradeCode }}
              </td>
              <td>
                {{ trade.tradeDescription }}
              </td>
              <td>
                {{ trade.status }}
              </td>
              <td>
                <span v-if="trade.obsoleteDateFrom">{{
                  formatDateCustom(trade.obsoleteDateFrom, 'DD/MM/yyyy')
                }}</span>
              </td>
              <td>
                {{ trade.notes }}
              </td>
              <td>
                <div class="btn-group btn-group">
                  <button class="btn btn-outline-secondary" @click="edit(trade.tradeCode)">
                    Edit
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    @click="confirmErase(trade.tradeCode)"
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-if="!trades" class="ghost">Loading trades...</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ConfirmDialog,
  confirmType,
  Subtitle,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import { formatDateCustom } from '../../../../services/scheme-common';

export default {
  name: 'SchemesEditTradesList',
  components: {
    Subtitle,
    ConfirmDialog,
  },
  data() {
    return {
      filterValue: null,
      errorMessage: null,
      loading: true,
    };
  },
  computed: {
    parentRoute() {
      return `/scheme/${this.$route.params.schemeId}/edit/scheme-trades`;
    },
    trades() {
      return this.$store.state.schemeTrade.trades;
    },
    filteredList() {
      if (!this.trades) {
        return [];
      }
      let t = JSON.parse(JSON.stringify(this.trades));
      t.sort((a, b) => a.tradeCode - b.tradeCode);

      if (!t || t.length === 0) {
        return [];
      }

      const { filterValue } = this;

      let code = -1;
      if (Number.isInteger(Number(filterValue)) && parseInt(filterValue, 10) > 0) {
        code = parseInt(filterValue, 10);
      }

      if (filterValue !== null || code > 0) {
        t = t.filter(
          (x) =>
            (x.tradeDescription !== null &&
              x.tradeDescription.toUpperCase().indexOf(filterValue.toUpperCase()) > -1) ||
            x.tradeCode === code
        );
      }

      return t;
    },
  },
  mounted() {
    if (!this.trades || this.trades.length === 0) {
      this.list();
    }
  },
  methods: {
    formatDateCustom,
    list() {
      this.$store
        .dispatch('schemeTrade/list', this.$route.params.schemeId)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.errorMessage = err;
        });
    },
    create() {
      this.$store.dispatch('schemeTrade/initialise', this.$route.params.schemeId).then(() => {
        this.$router.push(`${this.parentRoute}/create`);
      });
    },
    edit(tradeCode) {
      const payload = {
        tradeCode,
        schemeId: this.$route.params.schemeId,
      };
      this.$store.dispatch('schemeTrade/get', payload).then(() => {
        this.$router.push(`${this.parentRoute}/edit/${tradeCode}`);
      });
    },
    erase(tradeCode) {
      const payload = {
        tradeCode,
        schemeId: this.$route.params.schemeId,
      };
      this.$store.dispatch('schemeTrade/delete', payload).then(() => {
        this.list();
      });
    },
    confirmErase(tradeCode) {
      this.$refs.confirmDeleteTrade
        .show({
          title: 'Delete Trade',
          message: 'Are you sure you want to delete this trade?',
          confirmType: confirmType.destructive,
        })
        .then((result) => {
          if (result === true) {
            this.erase(tradeCode);
          }
        });
    },
    clear() {
      this.filterValue = null;
    },
  },
};
</script>
