<template>
  <div>
    <div class="tab-container">
      <header class="d-flex justify-content-center py-3">
        <ul class="nav nav-pills nav-fill">
          <li class="nav-item">
            <router-link :to="`${parentRoute}`" class="nav-link" :class="endsWith(parentRoute)"
              >Overview
            </router-link>
          </li>

          <li class="nav-item">
            <router-link :to="`${parentRoute}/trades`" class="nav-link" :class="endsWith('trades')"
              >Trades & Registrations
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              :to="`${parentRoute}/contacts`"
              class="nav-link"
              :class="endsWith('contacts')"
              >Contacts
            </router-link>
          </li>

          <li class="nav-item">
            <router-link :to="`${parentRoute}/notes`" class="nav-link" :class="endsWith('notes')"
              >Internal Notes
            </router-link>
          </li>

          <li class="nav-item">
            <router-link :to="`${parentRoute}/emails`" class="nav-link" :class="endsWith('emails')"
              >Emails
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              :to="`${parentRoute}/formatted-activity-logs`"
              class="nav-link"
              :class="endsWith('formatted-activity-logs')"
              >Activity Logs
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              :to="`${parentRoute}/raw-activity-logs`"
              class="nav-link"
              :class="endsWith('raw-activity-logs')"
              >Raw Activity Logs
            </router-link>
          </li>

        </ul>
      </header>
    </div>
    <div class="p-3"></div>
  </div>
</template>

<script>
export default {
  name: 'SchemeBusinessTabs',
  computed: {
    parentRoute() {
      let baseRoute = 'scheme-business';
      if (this.$route.meta.pendingLicences) {
        baseRoute = 'pending-licences';
      }
      return `/${baseRoute}/${this.$route.params.schemeId}/${this.$route.params.schemeBusinessId}`;
    },
  },
  methods: {
    includes(str) {
      return this.$route.path.includes(str) ? 'active' : '';
    },
    endsWith(str) {
      return this.$route.path.endsWith(str) ? 'active' : '';
    },
  },
};
</script>
