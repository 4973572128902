<template>
  <card label="Notifications">
    <div class="row">
      <div class="col">
        <div v-if="errorMessage" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>

        <div v-if="successMessage" class="alert alert-success" role="alert">
          {{ successMessage }}
        </div>
      </div>
    </div>

    <smart-input
      id="remediationNotificationEmail"
      v-model="model.remediationNotificationEmail"
      label="Remediation Notification Email"
    />

    <smart-input
      id="lastName"
      v-model="model.creditNotificationEmail"
      label="Credit Notification Email"
    />

    <smart-submit-large label="Save" :loading="loading" @click="save" />
  </card>
</template>

<script>
import {
  Card,
  SmartSubmitLarge,
  SmartInput,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import { formatDate } from '../../../services/scheme-common';

export default {
  name: 'LicenceNumberViewRetrofitAccountNotifications',
  components: {
    Card,
    SmartSubmitLarge,
    SmartInput,
  },
  data() {
    return {
      successMessage: null,
      errorMessage: null,
      loading: false,
    };
  },
  computed: {
    model() {
      return this.$store.state.licenceNumber.retrofitAccount;
    },
    retrofitMatrixTypes() {
      return this.$store.state.licenceNumber.appData.matrixTypes.map((x) => x.type);
    },
    schemes() {
      return [
        // { schemeId: '', schemeName: '' },
        ...this.$store.state.licenceNumber.appData.schemes.filter(
          (x) => this.$store.state.licenceNumber.trustmarkBusiness.schemes.indexOf(x.schemeId) > -1
        ),
      ];
    },
  },
  mounted() {},
  methods: {
    formatDate,
    inSchemes(schemeId) {
      return this.schemes.find((x) => x.schemeId === schemeId) !== undefined;
    },
    getSchemeName(schemeId) {
      const scheme = this.$store.state.licenceNumber.appData.schemes.find(
        (x) => x.schemeId === schemeId
      );
      if (scheme) {
        return scheme.schemeName;
      }
      return '';
    },
    asString(statusCode) {
      return this.$store.state.auth.appData.licenceNumberStatuses.find(
        (x) => x.status === parseInt(statusCode, 10)
      ).description;
    },
    save() {
      this.errorMessage = null;
      this.successMessage = null;

      this.$store
        .dispatch('licenceNumber/putNotificationEmails', {
          retrofitAccountId: this.model.retrofitAccountId,
          emails: {
            remediationNotificationEmail: this.model.remediationNotificationEmail,
            creditNotificationEmail: this.model.creditNotificationEmail,
          },
        })
        .then(() => {
          this.successMessage = 'Notification Emails saved';
          this.loading = false;
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        });
    },
  },
};
</script>

<style scoped lang="scss">
.ml-2 {
  margin-left: 10px;
}

ul {
  padding-left: 1rem;
}

.light {
  color: $gray-600;
}

.remove {
  position: relative;
  bottom: -45px;
}
</style>
