import { HTTP, authHeader, HTTP_FILE } from './http-service';

class DisputeEvidenceService {
  list(disputeId) {
    return HTTP.get(`/disputeEvidence/dispute/${disputeId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  get(disputeId, evidenceId) {
    return HTTP.get(`/disputeEvidence/${evidenceId}/dispute/${disputeId}/`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  create(payload) {
    return HTTP.post(`/disputeEvidence`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/disputeEvidence`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  delete(payload) {
    return HTTP.delete(
      `/disputeEvidence/${payload.disputeEvidenceId}/dispute/${payload.disputeId}`,
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }

  getSignedUrl(disputeId, filename) {
    return HTTP_FILE.get(
      `/DisputeFile/GetPresignedPost/${disputeId}?filename=${encodeURIComponent(filename)}`
    ).then((response) => {
      return response.data;
    });
  }
}

export default new DisputeEvidenceService();
