import SchemeService from '../services/scheme-service';

export const scheme = {
  namespaced: true,
  state: {
    searchResult: null,
    pageNumber: 0,
    scheme: null,
    lastSearchConfig: null,
    dashboard: null,
  },
  actions: {
    registerScheme({ commit, dispatch }, data) {
      commit('setIsBusy', true, { root: true });
      return SchemeService.register(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          setTimeout(() => {
            dispatch('executeLastSearch');
          }, 6000);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    executeLastSearch({ dispatch, state, commit }) {
      commit('setIsBusy', true, { root: true });
      if (!state.lastSearchConfig) {
        commit('setIsBusy', false, { root: true });
        throw new Error('No Scheme search has been run yet - cannot rerun');
      }
      dispatch('searchScheme', state.lastSearchConfig).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    searchScheme({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      data.type = 'standard';
      commit('setLastSearchConfig', data);
      return SchemeService.search(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setSchemeSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    searchSchemeAdvanced({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      data.type = 'advanced';
      commit('setLastSearchConfig', data);
      return SchemeService.advancedSearch(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setSchemeSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    getSchemeDetails({ commit }, schemeId) {
      commit('setIsBusy', true, { root: true });
      commit('setScheme', null);
      commit('schemeContact/setContacts', [], { root: true });
      commit('schemeContact/setContact', null, { root: true });
      commit('schemeTrade/setSchemeTrades', [], { root: true });
      commit('setDashboard', null);
      const payload = {
        schemeId,
      };
      return SchemeService.get(payload).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setScheme', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateScheme({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return SchemeService.update(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    deleteScheme({ commit }, data) {
      commit('setIsBusy', true, { root: true });

      return SchemeService.delete(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('removeFromSearchResult', data.schemeId);
          const { message } = result;
          return Promise.resolve(message);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    getDashboard({ commit }, schemeId) {
      commit('setIsBusy', true, { root: true });
      const payload = {
        schemeId,
      };
      return SchemeService.getDashboard(payload).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          result.model.schemeId = schemeId;
          commit('setDashboard', result.model);
          return Promise.resolve('/');
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setSchemeSearchResult(state, searchResult) {
      state.searchResult = searchResult;
    },
    setScheme(state, newScheme) {
      state.scheme = newScheme;
    },
    setLastSearchConfig(state, searchconfig) {
      state.lastSearchConfig = searchconfig;
    },
    setSearchPageNumber(state, newPageNumber) {
      state.pageNumber = newPageNumber;
    },
    removeFromSearchResult(state, schemeId) {
      if (state.searchResult && state.searchResult.results) {
        state.searchResult.results = state.searchResult.results.filter(
          (x) => x.schemeId !== schemeId
        );
      }
    },
    setDashboard(state, newDashboard) {
      state.dashboard = newDashboard;
    },
  },
};
