import { HTTP_RETROFIT, authHeader } from './http-service';

class AuditQuestionsService {
  getAll() {
    return HTTP_RETROFIT.get(`/QuestionSet/GetAll`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getVersion(payload) {
    return HTTP_RETROFIT.get(`/QuestionSet/${payload.id}/Version/${payload.version}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getDraft(id) {
    return HTTP_RETROFIT.get(`/QuestionSet/${id}/Draft`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getReview(id) {
    return HTTP_RETROFIT.get(`/QuestionSet/${id}/Review`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getCurrent(id) {
    return HTTP_RETROFIT.get(`/QuestionSet/${id}/Current`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getVersionList(id) {
    return HTTP_RETROFIT.get(`/QuestionSet/${id}/VersionList`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  post(payload) {
    return HTTP_RETROFIT.post(`/QuestionSet`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  put(payload) {
    return HTTP_RETROFIT.put(`/QuestionSet/${payload.auditQuestionSetId}`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  addQuestion(payload) {
    return HTTP_RETROFIT.put(`/QuestionSet/${payload.auditQuestionSetId}/AddQuestion`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  review(payload) {
    return HTTP_RETROFIT.put(
      `/QuestionSet/${payload.auditQuestionSetId}/Review`,
      {},
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }

  publish(payload) {
    return HTTP_RETROFIT.put(
      `/QuestionSet/${payload.auditQuestionSetId}/Publish`,
      {},
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }

  import(file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return HTTP_RETROFIT.post('/QuestionSet/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => {
      return response.data;
    });
  }

  delete(payload) {
    return HTTP_RETROFIT.delete(`/QuestionSet/${payload.auditQuestionSetId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  appData() {
    return HTTP_RETROFIT.get(`/AppData`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  search(payload) {
    return HTTP_RETROFIT.post(`/AuditQuestionSetSearch/Search`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  advancedSearch(payload) {
    return HTTP_RETROFIT.post(`/AuditQuestionSetSearch/Searchadvance`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new AuditQuestionsService();
