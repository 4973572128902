<template>
  <subtitle>Activity Logs</subtitle>

  <div v-if="model">
    <div class="form-group">
      <div v-if="model.length === 0" class="alert alert-info" role="alert">
        No activity log for this business.
      </div>
    </div>

    <card
      v-for="a in model"
      :key="a.updatedAt"
      :label="`${a.updatedBy} made changes ${
        a.updatedAt ? `on ${$helpers.dateTimeString(a.updatedAt)}` : ''
      }`"
    >
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Field</th>
            <th>Old Value</th>
            <th>New Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="f in a.fields" :key="f.field">
            <td>{{ f.field }}</td>
            <td>{{ f.oldValue }}</td>
            <td>{{ f.newValue }}</td>
          </tr>
        </tbody>
      </table>
    </card>
  </div>
</template>

<script>
import { Subtitle, Card } from '@trustmark-2005-ltd/trustmark-shared-components';
import { HTTP, authHeader } from '../../services/http-service';

export default {
  name: 'SchemeBusinessFormattedActivityLogs',
  components: {
    Subtitle,
    Card,
  },
  data() {
    return {
      model: null,
    };
  },
  computed: {
    authHeader() {
      return authHeader;
    },
  },
  mounted() {
    return HTTP.get(
      `/activitylog/business/${this.$route.params.schemeId}/${this.$route.params.schemeBusinessId}/formatted`,
      {
        headers: this.authHeader(),
      }
    ).then((response) => {
      this.model = response.data.model;
    });
  },
  methods: {},
};
</script>
