<template>
  <div>
    <subtitle>Registration</subtitle>

    <div class="mt-1 mb-3">
      <span
        >Confirm when this business has been registered with your scheme. A business will only be
        able to lodge work during these registration periods.</span
      >
    </div>

    <div class="mb-4">
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group me-2" role="group">
          <button
            id="btnGroupCreateOptions"
            type="button"
            class="btn btn-secondary dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Create
          </button>
          <ul class="dropdown-menu" aria-labelledby="btnGroupCreateOptions">
            <li v-if="!hasCurrent">
              <a class="dropdown-item" href="#" :disabled="hasCurrent" @click="create"
                >Current Registration</a
              >
            </li>
            <li v-if="hasRegistrations">
              <a class="dropdown-item" href="#">Historic Registration</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="model" class="h-100">
      <form ref="formCurrentRegistration" class="has-validation">
        <div :disabled="loading" class="pt-3">
          <div class="row">
            <div class="col-sm-12 col-md-10 col-lg-10">
              <card
                v-if="hasCurrent"
                :label="currentRegistrationTitle"
                :class="currentRegistrationClass"
              >
                <div class="row">
                  <span> {{ deregisteredReason }}</span>
                </div>
                <div class="row p-2">
                  <div class="col-sm-3">
                    <label for="fromDate" class="form-label">From Date:</label>
                  </div>
                  <div v-if="amending" class="col-sm-3">
                    <datepicker
                      id="fromDate"
                      v-model="fromDate"
                      class="form-control"
                      input-format="dd/MM/yyyy"
                    />
                  </div>
                  <div v-if="!amending" class="col-sm-3">
                    <input v-model="fromDateAsString" readonly class="form-control-plaintext" />
                  </div>
                </div>

                <div class="row p-2">
                  <div class="col-sm-3">
                    <label for="toDate" class="form-label">To Date:</label>
                  </div>
                  <div v-if="amending" class="col-sm-3">
                    <datepicker
                      id="toDate"
                      v-model="toDate"
                      class="form-control"
                      input-format="dd/MM/yyyy"
                    />
                  </div>
                  <div v-if="!amending" class="col-sm-6">
                    <div class="row">
                      <div class="col-6">
                        <input v-model="toDateAsString" readonly class="form-control-plaintext" />
                      </div>
                      <div class="col-6">
                        <button
                          v-if="!newCurrent && !isDeregistered && !amending"
                          type="button"
                          class="btn btn-danger ms-1"
                          @click="toggleEndPrompt"
                        >
                          End Registration
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-2">
                  <div v-if="!isDeregistered && !amending" class="btn btn-secondary" @click="amend">
                    Amend Dates
                  </div>
                  <div
                    v-if="!isDeregistered && amending"
                    class="btn btn-secondary"
                    @click="setDates"
                  >
                    Set Dates
                  </div>

                  <div v-if="amending" class="btn btn-outline-secondary ms-1" @click="cancelAmend">
                    Cancel
                  </div>

                  <div
                    v-if="!newCurrent && isDeregistered"
                    class="btn btn-primary ms-1"
                    @click="reinstate"
                  >
                    Reinstate
                  </div>
                </div>

                <div v-if="endPrompt" class="p-2">
                  <div class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Confirm End Registration</h4>
                    <p class="mb-1">Please provide a reason:</p>
                    <div class="col-sm-12 col-md-6">
                      <select
                        v-model="newDeregisteredReason"
                        class="form-control"
                        list="reasons"
                        name="reason"
                      >
                        <option>Elective withdrawal from scheme</option>
                        <option>Elective withdrawal from TrustMark</option>
                        <option>Removal by scheme - not for Annex A reasons</option>
                        <option>Suspension</option>
                        <option>Expelled by scheme - Non-compliance reason</option>
                      </select>
                      <p class="mt-3 mb-1">This registration ends at 23:59:59 on:</p>
                      <div class="col-sm-12 col-md-12">
                        <div class="btn-group">
                          <datepicker
                            id="toDate1"
                            v-model="toDate"
                            class="form-control"
                            input-format="dd/MM/yyyy"
                          />
                          <button
                            class="btn btn-outline-secondary"
                            type="button"
                            @click="setToDateToday()"
                          >
                            End Today
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <p class="mb-1">Are you sure you would like to do this now?</p>
                    <div class="btn btn-secondary ms-1" @click="toggleEndPrompt">
                      No Keep Active
                    </div>
                    <div class="btn btn-danger ms-1" @click="endRegistration">
                      Yes End Registration
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </form>

      <div class="pt-3">
        <div class="row">
          <div class="col-sm-12 col-md-10 col-lg-10">
            <card label="Registration History">
              <div v-if="!hasRegistrations">
                <span>This business doesn't have any registration history.</span>
              </div>

              <div v-if="hasRegistrations">
                <div class="row">
                  <div-column-header :colclass="'col-sm-3'">From Date</div-column-header>
                  <div-column-header :colclass="'col-sm-3'">To Date</div-column-header>
                  <div-column-header :colclass="'col-sm-5'">Information</div-column-header>
                  <div-column-header :colclass="'col-sm-1'"></div-column-header>
                </div>
                <div
                  v-for="registration in registrations"
                  :key="registration.fromDate"
                  class="pt-1 pb-1"
                >
                  <div class="row" :class="registration.isDeregistered ? 'alert-danger' : ''">
                    <div class="col-sm-3 d-flex align-items-center">
                      [{{ $helpers.dateString(registration.fromDate) }}]
                      {{ registration.fromDateString }}
                    </div>
                    <div class="col-sm-3 d-flex align-items-center">
                      [{{ $helpers.dateTimeString(registration.toDate) }}]
                      {{ registration.toDateString }}
                    </div>
                    <div class="col-sm-5 d-flex align-items-center">
                      {{ getInformation(registration) }}
                    </div>
                    <div class="col-sm-1">
                      <button
                        v-if="registration.isDeregistered"
                        type="button"
                        class="btn btn-danger m-1"
                        @click="voidDeregistered(registration)"
                      >
                        Void
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import { Subtitle, Card, DivColumnHeader } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'SchemesEditRegistration',
  components: {
    Card,
    Datepicker,
    Subtitle,
    DivColumnHeader,
  },
  props: {
    v: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      fromDate: null,
      toDate: null,
      amending: false,
      endPrompt: false,
      loading: false,
      newCurrent: false,
      newDeregisteredReason: null,
    };
  },
  computed: {
    v$() {
      return this.v;
    },
    model() {
      return this.$store.state.business.business;
    },
    currentRegistrationFromDate() {
      if (!this.model) return null;
      return this.model.currentRegistrationFromDate;
    },
    currentRegistrationToDate() {
      if (!this.model) return null;
      return this.model.currentRegistrationToDate;
    },
    isDeregistered() {
      if (!this.model) return null;
      return this.model.isDeregistered;
    },
    deregisteredReason() {
      if (!this.model) return null;
      return this.model.deregisteredReason;
    },
    hasCurrent() {
      return (
        this.currentRegistrationFromDate !== null ||
        this.currentRegistrationToDate !== null ||
        this.newCurrent
      );
    },
    fromDateAsString() {
      return this.$helpers.dateString(this.currentRegistrationFromDate, 'Not set');
    },
    toDateAsString() {
      return this.$helpers.dateTimeString(this.currentRegistrationToDate, 'Ongoing');
    },
    newRegistration() {
      return this.$store.state.auth.appData.models.businessRegistration;
    },
    registrations() {
      const r = this.$store.state.business.business.registrationPeriods;
      return r.sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate));
    },
    hasRegistrations() {
      return this.model && this.registrations.length > 0;
    },
    currentRegistrationClass() {
      return this.isDeregistered ? 'alert-danger' : '';
    },
    currentRegistrationTitle() {
      return this.isDeregistered ? 'Currently Deregistered' : 'Current Registration';
    },
  },
  mounted() {
    if (!this.newRegistration) {
      this.$store.dispatch('auth/getAppData').then(() => {
        this.loading = true;
      });
    }
  },
  methods: {
    setToDateToday() {
      const newFromDate = new Date();
      newFromDate.setDate(newFromDate.getDate() - 1);
      this.toDate = newFromDate;
    },
    amend() {
      this.fromDate = new Date(this.currentRegistrationFromDate);
      if (this.currentRegistrationToDate !== null) {
        this.toDate = new Date(this.currentRegistrationToDate);
      } else {
        this.toDate = null;
      }
      this.amending = true;
      this.endPrompt = false;
    },
    cancelAmend() {
      this.amending = false;
      this.newCurrent = false;
    },
    toggleEndPrompt() {
      this.endPrompt = !this.endPrompt;
      this.amending = false;
    },
    endRegistration() {
      const { toDate } = this;
      if (toDate === null) {
        return;
      }
      this.endPrompt = false;
      this.newCurrent = false;

      const newReg = JSON.parse(JSON.stringify(this.newRegistration));
      newReg.fromDate = this.$helpers.dateForApi(this.currentRegistrationFromDate);
      newReg.fromDateString = this.$helpers.dateString(newReg.fromDate);
      newReg.toDate = this.$helpers.dateEndOfDayForApi(toDate);
      newReg.toDateString = this.$helpers.dateTimeString(newReg.toDate);
      newReg.isDeregistered = this.isDeregistered;
      newReg.deregisteredReason = this.deregisteredReason;
      this.registrations.push(newReg);

      // Set a new degregistration starting from the next day after the end of the registration
      const newFromDate = new Date(this.toDate);
      newFromDate.setDate(newFromDate.getDate() + 1);
      const payload = {
        fromDate: this.$helpers.dateForApi(newFromDate),
        toDate: null,
        isDeregistered: true,
        deregisteredReason: this.newDeregisteredReason,
      };

      this.$store.dispatch('business/setCurrentRegistration', payload);
      this.fromDate = payload.fromDate;
      this.toDate = null;
    },
    reinstate() {
      let reinstateHistory = false;
      let newFromDate = new Date();
      if (
        this.$helpers.dateForApi(this.currentRegistrationFromDate) ===
        this.$helpers.dateForApi(new Date())
      ) {
        if (this.registrations.length > 0) {
          // Grab the history date to restate as it will be  active again
          const reinstateRegistration = this.registrations[0];
          this.registrations.splice(0, 1);
          newFromDate = reinstateRegistration.fromDate;
          reinstateHistory = true;
        }
      }
      const { toDate } = this;
      if (toDate === null && !reinstateHistory) {
        this.amend();
        return;
      }
      this.endPrompt = false;
      this.newCurrent = false;
      this.amending = false;

      if (!reinstateHistory) {
        // Add a new history entry
        const newReg = JSON.parse(JSON.stringify(this.newRegistration));
        newReg.fromDate = this.$helpers.dateForApi(this.currentRegistrationFromDate);
        newReg.toDate = this.$helpers.dateEndOfDayForApi(toDate);
        newReg.isDeregistered = true;
        newReg.deregisteredReason = this.deregisteredReason;
        this.registrations.push(newReg);

        // Set a new current registration starting from the next day after the degregistration ended
        newFromDate = new Date(this.toDate);
        newFromDate.setDate(newFromDate.getDate() + 1);
      }

      const payload = {
        fromDate: this.$helpers.dateForApi(newFromDate),
        toDate: null,
        isDeregistered: false,
        deregisteredReason: null,
      };
      this.$store.dispatch('business/setCurrentRegistration', payload);
      this.fromDate = payload.fromDate;
      this.toDate = null;
    },
    create() {
      this.newCurrent = true;
      this.amending = true;

      this.fromDate = new Date();
      this.toDate = null;
    },
    setDates() {
      const payload = {
        fromDate: this.$helpers.dateForApi(this.fromDate),
        toDate: this.$helpers.dateEndOfDayForApi(this.toDate),
      };
      this.$store.dispatch('business/setCurrentRegistration', payload);
      this.amending = false;
      this.newCurrent = false;
    },
    getInformation(registration) {
      if (registration.isDeregistered) {
        return `Deregistered. ${this.$helpers.capFirstLetter(registration.reason)}`;
      }
      if (registration.isVoided) {
        return `Deregistered Voided. ${this.$helpers.capFirstLetter(registration.reason)}`;
      }
      return 'Active';
    },
    voidDeregistered(registration) {
      // TODO: confirm and save
      registration.isDeregistered = false;
      registration.isVoided = true;
    },
  },
};
</script>
