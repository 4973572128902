<template>
  <div>
    <h1>Registration Billing</h1>
    <div class="btn-toolbar mb-3" role="toolbar">
      <form>
        <div class="row">
          <div class="col">
            <smart-select-key-value
              id="FilterPeriod"
              v-model="period"
              label="Filter Period"
              :is-required="false"
              :options="periods"
              @change="periodFilterChange"
            />
          </div>
          <div class="col-8">
            <smart-select-key-value
              id="FilterSchemeId"
              v-model="schemeId"
              label="Or Scheme"
              :is-required="false"
              :options="schemeIds"
              @change="schemeFilterChange"
            />
          </div>
        </div>
        <div class="row">
          <div class="mt-0">
            <smart-button :loading="loading" :click="doExport">Export CSV</smart-button>
          </div>
        </div>
      </form>
    </div>

    <div
      class="col-sm-12 col-md-12 col-lg-12"
      style="max-height: calc(100vh - 300px); overflow-x: scroll"
    >
      <table>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col"></th>
            <th scope="col">Scheme</th>
            <th scope="col">Period</th>
            <th scope="col" style="text-align: center">
              Billable Quantity
              <popover placement="top" content="Active Licences + Suspended Net Licences" />
            </th>
            <th scope="col" style="text-align: center">Trades</th>
            <th scope="col" style="text-align: center">Registered Businesses</th>
            <th scope="col" style="text-align: center">Active Licences</th>
            <th scope="col" style="text-align: center">Inactive Licences</th>
            <th scope="col" style="text-align: center">Joiners</th>
            <th scope="col" style="text-align: center">Leavers</th>
            <th scope="col" style="text-align: center">Suspended Businesses</th>
            <th scope="col" style="text-align: center">
              New
              <popover
                placement="top"
                content="Number of your scheme TMLNs that have been suspended"
              />
            </th>
            <th scope="col">
              Aged
              <popover
                placement="top"
                content="Suspended in a previous period and still suspended"
              />
            </th>
            <th scope="col">
              Suspended Distinct Licences
              <popover
                placement="top"
                content="Number of your scheme TMLNs that have been suspended"
              />
            </th>
            <th scope="col">
              Suspended Net Licences
              <popover
                placement="top"
                content="TMLNs that are still suspended or were suspended within the period and have moved to deregistred, these will be charged"
              />
            </th>
            <th scope="col">Is a Declaration</th>
            <th scope="col">Declaration Submitted</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(reg, i) in registrations" :key="reg.period">
            <td>{{ i + 1 }}</td>
            <td>
              <button
                v-if="parseInt(reg.period) >= 202402"
                class="btn btn-outline-secondary btn-sm"
                type="button"
                :loading="loading"
                @click.prevent="doIndividualExport(reg.period, reg.schemeId)"
              >
                <font-awesome-icon icon="download" class="icon" />
              </button>
            </td>
            <td style="white-space: nowrap">
              <router-link :to="`/scheme/${reg.schemeId}`">
                {{ reg.schemeName }}
              </router-link>
            </td>
            <td style="white-space: nowrap; text-align: right">{{ reg.period }}</td>
            <td style="text-align: right">
              {{ reg.activeLicenceCount + reg.suspendedNetLicenceCount }}
              <div
                v-if="
                  reg.retrofitAssessorCount ||
                  reg.retrofitCoordinatorCount ||
                  reg.suspendedRetrofitAssessorCount ||
                  reg.suspendedRetrofitCoordinatorCount
                "
                class="ml-2"
                style="display: inline-block"
              >
                <popover placement="top" :content="getRetrofitCounts(reg)" />
              </div>
            </td>
            <td style="text-align: right">
              {{ reg.tradeCount }}
            </td>
            <td style="text-align: right">
              {{ reg.activeRegisteredBusinesses }}
            </td>
            <td style="text-align: right">
              {{ reg.activeLicenceCount }}
            </td>
            <td style="text-align: right">
              {{ reg.inactiveLicenceCount }}
            </td>
            <td style="text-align: right">
              {{ reg.joiners }}
            </td>
            <td style="text-align: right">
              {{ reg.leavers }}
            </td>
            <td style="text-align: right">
              {{ reg.suspendedBusinessCount }}
            </td>
            <td style="text-align: right">
              {{ reg.suspendedNewBusinessCount }}
            </td>
            <td style="text-align: right">
              {{ reg.suspendedAgedBusinessCount }}
            </td>
            <td style="text-align: right">
              {{ reg.suspendedDistinctLicenceCount }}
            </td>
            <td style="text-align: right">
              {{ reg.suspendedNetLicenceCount }}
            </td>
            <td style="text-align: center">
              {{ reg.isDeclaration ? 'Yes' : 'No' }}
            </td>
            <td>
              <span v-if="reg.isDeclaration && reg.updatedBy">
                On {{ $helpers.dateTimeString(reg.updatedAt) }} by {{ reg.updatedBy }}
              </span>
              <span v-else-if="reg.isDeclaration"> Not yet submitted </span>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { SmartButton, SmartSelectKeyValue } from '@trustmark-2005-ltd/trustmark-shared-components';
import ExportBillingRegistrationService from '../../services/export-billing-registration-service';
import Popover from '../Popover.vue';

export default {
  name: 'RegistrationBillingList',
  components: { Popover, SmartButton, SmartSelectKeyValue },
  data() {
    return {
      period: this.currentPeriod(),
      schemeId: 'all',
      loading: false,
      errorMessage: null,
      isExportingByPeriod: false,
    };
  },
  computed: {
    registrations() {
      return this.$store.state.registrationBilling.registrations;
    },
    registrationBilling() {
      return this.$store.state.registrationBilling.registrationBilling;
    },
    schemeIds() {
      const opts = this.$store.state.auth.appData.schemes.map((s) => {
        return { key: s.schemeId, value: s.schemeName };
      });
      opts.unshift({ key: 'all', value: 'All Schemes' });
      return opts;
    },
    periods() {
      let list = this.$store.state.auth.appData.periodFilters;
      list = list.sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
      const opts = list.map((s) => {
        return { key: s, value: `${s.substring(0, 4)} ${s.substring(4, 6)}` };
      });
      opts.unshift({ key: 'all', value: 'All Periods' });
      return opts;
    },
  },
  mounted() {
    if (!this.registrations) {
      this.period = this.currentPeriod();
      this.periodFilterChange();
    }
  },
  methods: {
    periodFilterChange() {
      this.loading = true;
      this.$store
        .dispatch('registrationBilling/listByPeriod', this.period)
        .then(() => {
          this.loading = false;
          this.schemeId = 'all';
          this.isExportingByPeriod = true;
        })
        .catch((err) => {
          this.loading = false;
          this.errorMessage = err;
        });
    },
    schemeFilterChange() {
      if (this.schemeId === 'all') {
        this.period = this.currentPeriod();
        this.periodFilterChange();
        return;
      }
      this.loading = true;
      this.$store
        .dispatch('registrationBilling/listBySchemeId', this.schemeId)
        .then(() => {
          this.loading = false;
          this.period = 'all';
          this.isExportingByPeriod = false;
        })
        .catch((err) => {
          this.loading = false;
          this.errorMessage = err;
          this.exportAddress = `/schemeregistrationbilling/scheme/${this.schemeId}/csv`;
        });
    },
    currentPeriod() {
      const month = (new Date().getMonth() + 1).toString().padStart(2, '0');
      const year = new Date().getFullYear();
      return `${year}${month}`;
    },
    doExport() {
      this.loading = true;

      if (this.isExportingByPeriod) {
        ExportBillingRegistrationService.exportPeriodCsv(this.period)
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        ExportBillingRegistrationService.exportSchemeCsv(this.schemeId)
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    doIndividualExport(period, schemeId) {
      this.loading = true;

      ExportBillingRegistrationService.exportPeriodAndSchemeIdCsv(period, schemeId)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getRetrofitCounts(reg) {
      return `Retrofit Assessors: ${reg.retrofitAssessorCount}<br />Retrofit Coordinators: ${reg.retrofitCoordinatorCount}<br />Suspended Retrofit Assessors: ${reg.suspendedRetrofitAssessorCount}<br />Suspended Retrofit Coordinators: ${reg.suspendedRetrofitCoordinatorCount}`;
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 0 auto;
}

th {
  /* header cell */
  position: sticky;
  top: 0;
  background-color: #71217b;
  border-bottom: 2px solid #71217b;
  color: #ece9e9;
  z-index: 10;
}

tbody tr:nth-child(odd) {
  background-color: #ece9e9;
}

th,
td {
  /* cell */
  padding: 0.75rem;
  font-size: 0.9375rem;
}

tbody {
  max-height: 200px !important;
}
</style>
