<template>
  <div class="card bg-light">
    <div class="card-header">
      Advanced Search
      <span class="float-end sticky-top" style="cursor: pointer" @click="close()">
        <font-awesome-icon icon="xmark" />
      </span>
    </div>
    <div class="card-body">
      <smart-input
        id="modelRegisteredCompanyName"
        v-model="model.registeredCompanyName"
        label="Registered Company Name"
        :is-required="false"
      />

      <smart-input id="modelTMLN" v-model="model.tmln" label="TMLN" :is-required="false" />

      <div class="row">
        <div class="col-6">
          <smart-button
            class-override="btn-outline-secondary"
            :disabled="false"
            :loading="false"
            :click="reset"
          >
            Reset
          </smart-button>
        </div>
        <div class="col-6">
          <smart-submit
            :small="true"
            :disabled="false"
            :loading="loading"
            :click="newAdvancedSearch"
            label="Apply"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SmartInput,
  SmartSubmit,
  SmartButton,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import { constants } from '../../services/scheme-common';

export default {
  name: 'PendingLicenceActionSearchAdvanced',
  components: {
    SmartButton,
    SmartSubmit,
    SmartInput,
  },
  emits: ['reset-requested', 'error-message-changed', 'close'],
  data() {
    return {
      loading: false,
      model: {
        tmln: null,
        registeredCompanyName: null,
      },
      matchTypes: [constants.matchTypes.exact, constants.matchTypes.partial],
    };
  },
  computed: {
    pageNumber() {
      return this.$store.state.licenceNumber.pageNumber;
    },
    lastSearchConfig() {
      return this.$store.state.licenceNumber.lastSearchConfig;
    },
    isAdvancedSearch() {
      return this.lastSearchConfig.type === 'advanced';
    },
  },
  watch: {
    pageNumber(newPageNumber) {
      if (this.isAdvancedSearch) {
        console.log(`advanced pageNumber ${newPageNumber}`);
        this.advancedSearch();
      }
    },
  },
  methods: {
    newAdvancedSearch() {
      this.$store.commit('pendingLicenceAction/setSearchPageNumber', 0, { root: true });
      this.advancedSearch();
    },
    advancedSearch() {
      if (this.loading) return;
      this.$emit('error-message-changed', null);
      this.loading = true;

      const payload = JSON.parse(JSON.stringify(this.model));
      payload.pageParameters = {
        size: 10,
        number: this.pageNumber,
      };
      payload.sort = {
        item: 'registeredCompanyName',
        direction: 'ascending',
      };
      payload.filterType = 0;

      this.$store
        .dispatch('pendingLicenceAction/advancedSearch', payload)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$emit('error-message-changed', err.message);
        });
    },
    reset() {
      this.model.status = null;
      this.$emit('reset-requested', null);
    },
    close() {
      this.$emit('close', null);
    },
  },
};
</script>
