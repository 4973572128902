import SoftwareProviderService from '../services/software-provider-service';

export const softwareProvider = {
  namespaced: true,
  state: {
    softwareProviders: null,
    softwareProvider: null,
    appData: {},
  },
  actions: {
    listSoftwareProviders({ commit }) {
      commit('setIsBusy', true, {
        root: true,
      });
      return SoftwareProviderService.list().then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setSoftwareProviders', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    getSoftwareProviderById({ commit }, retrofitAccountId) {
      commit('setIsBusy', true, {
        root: true,
      });
      commit('setSoftwareProvider', null);
      return SoftwareProviderService.get(retrofitAccountId).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setSoftwareProvider', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    enableAccount({ commit }, retrofitAccountId) {
      commit('setIsBusy', true, {
        root: true,
      });
      return SoftwareProviderService.enableAccount(retrofitAccountId).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setSoftwareProvider', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    disableAccount({ commit }, retrofitAccountId) {
      commit('setIsBusy', true, {
        root: true,
      });
      return SoftwareProviderService.disableAccount(retrofitAccountId).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setSoftwareProvider', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    deleteSoftwareProvider({ commit }, retrofitAccountId) {
      commit('setIsBusy', true, {
        root: true,
      });
      return SoftwareProviderService.deleteSoftwareProvider(retrofitAccountId).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    createRetrofitAccount({ commit }, payload) {
      commit('setIsBusy', true, {
        root: true,
      });
      commit('setSoftwareProvider', null);
      payload.retrofitAccountType = 'SoftwareProvider';
      payload.isSoftwareProvider = true;
      return SoftwareProviderService.post(payload).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setSoftwareProvider', result.model);

          SoftwareProviderService.list().then((resp) => {
            commit('setSoftwareProviders', resp.model);
          });

          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    addUser({ commit }, { retrofitAccountId, accountId, firstName, lastName, roles }) {
      commit('setIsBusy', true, {
        root: true,
      });
      return SoftwareProviderService.user(retrofitAccountId, {
        accountId,
        firstName,
        lastName,
        roles,
      }).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setSoftwareProvider', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    getAppData({ commit }) {
      commit('setIsBusy', true, {
        root: true,
      });
      return SoftwareProviderService.appData().then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setRetrofitAccountAppData', result);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setSoftwareProvider(state, newSoftwareProvider) {
      state.softwareProvider = newSoftwareProvider;
    },
    setRetrofitAccountAppData(state, newAppData) {
      state.appData = newAppData;
    },
    setSoftwareProviders(state, newSoftwareProviders) {
      state.softwareProviders = newSoftwareProviders;
    },
  },
};
