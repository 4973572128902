import SchemeBusinessEmailService from '../services/scheme-business-email-service';

export const schemeBusinessEmail = {
  namespaced: true,
  state: {
    emails: null,
  },
  actions: {
    listEmails({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeBusinessEmailService.list(payload.schemeId, payload.schemeBusinessId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setEmails', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    resendEmail({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return SchemeBusinessEmailService.resend(
        payload.schemeId,
        payload.schemeBusinessId,
        payload.emailId
      )
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setEmails(state, newEmails) {
      state.emails = newEmails;
    },
  },
};
