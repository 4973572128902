import { HTTP, authHeader } from './http-service';

class TradeCheckService {
  get(tmln, scheme) {
    return HTTP.post(`/TradeCheck/scheme/${scheme}/tmln/${tmln}`, null, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new TradeCheckService();
